<header class="unity-header">
    <div class="unity-header-left">
        <div class="unity-header-item app-switcher">
            <svg class="icon">
                <defs>
                    <linearGradient id="insurity-i-gradient" x1="0" x2="0" y1="0" y2="1">
                        <stop class="stop1" offset="0%" />
                        <stop class="stop2" offset="100%" />
                    </linearGradient>
                </defs>
                <g class="i">
                    <path class="stem" d="M9.487 8.46h5.043v15.54h-5.043v-15.54z"></path>
                    <path
                        class="circle"
                        d="M12.010 4.816c-0.897 0-1.626-0.73-1.626-1.626s0.729-1.626 1.626-1.626 1.626 0.73 1.626 1.626c0 0.897-0.729 1.626-1.626 1.626zM12.010 0c-1.762 0-3.19 1.428-3.19 3.19s1.428 3.19 3.19 3.19 3.19-1.428 3.19-3.19c0-1.761-1.428-3.19-3.19-3.19z"
                    ></path>
                </g>
                <g class="app-switcher">
                    <path
                        class="dot"
                        d="M24 21c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                    ></path>
                    <path
                        class="dot"
                        d="M24 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                    ></path>
                    <path
                        class="dot"
                        d="M24 3c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                    ></path>
                    <path class="stem" d="M9.487 8.46h5.043v15.54h-5.043v-15.54z"></path>
                    <path
                        class="circle"
                        d="M12.010 4.816c-0.897 0-1.626-0.73-1.626-1.626s0.729-1.626 1.626-1.626 1.626 0.73 1.626 1.626c0 0.897-0.729 1.626-1.626 1.626zM12.010 0c-1.762 0-3.19 1.428-3.19 3.19s1.428 3.19 3.19 3.19 3.19-1.428 3.19-3.19c0-1.761-1.428-3.19-3.19-3.19z"
                    ></path>
                    <path
                        class="dot"
                        d="M6 21c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                    ></path>
                    <path
                        class="dot"
                        d="M6 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"
                    ></path>
                    <path class="dot" d="M6 3c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z"></path>
                </g>
            </svg>
        </div>
        <h1 class="unity-header-item app-name" id="underwritingLogo" (click)="onLogoClick()">Sure Underwriting Workbench</h1>
        <div id="unityTenant" class="unity-header-item app-area" *ngIf="currentTenant">
            <button unity-button *ngIf="availableTenants?.length > 1; else staticTenant" class="link" (click)="onTenantClick()">
                {{currentTenant}}
                <svg class="unity-icon unity-icon-chevron-down"><use xlink:href="#unity-icon-chevron-down"></use></svg>
            </button>
            <ng-template #staticTenant>{{currentTenant}}</ng-template>
        </div>
        <div class="unity-header-item work-area" *ngIf="currentAreaOrConfig">
            <unity-badge>
                <button unity-button *ngIf="availableAreas?.length > 1; else staticArea" class="link on_dark" (click)="onAreaClick()">
                    {{currentAreaOrConfig}}
                    <svg class="unity-icon unity-icon-chevron-down"><use xlink:href="#unity-icon-chevron-down"></use></svg>
                </button>
                <ng-template #staticArea>{{currentAreaOrConfig}}</ng-template>
            </unity-badge>
        </div>
        <div class="unity-header-item">
            <ng-content select="[main]"></ng-content>
        </div>
    </div>
    <div class="unity-header-right">
        <div class="unity-header--item">
            <user-task-timer></user-task-timer>
        </div>
        <div class="unity-header-item">
            <ng-content select="[right]"></ng-content>
        </div>
        <ng-container *ngIf="view != 'Admin'">
            <div class="unity-header-item">
                <button
                    unity-button
                    id="notificationsButton"
                    *ngIf="currentAreaOrConfig"
                    class="main-menu-button icon-only on_dark"
                    [ngbPopover]="notifications"
                    placement="bottom-right"
                    popoverClass="notifications-popover"
                    #notifyPopover="ngbPopover"
                    [autoClose]="'outside'"
                    (hidden)="handleDeletedNotifications()"
                    [badgeCount]="getUnreadNotificationsCount()"
                >
                    <svg class="unity-icon-bell">
                        <use xlink:href="#unity-icon-bell"></use>
                    </svg>
                </button>
                <ng-template #notifications>
                    <sm-global-notifications
                        [settings]="notificationCenter"
                        [notificationCards]="notificationCardVMs"
                        (onClickLink)="onClickLink($event)"
                        (onClose)="handleReadNotifications($event)"
                    />
                </ng-template>
            </div>
        </ng-container>
        <div class="unity-header-item">
            <sm-sync-status *ngIf="syncStatus" [status]="syncStatus" [placement]="'bottom-right'"></sm-sync-status>
        </div>
        <div class="unity-header-actions">
            <div class="unity-header-item">
                <button unity-button
                        *ngIf="!hideMenu"
                        id="mainMenuButton"
                        class="main-menu-button icon-only on_dark"
                        placement="bottom-right"
                        #p1="ngbPopover"
                        (document:click)="p1.close()"
                        (click)="$event.stopPropagation()"
                        [ngbPopover]="mainMenuPop">
                    <svg class="unity-icon unity-icon-more-vertical"><use xlink:href="#unity-icon-more-vertical"></use></svg>
                </button>
                <ng-template #mainMenuPop>
                    <sm-main-menu [view]="view"></sm-main-menu>
                </ng-template>
            </div>
        </div>
        <div class="unity-header--item">
            <unity-avatar id="unityAvatar" [model]="badge" role="button" placement="bottom-right" [autoClose]="'outside'" [ngbPopover]="accountPop"></unity-avatar>
            <ng-template #accountPop>
                <unity-user-menu [adminUser]="adminUser"></unity-user-menu>
            </ng-template>
        </div>
    </div>
</header>

