<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>    
        <select 
            [id]="getInputIdOrName()"
            class="form-select form-control" 
            [ngClass]="getInputCssClass()"
            [disabled]="model.Disabled"       
            [(ngModel)]="model.Value"
            (change)="valueChange($event)"
        >
            <option value="" disabled selected hidden>Please Select</option>
            <option *ngFor="let option of getOptions()">{{option}}</option>
        </select>
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>        
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>