import { Component, DestroyRef } from '@angular/core';
import { Task, Workflow } from '@Core/CodeGen/Models/area.models';
import { UserContext } from '@Core/Lib/Contexts/user-context';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import _ from "lodash";
import { TaskTimer, TaskUtils } from '@Core/Lib/Utils/task-utils';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'user-task-timer',
    templateUrl: './user-task-timer.component.html'
})
export class UserTaskTimerComponent {
    private ngUnsubscribe: Subject<boolean> = new Subject();

    public userTaskTimer: TaskTimer = null;
    public workflow: Workflow = null;

    constructor(
        private userContext: UserContext,
        private router: Router,
        private destroyRef: DestroyRef
    ) { }

    ngOnInit(): void {
        combineLatest([
            this.userContext.getStore<Task>(new Task()).values,
            this.userContext.getStore<Workflow>(new Workflow()).values
        ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([tasks, workflows]) => {
            const filteredTasks = this.filterTasks(tasks);

            this.userTaskTimer = TaskUtils.getMostImminentTimerInTaskList(filteredTasks);
            this.workflow = _.find(workflows, (workflow) => workflow.Id == this.userTaskTimer?.WorkflowId);
        });
    }

    filterTasks(tasks: Task[] = []): any {
        const currDate = new Date();

        let filteredTasks = _.filter(tasks, (task) => {
            if (
                task.ObligationStatus == "Required" 
                && task.CompletionStatus == "Incomplete" 
                && task.StartDate <= currDate 
                && (task.AvailabilityStatus == "Available" || task.AvailabilityStatus == "Locked")
            ) {
                return task;
            }  
        });

        return filteredTasks;
    }
    
    onTaskTimerClick(): void {
        if (this.workflow && this.workflow.IsRunningFor().length) {
            const accountId = ModelUtils.getIdFromDomainId(this.workflow.IsRunningFor()[0].Out);
            this.router.navigate(['Work', 'Account', accountId, 'Workflow', this.workflow.Id]);
        }
    }
};