import { Injectable } from '@angular/core';
import { Section } from '@Core/CodeGen/Models/area.models';

import _ from 'lodash';
import { NgxMaskService } from 'ngx-mask';
import { Observable, Subject } from 'rxjs';
import { SmInputZipcodeComponent } from '../inputs/sm-input-zipcode/sm-input-zipcode.component';
import { SmInputNumberComponent } from '../inputs/sm-input-number/sm-input-number.component';
import { SmFormFieldOptionsVM, SmFormFieldVM, SmFormTypes } from './sm-form-field.component';
import { SmFormSectionInstanceVM } from './sm-form-section-instance.component';
import { SmFormSectionVM } from './sm-form-section.component';
import { SmFormElementVM, SmFormState, SmFormVM } from './sm-form.component';
import { SmDateFormats, SmDatePipe } from '@Shared/Pipes/sm-date.pipe';
import { FormatUtils } from "@Core/Lib/Utils/format-utils";
import { SmInputMoneyComponent } from '../inputs/sm-input-money/sm-input-money.component';

/// Used to alert fields/sections that they have been changed.
@Injectable({
    providedIn: 'root',
})
export class SmFormService{

    constructor(private maskService: NgxMaskService) { }
    
    // #region Events

    // #region Element Changed

    private _elementChanged$ = new Subject<SmElementChangedEvent>();

    public getElementChanged(): Observable<SmElementChangedEvent>  {
        return this._elementChanged$;
    }

    public sendElementChanged(elementVM: SmFormElementVM) {
        this._elementChanged$.next({ ElementId: elementVM.Id, ElementVM: elementVM });
    }

    // #endregion Element Changed

    // #region Section Added

    private _sectionAdded$ = new Subject<SmSectionAddedEvent>();

    public getSectionAdded(): Observable<SmSectionAddedEvent>  {
        return this._sectionAdded$;
    }

    public sendSectionAdded(sectionVM: SmFormSectionVM) {
        this._sectionAdded$.next({ SectionVM: sectionVM});       
    }

    private _openSectionInstance$ = new Subject<SmOpenSectionInstanceEvent>();

    public getOpenSectionInstance(): Observable<SmOpenSectionInstanceEvent>  {
        return this._openSectionInstance$;
    }

    public sendOpenSectionInstance(instanceId: string) {
        this._openSectionInstance$.next({ InstanceId: instanceId });
    }

    // #endregion Section Added

    // #region Section Deleted

    private _sectionDeleted$ = new Subject<SmSectionDeletedEvent>();

    public getSectionDeleted(): Observable<SmSectionDeletedEvent>  {
        return this._sectionDeleted$;
    }

    public sendSectionDeleted(sectionVM: SmFormSectionVM, instanceId: string) {
        this._sectionDeleted$.next({SectionVM: sectionVM, InstanceId: instanceId });
    }

    // #endregion Section Deleted

    // #region Element Focused

    private _elementFocused$ = new Subject<SmElementFocusedEvent>();

    public getElementFocused(): Observable<SmElementFocusedEvent>  {
        return this._elementFocused$;
    }

    public sendElementFocused(elementId: string, type: 'Section' | 'Field' | 'ParentSection') {
        this._elementFocused$.next({ Id: elementId, Type: type });
    }
    
    // #endregion Element Focused

    // #endregion Events

    // #region Formatting Fields
        
    public formatFieldValue(value: any, 
        type: SmFormTypes, 
        options: SmFormFieldOptionsVM,
        state: SmFormState,
        prefix: string = null, 
        suffix: any = null) {

        switch (type) {
            // No formatting
            case SmFormTypes.Boolean:
            case SmFormTypes.Email:
            case SmFormTypes.String:
            case SmFormTypes.Select:
            case SmFormTypes.Phone:
            case SmFormTypes.Phone:
            case SmFormTypes.Year:
            case SmFormTypes.RadioOptions:
                return this.applyPrefixAndSuffixToFieldValue(value, prefix, suffix);
            case SmFormTypes.Url:
                var display = options?.ShowUrlInViewOnly === false && value ? "Link" : value;
                return this.applyPrefixAndSuffixToFieldValue(display, prefix, suffix);

            case SmFormTypes.ContractDate:
                var format = options?.DateFormat ?? SmDateFormats.Long;
                let datePipe = new SmDatePipe();
                return this.applyPrefixAndSuffixToFieldValue(datePipe.transform(value, format), prefix, suffix);

            case SmFormTypes.Decimal: 
                this.maskService.thousandSeparator = FormatUtils.getThousandSeparator();
                this.maskService.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";
                var convertedDecimal = SmInputNumberComponent.formatNumber(value,
                    this.maskService,
                    options?.NumberOfDecimals ?? 3,
                    options?.DisplayAsPercentage ?? false);

                    return this.applyPrefixAndSuffixToFieldValue(convertedDecimal, prefix, suffix)
            case SmFormTypes.Integer:
                if (_.isNil(value) || value === '')
                    return this.applyPrefixAndSuffixToFieldValue('', prefix, suffix);
        
                this.maskService.thousandSeparator = FormatUtils.getThousandSeparator();
                this.maskService.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";
                var convertedInteger = SmInputNumberComponent.formatNumber(value,
                    this.maskService,
                    0, 
                    false);
                return this.applyPrefixAndSuffixToFieldValue(convertedInteger, prefix, suffix);

            case SmFormTypes.Money:
                this.maskService.thousandSeparator = FormatUtils.getThousandSeparator();
                this.maskService.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";

                var convertedMoney = SmInputMoneyComponent.formatMoney(value,
                    this.maskService,
                    options?.DisplayAsWholeNumber ?? false,
                    options?.Currency ?? state?.Currency ?? 'USD');

                return this.applyPrefixAndSuffixToFieldValue(convertedMoney, prefix, suffix);
            case SmFormTypes.MultiSelect:
                if (_.isNil(value) || value == "") {
                    return this.applyPrefixAndSuffixToFieldValue('', prefix, suffix);
                } else if (_.isArray(value)) {
                    return this.applyPrefixAndSuffixToFieldValue(value.join(", "), prefix, suffix);
                } else {
                    return this.applyPrefixAndSuffixToFieldValue(value, prefix, suffix);
                }

            case SmFormTypes.Timestamp: 
                let datePipeForTimestamp = new SmDatePipe();
                var dateString = datePipeForTimestamp.transform(value, SmDateFormats.Timestamp);
                return this.applyPrefixAndSuffixToFieldValue(dateString, prefix, suffix);
            case SmFormTypes.Zip:
                var convertedZipcode = SmInputZipcodeComponent.formatZipcode(value,
                    this.maskService);

                return this.applyPrefixAndSuffixToFieldValue(convertedZipcode, prefix, suffix)
            default:
                console.error("Unsupported type in formatFieldValue().");

        }
    }

    // #endregion Formatting Fields

    // #region Participation Status

    public getSectionParticipationStatus(section: Section, 
        childrenVMs: SmFormElementVM[],
        formState: SmFormState): 'Added' | 'Changed' | 'Deleted' {
        var participationStatus;

        if (section.ParticipationStatus == "Added" || section.ParticipationStatus == "Reinstated") {
            participationStatus = 'Added';
        } else if (section.ParticipationStatus == "Deleted") {
            participationStatus = 'Deleted';
        } else if (section.ParticipationStatus == "PreviouslyAdded" && this.itemsHavePreviousValue(childrenVMs, formState)) {
            participationStatus = 'Changed';
        }

        return participationStatus;
    }

    public fieldHasPreviousValue(fieldVM: SmFormFieldVM, formState: SmFormState) {
        if ((formState == null || formState.IsStartingOrCopy) ||
            (_.isNil(fieldVM.PreviousValue) && !fieldVM.Value))
            return false;
        
        var formattedValue = this.formatFieldValue(fieldVM.Value, fieldVM.FieldType, fieldVM.Options, formState);
        var formattedPrevValue = this.formatFieldValue(fieldVM.PreviousValue, fieldVM.FieldType, fieldVM.Options, formState);
            
        return formattedValue !== formattedPrevValue;
    }

    public itemsHavePreviousValue(items: SmFormElementVM[], formState: SmFormState) {
        var fields = _.filter(items, i => i.ElementType == 'Field') as SmFormFieldVM[];
        var sections = _.filter(items, i => i.ElementType == 'Section') as SmFormSectionVM[];

        var hasPrevValue = false;

        hasPrevValue = _.some(fields, f => this.fieldHasPreviousValue(f, formState));

        if (hasPrevValue)
            return true;

        hasPrevValue = _.some(sections, s => this.sectionHasChildWithPreviousValue(s, formState));
        return hasPrevValue;
    }

    public sectionHasChildWithPreviousValue(section: SmFormSectionVM, formState: SmFormState): boolean {

        if (section.Repeatable) {
            return _.some(section.Instances, i => {
                return i.ParticipationStatus == 'Added' || i.ParticipationStatus == 'Deleted' 
                    || this.itemsHavePreviousValue(i.Items, formState)
            });
        } else {
            return this.itemsHavePreviousValue(section.Items, formState);
        }
    }

    // #endregion Participation Status

    // #region Errors

    public childrenHaveAtLeastOneError(children: SmFormElementVM[]): boolean {
        if (!children?.length)
            return false;

        var childFields = _.filter(children, i => i.ElementType == 'Field') as SmFormFieldVM[];
        var childSections = _.filter(children, i => i.ElementType == 'Section') as SmFormSectionVM[];
        var hasError = _.some(childFields, cf => this.fieldHasError(cf));
        hasError = hasError || _.some(childSections, cs => this.sectionHasError(cs));

        return hasError;
    }

    public instancesHaveAtLeastOneError(instances: SmFormSectionInstanceVM[]) {
        return _.some(instances, i => this.childrenHaveAtLeastOneError(i.Items));
    }

    public sectionHasError(section: SmFormSectionVM) {       
        if (section.Hidden || section.ParentIsDeleted)
            return false;

        if (section.Repeatable) {
            if (this.repeatableSectionHasMinError(section) || this.repeatableSectionHasMaxError(section))
                return true;

            return this.instancesHaveAtLeastOneError(section.Instances);  
        } else {
            return this.childrenHaveAtLeastOneError(section.Items);    
        }
    }

    public fieldHasError(field: SmFormFieldVM) {
        return !field.Hidden && field.Errors && field.Errors.length > 0;
    }

    public repeatableSectionHasMinError(section: SmFormSectionVM): boolean {
        if (section.Hidden || !section.Repeatable || section.ParentIsDeleted ||  _.isNil(section.MinRepeatableInstances))
            return false;
        
        var instanceCount = _.filter(section.Instances, i => i.ParticipationStatus != 'Deleted').length;
        return instanceCount < section.MinRepeatableInstances;
    }

    public repeatableSectionHasMaxError(section: SmFormSectionVM): boolean {
        if (section.Hidden || !section.Repeatable || section.ParentIsDeleted || _.isNil(section.MaxRepeatableInstances))
            return false;

        var instanceCount = _.filter(section.Instances, i => i.ParticipationStatus != 'Deleted').length;
        return instanceCount > section.MaxRepeatableInstances;
    }

    // #endregion Errors

    // #region Expansion

    public expandAllAncestorsOfElement(form: SmFormVM, id: string) {
        var sections = form.Sections;

        _.forEach(sections, section => {
            this.sectionIsAncestorOfElement(section, id, true);
        });
    }

    // #endregion

    // #region Traversal

    public sectionIsAncestorOfElement(section: SmFormSectionVM, id: string, expandIfTrue: boolean = false): boolean {     
        var found = false;

        if (section.Repeatable) {
            if (_.some(section.Instances, instance => instance.Id == id || this.instanceIsAncestorOfElement(instance, id, expandIfTrue))) 
                found = true;
        } else {
            if (_.some(section.Items, i => i.Id == id)) {
                found = true;
            } else {
                var childSections = _.filter(section.Items, i => i.ElementType == 'Section') as SmFormSectionVM[];
                if (_.some(childSections, childSection => this.sectionIsAncestorOfElement(childSection, id, expandIfTrue)))            
                    found = true;
            } 
        }

        if (found && expandIfTrue)
            section.ToggledToHide = false;
        return found;
    }

    public instanceIsAncestorOfElement(instance: SmFormSectionInstanceVM, id: string, expandIfTrue: boolean = false): boolean {
        var found = false;

        if (_.some(instance.Items, i => i.Id == id)) {
            found = true;
        } else {
            var childSections = _.filter(instance.Items, i => i.ElementType == 'Section') as SmFormSectionVM[];
            if (_.some(childSections, childSection => this.sectionIsAncestorOfElement(childSection, id, expandIfTrue)))
                found = true;
        }
        
        if (found && expandIfTrue)
            instance.ToggledToHide = false;
        return found;
    }

    // #endregion Traversal

    public applyPrefixAndSuffixToFieldValue(value: string, 
        prefix: string = null, 
        suffix: string = null): any {

        let formattedValue: string = value;

        if (prefix && prefix.trim() !== "") {
            formattedValue = prefix + formattedValue;
        }

        if ((suffix && suffix.trim() !== "")) {
            formattedValue = formattedValue + suffix;
        }

        return formattedValue;
    }
    
}


export interface SmElementChangedEvent {
    ElementId: string;
    ElementVM: SmFormElementVM;
}

export interface SmSectionAddedEvent {
    SectionVM: SmFormSectionVM;
}

export interface SmOpenSectionInstanceEvent {
    InstanceId: string;
}

export interface SmSectionDeletedEvent {
    SectionVM: SmFormSectionVM;
    InstanceId: string;
}

export interface SmElementFocusedEvent {
    Id: string;
    // Field: an invalid/required field
    // Section: a max/min error on the FE representation of the repeatable section grouping. 
    // ParentSection: This is the parent of the min/max issue (this is the Id the backend stores)
    Type: 'Section' | 'Field' | 'ParentSection'; 
}