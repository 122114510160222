import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import _ from 'lodash';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { BaseService } from '@Services/base-service';
import { LoggingService } from '@Services/logging-service';
import { GlobalMessages, } from '@Services/global-messages';
import { LoadingService } from '@Services/loading-service';

import { UserContext } from '@Core/Lib/Contexts/user-context';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import {
    Program, SMIdentity, 
    Account, Task, ReservationSet, WorkGroup, SMIdentityWorksAccount 
} from '@Core/CodeGen/Models/area.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class UserWorkflowsService extends BaseService {

    private serviceUrls = class ServiceUrls {
        public static baseUrl: string = BaseService.baseUrl + '/Workflows/';

        public static Programs: string = ServiceUrls.baseUrl + 'Programs';
        public static TenantUsers: string = ServiceUrls.baseUrl + 'TenantUsers';
        public static Accounts: string = BaseService.baseUrl + '/Accounts';
        public static AccountById(accountId: string) {
            return this.Accounts + `/${accountId}`;
        }

        public static Phases: string = ServiceUrls.baseUrl + 'Phases';
        public static ReservationSets: string = ServiceUrls.baseUrl + 'ReservationSets';
        public static WorkGroups: string = ServiceUrls.baseUrl + 'WorkGroups';
    }

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        private userContext: UserContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    loadPrograms(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Programs).pipe(share());
        this.handleNormalGetRequest(Program, request, this.userContext);
        return request;
    }

    loadUsers(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.TenantUsers).pipe(share());
        this.handleNormalGetRequest(SMIdentity, request, this.userContext);
        return request;
    }

    loadAccounts(): Observable<Response> {
        const cntxt = this.userContext;
        const params = new HttpParams()
            .set("includeTasks", 'true');
        const request = this.http.get<any>(this.serviceUrls.Accounts, { params }).pipe(share());

        request.subscribe({
            next: response => {
                const accountResponse = { Content: response.Content.accounts };
                const tasksReponse = { Content: response.Content.tasks };
                cntxt.loadApiResponseModels(Account, accountResponse);
                cntxt.loadApiResponseModels(Task, tasksReponse);
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    loadWorkGroups(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.WorkGroups).pipe(share());
        this.handleNormalGetRequest(WorkGroup, request, this.userContext);
        return request;
    }

    //#region Work Group Reservations

    loadReservationSets(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.ReservationSets).pipe(share());
        this.handleNormalGetRequest(ReservationSet, request, this.userContext);
        return request;
    }

    startWorkingWorkGroup(workGroupId: string): Observable<Response> {
        const request = this.http.post<any>(this.serviceUrls.ReservationSets, JSON.stringify(workGroupId)).pipe(share());
        this.handleNormalGetRequest(ReservationSet, request, this.userContext);
        return request;
    }

    stopWorkingWorkGroup(reservationSet: ReservationSet): Observable<Response> {
        const request = this.http.delete<any>(this.serviceUrls.ReservationSets + "/" + reservationSet.Id).pipe(share());
        this.handleNormalDeleteRequest(ReservationSet, request, reservationSet, this.userContext);
        return request;
    }

    reserveAccount(reservationSet: ReservationSet): Observable<Response> {
        const request = this.http.post<any>(this.serviceUrls.ReservationSets + "/" + reservationSet.Id + "/Reserve", null).pipe(share());
        request.subscribe({
            next: response => {
                const accountResponse = { Content: response.Content.account };
                const tasksReponse = { Content: response.Content.tasks };
                this.userContext.loadApiResponseModels(Account, accountResponse);
                this.userContext.loadApiResponseModels(Task, tasksReponse);
            },
            error:error => {
                this.handleError(error);
            }
        });
        return request;
    }

    getWorkGroup(workGroupId: string): Observable<Response> {
        const params = new HttpParams()
            .set("updateNumUnreservedAccounts", 'true');
        const request = this.http.get<any>(this.serviceUrls.WorkGroups + "/" + workGroupId, { params }).pipe(share());
        this.handleNormalGetRequest(WorkGroup, request, this.userContext);
        return request;
    }

    //#endregion Work Group Reservations
    
}