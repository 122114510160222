import { Injectable } from "@angular/core";

import _ from 'lodash';

import { DataContext } from '@Core/Lib/Contexts/data-context';

// UserContext - Singleton (injectable).  Applies to the UW dashboard for the logged in user.
// If the user switches tenants, it is assumed that this will be reset.
@Injectable({
    providedIn: 'root',
})
export class UserContext extends DataContext {    
    // All data except Accounts
    public quickDataLoaded: boolean = false;    
    // Accounts Loaded tracked separately. we resolve the Accounts data into the context at a separate time
    // because it can take a lot longer to load.
    public accountsLoaded: boolean = false;

    public everythingLoaded(): boolean {
        return this.quickDataLoaded && this.accountsLoaded;        
    }
    
    constructor() {
        super();
    }
}