<ng-container *ngIf="!readOnly">
    <input
        [id]="id"
        class="form-control"
        [(ngModel)]="inputValue"
        [ngClass]="applyclasses()"
        [mask]="componentMask"
        placeholder="{{displayAsPercentage ? '%' : ''}}"
        suffix="{{displayAsPercentage ? '%' : ''}}"
        [thousandSeparator]="thousandSeparator"
        [decimalMarker]="decimalMarker"
        [disabled]="disabled"
        [allowNegativeNumbers]="true"
        (ngModelChange)="valueChange($event)"
        (focusin)="focusin.emit($event)"
        (focusout)="focusout.emit($event)"
    />
</ng-container>
<ng-container *ngIf="readOnly">
    <span [id]="id" [class]="formatNegatives() ? 'text-danger' : ''">
        {{ inputValue | mask:componentMask:{thousandSeparator: thousandSeparator, decimalMarker: decimalMarker} }}<ng-container *ngIf="displayAsPercentage">%</ng-container>
    </span>
</ng-container>