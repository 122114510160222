import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { SmFormFieldVM } from "./sm-form-field.component";
import { SmFormSectionInstanceVM } from "./sm-form-section-instance.component";
import { SmFormService } from "./sm-form-service";
import { SmFormState } from "./sm-form.component";
import { SmFormRowVM } from "./rows/sm-form-row.component";

@Component({
    selector: 'sm-form-section-modal',
    templateUrl: './sm-form-section-modal.component.html',
})
export class SmFormSectionModalComponent  {

    @Input() model: SmFormSectionInstanceVM;
    @Input() elementToFocus: string;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<any> = new EventEmitter();
    // Propagate whenever a change happens that merits parent components to recheck statuses.
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();
    
    public elements: (SmFormFieldVM | SmFormRowVM)[] = [];

    constructor(public activeModal: NgbActiveModal,
        private formService: SmFormService) { }

    ngOnInit() {
        this.elements = this.model.Items.filter(i => {
            return i.ElementType == 'Field' || i.ElementType == 'Row';
        }) as (SmFormFieldVM | SmFormRowVM)[];          
    }

    ngAfterViewInit() {
        if (this.elementToFocus) {
            this.formService.sendElementFocused(this.elementToFocus, 'Field');
        } 
    }
    
    public getTitle() {
        var firstElement = this.elements[0];
        if (firstElement && firstElement.ElementType == "Field") {
            return (firstElement as SmFormFieldVM).Value;
        }
    }
}