import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";
import { InfoLineState } from "@Shared/Components/sm-info-stack/sm-info-stack.component";

@Component({
    selector: 'sm-form-field-email',
    templateUrl: './sm-form-field-email.component.html',
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmFormFieldEmailComponent extends SmFormFieldBase {
    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    public infoStackState: InfoLineState = 'Email';
}