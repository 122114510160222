import { Directive, TemplateRef, ViewContainerRef, Input, ChangeDetectorRef, DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService } from "@Services/auth-service";
import { FeatureFacetService } from "@Services/feature-facet.service";
import _ from "lodash";

// This is used for tenant or area-level access. Do not use these for
// any user-level permissions (use sm-permissions for that). 
@Directive({
    selector: '[sm-tenant-has-facet]',
})
export class TenantHasFacetDirective {
    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService,
        private facetService: FeatureFacetService,
        private cdRef: ChangeDetectorRef,
        private destroyRef: DestroyRef) 
    {}

    @Input('sm-tenant-has-facet') set hasPermission(facetId: string) {
        this.facetService.getAllFacets()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(allFacets => {
            this.viewContainer.clear();
            if (FeatureFacetService.checkIfFacetExists(facetId, allFacets, this.authService.spaName, this.authService.getAreaKey())){
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            // This directive may be in an OnPush component. Force change detection
            this.cdRef.detectChanges();
        });    
    }
}

@Directive({
    selector: '[sm-tenant-has-not-facet]',
})
export class TenantHasNotFacetDirective {

    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService,
        private facetService: FeatureFacetService,
        private cdRef: ChangeDetectorRef,
        private destroyRef: DestroyRef) 
    {}

    @Input('sm-tenant-has-not-facet') set hasPermission(facetId: string) {
        this.facetService.getAllFacets()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(allFacets => {
            this.viewContainer.clear();

            if (!FeatureFacetService.checkIfFacetExists(facetId, allFacets, this.authService.spaName, this.authService.getAreaKey())){
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            // This directive may be in an OnPush component. Force change detection
            this.cdRef.detectChanges();
        });    
    }
}