import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";
import _ from "lodash";

@Component({
    selector: 'sm-form-field-date',
    templateUrl: './sm-form-field-date.component.html',
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// The Value should be a ContractDate
export class SmFormFieldDateComponent extends SmFormFieldBase {
    public valueAsDate: Date;

    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    public ngOnInit() {
        this.setValueAsDate();
        super.ngOnInit();
    }

    public ngOnChanges() {
        this.setValueAsDate();
    }

    private setValueAsDate() {
        if (!this.model.Value)
            this.valueAsDate = null;
        else
            this.valueAsDate = new Date(this.model.Value);
    }

    change(newValue: string) {
        this.model.Value = newValue;
        super.valueChange(this.model.Value, "input");
    }
}