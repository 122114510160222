/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RetryInterceptor } from './retry-http-interceptor';
import { ContentTypeInterceptor } from './content-type-http-interceptor';
import { AuthInterceptor } from './auth-http-interceptor';
import { LocalProxyRouteInterceptor } from './local-proxy-route-http-interceptor';
import { environment } from 'src/environments/environment';

/** Http interceptor providers in outside-in order */
export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
  environment.env === 'local' ? // only include this interceptor in local environments
    { provide: HTTP_INTERCEPTORS, useClass: LocalProxyRouteInterceptor, multi: true } : []
];