import { Injectable } from "@angular/core";

import { Observable, Subject, of, ReplaySubject } from "rxjs";
import { NgxPermissionsService } from "ngx-permissions";
import { share, mergeMap } from "rxjs/operators";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { TenantUser } from '@Core/CodeGen/Models/configuration.models';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { PermissionsShared } from "@Shared/Directives/sm-permissions-shared.class";
import { AuthService } from "@Services/auth-service";
import { UserContext } from "@Core/Lib/Contexts/user-context";
import { AdminUser } from "@Core/CodeGen/Models/admin.models";
import { environment } from "src/environments/environment";

class Response extends HttpResponse<any>{ }

@Injectable({ providedIn: 'root' })
export class UserService {
    private serviceUrls = class ServiceUrls {
        public static baseServiceUrl: string = '/';

        public static CurrentUser: string = ServiceUrls.baseServiceUrl + 'Tenant/CurrentUserInfo';
        public static ChangePassword: string = ServiceUrls.baseServiceUrl + 'Tenant/Users/ChangePassword';
        public static CurrentAdminUser: string = ServiceUrls.baseServiceUrl + 'Admin/CurrentAdminUserInfo';
    }

    private _user$: Subject<TenantUser>;
    private _adminUser$: Subject<AdminUser>;
    private _permissions: Subject<string[]>;

    constructor(
        private http: HttpClient,
        private permissionsService: NgxPermissionsService,
        private tenantContext: TenantContext,
        private authService: AuthService,
        private userContext: UserContext
    ) {
        if (!environment.requireAPIM) {
            this.serviceUrls.CurrentAdminUser = this.serviceUrls.baseServiceUrl + 'Admin/CurrentAdminUserInfo';
        }
    }

    private getUser() {
        let baseUrl = this.getBaseUrl();
        let url = baseUrl + this.serviceUrls.CurrentUser;
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe({
            next: response => {
                let user = new TenantUser().deserialize(response.Content, this.tenantContext);
                const permissions: string[] = response.Content['@Permissions'];
                if (!this._permissions) {
                    this._permissions = new ReplaySubject<string[]>();
                }            
                this._permissions.next(permissions);
                this.permissionsService.loadPermissions(permissions);
                if (!this._user$) {
                    this._user$ = new ReplaySubject<TenantUser>();
                }
                this._user$.next(user);
                return user;
            }, 
            error: (error) => {
                this.handleError(error)
            }
        });
        return request;
    }

    // Only for tenant user (Don't use in Admin)
    get User$(): Observable<TenantUser> {
        if (!this._user$) {
            this._user$ = new ReplaySubject<TenantUser>(1);
            if (this.authService.getSubscriptionKey()) {
                this.getUser();
            } else {
                // wait 2 seconds to get the user, so that
                // the authService can get the subscriptionKey first
                setTimeout(() => {
                    this.getUser();
                }, 2000);
            }
        }

        return this._user$;
    }

    private getAdminUser() {
        let baseUrl = this.getBaseUrl();
        let url = baseUrl + this.serviceUrls.CurrentAdminUser;
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe({ 
            next: response => {
                let user = new AdminUser().deserialize(response.Content, this.userContext);
                const permissions: string[] = response.Content['@Permissions'];
                if (!this._permissions) {
                    this._permissions = new ReplaySubject<string[]>();
                }            
                this._permissions.next(permissions);
                this.permissionsService.loadPermissions(permissions);
                if (!this._adminUser$) {
                    this._adminUser$ = new ReplaySubject<AdminUser>();
                }
                this._adminUser$.next(user);
                return user;
            }, 
            error: (error) => {
                this.handleError(error)
            }
        });
        return request;
    }

    // For use in admin
    get AdminUser$(): Observable<AdminUser> {
        if (!this._adminUser$) {
            this._adminUser$ = new ReplaySubject<AdminUser>(1);
            if (this.authService.getSubscriptionKey()) {
                this.getAdminUser();
            } else {
                // wait 2 seconds to get the user, so that
                // the authService can get the subscriptionKey first
                setTimeout(() => {
                    this.getAdminUser();
                }, 2000);
            }
        }

        return this._adminUser$;
    }

    get Permissions(): Observable<string[]> {
        if (!this._permissions) {
            this._permissions = new ReplaySubject<string[]>(1);
            this.getUser();
        }
        return this._permissions;
    }

    public Has(permission: string): Observable<boolean> {        
        return this.Permissions.pipe(mergeMap(permissions => of(PermissionsShared.Has(permissions, permission))));
    } 

    public HasAny(permissions: string[]): Observable<boolean> {
        return this.Permissions.pipe(mergeMap(grantedPermisssions => of(PermissionsShared.HasAny(grantedPermisssions, permissions))));
    }

    public HasAll(permissions: string[]): Observable<boolean> {
        return this.Permissions.pipe(mergeMap(grantedPermisssions => of(PermissionsShared.HasAll(grantedPermisssions, permissions))));
    }

    private handleError(error: any): Promise<any> {
        console.log('An error occurred', error); // for demo purposes only
        return Promise.reject(error.Message || error);
    }

    // wrapper for unit testing, since window is not a writable property and cannot be spied upon
    private windowAssign(url: string): void {
        window.location.assign(url);
    }

    private getBaseUrl(): string {
        return environment.applicationEndpoint;
    }

    public reset() {
        this._user$ = null;
        this._permissions = null;
    }

}
