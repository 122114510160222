import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import _ from 'lodash';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';
import { ITypedServiceResponse } from '@Core/Lib/model';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import * as Models from '@Core/CodeGen/Models/configuration.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class TenantService extends BaseService {

    public static baseUrl: string = "";

    constructor(protected http: HttpClient,
                protected globalMessages: GlobalMessages,
                protected loggingService: LoggingService,
                protected loadingService: LoadingService) {
        super(globalMessages, loggingService, loadingService);
        TenantService.baseUrl = BaseService.baseUrl + "/Tenant/";
    }

    public UpdateWebhookActivity(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.WebhookActivity>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks/UpdateActivity";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.WebhookActivity, request, context);
        });
        return request;
    }

    public ListWebhookActivities(contexts: DataContext[] | DataContext,
        optionalParameters: {
            eventDateFilter?: string,
            numActivities?: string,
            webhookName?: string,
            eventName?: string,
            action?: string,
            status?: string,
            area?: string,
            accountNum?: string,
            sentDateFilter?: string,
            completedDateFilter?: string,
            webhookId?: string,
            accountId?: string,
            workflowId?: string
        }): Observable<ITypedServiceResponse<Models.WebhookActivity>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks/Activities";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.eventDateFilter != null)
            params = params.set('eventDateFilter', optionalParameters.eventDateFilter);
        if (optionalParameters?.numActivities != null)
            params = params.set('numActivities', optionalParameters.numActivities);
        if (optionalParameters?.webhookName != null)
            params = params.set('webhookName', optionalParameters.webhookName);
        if (optionalParameters?.eventName != null)
            params = params.set('eventName', optionalParameters.eventName);
        if (optionalParameters?.action != null)
            params = params.set('action', optionalParameters.action);
        if (optionalParameters?.status != null)
            params = params.set('status', optionalParameters.status);
        if (optionalParameters?.area != null)
            params = params.set('area', optionalParameters.area);
        if (optionalParameters?.accountNum != null)
            params = params.set('accountNum', optionalParameters.accountNum);
        if (optionalParameters?.sentDateFilter != null)
            params = params.set('sentDateFilter', optionalParameters.sentDateFilter);
        if (optionalParameters?.completedDateFilter != null)
            params = params.set('completedDateFilter', optionalParameters.completedDateFilter);
        if (optionalParameters?.webhookId != null)
            params = params.set('webhookId', optionalParameters.webhookId);
        if (optionalParameters?.accountId != null)
            params = params.set('accountId', optionalParameters.accountId);
        if (optionalParameters?.workflowId != null)
            params = params.set('workflowId', optionalParameters.workflowId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WebhookActivity, request, context);
        });
        return request;
    }

    public RetrieveWebhook(contexts: DataContext[] | DataContext, webhookId: string): Observable<ITypedServiceResponse<Models.Webhook>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks/{webhookId}";
        url = url.replace("{webhookId}", webhookId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Webhook, request, context);
        });
        return request;
    }

    public ListWebhooks(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Webhook>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Webhook, request, context);
        });
        return request;
    }

    public AddWebhook(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Webhook>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Webhook, request, context);
        });
        return request;
    }

    public ModifyWebhook(body: any, contexts: DataContext[] | DataContext, webhookId: string): Observable<ITypedServiceResponse<Models.Webhook>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks/{webhookId}";
        url = url.replace("{webhookId}", webhookId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Webhook, request, context);
        });
        return request;
    }

    public RegenerateSigningKey(body: any, contexts: DataContext[] | DataContext, webhookId: string): Observable<ITypedServiceResponse<Models.Webhook>> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks/{webhookId}/RegenerateSigningKey";
        url = url.replace("{webhookId}", webhookId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Webhook, request, context);
        });
        return request;
    }

    public DeleteWebhook(idOfDeletedItem: string, contexts: DataContext[] | DataContext, webhookId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Webhooks/{webhookId}";
        url = url.replace("{webhookId}", webhookId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListAllWorkgroups(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = TenantService.baseUrl + "WorkGroups";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

    public RetrieveAWorkgroup(contexts: DataContext[] | DataContext, workGroupId: string): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = TenantService.baseUrl + "WorkGroups/{workGroupId}";
        url = url.replace("{workGroupId}", workGroupId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

    public AddWorkgroup(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = TenantService.baseUrl + "WorkGroups";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

    public ModifyWorkgroup(body: any, contexts: DataContext[] | DataContext, workGroupId: string): Observable<ITypedServiceResponse<Models.WorkGroup>> {
        // Build the url
        var url = TenantService.baseUrl + "WorkGroups/{workGroupId}";
        url = url.replace("{workGroupId}", workGroupId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.WorkGroup, request, context);
        });
        return request;
    }

    public ListAccountRoles(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = TenantService.baseUrl + "AccountRoles";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public RetrieveAccountRole(contexts: DataContext[] | DataContext, accountRoleId: string): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = TenantService.baseUrl + "AccountRoles/{accountRoleId}";
        url = url.replace("{accountRoleId}", accountRoleId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public AddAccountRole(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = TenantService.baseUrl + "AccountRoles";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public EditAccountRole(body: any, contexts: DataContext[] | DataContext, accountRoleId: string): Observable<ITypedServiceResponse<Models.AccountRole>> {
        // Build the url
        var url = TenantService.baseUrl + "AccountRoles/{accountRoleId}";
        url = url.replace("{accountRoleId}", accountRoleId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.AccountRole, request, context);
        });
        return request;
    }

    public ListAreas(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Area>> {
        // Build the url
        var url = TenantService.baseUrl + "Areas";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Area, request, context);
        });
        return request;
    }

    public UpdateArea(body: any, contexts: DataContext[] | DataContext, areaId: string): Observable<ITypedServiceResponse<Models.Area>> {
        // Build the url
        var url = TenantService.baseUrl + "Areas/{areaId}";
        url = url.replace("{areaId}", areaId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Area, request, context);
        });
        return request;
    }

    public ListBusinessLocations(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.BusinessLocation>> {
        // Build the url
        var url = TenantService.baseUrl + "BusinessLocations";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.BusinessLocation, request, context);
        });
        return request;
    }

    public RetrieveBusinessLocation(contexts: DataContext[] | DataContext, businessLocationId: string): Observable<ITypedServiceResponse<Models.BusinessLocation>> {
        // Build the url
        var url = TenantService.baseUrl + "BusinessLocations/{businessLocationId}";
        url = url.replace("{businessLocationId}", businessLocationId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.BusinessLocation, request, context);
        });
        return request;
    }

    public CreateBusinessLocation(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.BusinessLocation>> {
        // Build the url
        var url = TenantService.baseUrl + "BusinessLocations";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.BusinessLocation, request, context);
        });
        return request;
    }

    public ModifyBusinessLocation(body: any, contexts: DataContext[] | DataContext, businessLocationId: string): Observable<ITypedServiceResponse<Models.BusinessLocation>> {
        // Build the url
        var url = TenantService.baseUrl + "BusinessLocations/{businessLocationId}";
        url = url.replace("{businessLocationId}", businessLocationId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.BusinessLocation, request, context);
        });
        return request;
    }

    public DeleteBusinessLocation(idOfDeletedItem: string, contexts: DataContext[] | DataContext, businessLocationId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "BusinessLocations/{businessLocationId}";
        url = url.replace("{businessLocationId}", businessLocationId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListTimeZones(): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "BusinessLocations/TimeZones";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListCompanies(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Company>> {
        // Build the url
        var url = TenantService.baseUrl + "Companies";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Company, request, context);
        });
        return request;
    }

    public RetrieveCompany(contexts: DataContext[] | DataContext, companyId: string): Observable<ITypedServiceResponse<Models.Company>> {
        // Build the url
        var url = TenantService.baseUrl + "Companies/{companyId}";
        url = url.replace("{companyId}", companyId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Company, request, context);
        });
        return request;
    }

    public AddCompany(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Company>> {
        // Build the url
        var url = TenantService.baseUrl + "Companies";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Company, request, context);
        });
        return request;
    }

    public ModifyCompany(body: any, contexts: DataContext[] | DataContext, companyId: string): Observable<ITypedServiceResponse<Models.Company>> {
        // Build the url
        var url = TenantService.baseUrl + "Companies/{companyId}";
        url = url.replace("{companyId}", companyId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Company, request, context);
        });
        return request;
    }

    public DeleteCompany(idOfDeletedItem: string, contexts: DataContext[] | DataContext, companyId: string): Observable<ITypedServiceResponse<Models.Company>> {
        // Build the url
        var url = TenantService.baseUrl + "Companies/{companyId}";
        url = url.replace("{companyId}", companyId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListDashboards(contexts: DataContext[] | DataContext,
        optionalParameters: {
            dashboardName?: string
        }): Observable<ITypedServiceResponse<Models.Dashboard>> {
        // Build the url
        var url = TenantService.baseUrl + "Dashboards";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.dashboardName != null)
            params = params.set('dashboardName', optionalParameters.dashboardName);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Dashboard, request, context);
        });
        return request;
    }

    public RetrieveDashboard(contexts: DataContext[] | DataContext, dashboardId: string): Observable<ITypedServiceResponse<Models.Dashboard>> {
        // Build the url
        var url = TenantService.baseUrl + "Dashboards/{dashboardId}";
        url = url.replace("{dashboardId}", dashboardId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Dashboard, request, context);
        });
        return request;
    }

    public CreateDashboard(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Dashboard>> {
        // Build the url
        var url = TenantService.baseUrl + "Dashboards";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Dashboard, request, context);
        });
        return request;
    }

    public ModifyDashboard(body: any, contexts: DataContext[] | DataContext, dashboardId: string): Observable<ITypedServiceResponse<Models.Dashboard>> {
        // Build the url
        var url = TenantService.baseUrl + "Dashboards/{dashboardId}";
        url = url.replace("{dashboardId}", dashboardId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Dashboard, request, context);
        });
        return request;
    }

    public DeleteDashboard(idOfDeletedItem: string, contexts: DataContext[] | DataContext, dashboardId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Dashboards/{dashboardId}";
        url = url.replace("{dashboardId}", dashboardId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListDocumentCategories(contexts: DataContext[] | DataContext,
        optionalParameters: {
            programId?: string
        }): Observable<ITypedServiceResponse<Models.DocumentCategory>> {
        // Build the url
        var url = TenantService.baseUrl + "/DocumentCategories";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.programId != null)
            params = params.set('programId', optionalParameters.programId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DocumentCategory, request, context);
        });
        return request;
    }

    public RetrieveDocumentCategory(contexts: DataContext[] | DataContext, categoryId: string): Observable<ITypedServiceResponse<Models.DocumentCategory>> {
        // Build the url
        var url = TenantService.baseUrl + "/DocumentCategories/{categoryId}";
        url = url.replace("{categoryId}", categoryId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DocumentCategory, request, context);
        });
        return request;
    }

    public AddDocumentCategory(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.DocumentCategory>> {
        // Build the url
        var url = TenantService.baseUrl + "/DocumentCategories";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DocumentCategory, request, context);
        });
        return request;
    }

    public ModifyDocumentCategory(body: any, contexts: DataContext[] | DataContext, categoryId: string): Observable<ITypedServiceResponse<Models.DocumentCategory>> {
        // Build the url
        var url = TenantService.baseUrl + "/DocumentCategories/{categoryId}";
        url = url.replace("{categoryId}", categoryId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.DocumentCategory, request, context);
        });
        return request;
    }

    public AddEmailTemplate(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.EmailTemplate>> {
        // Build the url
        var url = TenantService.baseUrl + "EmailTemplates";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.EmailTemplate, request, context);
        });
        return request;
    }

    public UpdateAnEmailTemplate(body: any, contexts: DataContext[] | DataContext, emailTemplateId: string): Observable<ITypedServiceResponse<Models.EmailTemplate>> {
        // Build the url
        var url = TenantService.baseUrl + "EmailTemplates/{emailTemplateId}";
        url = url.replace("{emailTemplateId}", emailTemplateId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.EmailTemplate, request, context);
        });
        return request;
    }

    public RetrieveEmailTemplate(contexts: DataContext[] | DataContext, templateId: string): Observable<ITypedServiceResponse<Models.EmailTemplate>> {
        // Build the url
        var url = TenantService.baseUrl + "EmailTemplates/{templateId}";
        url = url.replace("{templateId}", templateId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.EmailTemplate, request, context);
        });
        return request;
    }

    public ListEmailTemplates(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.EmailTemplate>> {
        // Build the url
        var url = TenantService.baseUrl + "EmailTemplates";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.EmailTemplate, request, context);
        });
        return request;
    }

    public ListFeatureFlags(): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "FeatureFlags";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListEnabledFeatureFacets(optionalParameters: {
            key?: string
        }): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "FeatureFacets";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.key != null)
            params = params.set('key', optionalParameters.key);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListAllEnabledFeatureFacets(): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "FeatureFacets/All";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public EnableFeatureFlag(body: any, featureFlagId: string,
        optionalParameters: {
            areaKey?: string
        }): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "FeatureFlags/{featureFlagId}/Enable";
        url = url.replace("{featureFlagId}", featureFlagId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.areaKey != null)
            params = params.set('areaKey', optionalParameters.areaKey);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public DisableFeatureFlag(body: any, featureFlagId: string,
        optionalParameters: {
            areaKey?: string
        }): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "FeatureFlags/{featureFlagId}/Disable";
        url = url.replace("{featureFlagId}", featureFlagId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.areaKey != null)
            params = params.set('areaKey', optionalParameters.areaKey);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListHolidays(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Holiday>> {
        // Build the url
        var url = TenantService.baseUrl + "Holidays";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Holiday, request, context);
        });
        return request;
    }

    public RetrieveHoliday(contexts: DataContext[] | DataContext, holidayId: string): Observable<ITypedServiceResponse<Models.Holiday>> {
        // Build the url
        var url = TenantService.baseUrl + "Holidays/{holidayId}";
        url = url.replace("{holidayId}", holidayId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Holiday, request, context);
        });
        return request;
    }

    public CreateHolidays(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Holiday>> {
        // Build the url
        var url = TenantService.baseUrl + "Holidays";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Holiday, request, context);
        });
        return request;
    }

    public ModifyHoliday(body: any, contexts: DataContext[] | DataContext, holidayId: string): Observable<ITypedServiceResponse<Models.Holiday>> {
        // Build the url
        var url = TenantService.baseUrl + "Holidays/{holidayId}";
        url = url.replace("{holidayId}", holidayId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Holiday, request, context);
        });
        return request;
    }

    public DeleteHoliday(idOfDeletedItem: string, contexts: DataContext[] | DataContext, holidayId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Holidays/{holidayId}";
        url = url.replace("{holidayId}", holidayId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListIntegrations(contexts: DataContext[] | DataContext,
        optionalParameters: {
            integrationType?: string
        }): Observable<ITypedServiceResponse<Models.App>> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.integrationType != null)
            params = params.set('integrationType', optionalParameters.integrationType);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.App, request, context);
        });
        return request;
    }

    public AddAppIntegration(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.App>> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.App, request, context);
        });
        return request;
    }

    public AddDeveloperIntegration(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Developer>> {
        // Build the url
        var url = TenantService.baseUrl + "Developers";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Developer, request, context);
        });
        return request;
    }

    public ModifyAppIntegration(body: any, contexts: DataContext[] | DataContext, integrationId: string): Observable<ITypedServiceResponse<Models.App>> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations/{integrationId}";
        url = url.replace("{integrationId}", integrationId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.App, request, context);
        });
        return request;
    }

    public ModifyDeveloper(body: any, contexts: DataContext[] | DataContext, developerId: string): Observable<ITypedServiceResponse<Models.Developer>> {
        // Build the url
        var url = TenantService.baseUrl + "Developers/{developerId}";
        url = url.replace("{developerId}", developerId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Developer, request, context);
        });
        return request;
    }

    public RegenerateCredentials(body: any, integrationId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations/{integrationId}/Credentials";
        url = url.replace("{integrationId}", integrationId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ViewCredentials(integrationId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations/{integrationId}/Credentials";
        url = url.replace("{integrationId}", integrationId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public EnableIntegration(body: any, contexts: DataContext[] | DataContext, integrationId: string): Observable<ITypedServiceResponse<Models.App>> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations/{integrationId}/Enable";
        url = url.replace("{integrationId}", integrationId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.App, request, context);
        });
        return request;
    }

    public DisableIntegration(body: any, contexts: DataContext[] | DataContext, integrationId: string): Observable<ITypedServiceResponse<Models.App>> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations/{integrationId}/Disable";
        url = url.replace("{integrationId}", integrationId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.App, request, context);
        });
        return request;
    }

    public DeleteIntegration(idOfDeletedItem: string, contexts: DataContext[] | DataContext, integrationId: string): Observable<ITypedServiceResponse<Models.App>> {
        // Build the url
        var url = TenantService.baseUrl + "Integrations/{integrationId}";
        url = url.replace("{integrationId}", integrationId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public RetrievePrioritization(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Prioritization>> {
        // Build the url
        var url = TenantService.baseUrl + "Prioritization";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Prioritization, request, context);
        });
        return request;
    }

    public AddPrioritization(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Prioritization>> {
        // Build the url
        var url = TenantService.baseUrl + "Prioritization";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Prioritization, request, context);
        });
        return request;
    }

    public ModifyPrioritization(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Prioritization>> {
        // Build the url
        var url = TenantService.baseUrl + "Prioritization";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Prioritization, request, context);
        });
        return request;
    }

    public ListProducers(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Producer>> {
        // Build the url
        var url = TenantService.baseUrl + "Producers";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Producer, request, context);
        });
        return request;
    }

    public RetrieveProducerByIdOrCode(contexts: DataContext[] | DataContext, producerIdentifier: string): Observable<ITypedServiceResponse<Models.Producer>> {
        // Build the url
        var url = TenantService.baseUrl + "Producers/{producerIdentifier}";
        url = url.replace("{producerIdentifier}", producerIdentifier);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Producer, request, context);
        });
        return request;
    }

    public AddProducer(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Producer>> {
        // Build the url
        var url = TenantService.baseUrl + "Producers";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Producer, request, context);
        });
        return request;
    }

    public SetNextProducerCodeNumber(body: any): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Producers/NextCodeNumber";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ModifyProducer(body: any, contexts: DataContext[] | DataContext, producerId: string): Observable<ITypedServiceResponse<Models.Producer>> {
        // Build the url
        var url = TenantService.baseUrl + "Producers/{producerId}";
        url = url.replace("{producerId}", producerId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Producer, request, context);
        });
        return request;
    }

    public SearchProducers(searchTerms: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Producers/Search";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('searchTerms', searchTerms);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListProducts(contexts: DataContext[] | DataContext,
        optionalParameters: {
            companyId?: string
        }): Observable<ITypedServiceResponse<Models.Product>> {
        // Build the url
        var url = TenantService.baseUrl + "Products";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.companyId != null)
            params = params.set('companyId', optionalParameters.companyId);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Product, request, context);
        });
        return request;
    }

    public RetrieveProduct(contexts: DataContext[] | DataContext, productId: string): Observable<ITypedServiceResponse<Models.Product>> {
        // Build the url
        var url = TenantService.baseUrl + "Products/{productId}";
        url = url.replace("{productId}", productId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Product, request, context);
        });
        return request;
    }

    public AddAProductToACompany(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Product>> {
        // Build the url
        var url = TenantService.baseUrl + "Products";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Product, request, context);
        });
        return request;
    }

    public ModifyProduct(body: any, contexts: DataContext[] | DataContext, productId: string): Observable<ITypedServiceResponse<Models.Product>> {
        // Build the url
        var url = TenantService.baseUrl + "Products/{productId}";
        url = url.replace("{productId}", productId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Product, request, context);
        });
        return request;
    }

    public DeleteProduct(idOfDeletedItem: string, contexts: DataContext[] | DataContext, productId: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Products/{productId}";
        url = url.replace("{productId}", productId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListPrograms(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Program>> {
        // Build the url
        var url = TenantService.baseUrl + "Programs";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Program, request, context);
        });
        return request;
    }

    public ListAllProgramRevisions(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ProgramRevision>> {
        // Build the url
        var url = TenantService.baseUrl + "Revisions";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ProgramRevision, request, context);
        });
        return request;
    }

    public ListRegions(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Region>> {
        // Build the url
        var url = TenantService.baseUrl + "Regions";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Region, request, context);
        });
        return request;
    }

    public RetrieveRegion(contexts: DataContext[] | DataContext, regionId: string): Observable<ITypedServiceResponse<Models.Region>> {
        // Build the url
        var url = TenantService.baseUrl + "Regions/{regionId}";
        url = url.replace("{regionId}", regionId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Region, request, context);
        });
        return request;
    }

    public AddRegion(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.Region>> {
        // Build the url
        var url = TenantService.baseUrl + "Regions";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Region, request, context);
        });
        return request;
    }

    public ModifyRegion(body: any, contexts: DataContext[] | DataContext, regionId: string): Observable<ITypedServiceResponse<Models.Region>> {
        // Build the url
        var url = TenantService.baseUrl + "Regions/{regionId}";
        url = url.replace("{regionId}", regionId);

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.Region, request, context);
        });
        return request;
    }

    public RetrieveReportConfigValues(): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Reports/ConfigValues";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListSecurityRoles(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.SecurityRole>> {
        // Build the url
        var url = TenantService.baseUrl + "SecurityRoles";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.SecurityRole, request, context);
        });
        return request;
    }

    public RetrieveSecurityRole(contexts: DataContext[] | DataContext, securityRoleId: string): Observable<ITypedServiceResponse<Models.SecurityRole>> {
        // Build the url
        var url = TenantService.baseUrl + "SecurityRoles/{securityRoleId}";
        url = url.replace("{securityRoleId}", securityRoleId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.SecurityRole, request, context);
        });
        return request;
    }

    public AddSecurityRole(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.SecurityRole>> {
        // Build the url
        var url = TenantService.baseUrl + "SecurityRoles";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.SecurityRole, request, context);
        });
        return request;
    }

    public ModifySecurityRole(body: any, contexts: DataContext[] | DataContext, securityRoleId: string): Observable<ITypedServiceResponse<Models.SecurityRole>> {
        // Build the url
        var url = TenantService.baseUrl + "SecurityRoles/{securityRoleId}";
        url = url.replace("{securityRoleId}", securityRoleId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.SecurityRole, request, context);
        });
        return request;
    }

    public DeleteSecurityRole(idOfDeletedItem: string, contexts: DataContext[] | DataContext, securityRoleId: string): Observable<ITypedServiceResponse<Models.SecurityRole>> {
        // Build the url
        var url = TenantService.baseUrl + "SecurityRoles/{securityRoleId}";
        url = url.replace("{securityRoleId}", securityRoleId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ResurrectSecurityRole(body: any, contexts: DataContext[] | DataContext, securityRoleId: string): Observable<ITypedServiceResponse<Models.SecurityRole>> {
        // Build the url
        var url = TenantService.baseUrl + "SecurityRoles/{securityRoleId}/Resurrect";
        url = url.replace("{securityRoleId}", securityRoleId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.SecurityRole, request, context);
        });
        return request;
    }

    public ListServiceLevelAgreements(contexts: DataContext[] | DataContext,
        optionalParameters: {
            includeAllVersions?: string
        }): Observable<ITypedServiceResponse<Models.ServiceLevelAgreement>> {
        // Build the url
        var url = TenantService.baseUrl + "ServiceLevelAgreements";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.includeAllVersions != null)
            params = params.set('includeAllVersions', optionalParameters.includeAllVersions);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ServiceLevelAgreement, request, context);
        });
        return request;
    }

    public RetrieveServiceLevelAgreement(contexts: DataContext[] | DataContext, serviceLevelAgreementId: string): Observable<ITypedServiceResponse<Models.ServiceLevelAgreement>> {
        // Build the url
        var url = TenantService.baseUrl + "ServiceLevelAgreements/{serviceLevelAgreementId}";
        url = url.replace("{serviceLevelAgreementId}", serviceLevelAgreementId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.ServiceLevelAgreement, request, context);
        });
        return request;
    }

    public CreateServiceLevelAgreement(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.ServiceLevelAgreement>> {
        // Build the url
        var url = TenantService.baseUrl + "ServiceLevelAgreements";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ServiceLevelAgreement, request, context);
        });
        return request;
    }

    public ModifyServiceLevelAgreement(body: any, contexts: DataContext[] | DataContext, serviceLevelAgreementId: string): Observable<ITypedServiceResponse<Models.ServiceLevelAgreement>> {
        // Build the url
        var url = TenantService.baseUrl + "ServiceLevelAgreements/{serviceLevelAgreementId}";
        url = url.replace("{serviceLevelAgreementId}", serviceLevelAgreementId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ServiceLevelAgreement, request, context);
        });
        return request;
    }

    public EnableServiceLevelAgreement(body: any, contexts: DataContext[] | DataContext, serviceLevelAgreementId: string): Observable<ITypedServiceResponse<Models.ServiceLevelAgreement>> {
        // Build the url
        var url = TenantService.baseUrl + "ServiceLevelAgreements/{serviceLevelAgreementId}/Enable";
        url = url.replace("{serviceLevelAgreementId}", serviceLevelAgreementId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ServiceLevelAgreement, request, context);
        });
        return request;
    }

    public DisableServiceLevelAgreement(body: any, contexts: DataContext[] | DataContext, serviceLevelAgreementId: string): Observable<ITypedServiceResponse<Models.ServiceLevelAgreement>> {
        // Build the url
        var url = TenantService.baseUrl + "ServiceLevelAgreements/{serviceLevelAgreementId}/Disable";
        url = url.replace("{serviceLevelAgreementId}", serviceLevelAgreementId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.ServiceLevelAgreement, request, context);
        });
        return request;
    }

    public ListAllTables(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.CustomTable>> {
        // Build the url
        var url = TenantService.baseUrl + "Tables";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.CustomTable, request, context);
        });
        return request;
    }

    public RetrieveTable(contexts: DataContext[] | DataContext, tableId: string): Observable<ITypedServiceResponse<Models.CustomTable>> {
        // Build the url
        var url = TenantService.baseUrl + "Tables/{tableId}";
        url = url.replace("{tableId}", tableId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.CustomTable, request, context);
        });
        return request;
    }

    public RetrieveRowsForATable(contexts: DataContext[] | DataContext, tableId: string): Observable<ITypedServiceResponse<Models.CustomTableRow>> {
        // Build the url
        var url = TenantService.baseUrl + "Tables/{tableId}/Rows";
        url = url.replace("{tableId}", tableId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.CustomTableRow, request, context);
        });
        return request;
    }

    public RetrieveAllRows(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.CustomTableRow>> {
        // Build the url
        var url = TenantService.baseUrl + "Rows";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.CustomTableRow, request, context);
        });
        return request;
    }

    public RetrieveRow(contexts: DataContext[] | DataContext, rowId: string): Observable<ITypedServiceResponse<Models.CustomTableRow>> {
        // Build the url
        var url = TenantService.baseUrl + "Rows/{rowId}";
        url = url.replace("{rowId}", rowId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.CustomTableRow, request, context);
        });
        return request;
    }

    public AddTableRow(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.CustomTableRow>> {
        // Build the url
        var url = TenantService.baseUrl + "Rows";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.CustomTableRow, request, context);
        });
        return request;
    }

    public UpdateTableRow(body: any, contexts: DataContext[] | DataContext, rowId: string): Observable<ITypedServiceResponse<Models.CustomTableRow>> {
        // Build the url
        var url = TenantService.baseUrl + "Rows/{rowId}";
        url = url.replace("{rowId}", rowId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.CustomTableRow, request, context);
        });
        return request;
    }

    public DeleteTableRow(idOfDeletedItem: string, contexts: DataContext[] | DataContext, rowId: string): Observable<ITypedServiceResponse<Models.CustomTableRow>> {
        // Build the url
        var url = TenantService.baseUrl + "Rows/{rowId}";
        url = url.replace("{rowId}", rowId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public ListAllTransactions(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.TransactionType>> {
        // Build the url
        var url = TenantService.baseUrl + "Transactions";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TransactionType, request, context);
        });
        return request;
    }

    public RetrieveTransactiontype(contexts: DataContext[] | DataContext, transactionTypeId: string): Observable<ITypedServiceResponse<Models.TransactionType>> {
        // Build the url
        var url = TenantService.baseUrl + "Transactions/{transactionTypeId}";
        url = url.replace("{transactionTypeId}", transactionTypeId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TransactionType, request, context);
        });
        return request;
    }

    public AddTransactionType(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.TransactionType>> {
        // Build the url
        var url = TenantService.baseUrl + "Transactions";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.TransactionType, request, context);
        });
        return request;
    }

    public UpdateTransactionType(body: any, contexts: DataContext[] | DataContext, transactionTypeId: string): Observable<ITypedServiceResponse<Models.TransactionType>> {
        // Build the url
        var url = TenantService.baseUrl + "Transactions/{transactionTypeId}";
        url = url.replace("{transactionTypeId}", transactionTypeId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.TransactionType, request, context);
        });
        return request;
    }

    public ListUsers(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = TenantService.baseUrl + "Users";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public ListIdentities(contexts: DataContext[] | DataContext, ids: string): Observable<ITypedServiceResponse<Models.SMIdentity>> {
        // Build the url
        var url = TenantService.baseUrl + "Identities";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('ids', ids);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.SMIdentity, request, context);
        });
        return request;
    }

    public RetrieveUser(contexts: DataContext[] | DataContext, identityKey: string): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = TenantService.baseUrl + "Users/{identityKey}";
        url = url.replace("{identityKey}", identityKey);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public ModifyUser(body: any, keyForUserToUpdate: string): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Users/{keyForUserToUpdate}";
        url = url.replace("{keyForUserToUpdate}", keyForUserToUpdate);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public InviteUsers(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = TenantService.baseUrl + "Users";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public ResendInvitationToUsers(body: any): Observable<Response> {
        // Build the url
        var url = TenantService.baseUrl + "Users/ResendInvite";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveCurrentUser(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = TenantService.baseUrl + "CurrentUserInfo";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public RemoveUserFromTenant(body: any, contexts: DataContext[] | DataContext, userId: string): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = TenantService.baseUrl + "Users/{userId}/Remove";
        url = url.replace("{userId}", userId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public EnableUserForTenant(body: any, contexts: DataContext[] | DataContext, userId: string): Observable<ITypedServiceResponse<Models.TenantUser>> {
        // Build the url
        var url = TenantService.baseUrl + "Users/{userId}/Enable";
        url = url.replace("{userId}", userId);

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.TenantUser, request, context);
        });
        return request;
    }

    public ListUserNotificationCenters(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = TenantService.baseUrl + "Notifications";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

    public RemoveNotificationsForUser(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = TenantService.baseUrl + "Notifications/Remove";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

    public MarkNotificationsAsRead(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = TenantService.baseUrl + "Notifications/Read";

        // Make the request
        let request = this.http.post<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

    public RetrieveUserNotificationSettings(contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = TenantService.baseUrl + "NotificationSettings";

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

    public UpdateUserNotificationSettings(body: any, contexts: DataContext[] | DataContext): Observable<ITypedServiceResponse<Models.UserNotificationCenter>> {
        // Build the url
        var url = TenantService.baseUrl + "NotificationSettings";

        // Make the request
        let request = this.http.put<any>(url, body).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalPostPutRequest(Models.UserNotificationCenter, request, context);
        });
        return request;
    }

}