<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>      
        <input 
            [id]="getInputIdOrName()"
            [(ngModel)]="model.Value" 
            type="number" 
            min="1000" 
            max="9999" 
            class="form-control w-100" 
            [ngClass]="getInputCssClass()"
            [disabled]="model.Disabled" 
            (input)="valueChange($event, 'input')"
            (focusin)="onFocusChanged.emit(true)"
            (focusout)="onFocusChanged.emit(false)"
        >
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>        
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>