import { Program } from '@Core/CodeGen/Models/area.models';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';
import { UserContext } from '@Core/Lib/Contexts/user-context';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { AuthService } from '@Services/auth-service';
import { FeatureFacetService } from '@Services/feature-facet.service';
import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'sm-quick-search-card',
    templateUrl: './sm-quick-search-card.component.html',
    styleUrls: ['./sm-quick-search-card.component.scss']
})
export class SmQuickSearchCard implements OnInit {
    constructor(private facetService: FeatureFacetService,
        private authService: AuthService,
        private userContext: UserContext) 
    {}

    @Input() MatchedItem: SearchResult;
    @Input() SearchType: SearchDataType;
    SearchDataTypes = SearchDataType;

    @HostBinding('attr.role') role = 'button';

    public resultName: string;
    public resultCode: string;
    public TENANT_PERMISSIONS = TenantPermissions;

    public accountDba: string;
    public accountTags: string[];
    public policyQuoteNumber: string;
    public policyInfo: PolicyInfo = null;
    public explctPrgmAccess: boolean = false;
    public acctProgram: Program = new Program();

    ngOnInit(): void {
        this.checkExplctPrgmAccess();

        switch(this.SearchType) {
            case SearchDataType.Account :
                this.BuildAccountSearchResults();
                break;

            case SearchDataType.Producer :
                this.BuildProducerSearchResults();
                break;
        };
    }

    private checkExplctPrgmAccess() {
        this.facetService.getAllFacets().subscribe(allFacets => {
            if (FeatureFacetService.checkIfFacetExists(this.TENANT_PERMISSIONS.Flag_Work_Accounts_ExplicitProgramAccess, 
                allFacets, this.authService.spaName, this.authService.getAreaKey())) {
                    this.explctPrgmAccess = true;
                    this.acctProgram = this.userContext.get(ModelUtils.createDomainId(new Program(), this.MatchedItem.data.programIds[0])) as Program;
            }
        });    
    }

    private BuildAccountSearchResults() : void {
        this.resultName = this.MatchedItem.data.name;
        this.resultCode = this.MatchedItem.data.code;
        this.accountDba = this.MatchedItem.data.dba;
        this.accountTags = this.MatchedItem.data.tags;

        if (this.MatchedItem.highlights) {
            const keys = Object.keys(this.MatchedItem.highlights);
            keys.forEach(key => {

                if (key === "Name") {
                    this.resultName = this.MatchedItem.highlights[key];
                }
                if (key === "Code") {
                    this.resultCode = this.MatchedItem.highlights[key];
                }
                if (key === "Dba") {
                    this.accountDba = this.MatchedItem.highlights[key];
                }
                if (key === "PolicyNumbers") {
                    this.policyInfo = this.MatchedItem.data.policyInfo;
                }
                if (key === "QuoteNumbers") {
                    this.policyInfo = this.MatchedItem.data.policyInfo;
                }
                if (key === "Tags") {
                    this.accountTags = this.MatchedItem.highlights[key];
                }
            });
        }
    }

    private BuildProducerSearchResults() : void {
        this.resultName = this.MatchedItem.data.name;
        this.resultCode = this.MatchedItem.data.code;
        this.accountDba =
            `${this.MatchedItem.data.address1} ${this.MatchedItem.data.city} ${this.MatchedItem.data.state} ${this.MatchedItem.data.zip}`;

        if (this.MatchedItem.highlights) {
            const keys = Object.keys(this.MatchedItem.highlights);
            keys.forEach(key => {

                if (key === "Name") {
                    this.resultName = this.MatchedItem.highlights[key];
                }
                if (key === "Code") {
                    this.resultCode = this.MatchedItem.highlights[key];
                }
            });
        }
    }
}

export enum SearchDataType {
    Account,
    Producer
}

export class SearchResult {
    score: number;
    highlights: any;
    data: any;
}

export class PolicyInfo {
    ProgramName: string;
    ProgramId: string;
    PolicySetRange: string;
    Product: string;
    Number: string;
    EffectiveDate?: string;
    ExpirationDate?: string;
}