<ng-container *ngIf="model.SpecialType == 'Currency'">
    <sm-form-currency-block
        [model]="model" 
        [readOnly]="readOnly"
        [formState]="formState"
        (onValueChanged)="onValueChanged.emit($event)"
        (onDetectChanges)="onDetectChanges.emit($event)">
    </sm-form-currency-block>
</ng-container>
<ng-container *ngIf="!model.SpecialType">
    <div class="d-flex">
        <sm-form-field *ngFor="let field of fields">
            [model]="field" 
            [readOnly]="readOnly"
            [formState]="formState"
            (onValueChanged)="onValueChanged.emit($event)"
            (onDetectChanges)="onDetectChanges.emit($event)">  
        </sm-form-field>
    </div>
</ng-container>
