import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeInStr',
    pure: false
})
export class TimeInStrPipe implements PipeTransform {
    // convert milliseconds and return a string in year, month, week, day or hour and minute
    transform(time: number, shortHand: boolean = true, countdown: boolean = false, maxUnit: TimeMaxUnitEnum = TimeMaxUnitEnum.Year): string {
        if (!time) {
            return "";
        } else {
            // in milliseconds
            const oneSecond = 1000;
            const oneMinute = oneSecond * 60;
            const oneHour = oneMinute * 60;
            const oneDay = oneHour * 24;
            const oneWeek = oneDay * 7;
            const oneYear = oneDay * 365;
            const oneMonth = oneYear / 12;

            let absTime = Math.abs(time); 
            let sign = (time > 0 ? "" : "-");

            if (absTime >= oneYear && maxUnit === TimeMaxUnitEnum.Year) {
                return (sign + Math.floor(absTime / oneYear)) + (shortHand ? 'y' : ' year(s)');
            }
            if (absTime >= oneMonth && maxUnit >= TimeMaxUnitEnum.Month) {
                return (sign + Math.floor(absTime / oneMonth)) + (shortHand ? 'mo' : ' month(s)');
            }
            if (absTime > oneMonth && maxUnit >= TimeMaxUnitEnum.Week) {
                return (sign + Math.floor(absTime / oneWeek)) + (shortHand ? 'w' : ' week(s)');
            }
            if ((absTime > oneWeek && absTime < oneMonth) && maxUnit >= TimeMaxUnitEnum.Week) {
                return (sign + Math.floor(absTime / oneDay)) + (shortHand ? 'd' : ' day(s)');
            }
            if (absTime >= oneDay && maxUnit >= TimeMaxUnitEnum.Day) {
                return (sign + Math.floor(absTime / oneDay)) + (shortHand ? 'd' : ' day(s)');
            }
            if (countdown) {
                let hours, minutes;

                hours = Math.floor(absTime / oneHour);
                absTime -= hours * oneHour;
                minutes = Math.floor(absTime / oneMinute);
                absTime -= minutes * oneMinute;

                return shortHand ? (sign + hours + ":" + minutes) : (hours > 0) ? (sign + hours + " hour(s)") : (sign + minutes + " min(s)");
            }
            else {

                if (absTime >= oneHour) {
                    return (sign + Math.floor(absTime / oneHour)) + (shortHand ? 'h' : ' hour(s)');
                }

                return (sign + Math.floor(absTime / oneMinute)) + (shortHand ? 'm' : ' minute(s)');
            }
        }
    }
}

export enum TimeMaxUnitEnum {
    Year = 4,
    Month = 3,
    Week = 2,
    Day = 1,
    Hour = 0
}