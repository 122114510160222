import { ModelUtils, EdgeRelationship } from '@Core/Lib/Utils/model-utils';
import { BaseDataContext } from '@Core/Lib/Contexts/base-data-context';
import { BaseModel, EdgeModel, VertexModel, DocumentModel } from '@Core/Lib/model';
import { ContractDate } from '@Core/Lib/contract-date';
import { ActionEligibilityStatus } from '@Core/Lib/Enums/action-eligibility-status';

import * as _ from 'lodash';

export class AdminInfo extends DocumentModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "AdminInfo";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get SubscriptionKey(): string {
        return this.data.SubscriptionKey;
    }
    set SubscriptionKey(value: string) {
        this.data.SubscriptionKey = value;
    }


    deserialize(input: Object, datacontext): AdminInfo {
        if (!input)
            return this;

        super._deserialize(input, datacontext);

        return this;
    }

    serialize(_model?: AdminInfo) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            SubscriptionKey: model.SubscriptionKey,
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): AdminInfo {
        let clone = new AdminInfo();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class AdminUserFillsSecurityRole extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "AdminUserFillsSecurityRole";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get CreatedDate(): Date {
        return this.data.CreatedDate ? new Date(this.data.CreatedDate) : undefined;
    }
    set CreatedDate(value: Date) {
        this.data.CreatedDate = value;
    }

    // Relationships

    //   Out to AdminUser
    private __IsFilledBy: AdminUser;

    IsFilledBy(context?: BaseDataContext): AdminUser {
        if (this.__IsFilledBy)
           return this.__IsFilledBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsFilledBy) as AdminUser;
    }
    setIsFilledBy(value: AdminUser) {
        this.__IsFilledBy = value;
    }
    get _IsFilledBy(): string {
        return this.Out;
    }
    set _IsFilledBy(value: string) {
        this.Out = value;
    }
    //   In to SecurityRole
    private __FillsSecurityRole: SecurityRole;

    FillsSecurityRole(context?: BaseDataContext): SecurityRole {
        if (this.__FillsSecurityRole)
           return this.__FillsSecurityRole;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._FillsSecurityRole) as SecurityRole;
    }
    setFillsSecurityRole(value: SecurityRole) {
        this.__FillsSecurityRole = value;
    }
    get _FillsSecurityRole(): string {
        return this.In;
    }
    set _FillsSecurityRole(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): AdminUserFillsSecurityRole {
       return ModelUtils.deserializeEdge<AdminUserFillsSecurityRole>(this, input, datacontext, super._deserialize);
    }


    clone(): AdminUserFillsSecurityRole {
        let clone = new AdminUserFillsSecurityRole();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class AreaTier extends DocumentModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "AreaTier";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get Name(): string {
        return this.data.Name;
    }
    set Name(value: string) {
        this.data.Name = value;
    }
    get DatabaseThroughputRUs(): number {
        return this.data.DatabaseThroughputRUs;
    }
    set DatabaseThroughputRUs(value: number) {
        this.data.DatabaseThroughputRUs = value;
    }


    deserialize(input: Object, datacontext): AreaTier {
        if (!input)
            return this;

        super._deserialize(input, datacontext);

        return this;
    }

    serialize(_model?: AreaTier) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            Name: model.Name,
            DatabaseThroughputRUs: model.DatabaseThroughputRUs,
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): AreaTier {
        let clone = new AreaTier();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IFeature {
    Id: string

    IsInModule?: object[],
    IsSecuredBy?: object[]

}

export class Feature extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Feature";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Feature }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    // Relationship IsInModule, returns Module ModuleProvidesFeature[]
    private __IsInModule: ModuleProvidesFeature[];
    IsInModule(_context?: BaseDataContext): ModuleProvidesFeature[] {
        if (this.__IsInModule)
            return this.__IsInModule;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsInModule), (id) => context.get(id) as ModuleProvidesFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsInModule(values: ModuleProvidesFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__IsInModule = values;
     }
    get _IsInModule(): Set<string> {
        if (!this._relationships.has("IsInModule"))
            this._relationships.set("IsInModule", new Set<string>());

        return this._relationships.get("IsInModule");
    }
    set _IsInModule(values: Set<string>) {
        this._relationships.set("IsInModule", values);
    }

    // Relationship IsSecuredBy, returns Permission PermissionSecuresFeature[]
    private __IsSecuredBy: PermissionSecuresFeature[];
    IsSecuredBy(_context?: BaseDataContext): PermissionSecuresFeature[] {
        if (this.__IsSecuredBy)
            return this.__IsSecuredBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsSecuredBy), (id) => context.get(id) as PermissionSecuresFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsSecuredBy(values: PermissionSecuresFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__IsSecuredBy = values;
     }
    get _IsSecuredBy(): Set<string> {
        if (!this._relationships.has("IsSecuredBy"))
            this._relationships.set("IsSecuredBy", new Set<string>());

        return this._relationships.get("IsSecuredBy");
    }
    set _IsSecuredBy(values: Set<string>) {
        this._relationships.set("IsSecuredBy", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsInModule',
            edgeType: ModuleProvidesFeature,
            otherVertexPropertyName: 'ProvidesFeature',
            otherVertexType: Module,
        },
        {
            propertyName: 'IsSecuredBy',
            edgeType: PermissionSecuresFeature,
            otherVertexPropertyName: 'Secures',
            otherVertexType: Permission,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Feature {
        return ModelUtils.deserializeVertex<Feature>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Feature) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            IsInModule: ModelUtils.serializeShallowEdge(model.IsInModule(), 'IsInModule'),
            IsSecuredBy: ModelUtils.serializeShallowEdge(model.IsSecuredBy(), 'IsSecuredBy'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Feature {
        let clone = new Feature();
        clone.data = _.cloneDeep(this.data);
        clone._IsInModule = _.cloneDeep(this._IsInModule);
        clone._IsSecuredBy = _.cloneDeep(this._IsSecuredBy);

        //clone.Context = this.Context;
        return clone;
    }
}

export class IdentityRepresentsOrganization extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "IdentityRepresentsOrganization";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to SMIdentity
    private __IsRepresentedBy: SMIdentity;

    IsRepresentedBy(context?: BaseDataContext): SMIdentity {
        if (this.__IsRepresentedBy)
           return this.__IsRepresentedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsRepresentedBy) as SMIdentity;
    }
    setIsRepresentedBy(value: SMIdentity) {
        this.__IsRepresentedBy = value;
    }
    get _IsRepresentedBy(): string {
        return this.Out;
    }
    set _IsRepresentedBy(value: string) {
        this.Out = value;
    }
    //   In to Organization
    private __Represents: Organization;

    Represents(context?: BaseDataContext): Organization {
        if (this.__Represents)
           return this.__Represents;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Represents) as Organization;
    }
    setRepresents(value: Organization) {
        this.__Represents = value;
    }
    get _Represents(): string {
        return this.In;
    }
    set _Represents(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): IdentityRepresentsOrganization {
       return ModelUtils.deserializeEdge<IdentityRepresentsOrganization>(this, input, datacontext, super._deserialize);
    }


    clone(): IdentityRepresentsOrganization {
        let clone = new IdentityRepresentsOrganization();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IModule {
    Id: string,

    ProvidesFeature?: object[]

}

export class Module extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Module";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Module }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    // Relationship ProvidesFeature, returns Feature ModuleProvidesFeature[]
    private __ProvidesFeature: ModuleProvidesFeature[];
    ProvidesFeature(_context?: BaseDataContext): ModuleProvidesFeature[] {
        if (this.__ProvidesFeature)
            return this.__ProvidesFeature;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._ProvidesFeature), (id) => context.get(id) as ModuleProvidesFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setProvidesFeature(values: ModuleProvidesFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__ProvidesFeature = values;
     }
    get _ProvidesFeature(): Set<string> {
        if (!this._relationships.has("ProvidesFeature"))
            this._relationships.set("ProvidesFeature", new Set<string>());

        return this._relationships.get("ProvidesFeature");
    }
    set _ProvidesFeature(values: Set<string>) {
        this._relationships.set("ProvidesFeature", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'ProvidesFeature',
            edgeType: ModuleProvidesFeature,
            otherVertexPropertyName: 'IsInModule',
            otherVertexType: Feature,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Module {
        return ModelUtils.deserializeVertex<Module>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Module) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            ProvidesFeature: ModelUtils.serializeShallowEdge(model.ProvidesFeature(), 'ProvidesFeature'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Module {
        let clone = new Module();
        clone.data = _.cloneDeep(this.data);
        clone._ProvidesFeature = _.cloneDeep(this._ProvidesFeature);

        //clone.Context = this.Context;
        return clone;
    }
}

export class ModuleProvidesFeature extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "ModuleProvidesFeature";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Module
    private __IsInModule: Module;

    IsInModule(context?: BaseDataContext): Module {
        if (this.__IsInModule)
           return this.__IsInModule;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsInModule) as Module;
    }
    setIsInModule(value: Module) {
        this.__IsInModule = value;
    }
    get _IsInModule(): string {
        return this.Out;
    }
    set _IsInModule(value: string) {
        this.Out = value;
    }
    //   In to Feature
    private __ProvidesFeature: Feature;

    ProvidesFeature(context?: BaseDataContext): Feature {
        if (this.__ProvidesFeature)
           return this.__ProvidesFeature;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._ProvidesFeature) as Feature;
    }
    setProvidesFeature(value: Feature) {
        this.__ProvidesFeature = value;
    }
    get _ProvidesFeature(): string {
        return this.In;
    }
    set _ProvidesFeature(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): ModuleProvidesFeature {
       return ModelUtils.deserializeEdge<ModuleProvidesFeature>(this, input, datacontext, super._deserialize);
    }


    clone(): ModuleProvidesFeature {
        let clone = new ModuleProvidesFeature();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IOrganization {
    Id: string,
    LegalName: string,
    Address?: { [index: string]: string },
    Phones?: { [index: string]: any }[],
    Website?: string,
    readonly Super: boolean,

    IsRepresentedBy?: object[],
    Owns?: object[]

}

export class Organization extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Organization";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Organization }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get LegalName(): string {
        return this.data.LegalName;
    }
    set LegalName(value: string) {
        this.data.LegalName = value;
    }
    get Address(): { [index: string]: string } {
        return this.data.Address;
    }
    set Address(value: { [index: string]: string }) {
        this.data.Address = value;
    }
    get Phones(): { [index: string]: any }[] {
        return this.data.Phones;
    }
    set Phones(value: { [index: string]: any }[]) {
        this.data.Phones = value;
    }
    get Website(): string {
        return this.data.Website;
    }
    set Website(value: string) {
        this.data.Website = value;
    }
    get Super(): boolean {
        return this.data.Super;
    }

    // Relationships

    // Relationship IsRepresentedBy, returns SMIdentity IdentityRepresentsOrganization[]
    private __IsRepresentedBy: IdentityRepresentsOrganization[];
    IsRepresentedBy(_context?: BaseDataContext): IdentityRepresentsOrganization[] {
        if (this.__IsRepresentedBy)
            return this.__IsRepresentedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsRepresentedBy), (id) => context.get(id) as IdentityRepresentsOrganization);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsRepresentedBy(values: IdentityRepresentsOrganization[]) {
         if (this.Context != null)
             throw Error;

        this.__IsRepresentedBy = values;
     }
    get _IsRepresentedBy(): Set<string> {
        if (!this._relationships.has("IsRepresentedBy"))
            this._relationships.set("IsRepresentedBy", new Set<string>());

        return this._relationships.get("IsRepresentedBy");
    }
    set _IsRepresentedBy(values: Set<string>) {
        this._relationships.set("IsRepresentedBy", values);
    }

    // Relationship Owns, returns Tenant OrganizationOwnsTenant[]
    private __Owns: OrganizationOwnsTenant[];
    Owns(_context?: BaseDataContext): OrganizationOwnsTenant[] {
        if (this.__Owns)
            return this.__Owns;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Owns), (id) => context.get(id) as OrganizationOwnsTenant);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setOwns(values: OrganizationOwnsTenant[]) {
         if (this.Context != null)
             throw Error;

        this.__Owns = values;
     }
    get _Owns(): Set<string> {
        if (!this._relationships.has("Owns"))
            this._relationships.set("Owns", new Set<string>());

        return this._relationships.get("Owns");
    }
    set _Owns(values: Set<string>) {
        this._relationships.set("Owns", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsRepresentedBy',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'Represents',
            otherVertexType: SMIdentity,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Owns',
            edgeType: OrganizationOwnsTenant,
            otherVertexPropertyName: 'IsOwnedBy',
            otherVertexType: Tenant,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Organization {
        return ModelUtils.deserializeVertex<Organization>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Organization) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            LegalName: model.LegalName,
            Address: model.Address,
            Phones: model.Phones,
            Website: model.Website,
            Super: model.Super,
            IsRepresentedBy: ModelUtils.serializeShallowEdge(model.IsRepresentedBy(), 'IsRepresentedBy'),
            Owns: ModelUtils.serializeShallowEdge(model.Owns(), 'Owns'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Organization {
        let clone = new Organization();
        clone.data = _.cloneDeep(this.data);
        clone._IsRepresentedBy = _.cloneDeep(this._IsRepresentedBy);
        clone._Owns = _.cloneDeep(this._Owns);

        //clone.Context = this.Context;
        return clone;
    }
}

export class OrganizationOwnsTenant extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "OrganizationOwnsTenant";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Organization
    private __IsOwnedBy: Organization;

    IsOwnedBy(context?: BaseDataContext): Organization {
        if (this.__IsOwnedBy)
           return this.__IsOwnedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsOwnedBy) as Organization;
    }
    setIsOwnedBy(value: Organization) {
        this.__IsOwnedBy = value;
    }
    get _IsOwnedBy(): string {
        return this.Out;
    }
    set _IsOwnedBy(value: string) {
        this.Out = value;
    }
    //   In to Tenant
    private __Owns: Tenant;

    Owns(context?: BaseDataContext): Tenant {
        if (this.__Owns)
           return this.__Owns;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Owns) as Tenant;
    }
    setOwns(value: Tenant) {
        this.__Owns = value;
    }
    get _Owns(): string {
        return this.In;
    }
    set _Owns(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): OrganizationOwnsTenant {
       return ModelUtils.deserializeEdge<OrganizationOwnsTenant>(this, input, datacontext, super._deserialize);
    }


    clone(): OrganizationOwnsTenant {
        let clone = new OrganizationOwnsTenant();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IPermission {
    Id: string,

    IsRequiredBy?: object[],
    IsGrantedBy?: object[],
    Requires?: object[],
    Secures?: object[]

}

export class Permission extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Permission";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Permission }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    // Relationship IsRequiredBy, returns Permission PermissionRequiresPermission[]
    private __IsRequiredBy: PermissionRequiresPermission[];
    IsRequiredBy(_context?: BaseDataContext): PermissionRequiresPermission[] {
        if (this.__IsRequiredBy)
            return this.__IsRequiredBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsRequiredBy), (id) => context.get(id) as PermissionRequiresPermission);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsRequiredBy(values: PermissionRequiresPermission[]) {
         if (this.Context != null)
             throw Error;

        this.__IsRequiredBy = values;
     }
    get _IsRequiredBy(): Set<string> {
        if (!this._relationships.has("IsRequiredBy"))
            this._relationships.set("IsRequiredBy", new Set<string>());

        return this._relationships.get("IsRequiredBy");
    }
    set _IsRequiredBy(values: Set<string>) {
        this._relationships.set("IsRequiredBy", values);
    }

    // Relationship IsGrantedBy, returns SecurityRole SecurityRoleGrantsPermission[]
    private __IsGrantedBy: SecurityRoleGrantsPermission[];
    IsGrantedBy(_context?: BaseDataContext): SecurityRoleGrantsPermission[] {
        if (this.__IsGrantedBy)
            return this.__IsGrantedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsGrantedBy), (id) => context.get(id) as SecurityRoleGrantsPermission);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsGrantedBy(values: SecurityRoleGrantsPermission[]) {
         if (this.Context != null)
             throw Error;

        this.__IsGrantedBy = values;
     }
    get _IsGrantedBy(): Set<string> {
        if (!this._relationships.has("IsGrantedBy"))
            this._relationships.set("IsGrantedBy", new Set<string>());

        return this._relationships.get("IsGrantedBy");
    }
    set _IsGrantedBy(values: Set<string>) {
        this._relationships.set("IsGrantedBy", values);
    }

    // Relationship Requires, returns Permission PermissionRequiresPermission[]
    private __Requires: PermissionRequiresPermission[];
    Requires(_context?: BaseDataContext): PermissionRequiresPermission[] {
        if (this.__Requires)
            return this.__Requires;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Requires), (id) => context.get(id) as PermissionRequiresPermission);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setRequires(values: PermissionRequiresPermission[]) {
         if (this.Context != null)
             throw Error;

        this.__Requires = values;
     }
    get _Requires(): Set<string> {
        if (!this._relationships.has("Requires"))
            this._relationships.set("Requires", new Set<string>());

        return this._relationships.get("Requires");
    }
    set _Requires(values: Set<string>) {
        this._relationships.set("Requires", values);
    }

    // Relationship Secures, returns Feature PermissionSecuresFeature[]
    private __Secures: PermissionSecuresFeature[];
    Secures(_context?: BaseDataContext): PermissionSecuresFeature[] {
        if (this.__Secures)
            return this.__Secures;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Secures), (id) => context.get(id) as PermissionSecuresFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setSecures(values: PermissionSecuresFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__Secures = values;
     }
    get _Secures(): Set<string> {
        if (!this._relationships.has("Secures"))
            this._relationships.set("Secures", new Set<string>());

        return this._relationships.get("Secures");
    }
    set _Secures(values: Set<string>) {
        this._relationships.set("Secures", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsRequiredBy',
            edgeType: PermissionRequiresPermission,
            otherVertexPropertyName: 'Requires',
            otherVertexType: Permission,
        },
        {
            propertyName: 'IsGrantedBy',
            edgeType: SecurityRoleGrantsPermission,
            otherVertexPropertyName: 'Grants',
            otherVertexType: SecurityRole,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Requires',
            edgeType: PermissionRequiresPermission,
            otherVertexPropertyName: 'IsRequiredBy',
            otherVertexType: Permission,
        },
        {
            propertyName: 'Secures',
            edgeType: PermissionSecuresFeature,
            otherVertexPropertyName: 'IsSecuredBy',
            otherVertexType: Feature,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Permission {
        return ModelUtils.deserializeVertex<Permission>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Permission) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            IsRequiredBy: ModelUtils.serializeShallowEdge(model.IsRequiredBy(), 'IsRequiredBy'),
            IsGrantedBy: ModelUtils.serializeShallowEdge(model.IsGrantedBy(), 'IsGrantedBy'),
            Requires: ModelUtils.serializeShallowEdge(model.Requires(), 'Requires'),
            Secures: ModelUtils.serializeShallowEdge(model.Secures(), 'Secures'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Permission {
        let clone = new Permission();
        clone.data = _.cloneDeep(this.data);
        clone._IsRequiredBy = _.cloneDeep(this._IsRequiredBy);
        clone._IsGrantedBy = _.cloneDeep(this._IsGrantedBy);
        clone._Requires = _.cloneDeep(this._Requires);
        clone._Secures = _.cloneDeep(this._Secures);

        //clone.Context = this.Context;
        return clone;
    }
}

export class PermissionRequiresPermission extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "PermissionRequiresPermission";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Permission
    private __IsRequiredBy: Permission;

    IsRequiredBy(context?: BaseDataContext): Permission {
        if (this.__IsRequiredBy)
           return this.__IsRequiredBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsRequiredBy) as Permission;
    }
    setIsRequiredBy(value: Permission) {
        this.__IsRequiredBy = value;
    }
    get _IsRequiredBy(): string {
        return this.Out;
    }
    set _IsRequiredBy(value: string) {
        this.Out = value;
    }
    //   In to Permission
    private __Requires: Permission;

    Requires(context?: BaseDataContext): Permission {
        if (this.__Requires)
           return this.__Requires;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Requires) as Permission;
    }
    setRequires(value: Permission) {
        this.__Requires = value;
    }
    get _Requires(): string {
        return this.In;
    }
    set _Requires(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): PermissionRequiresPermission {
       return ModelUtils.deserializeEdge<PermissionRequiresPermission>(this, input, datacontext, super._deserialize);
    }


    clone(): PermissionRequiresPermission {
        let clone = new PermissionRequiresPermission();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class PermissionSecuresFeature extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "PermissionSecuresFeature";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Permission
    private __IsSecuredBy: Permission;

    IsSecuredBy(context?: BaseDataContext): Permission {
        if (this.__IsSecuredBy)
           return this.__IsSecuredBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsSecuredBy) as Permission;
    }
    setIsSecuredBy(value: Permission) {
        this.__IsSecuredBy = value;
    }
    get _IsSecuredBy(): string {
        return this.Out;
    }
    set _IsSecuredBy(value: string) {
        this.Out = value;
    }
    //   In to Feature
    private __Secures: Feature;

    Secures(context?: BaseDataContext): Feature {
        if (this.__Secures)
           return this.__Secures;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Secures) as Feature;
    }
    setSecures(value: Feature) {
        this.__Secures = value;
    }
    get _Secures(): string {
        return this.In;
    }
    set _Secures(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): PermissionSecuresFeature {
       return ModelUtils.deserializeEdge<PermissionSecuresFeature>(this, input, datacontext, super._deserialize);
    }


    clone(): PermissionSecuresFeature {
        let clone = new PermissionSecuresFeature();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IPersona {
    Id: string,
    Name: string,
    MenuLabel: string,
    ApplicationUrl: string,

    IsPerformedBy?: object[],
    Experiences?: object[]

}

export class Persona extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Persona";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Persona }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get Name(): string {
        return this.data.Name;
    }
    set Name(value: string) {
        this.data.Name = value;
    }
    get MenuLabel(): string {
        return this.data.MenuLabel;
    }
    set MenuLabel(value: string) {
        this.data.MenuLabel = value;
    }
    get ApplicationUrl(): string {
        return this.data.ApplicationUrl;
    }
    set ApplicationUrl(value: string) {
        this.data.ApplicationUrl = value;
    }

    // Relationships

    // Relationship IsPerformedBy, returns User UserActsAsPersona[]
    private __IsPerformedBy: UserActsAsPersona[];
    IsPerformedBy(_context?: BaseDataContext): UserActsAsPersona[] {
        if (this.__IsPerformedBy)
            return this.__IsPerformedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsPerformedBy), (id) => context.get(id) as UserActsAsPersona);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsPerformedBy(values: UserActsAsPersona[]) {
         if (this.Context != null)
             throw Error;

        this.__IsPerformedBy = values;
     }
    get _IsPerformedBy(): Set<string> {
        if (!this._relationships.has("IsPerformedBy"))
            this._relationships.set("IsPerformedBy", new Set<string>());

        return this._relationships.get("IsPerformedBy");
    }
    set _IsPerformedBy(values: Set<string>) {
        this._relationships.set("IsPerformedBy", values);
    }

    // Relationship Experiences, returns Tenant PersonaExperiencesTenant[]
    private __Experiences: PersonaExperiencesTenant[];
    Experiences(_context?: BaseDataContext): PersonaExperiencesTenant[] {
        if (this.__Experiences)
            return this.__Experiences;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Experiences), (id) => context.get(id) as PersonaExperiencesTenant);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setExperiences(values: PersonaExperiencesTenant[]) {
         if (this.Context != null)
             throw Error;

        this.__Experiences = values;
     }
    get _Experiences(): Set<string> {
        if (!this._relationships.has("Experiences"))
            this._relationships.set("Experiences", new Set<string>());

        return this._relationships.get("Experiences");
    }
    set _Experiences(values: Set<string>) {
        this._relationships.set("Experiences", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsPerformedBy',
            edgeType: UserActsAsPersona,
            otherVertexPropertyName: 'ActsAs',
            otherVertexType: User,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Experiences',
            edgeType: PersonaExperiencesTenant,
            otherVertexPropertyName: 'IsExperienceFor',
            otherVertexType: Tenant,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Persona {
        return ModelUtils.deserializeVertex<Persona>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Persona) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            Name: model.Name,
            MenuLabel: model.MenuLabel,
            ApplicationUrl: model.ApplicationUrl,
            IsPerformedBy: ModelUtils.serializeShallowEdge(model.IsPerformedBy(), 'IsPerformedBy'),
            Experiences: ModelUtils.serializeShallowEdge(model.Experiences(), 'Experiences'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Persona {
        let clone = new Persona();
        clone.data = _.cloneDeep(this.data);
        clone._IsPerformedBy = _.cloneDeep(this._IsPerformedBy);
        clone._Experiences = _.cloneDeep(this._Experiences);

        //clone.Context = this.Context;
        return clone;
    }
}

export class PersonaExperiencesTenant extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "PersonaExperiencesTenant";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Persona
    private __IsExperienceFor: Persona;

    IsExperienceFor(context?: BaseDataContext): Persona {
        if (this.__IsExperienceFor)
           return this.__IsExperienceFor;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsExperienceFor) as Persona;
    }
    setIsExperienceFor(value: Persona) {
        this.__IsExperienceFor = value;
    }
    get _IsExperienceFor(): string {
        return this.Out;
    }
    set _IsExperienceFor(value: string) {
        this.Out = value;
    }
    //   In to Tenant
    private __Experiences: Tenant;

    Experiences(context?: BaseDataContext): Tenant {
        if (this.__Experiences)
           return this.__Experiences;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Experiences) as Tenant;
    }
    setExperiences(value: Tenant) {
        this.__Experiences = value;
    }
    get _Experiences(): string {
        return this.In;
    }
    set _Experiences(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): PersonaExperiencesTenant {
       return ModelUtils.deserializeEdge<PersonaExperiencesTenant>(this, input, datacontext, super._deserialize);
    }


    clone(): PersonaExperiencesTenant {
        let clone = new PersonaExperiencesTenant();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface ISecurityRole {
    Id: string,

    IsFilledBy?: object[],
    Grants?: object[]

}

export class SecurityRole extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "SecurityRole";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof SecurityRole }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    // Relationship IsFilledBy, returns AdminUser AdminUserFillsSecurityRole[]
    private __IsFilledBy: AdminUserFillsSecurityRole[];
    IsFilledBy(_context?: BaseDataContext): AdminUserFillsSecurityRole[] {
        if (this.__IsFilledBy)
            return this.__IsFilledBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsFilledBy), (id) => context.get(id) as AdminUserFillsSecurityRole);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsFilledBy(values: AdminUserFillsSecurityRole[]) {
         if (this.Context != null)
             throw Error;

        this.__IsFilledBy = values;
     }
    get _IsFilledBy(): Set<string> {
        if (!this._relationships.has("IsFilledBy"))
            this._relationships.set("IsFilledBy", new Set<string>());

        return this._relationships.get("IsFilledBy");
    }
    set _IsFilledBy(values: Set<string>) {
        this._relationships.set("IsFilledBy", values);
    }

    // Relationship Grants, returns Permission SecurityRoleGrantsPermission[]
    private __Grants: SecurityRoleGrantsPermission[];
    Grants(_context?: BaseDataContext): SecurityRoleGrantsPermission[] {
        if (this.__Grants)
            return this.__Grants;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Grants), (id) => context.get(id) as SecurityRoleGrantsPermission);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setGrants(values: SecurityRoleGrantsPermission[]) {
         if (this.Context != null)
             throw Error;

        this.__Grants = values;
     }
    get _Grants(): Set<string> {
        if (!this._relationships.has("Grants"))
            this._relationships.set("Grants", new Set<string>());

        return this._relationships.get("Grants");
    }
    set _Grants(values: Set<string>) {
        this._relationships.set("Grants", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsFilledBy',
            edgeType: AdminUserFillsSecurityRole,
            otherVertexPropertyName: 'FillsSecurityRole',
            otherVertexType: AdminUser,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Grants',
            edgeType: SecurityRoleGrantsPermission,
            otherVertexPropertyName: 'IsGrantedBy',
            otherVertexType: Permission,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): SecurityRole {
        return ModelUtils.deserializeVertex<SecurityRole>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: SecurityRole) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            IsFilledBy: ModelUtils.serializeShallowEdge(model.IsFilledBy(), 'IsFilledBy'),
            Grants: ModelUtils.serializeShallowEdge(model.Grants(), 'Grants'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): SecurityRole {
        let clone = new SecurityRole();
        clone.data = _.cloneDeep(this.data);
        clone._IsFilledBy = _.cloneDeep(this._IsFilledBy);
        clone._Grants = _.cloneDeep(this._Grants);

        //clone.Context = this.Context;
        return clone;
    }
}

export class SecurityRoleGrantsPermission extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "SecurityRoleGrantsPermission";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to SecurityRole
    private __IsGrantedBy: SecurityRole;

    IsGrantedBy(context?: BaseDataContext): SecurityRole {
        if (this.__IsGrantedBy)
           return this.__IsGrantedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsGrantedBy) as SecurityRole;
    }
    setIsGrantedBy(value: SecurityRole) {
        this.__IsGrantedBy = value;
    }
    get _IsGrantedBy(): string {
        return this.Out;
    }
    set _IsGrantedBy(value: string) {
        this.Out = value;
    }
    //   In to Permission
    private __Grants: Permission;

    Grants(context?: BaseDataContext): Permission {
        if (this.__Grants)
           return this.__Grants;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Grants) as Permission;
    }
    setGrants(value: Permission) {
        this.__Grants = value;
    }
    get _Grants(): string {
        return this.In;
    }
    set _Grants(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): SecurityRoleGrantsPermission {
       return ModelUtils.deserializeEdge<SecurityRoleGrantsPermission>(this, input, datacontext, super._deserialize);
    }


    clone(): SecurityRoleGrantsPermission {
        let clone = new SecurityRoleGrantsPermission();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface ISMIdentity {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,

    IsPermittedFor?: object[],
    Represents?: object[]

}

export class SMIdentity extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "SMIdentity";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof SMIdentity }[] = [
            {className: 'AdminUser', type: AdminUser},
            {className: 'User', type: User},
            {className: 'App', type: App},
            {className: 'Contact', type: Contact},
            {className: 'Developer', type: Developer},
            {className: 'TenantUser', type: TenantUser},

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get DisplayName(): string {
        return this.data.DisplayName;
    }
    set DisplayName(value: string) {
        this.data.DisplayName = value;
    }
    get CreatedDate(): Date {
        return this.data.CreatedDate ? new Date(this.data.CreatedDate) : undefined;
    }
    get LastLoginDate(): Date {
        return this.data.LastLoginDate ? new Date(this.data.LastLoginDate) : undefined;
    }
    set LastLoginDate(value: Date) {
        this.data.LastLoginDate = value;
    }
    get IsActive(): boolean {
        return this.data.IsActive;
    }

    // Relationships

    // Relationship IsPermittedFor, returns Tenant TenantPermitsIdentity[]
    private __IsPermittedFor: TenantPermitsIdentity[];
    IsPermittedFor(_context?: BaseDataContext): TenantPermitsIdentity[] {
        if (this.__IsPermittedFor)
            return this.__IsPermittedFor;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsPermittedFor), (id) => context.get(id) as TenantPermitsIdentity);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsPermittedFor(values: TenantPermitsIdentity[]) {
         if (this.Context != null)
             throw Error;

        this.__IsPermittedFor = values;
     }
    get _IsPermittedFor(): Set<string> {
        if (!this._relationships.has("IsPermittedFor"))
            this._relationships.set("IsPermittedFor", new Set<string>());

        return this._relationships.get("IsPermittedFor");
    }
    set _IsPermittedFor(values: Set<string>) {
        this._relationships.set("IsPermittedFor", values);
    }

    // Relationship Represents, returns Organization IdentityRepresentsOrganization[]
    private __Represents: IdentityRepresentsOrganization[];
    Represents(_context?: BaseDataContext): IdentityRepresentsOrganization[] {
        if (this.__Represents)
            return this.__Represents;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Represents), (id) => context.get(id) as IdentityRepresentsOrganization);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setRepresents(values: IdentityRepresentsOrganization[]) {
         if (this.Context != null)
             throw Error;

        this.__Represents = values;
     }
    get _Represents(): Set<string> {
        if (!this._relationships.has("Represents"))
            this._relationships.set("Represents", new Set<string>());

        return this._relationships.get("Represents");
    }
    set _Represents(values: Set<string>) {
        this._relationships.set("Represents", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): SMIdentity {
        return ModelUtils.deserializeVertex<SMIdentity>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: SMIdentity) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): SMIdentity {
        let clone = new SMIdentity();
        clone.data = _.cloneDeep(this.data);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._Represents = _.cloneDeep(this._Represents);

        //clone.Context = this.Context;
        return clone;
    }
}

export interface ITenant {
    Id: string,
    Key: string,
    Summary?: string,
    Description: string,
    readonly Mode: string,
    FeatureFacets?: { [index: string]: boolean },
    SubscriptionKey?: string,
    LastAccessed?: Date,
    SalesforceCustomerId?: string,

    IsOwnedBy?: object[],
    IsExperienceFor?: object[],
    DeploysTo?: object[],
    Enables?: object[],
    Permits?: object[],
    SubscribesTo?: object[]

}

export class Tenant extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Tenant";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Tenant }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get Key(): string {
        return this.data.Key;
    }
    set Key(value: string) {
        this.data.Key = value;
    }
    get Summary(): string {
        return this.data.Summary;
    }
    set Summary(value: string) {
        this.data.Summary = value;
    }
    get Description(): string {
        return this.data.Description;
    }
    set Description(value: string) {
        this.data.Description = value;
    }
    get Mode(): string {
        return this.data.Mode;
    }
    get FeatureFacets(): { [index: string]: boolean } {
        return this.data.FeatureFacets;
    }
    set FeatureFacets(value: { [index: string]: boolean }) {
        this.data.FeatureFacets = value;
    }
    get SubscriptionKey(): string {
        return this.data.SubscriptionKey;
    }
    set SubscriptionKey(value: string) {
        this.data.SubscriptionKey = value;
    }
    get LastAccessed(): Date {
        return this.data.LastAccessed ? new Date(this.data.LastAccessed) : undefined;
    }
    set LastAccessed(value: Date) {
        this.data.LastAccessed = value;
    }
    get SalesforceCustomerId(): string {
        return this.data.SalesforceCustomerId;
    }
    set SalesforceCustomerId(value: string) {
        this.data.SalesforceCustomerId = value;
    }

    // Relationships

    // Relationship IsOwnedBy, returns Organization OrganizationOwnsTenant[]
    private __IsOwnedBy: OrganizationOwnsTenant[];
    IsOwnedBy(_context?: BaseDataContext): OrganizationOwnsTenant[] {
        if (this.__IsOwnedBy)
            return this.__IsOwnedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsOwnedBy), (id) => context.get(id) as OrganizationOwnsTenant);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsOwnedBy(values: OrganizationOwnsTenant[]) {
         if (this.Context != null)
             throw Error;

        this.__IsOwnedBy = values;
     }
    get _IsOwnedBy(): Set<string> {
        if (!this._relationships.has("IsOwnedBy"))
            this._relationships.set("IsOwnedBy", new Set<string>());

        return this._relationships.get("IsOwnedBy");
    }
    set _IsOwnedBy(values: Set<string>) {
        this._relationships.set("IsOwnedBy", values);
    }

    // Relationship IsExperienceFor, returns Persona PersonaExperiencesTenant[]
    private __IsExperienceFor: PersonaExperiencesTenant[];
    IsExperienceFor(_context?: BaseDataContext): PersonaExperiencesTenant[] {
        if (this.__IsExperienceFor)
            return this.__IsExperienceFor;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsExperienceFor), (id) => context.get(id) as PersonaExperiencesTenant);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsExperienceFor(values: PersonaExperiencesTenant[]) {
         if (this.Context != null)
             throw Error;

        this.__IsExperienceFor = values;
     }
    get _IsExperienceFor(): Set<string> {
        if (!this._relationships.has("IsExperienceFor"))
            this._relationships.set("IsExperienceFor", new Set<string>());

        return this._relationships.get("IsExperienceFor");
    }
    set _IsExperienceFor(values: Set<string>) {
        this._relationships.set("IsExperienceFor", values);
    }

    // Relationship DeploysTo, returns TenantArea TenantDeploysToArea[]
    private __DeploysTo: TenantDeploysToArea[];
    DeploysTo(_context?: BaseDataContext): TenantDeploysToArea[] {
        if (this.__DeploysTo)
            return this.__DeploysTo;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._DeploysTo), (id) => context.get(id) as TenantDeploysToArea);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setDeploysTo(values: TenantDeploysToArea[]) {
         if (this.Context != null)
             throw Error;

        this.__DeploysTo = values;
     }
    get _DeploysTo(): Set<string> {
        if (!this._relationships.has("DeploysTo"))
            this._relationships.set("DeploysTo", new Set<string>());

        return this._relationships.get("DeploysTo");
    }
    set _DeploysTo(values: Set<string>) {
        this._relationships.set("DeploysTo", values);
    }

    // Relationship Enables, returns TenantFeature TenantEnablesTenantFeature[]
    private __Enables: TenantEnablesTenantFeature[];
    Enables(_context?: BaseDataContext): TenantEnablesTenantFeature[] {
        if (this.__Enables)
            return this.__Enables;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Enables), (id) => context.get(id) as TenantEnablesTenantFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setEnables(values: TenantEnablesTenantFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__Enables = values;
     }
    get _Enables(): Set<string> {
        if (!this._relationships.has("Enables"))
            this._relationships.set("Enables", new Set<string>());

        return this._relationships.get("Enables");
    }
    set _Enables(values: Set<string>) {
        this._relationships.set("Enables", values);
    }

    // Relationship Permits, returns SMIdentity TenantPermitsIdentity[]
    private __Permits: TenantPermitsIdentity[];
    Permits(_context?: BaseDataContext): TenantPermitsIdentity[] {
        if (this.__Permits)
            return this.__Permits;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Permits), (id) => context.get(id) as TenantPermitsIdentity);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setPermits(values: TenantPermitsIdentity[]) {
         if (this.Context != null)
             throw Error;

        this.__Permits = values;
     }
    get _Permits(): Set<string> {
        if (!this._relationships.has("Permits"))
            this._relationships.set("Permits", new Set<string>());

        return this._relationships.get("Permits");
    }
    set _Permits(values: Set<string>) {
        this._relationships.set("Permits", values);
    }

    // Relationship SubscribesTo, returns TenantModule TenantSubscribesToTenantModule[]
    private __SubscribesTo: TenantSubscribesToTenantModule[];
    SubscribesTo(_context?: BaseDataContext): TenantSubscribesToTenantModule[] {
        if (this.__SubscribesTo)
            return this.__SubscribesTo;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._SubscribesTo), (id) => context.get(id) as TenantSubscribesToTenantModule);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setSubscribesTo(values: TenantSubscribesToTenantModule[]) {
         if (this.Context != null)
             throw Error;

        this.__SubscribesTo = values;
     }
    get _SubscribesTo(): Set<string> {
        if (!this._relationships.has("SubscribesTo"))
            this._relationships.set("SubscribesTo", new Set<string>());

        return this._relationships.get("SubscribesTo");
    }
    set _SubscribesTo(values: Set<string>) {
        this._relationships.set("SubscribesTo", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsOwnedBy',
            edgeType: OrganizationOwnsTenant,
            otherVertexPropertyName: 'Owns',
            otherVertexType: Organization,
        },
        {
            propertyName: 'IsExperienceFor',
            edgeType: PersonaExperiencesTenant,
            otherVertexPropertyName: 'Experiences',
            otherVertexType: Persona,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'DeploysTo',
            edgeType: TenantDeploysToArea,
            otherVertexPropertyName: 'IsFor',
            otherVertexType: TenantArea,
        },
        {
            propertyName: 'Enables',
            edgeType: TenantEnablesTenantFeature,
            otherVertexPropertyName: 'IsEnabledBy',
            otherVertexType: TenantFeature,
        },
        {
            propertyName: 'Permits',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'IsPermittedFor',
            otherVertexType: SMIdentity,
        },
        {
            propertyName: 'SubscribesTo',
            edgeType: TenantSubscribesToTenantModule,
            otherVertexPropertyName: 'IsSubscribedBy',
            otherVertexType: TenantModule,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Tenant {
        return ModelUtils.deserializeVertex<Tenant>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Tenant) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            Key: model.Key,
            Summary: model.Summary,
            Description: model.Description,
            Mode: model.Mode,
            FeatureFacets: model.FeatureFacets,
            SubscriptionKey: model.SubscriptionKey,
            LastAccessed: model.LastAccessed,
            SalesforceCustomerId: model.SalesforceCustomerId,
            IsOwnedBy: ModelUtils.serializeShallowEdge(model.IsOwnedBy(), 'IsOwnedBy'),
            IsExperienceFor: ModelUtils.serializeShallowEdge(model.IsExperienceFor(), 'IsExperienceFor'),
            DeploysTo: ModelUtils.serializeShallowEdge(model.DeploysTo(), 'DeploysTo'),
            Enables: ModelUtils.serializeShallowEdge(model.Enables(), 'Enables'),
            Permits: ModelUtils.serializeShallowEdge(model.Permits(), 'Permits'),
            SubscribesTo: ModelUtils.serializeShallowEdge(model.SubscribesTo(), 'SubscribesTo'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Tenant {
        let clone = new Tenant();
        clone.data = _.cloneDeep(this.data);
        clone._IsOwnedBy = _.cloneDeep(this._IsOwnedBy);
        clone._IsExperienceFor = _.cloneDeep(this._IsExperienceFor);
        clone._DeploysTo = _.cloneDeep(this._DeploysTo);
        clone._Enables = _.cloneDeep(this._Enables);
        clone._Permits = _.cloneDeep(this._Permits);
        clone._SubscribesTo = _.cloneDeep(this._SubscribesTo);

        //clone.Context = this.Context;
        return clone;
    }
}

export class TenantActivity extends DocumentModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantActivity";
    }

    // Properties
    get DeletedDate(): Date {
        return this.data.DeletedDate ? new Date(this.data.DeletedDate) : undefined;
    }
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get Message(): string {
        return this.data.Message;
    }
    set Message(value: string) {
        this.data.Message = value;
    }
    get TenantKey(): string {
        return this.data.TenantKey;
    }
    set TenantKey(value: string) {
        this.data.TenantKey = value;
    }
    get AreaName(): string {
        return this.data.AreaName;
    }
    set AreaName(value: string) {
        this.data.AreaName = value;
    }
    get EventName(): string {
        return this.data.EventName;
    }
    set EventName(value: string) {
        this.data.EventName = value;
    }
    get IdentityKey(): string {
        return this.data.IdentityKey;
    }
    set IdentityKey(value: string) {
        this.data.IdentityKey = value;
    }
    get ActivityTime(): Date {
        return this.data.ActivityTime ? new Date(this.data.ActivityTime) : undefined;
    }
    set ActivityTime(value: Date) {
        this.data.ActivityTime = value;
    }


    deserialize(input: Object, datacontext): TenantActivity {
        if (!input)
            return this;

        super._deserialize(input, datacontext);

        return this;
    }

    serialize(_model?: TenantActivity) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            DeletedDate: model.DeletedDate,
            Id: model.Id,
            Message: model.Message,
            TenantKey: model.TenantKey,
            AreaName: model.AreaName,
            EventName: model.EventName,
            IdentityKey: model.IdentityKey,
            ActivityTime: model.ActivityTime,
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): TenantActivity {
        let clone = new TenantActivity();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface ITenantArea {
    Id: string,
    Name: string,
    TenantKey: string,
    Description: string,
    readonly Mode: string,
    readonly IsProduction: boolean,
    readonly LastAccessed?: Date,
    readonly Tier?: string,
    readonly CustomDatabaseThroughputRUs?: number,
    Alias?: string,
    readonly Order: number,
    readonly RebuildApprovals?: any[],
    readonly DeleteApprovals?: any[],
    readonly CollectionDeleted?: boolean,
    FeatureFacets?: { [index: string]: boolean }

    IsFor?: object[],
    IsWorkedBy?: object[]

}

export class TenantArea extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantArea";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof TenantArea }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }
    get Name(): string {
        return this.data.Name;
    }
    set Name(value: string) {
        this.data.Name = value;
    }
    get TenantKey(): string {
        return this.data.TenantKey;
    }
    set TenantKey(value: string) {
        this.data.TenantKey = value;
    }
    get Description(): string {
        return this.data.Description;
    }
    set Description(value: string) {
        this.data.Description = value;
    }
    get Mode(): string {
        return this.data.Mode;
    }
    get IsProduction(): boolean {
        return this.data.IsProduction;
    }
    get LastAccessed(): Date {
        return this.data.LastAccessed ? new Date(this.data.LastAccessed) : undefined;
    }
    get Tier(): string {
        return this.data.Tier;
    }
    get CustomDatabaseThroughputRUs(): number {
        return this.data.CustomDatabaseThroughputRUs;
    }
    get Alias(): string {
        return this.data.Alias;
    }
    set Alias(value: string) {
        this.data.Alias = value;
    }
    get Order(): number {
        return this.data.Order;
    }
    get RebuildApprovals(): any[] {
        return this.data.RebuildApprovals;
    }
    get DeleteApprovals(): any[] {
        return this.data.DeleteApprovals;
    }
    get CollectionDeleted(): boolean {
        return this.data.CollectionDeleted;
    }
    get FeatureFacets(): { [index: string]: boolean } {
        return this.data.FeatureFacets;
    }
    set FeatureFacets(value: { [index: string]: boolean }) {
        this.data.FeatureFacets = value;
    }

    // Relationships

    // Relationship IsFor, returns Tenant TenantDeploysToArea[]
    private __IsFor: TenantDeploysToArea[];
    IsFor(_context?: BaseDataContext): TenantDeploysToArea[] {
        if (this.__IsFor)
            return this.__IsFor;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsFor), (id) => context.get(id) as TenantDeploysToArea);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsFor(values: TenantDeploysToArea[]) {
         if (this.Context != null)
             throw Error;

        this.__IsFor = values;
     }
    get _IsFor(): Set<string> {
        if (!this._relationships.has("IsFor"))
            this._relationships.set("IsFor", new Set<string>());

        return this._relationships.get("IsFor");
    }
    set _IsFor(values: Set<string>) {
        this._relationships.set("IsFor", values);
    }

    // Relationship IsWorkedBy, returns TenantUser TenantUserWorksInArea[]
    private __IsWorkedBy: TenantUserWorksInArea[];
    IsWorkedBy(_context?: BaseDataContext): TenantUserWorksInArea[] {
        if (this.__IsWorkedBy)
            return this.__IsWorkedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsWorkedBy), (id) => context.get(id) as TenantUserWorksInArea);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsWorkedBy(values: TenantUserWorksInArea[]) {
         if (this.Context != null)
             throw Error;

        this.__IsWorkedBy = values;
     }
    get _IsWorkedBy(): Set<string> {
        if (!this._relationships.has("IsWorkedBy"))
            this._relationships.set("IsWorkedBy", new Set<string>());

        return this._relationships.get("IsWorkedBy");
    }
    set _IsWorkedBy(values: Set<string>) {
        this._relationships.set("IsWorkedBy", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsFor',
            edgeType: TenantDeploysToArea,
            otherVertexPropertyName: 'DeploysTo',
            otherVertexType: Tenant,
        },
        {
            propertyName: 'IsWorkedBy',
            edgeType: TenantUserWorksInArea,
            otherVertexPropertyName: 'WorksIn',
            otherVertexType: TenantUser,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [

    ];

    deserialize(input: Object, datacontext: BaseDataContext): TenantArea {
        return ModelUtils.deserializeVertex<TenantArea>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: TenantArea) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            Name: model.Name,
            TenantKey: model.TenantKey,
            Description: model.Description,
            Mode: model.Mode,
            IsProduction: model.IsProduction,
            LastAccessed: model.LastAccessed,
            Tier: model.Tier,
            CustomDatabaseThroughputRUs: model.CustomDatabaseThroughputRUs,
            Alias: model.Alias,
            Order: model.Order,
            RebuildApprovals: model.RebuildApprovals,
            DeleteApprovals: model.DeleteApprovals,
            CollectionDeleted: model.CollectionDeleted,
            FeatureFacets: model.FeatureFacets,
            IsFor: ModelUtils.serializeShallowEdge(model.IsFor(), 'IsFor'),
            IsWorkedBy: ModelUtils.serializeShallowEdge(model.IsWorkedBy(), 'IsWorkedBy'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): TenantArea {
        let clone = new TenantArea();
        clone.data = _.cloneDeep(this.data);
        clone._IsFor = _.cloneDeep(this._IsFor);
        clone._IsWorkedBy = _.cloneDeep(this._IsWorkedBy);

        //clone.Context = this.Context;
        return clone;
    }
}

export class TenantDeploysToArea extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantDeploysToArea";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Tenant
    private __IsFor: Tenant;

    IsFor(context?: BaseDataContext): Tenant {
        if (this.__IsFor)
           return this.__IsFor;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsFor) as Tenant;
    }
    setIsFor(value: Tenant) {
        this.__IsFor = value;
    }
    get _IsFor(): string {
        return this.Out;
    }
    set _IsFor(value: string) {
        this.Out = value;
    }
    //   In to TenantArea
    private __DeploysTo: TenantArea;

    DeploysTo(context?: BaseDataContext): TenantArea {
        if (this.__DeploysTo)
           return this.__DeploysTo;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._DeploysTo) as TenantArea;
    }
    setDeploysTo(value: TenantArea) {
        this.__DeploysTo = value;
    }
    get _DeploysTo(): string {
        return this.In;
    }
    set _DeploysTo(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): TenantDeploysToArea {
       return ModelUtils.deserializeEdge<TenantDeploysToArea>(this, input, datacontext, super._deserialize);
    }


    clone(): TenantDeploysToArea {
        let clone = new TenantDeploysToArea();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class TenantEnablesTenantFeature extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantEnablesTenantFeature";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Tenant
    private __IsEnabledBy: Tenant;

    IsEnabledBy(context?: BaseDataContext): Tenant {
        if (this.__IsEnabledBy)
           return this.__IsEnabledBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsEnabledBy) as Tenant;
    }
    setIsEnabledBy(value: Tenant) {
        this.__IsEnabledBy = value;
    }
    get _IsEnabledBy(): string {
        return this.Out;
    }
    set _IsEnabledBy(value: string) {
        this.Out = value;
    }
    //   In to TenantFeature
    private __Enables: TenantFeature;

    Enables(context?: BaseDataContext): TenantFeature {
        if (this.__Enables)
           return this.__Enables;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Enables) as TenantFeature;
    }
    setEnables(value: TenantFeature) {
        this.__Enables = value;
    }
    get _Enables(): string {
        return this.In;
    }
    set _Enables(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): TenantEnablesTenantFeature {
       return ModelUtils.deserializeEdge<TenantEnablesTenantFeature>(this, input, datacontext, super._deserialize);
    }


    clone(): TenantEnablesTenantFeature {
        let clone = new TenantEnablesTenantFeature();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface ITenantFeature {
    Id: string

    IsEnabledBy?: object[],
    IsProvidedBy?: object[]

}

export class TenantFeature extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantFeature";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof TenantFeature }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    // Relationship IsEnabledBy, returns Tenant TenantEnablesTenantFeature[]
    private __IsEnabledBy: TenantEnablesTenantFeature[];
    IsEnabledBy(_context?: BaseDataContext): TenantEnablesTenantFeature[] {
        if (this.__IsEnabledBy)
            return this.__IsEnabledBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsEnabledBy), (id) => context.get(id) as TenantEnablesTenantFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsEnabledBy(values: TenantEnablesTenantFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__IsEnabledBy = values;
     }
    get _IsEnabledBy(): Set<string> {
        if (!this._relationships.has("IsEnabledBy"))
            this._relationships.set("IsEnabledBy", new Set<string>());

        return this._relationships.get("IsEnabledBy");
    }
    set _IsEnabledBy(values: Set<string>) {
        this._relationships.set("IsEnabledBy", values);
    }

    // Relationship IsProvidedBy, returns TenantModule TenantModuleProvidesTenantFeature[]
    private __IsProvidedBy: TenantModuleProvidesTenantFeature[];
    IsProvidedBy(_context?: BaseDataContext): TenantModuleProvidesTenantFeature[] {
        if (this.__IsProvidedBy)
            return this.__IsProvidedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsProvidedBy), (id) => context.get(id) as TenantModuleProvidesTenantFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsProvidedBy(values: TenantModuleProvidesTenantFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__IsProvidedBy = values;
     }
    get _IsProvidedBy(): Set<string> {
        if (!this._relationships.has("IsProvidedBy"))
            this._relationships.set("IsProvidedBy", new Set<string>());

        return this._relationships.get("IsProvidedBy");
    }
    set _IsProvidedBy(values: Set<string>) {
        this._relationships.set("IsProvidedBy", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsEnabledBy',
            edgeType: TenantEnablesTenantFeature,
            otherVertexPropertyName: 'Enables',
            otherVertexType: Tenant,
        },
        {
            propertyName: 'IsProvidedBy',
            edgeType: TenantModuleProvidesTenantFeature,
            otherVertexPropertyName: 'Provides',
            otherVertexType: TenantModule,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [

    ];

    deserialize(input: Object, datacontext: BaseDataContext): TenantFeature {
        return ModelUtils.deserializeVertex<TenantFeature>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: TenantFeature) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            IsEnabledBy: ModelUtils.serializeShallowEdge(model.IsEnabledBy(), 'IsEnabledBy'),
            IsProvidedBy: ModelUtils.serializeShallowEdge(model.IsProvidedBy(), 'IsProvidedBy'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): TenantFeature {
        let clone = new TenantFeature();
        clone.data = _.cloneDeep(this.data);
        clone._IsEnabledBy = _.cloneDeep(this._IsEnabledBy);
        clone._IsProvidedBy = _.cloneDeep(this._IsProvidedBy);

        //clone.Context = this.Context;
        return clone;
    }
}

export interface ITenantModule {
    Id: string,

    IsSubscribedBy?: object[],
    Provides?: object[]

}

export class TenantModule extends VertexModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantModule";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof TenantModule }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    // Relationship IsSubscribedBy, returns Tenant TenantSubscribesToTenantModule[]
    private __IsSubscribedBy: TenantSubscribesToTenantModule[];
    IsSubscribedBy(_context?: BaseDataContext): TenantSubscribesToTenantModule[] {
        if (this.__IsSubscribedBy)
            return this.__IsSubscribedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsSubscribedBy), (id) => context.get(id) as TenantSubscribesToTenantModule);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsSubscribedBy(values: TenantSubscribesToTenantModule[]) {
         if (this.Context != null)
             throw Error;

        this.__IsSubscribedBy = values;
     }
    get _IsSubscribedBy(): Set<string> {
        if (!this._relationships.has("IsSubscribedBy"))
            this._relationships.set("IsSubscribedBy", new Set<string>());

        return this._relationships.get("IsSubscribedBy");
    }
    set _IsSubscribedBy(values: Set<string>) {
        this._relationships.set("IsSubscribedBy", values);
    }

    // Relationship Provides, returns TenantFeature TenantModuleProvidesTenantFeature[]
    private __Provides: TenantModuleProvidesTenantFeature[];
    Provides(_context?: BaseDataContext): TenantModuleProvidesTenantFeature[] {
        if (this.__Provides)
            return this.__Provides;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Provides), (id) => context.get(id) as TenantModuleProvidesTenantFeature);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setProvides(values: TenantModuleProvidesTenantFeature[]) {
         if (this.Context != null)
             throw Error;

        this.__Provides = values;
     }
    get _Provides(): Set<string> {
        if (!this._relationships.has("Provides"))
            this._relationships.set("Provides", new Set<string>());

        return this._relationships.get("Provides");
    }
    set _Provides(values: Set<string>) {
        this._relationships.set("Provides", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsSubscribedBy',
            edgeType: TenantSubscribesToTenantModule,
            otherVertexPropertyName: 'SubscribesTo',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Provides',
            edgeType: TenantModuleProvidesTenantFeature,
            otherVertexPropertyName: 'IsProvidedBy',
            otherVertexType: TenantFeature,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): TenantModule {
        return ModelUtils.deserializeVertex<TenantModule>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: TenantModule) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            IsSubscribedBy: ModelUtils.serializeShallowEdge(model.IsSubscribedBy(), 'IsSubscribedBy'),
            Provides: ModelUtils.serializeShallowEdge(model.Provides(), 'Provides'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): TenantModule {
        let clone = new TenantModule();
        clone.data = _.cloneDeep(this.data);
        clone._IsSubscribedBy = _.cloneDeep(this._IsSubscribedBy);
        clone._Provides = _.cloneDeep(this._Provides);

        //clone.Context = this.Context;
        return clone;
    }
}

export class TenantModuleProvidesTenantFeature extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantModuleProvidesTenantFeature";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to TenantModule
    private __IsProvidedBy: TenantModule;

    IsProvidedBy(context?: BaseDataContext): TenantModule {
        if (this.__IsProvidedBy)
           return this.__IsProvidedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsProvidedBy) as TenantModule;
    }
    setIsProvidedBy(value: TenantModule) {
        this.__IsProvidedBy = value;
    }
    get _IsProvidedBy(): string {
        return this.Out;
    }
    set _IsProvidedBy(value: string) {
        this.Out = value;
    }
    //   In to TenantFeature
    private __Provides: TenantFeature;

    Provides(context?: BaseDataContext): TenantFeature {
        if (this.__Provides)
           return this.__Provides;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Provides) as TenantFeature;
    }
    setProvides(value: TenantFeature) {
        this.__Provides = value;
    }
    get _Provides(): string {
        return this.In;
    }
    set _Provides(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): TenantModuleProvidesTenantFeature {
       return ModelUtils.deserializeEdge<TenantModuleProvidesTenantFeature>(this, input, datacontext, super._deserialize);
    }


    clone(): TenantModuleProvidesTenantFeature {
        let clone = new TenantModuleProvidesTenantFeature();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class TenantPermitsIdentity extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantPermitsIdentity";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Tenant
    private __IsPermittedFor: Tenant;

    IsPermittedFor(context?: BaseDataContext): Tenant {
        if (this.__IsPermittedFor)
           return this.__IsPermittedFor;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsPermittedFor) as Tenant;
    }
    setIsPermittedFor(value: Tenant) {
        this.__IsPermittedFor = value;
    }
    get _IsPermittedFor(): string {
        return this.Out;
    }
    set _IsPermittedFor(value: string) {
        this.Out = value;
    }
    //   In to SMIdentity
    private __Permits: SMIdentity;

    Permits(context?: BaseDataContext): SMIdentity {
        if (this.__Permits)
           return this.__Permits;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Permits) as SMIdentity;
    }
    setPermits(value: SMIdentity) {
        this.__Permits = value;
    }
    get _Permits(): string {
        return this.In;
    }
    set _Permits(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): TenantPermitsIdentity {
       return ModelUtils.deserializeEdge<TenantPermitsIdentity>(this, input, datacontext, super._deserialize);
    }


    clone(): TenantPermitsIdentity {
        let clone = new TenantPermitsIdentity();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class TenantSubscribesToTenantModule extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantSubscribesToTenantModule";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to Tenant
    private __IsSubscribedBy: Tenant;

    IsSubscribedBy(context?: BaseDataContext): Tenant {
        if (this.__IsSubscribedBy)
           return this.__IsSubscribedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsSubscribedBy) as Tenant;
    }
    setIsSubscribedBy(value: Tenant) {
        this.__IsSubscribedBy = value;
    }
    get _IsSubscribedBy(): string {
        return this.Out;
    }
    set _IsSubscribedBy(value: string) {
        this.Out = value;
    }
    //   In to TenantModule
    private __SubscribesTo: TenantModule;

    SubscribesTo(context?: BaseDataContext): TenantModule {
        if (this.__SubscribesTo)
           return this.__SubscribesTo;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._SubscribesTo) as TenantModule;
    }
    setSubscribesTo(value: TenantModule) {
        this.__SubscribesTo = value;
    }
    get _SubscribesTo(): string {
        return this.In;
    }
    set _SubscribesTo(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): TenantSubscribesToTenantModule {
       return ModelUtils.deserializeEdge<TenantSubscribesToTenantModule>(this, input, datacontext, super._deserialize);
    }


    clone(): TenantSubscribesToTenantModule {
        let clone = new TenantSubscribesToTenantModule();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class TenantUserWorksInArea extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantUserWorksInArea";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to TenantUser
    private __IsWorkedBy: TenantUser;

    IsWorkedBy(context?: BaseDataContext): TenantUser {
        if (this.__IsWorkedBy)
           return this.__IsWorkedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsWorkedBy) as TenantUser;
    }
    setIsWorkedBy(value: TenantUser) {
        this.__IsWorkedBy = value;
    }
    get _IsWorkedBy(): string {
        return this.Out;
    }
    set _IsWorkedBy(value: string) {
        this.Out = value;
    }
    //   In to TenantArea
    private __WorksIn: TenantArea;

    WorksIn(context?: BaseDataContext): TenantArea {
        if (this.__WorksIn)
           return this.__WorksIn;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._WorksIn) as TenantArea;
    }
    setWorksIn(value: TenantArea) {
        this.__WorksIn = value;
    }
    get _WorksIn(): string {
        return this.In;
    }
    set _WorksIn(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): TenantUserWorksInArea {
       return ModelUtils.deserializeEdge<TenantUserWorksInArea>(this, input, datacontext, super._deserialize);
    }


    clone(): TenantUserWorksInArea {
        let clone = new TenantUserWorksInArea();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class UserActsAsPersona extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "UserActsAsPersona";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to User
    private __IsPerformedBy: User;

    IsPerformedBy(context?: BaseDataContext): User {
        if (this.__IsPerformedBy)
           return this.__IsPerformedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsPerformedBy) as User;
    }
    setIsPerformedBy(value: User) {
        this.__IsPerformedBy = value;
    }
    get _IsPerformedBy(): string {
        return this.Out;
    }
    set _IsPerformedBy(value: string) {
        this.Out = value;
    }
    //   In to Persona
    private __ActsAs: Persona;

    ActsAs(context?: BaseDataContext): Persona {
        if (this.__ActsAs)
           return this.__ActsAs;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._ActsAs) as Persona;
    }
    setActsAs(value: Persona) {
        this.__ActsAs = value;
    }
    get _ActsAs(): string {
        return this.In;
    }
    set _ActsAs(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): UserActsAsPersona {
       return ModelUtils.deserializeEdge<UserActsAsPersona>(this, input, datacontext, super._deserialize);
    }


    clone(): UserActsAsPersona {
        let clone = new UserActsAsPersona();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class UserDevelopsAsDeveloper extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "UserDevelopsAsDeveloper";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to User
    private __IsDeveloperFor: User;

    IsDeveloperFor(context?: BaseDataContext): User {
        if (this.__IsDeveloperFor)
           return this.__IsDeveloperFor;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsDeveloperFor) as User;
    }
    setIsDeveloperFor(value: User) {
        this.__IsDeveloperFor = value;
    }
    get _IsDeveloperFor(): string {
        return this.Out;
    }
    set _IsDeveloperFor(value: string) {
        this.Out = value;
    }
    //   In to Developer
    private __DevelopsAs: Developer;

    DevelopsAs(context?: BaseDataContext): Developer {
        if (this.__DevelopsAs)
           return this.__DevelopsAs;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._DevelopsAs) as Developer;
    }
    setDevelopsAs(value: Developer) {
        this.__DevelopsAs = value;
    }
    get _DevelopsAs(): string {
        return this.In;
    }
    set _DevelopsAs(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): UserDevelopsAsDeveloper {
       return ModelUtils.deserializeEdge<UserDevelopsAsDeveloper>(this, input, datacontext, super._deserialize);
    }


    clone(): UserDevelopsAsDeveloper {
        let clone = new UserDevelopsAsDeveloper();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export class UserManagesApp extends EdgeModel {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "UserManagesApp";
    }

    // Properties
    get Id(): string {
        return this.data.Id;
    }
    set Id(value: string) {
        this.data.Id = value;
    }

    // Relationships

    //   Out to User
    private __IsManagedBy: User;

    IsManagedBy(context?: BaseDataContext): User {
        if (this.__IsManagedBy)
           return this.__IsManagedBy;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._IsManagedBy) as User;
    }
    setIsManagedBy(value: User) {
        this.__IsManagedBy = value;
    }
    get _IsManagedBy(): string {
        return this.Out;
    }
    set _IsManagedBy(value: string) {
        this.Out = value;
    }
    //   In to App
    private __Manages: App;

    Manages(context?: BaseDataContext): App {
        if (this.__Manages)
           return this.__Manages;

        const _context = context || this.Context;
        if(!_context) return null;
        return _context.get(this._Manages) as App;
    }
    setManages(value: App) {
        this.__Manages = value;
    }
    get _Manages(): string {
        return this.In;
    }
    set _Manages(value: string) {
        this.In = value;
    }

    deserialize(input: Object, datacontext): UserManagesApp {
       return ModelUtils.deserializeEdge<UserManagesApp>(this, input, datacontext, super._deserialize);
    }


    clone(): UserManagesApp {
        let clone = new UserManagesApp();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IApp {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,
    Description?: string,
    readonly ApiManagementSubscriptionPrimaryKey?: string,
    readonly ApiManagementSubscriptionSecondaryKey?: string,

    IsManagedBy?: object[],
    IsPermittedFor?: object[],
    Represents?: object[]

}

export class App extends SMIdentity {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "App";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof App }[] = [
            {className: 'Developer', type: Developer},

        ];
        return derivedTypes;
    }

    // Properties
    get Description(): string {
        return this.data.Description;
    }
    set Description(value: string) {
        this.data.Description = value;
    }
    get ApiManagementSubscriptionPrimaryKey(): string {
        return this.data.ApiManagementSubscriptionPrimaryKey;
    }
    get ApiManagementSubscriptionSecondaryKey(): string {
        return this.data.ApiManagementSubscriptionSecondaryKey;
    }

    // Relationships

    // Relationship IsManagedBy, returns User UserManagesApp[]
    private __IsManagedBy: UserManagesApp[];
    IsManagedBy(_context?: BaseDataContext): UserManagesApp[] {
        if (this.__IsManagedBy)
            return this.__IsManagedBy;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsManagedBy), (id) => context.get(id) as UserManagesApp);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsManagedBy(values: UserManagesApp[]) {
         if (this.Context != null)
             throw Error;

        this.__IsManagedBy = values;
     }
    get _IsManagedBy(): Set<string> {
        if (!this._relationships.has("IsManagedBy"))
            this._relationships.set("IsManagedBy", new Set<string>());

        return this._relationships.get("IsManagedBy");
    }
    set _IsManagedBy(values: Set<string>) {
        this._relationships.set("IsManagedBy", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsManagedBy',
            edgeType: UserManagesApp,
            otherVertexPropertyName: 'Manages',
            otherVertexType: User,
        },
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): App {
        return ModelUtils.deserializeVertex<App>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: App) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            Description: model.Description,
            ApiManagementSubscriptionPrimaryKey: model.ApiManagementSubscriptionPrimaryKey,
            ApiManagementSubscriptionSecondaryKey: model.ApiManagementSubscriptionSecondaryKey,
            IsManagedBy: ModelUtils.serializeShallowEdge(model.IsManagedBy(), 'IsManagedBy'),
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): App {
        let clone = new App();
        clone.data = _.cloneDeep(this.data);
        clone._IsManagedBy = _.cloneDeep(this._IsManagedBy);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._Represents = _.cloneDeep(this._Represents);

        //clone.Context = this.Context;
        return clone;
    }
}

export class TenantUserActivity extends TenantActivity {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantUserActivity";
    }

    // Properties
    get AffectedIdentityKey(): string {
        return this.data.AffectedIdentityKey;
    }
    set AffectedIdentityKey(value: string) {
        this.data.AffectedIdentityKey = value;
    }


    deserialize(input: Object, datacontext): TenantUserActivity {
        if (!input)
            return this;

        super._deserialize(input, datacontext);

        return this;
    }

    serialize(_model?: TenantUserActivity) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            DeletedDate: model.DeletedDate,
            Id: model.Id,
            Message: model.Message,
            TenantKey: model.TenantKey,
            AreaName: model.AreaName,
            EventName: model.EventName,
            IdentityKey: model.IdentityKey,
            ActivityTime: model.ActivityTime,
            AffectedIdentityKey: model.AffectedIdentityKey,
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): TenantUserActivity {
        let clone = new TenantUserActivity();
        clone.data = _.cloneDeep(this.data);

        //clone.Context = this.Context;
        return clone;
    }

}

export interface IUser {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,
    Logon: string,
    Name?: { [index: string]: string },
    Address?: { [index: string]: string },
    Phones?: { [index: string]: any }[],
    Emails?: { [index: string]: any }[],

    IsPermittedFor?: object[],
    Represents?: object[],
    DevelopsAs?: object[],
    Manages?: object[],
    ActsAs?: object[]

}

export class User extends SMIdentity {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "User";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof User }[] = [
            {className: 'AdminUser', type: AdminUser},
            {className: 'Contact', type: Contact},
            {className: 'TenantUser', type: TenantUser},

        ];
        return derivedTypes;
    }

    // Properties
    get Logon(): string {
        return this.data.Logon;
    }
    set Logon(value: string) {
        this.data.Logon = value;
    }
    get Name(): { [index: string]: string } {
        return this.data.Name;
    }
    set Name(value: { [index: string]: string }) {
        this.data.Name = value;
    }
    get Address(): { [index: string]: string } {
        return this.data.Address;
    }
    set Address(value: { [index: string]: string }) {
        this.data.Address = value;
    }
    get Phones(): { [index: string]: any }[] {
        return this.data.Phones;
    }
    set Phones(value: { [index: string]: any }[]) {
        this.data.Phones = value;
    }
    get Emails(): { [index: string]: any }[] {
        return this.data.Emails;
    }
    set Emails(value: { [index: string]: any }[]) {
        this.data.Emails = value;
    }

    // Relationships

    // Relationship DevelopsAs, returns Developer UserDevelopsAsDeveloper[]
    private __DevelopsAs: UserDevelopsAsDeveloper[];
    DevelopsAs(_context?: BaseDataContext): UserDevelopsAsDeveloper[] {
        if (this.__DevelopsAs)
            return this.__DevelopsAs;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._DevelopsAs), (id) => context.get(id) as UserDevelopsAsDeveloper);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setDevelopsAs(values: UserDevelopsAsDeveloper[]) {
         if (this.Context != null)
             throw Error;

        this.__DevelopsAs = values;
     }
    get _DevelopsAs(): Set<string> {
        if (!this._relationships.has("DevelopsAs"))
            this._relationships.set("DevelopsAs", new Set<string>());

        return this._relationships.get("DevelopsAs");
    }
    set _DevelopsAs(values: Set<string>) {
        this._relationships.set("DevelopsAs", values);
    }

    // Relationship Manages, returns App UserManagesApp[]
    private __Manages: UserManagesApp[];
    Manages(_context?: BaseDataContext): UserManagesApp[] {
        if (this.__Manages)
            return this.__Manages;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._Manages), (id) => context.get(id) as UserManagesApp);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setManages(values: UserManagesApp[]) {
         if (this.Context != null)
             throw Error;

        this.__Manages = values;
     }
    get _Manages(): Set<string> {
        if (!this._relationships.has("Manages"))
            this._relationships.set("Manages", new Set<string>());

        return this._relationships.get("Manages");
    }
    set _Manages(values: Set<string>) {
        this._relationships.set("Manages", values);
    }

    // Relationship ActsAs, returns Persona UserActsAsPersona[]
    private __ActsAs: UserActsAsPersona[];
    ActsAs(_context?: BaseDataContext): UserActsAsPersona[] {
        if (this.__ActsAs)
            return this.__ActsAs;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._ActsAs), (id) => context.get(id) as UserActsAsPersona);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setActsAs(values: UserActsAsPersona[]) {
         if (this.Context != null)
             throw Error;

        this.__ActsAs = values;
     }
    get _ActsAs(): Set<string> {
        if (!this._relationships.has("ActsAs"))
            this._relationships.set("ActsAs", new Set<string>());

        return this._relationships.get("ActsAs");
    }
    set _ActsAs(values: Set<string>) {
        this._relationships.set("ActsAs", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },
        {
            propertyName: 'DevelopsAs',
            edgeType: UserDevelopsAsDeveloper,
            otherVertexPropertyName: 'IsDeveloperFor',
            otherVertexType: Developer,
        },
        {
            propertyName: 'Manages',
            edgeType: UserManagesApp,
            otherVertexPropertyName: 'IsManagedBy',
            otherVertexType: App,
        },
        {
            propertyName: 'ActsAs',
            edgeType: UserActsAsPersona,
            otherVertexPropertyName: 'IsPerformedBy',
            otherVertexType: Persona,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): User {
        return ModelUtils.deserializeVertex<User>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: User) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            Logon: model.Logon,
            Name: model.Name,
            Address: model.Address,
            Phones: model.Phones,
            Emails: model.Emails,
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
            DevelopsAs: ModelUtils.serializeShallowEdge(model.DevelopsAs(), 'DevelopsAs'),
            Manages: ModelUtils.serializeShallowEdge(model.Manages(), 'Manages'),
            ActsAs: ModelUtils.serializeShallowEdge(model.ActsAs(), 'ActsAs'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): User {
        let clone = new User();
        clone.data = _.cloneDeep(this.data);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._Represents = _.cloneDeep(this._Represents);
        clone._DevelopsAs = _.cloneDeep(this._DevelopsAs);
        clone._Manages = _.cloneDeep(this._Manages);
        clone._ActsAs = _.cloneDeep(this._ActsAs);

        //clone.Context = this.Context;
        return clone;
    }
}

export interface IAdminUser {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,
    Logon: string,
    Name?: { [index: string]: string },
    Address?: { [index: string]: string },
    Phones?: { [index: string]: any }[],
    Emails?: { [index: string]: any }[],
    Super?: boolean,

    IsPermittedFor?: object[],
    FillsSecurityRole?: object[],
    Represents?: object[],
    DevelopsAs?: object[],
    Manages?: object[],
    ActsAs?: object[]

}

export class AdminUser extends User {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "AdminUser";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof AdminUser }[] = [

        ];
        return derivedTypes;
    }

    // Properties
    get Super(): boolean {
        return this.data.Super;
    }
    set Super(value: boolean) {
        this.data.Super = value;
    }

    // Relationships

    // Relationship FillsSecurityRole, returns SecurityRole AdminUserFillsSecurityRole[]
    private __FillsSecurityRole: AdminUserFillsSecurityRole[];
    FillsSecurityRole(_context?: BaseDataContext): AdminUserFillsSecurityRole[] {
        if (this.__FillsSecurityRole)
            return this.__FillsSecurityRole;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._FillsSecurityRole), (id) => context.get(id) as AdminUserFillsSecurityRole);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setFillsSecurityRole(values: AdminUserFillsSecurityRole[]) {
         if (this.Context != null)
             throw Error;

        this.__FillsSecurityRole = values;
     }
    get _FillsSecurityRole(): Set<string> {
        if (!this._relationships.has("FillsSecurityRole"))
            this._relationships.set("FillsSecurityRole", new Set<string>());

        return this._relationships.get("FillsSecurityRole");
    }
    set _FillsSecurityRole(values: Set<string>) {
        this._relationships.set("FillsSecurityRole", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'FillsSecurityRole',
            edgeType: AdminUserFillsSecurityRole,
            otherVertexPropertyName: 'IsFilledBy',
            otherVertexType: SecurityRole,
        },
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },
        {
            propertyName: 'DevelopsAs',
            edgeType: UserDevelopsAsDeveloper,
            otherVertexPropertyName: 'IsDeveloperFor',
            otherVertexType: Developer,
        },
        {
            propertyName: 'Manages',
            edgeType: UserManagesApp,
            otherVertexPropertyName: 'IsManagedBy',
            otherVertexType: App,
        },
        {
            propertyName: 'ActsAs',
            edgeType: UserActsAsPersona,
            otherVertexPropertyName: 'IsPerformedBy',
            otherVertexType: Persona,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): AdminUser {
        return ModelUtils.deserializeVertex<AdminUser>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: AdminUser) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            Logon: model.Logon,
            Name: model.Name,
            Address: model.Address,
            Phones: model.Phones,
            Emails: model.Emails,
            Super: model.Super,
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            FillsSecurityRole: ModelUtils.serializeShallowEdge(model.FillsSecurityRole(), 'FillsSecurityRole'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
            DevelopsAs: ModelUtils.serializeShallowEdge(model.DevelopsAs(), 'DevelopsAs'),
            Manages: ModelUtils.serializeShallowEdge(model.Manages(), 'Manages'),
            ActsAs: ModelUtils.serializeShallowEdge(model.ActsAs(), 'ActsAs'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): AdminUser {
        let clone = new AdminUser();
        clone.data = _.cloneDeep(this.data);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._FillsSecurityRole = _.cloneDeep(this._FillsSecurityRole);
        clone._Represents = _.cloneDeep(this._Represents);
        clone._DevelopsAs = _.cloneDeep(this._DevelopsAs);
        clone._Manages = _.cloneDeep(this._Manages);
        clone._ActsAs = _.cloneDeep(this._ActsAs);

        //clone.Context = this.Context;
        return clone;
    }
}

export interface IContact {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,
    Logon: string,
    Name?: { [index: string]: string },
    Address?: { [index: string]: string },
    Phones?: { [index: string]: any }[],
    Emails?: { [index: string]: any }[],

    IsPermittedFor?: object[],
    Represents?: object[],
    DevelopsAs?: object[],
    Manages?: object[],
    ActsAs?: object[]

}

export class Contact extends User {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Contact";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Contact }[] = [

        ];
        return derivedTypes;
    }

    // Properties

    // Relationships


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },
        {
            propertyName: 'DevelopsAs',
            edgeType: UserDevelopsAsDeveloper,
            otherVertexPropertyName: 'IsDeveloperFor',
            otherVertexType: Developer,
        },
        {
            propertyName: 'Manages',
            edgeType: UserManagesApp,
            otherVertexPropertyName: 'IsManagedBy',
            otherVertexType: App,
        },
        {
            propertyName: 'ActsAs',
            edgeType: UserActsAsPersona,
            otherVertexPropertyName: 'IsPerformedBy',
            otherVertexType: Persona,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Contact {
        return ModelUtils.deserializeVertex<Contact>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Contact) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            Logon: model.Logon,
            Name: model.Name,
            Address: model.Address,
            Phones: model.Phones,
            Emails: model.Emails,
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
            DevelopsAs: ModelUtils.serializeShallowEdge(model.DevelopsAs(), 'DevelopsAs'),
            Manages: ModelUtils.serializeShallowEdge(model.Manages(), 'Manages'),
            ActsAs: ModelUtils.serializeShallowEdge(model.ActsAs(), 'ActsAs'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Contact {
        let clone = new Contact();
        clone.data = _.cloneDeep(this.data);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._Represents = _.cloneDeep(this._Represents);
        clone._DevelopsAs = _.cloneDeep(this._DevelopsAs);
        clone._Manages = _.cloneDeep(this._Manages);
        clone._ActsAs = _.cloneDeep(this._ActsAs);

        //clone.Context = this.Context;
        return clone;
    }
}

export interface IDeveloper {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,
    Description?: string,
    readonly ApiManagementSubscriptionPrimaryKey?: string,
    readonly ApiManagementSubscriptionSecondaryKey?: string,

    IsDeveloperFor?: object[],
    IsManagedBy?: object[],
    IsPermittedFor?: object[],
    Represents?: object[]

}

export class Developer extends App {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "Developer";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof Developer }[] = [

        ];
        return derivedTypes;
    }

    // Properties

    // Relationships

    // Relationship IsDeveloperFor, returns User UserDevelopsAsDeveloper[]
    private __IsDeveloperFor: UserDevelopsAsDeveloper[];
    IsDeveloperFor(_context?: BaseDataContext): UserDevelopsAsDeveloper[] {
        if (this.__IsDeveloperFor)
            return this.__IsDeveloperFor;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._IsDeveloperFor), (id) => context.get(id) as UserDevelopsAsDeveloper);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setIsDeveloperFor(values: UserDevelopsAsDeveloper[]) {
         if (this.Context != null)
             throw Error;

        this.__IsDeveloperFor = values;
     }
    get _IsDeveloperFor(): Set<string> {
        if (!this._relationships.has("IsDeveloperFor"))
            this._relationships.set("IsDeveloperFor", new Set<string>());

        return this._relationships.get("IsDeveloperFor");
    }
    set _IsDeveloperFor(values: Set<string>) {
        this._relationships.set("IsDeveloperFor", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsDeveloperFor',
            edgeType: UserDevelopsAsDeveloper,
            otherVertexPropertyName: 'DevelopsAs',
            otherVertexType: User,
        },
        {
            propertyName: 'IsManagedBy',
            edgeType: UserManagesApp,
            otherVertexPropertyName: 'Manages',
            otherVertexType: User,
        },
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): Developer {
        return ModelUtils.deserializeVertex<Developer>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: Developer) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            Description: model.Description,
            ApiManagementSubscriptionPrimaryKey: model.ApiManagementSubscriptionPrimaryKey,
            ApiManagementSubscriptionSecondaryKey: model.ApiManagementSubscriptionSecondaryKey,
            IsDeveloperFor: ModelUtils.serializeShallowEdge(model.IsDeveloperFor(), 'IsDeveloperFor'),
            IsManagedBy: ModelUtils.serializeShallowEdge(model.IsManagedBy(), 'IsManagedBy'),
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): Developer {
        let clone = new Developer();
        clone.data = _.cloneDeep(this.data);
        clone._IsDeveloperFor = _.cloneDeep(this._IsDeveloperFor);
        clone._IsManagedBy = _.cloneDeep(this._IsManagedBy);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._Represents = _.cloneDeep(this._Represents);

        //clone.Context = this.Context;
        return clone;
    }
}

export interface ITenantUser {
    Id: string,
    DisplayName?: string,
    readonly CreatedDate?: Date,
    LastLoginDate?: Date,
    readonly IsActive: boolean,
    Logon: string,
    Name?: { [index: string]: string },
    Address?: { [index: string]: string },
    Phones?: { [index: string]: any }[],
    Emails?: { [index: string]: any }[],

    IsPermittedFor?: object[],
    Represents?: object[],
    DevelopsAs?: object[],
    Manages?: object[],
    WorksIn?: object[],
    ActsAs?: object[]

}

export class TenantUser extends User {
    get Domain(): string {
        return "Admin";
    }
    get Type(): string {
        return "TenantUser";
    }
    static get DerivedTypes() {
        const derivedTypes: { className: string, type: typeof TenantUser }[] = [

        ];
        return derivedTypes;
    }

    // Properties

    // Relationships

    // Relationship WorksIn, returns TenantArea TenantUserWorksInArea[]
    private __WorksIn: TenantUserWorksInArea[];
    WorksIn(_context?: BaseDataContext): TenantUserWorksInArea[] {
        if (this.__WorksIn)
            return this.__WorksIn;
         else {
            const context = _context || this.Context;
            if(!context) return null;
            // convert set to array of edges
            var edges = _.map(Array.from(this._WorksIn), (id) => context.get(id) as TenantUserWorksInArea);
            return _.filter(edges, e => { return !_.isNil(e); });
         }
     }
    setWorksIn(values: TenantUserWorksInArea[]) {
         if (this.Context != null)
             throw Error;

        this.__WorksIn = values;
     }
    get _WorksIn(): Set<string> {
        if (!this._relationships.has("WorksIn"))
            this._relationships.set("WorksIn", new Set<string>());

        return this._relationships.get("WorksIn");
    }
    set _WorksIn(values: Set<string>) {
        this._relationships.set("WorksIn", values);
    }


    public readonly inRelationships: EdgeRelationship[] = [
        {
            propertyName: 'IsPermittedFor',
            edgeType: TenantPermitsIdentity,
            otherVertexPropertyName: 'Permits',
            otherVertexType: Tenant,
        },

    ];
    public readonly outRelationships: EdgeRelationship[] = [
        {
            propertyName: 'Represents',
            edgeType: IdentityRepresentsOrganization,
            otherVertexPropertyName: 'IsRepresentedBy',
            otherVertexType: Organization,
        },
        {
            propertyName: 'DevelopsAs',
            edgeType: UserDevelopsAsDeveloper,
            otherVertexPropertyName: 'IsDeveloperFor',
            otherVertexType: Developer,
        },
        {
            propertyName: 'Manages',
            edgeType: UserManagesApp,
            otherVertexPropertyName: 'IsManagedBy',
            otherVertexType: App,
        },
        {
            propertyName: 'WorksIn',
            edgeType: TenantUserWorksInArea,
            otherVertexPropertyName: 'IsWorkedBy',
            otherVertexType: TenantArea,
        },
        {
            propertyName: 'ActsAs',
            edgeType: UserActsAsPersona,
            otherVertexPropertyName: 'IsPerformedBy',
            otherVertexType: Persona,
        },

    ];

    deserialize(input: Object, datacontext: BaseDataContext): TenantUser {
        return ModelUtils.deserializeVertex<TenantUser>(this, input, datacontext, this.inRelationships, this.outRelationships, super._deserialize);
    }
    serialize(_model?: TenantUser) {
        const model = _model || this;
        const ret = {
            ['@Type']: model.Type,
            Id: model.Id,
            DisplayName: model.DisplayName,
            CreatedDate: model.CreatedDate,
            LastLoginDate: model.LastLoginDate,
            IsActive: model.IsActive,
            Logon: model.Logon,
            Name: model.Name,
            Address: model.Address,
            Phones: model.Phones,
            Emails: model.Emails,
            IsPermittedFor: ModelUtils.serializeShallowEdge(model.IsPermittedFor(), 'IsPermittedFor'),
            Represents: ModelUtils.serializeShallowEdge(model.Represents(), 'Represents'),
            DevelopsAs: ModelUtils.serializeShallowEdge(model.DevelopsAs(), 'DevelopsAs'),
            Manages: ModelUtils.serializeShallowEdge(model.Manages(), 'Manages'),
            WorksIn: ModelUtils.serializeShallowEdge(model.WorksIn(), 'WorksIn'),
            ActsAs: ModelUtils.serializeShallowEdge(model.ActsAs(), 'ActsAs'),
        };
        ModelUtils.removePropertiesSetToUndefined(ret);
        return ret;
    }

    clone(): TenantUser {
        let clone = new TenantUser();
        clone.data = _.cloneDeep(this.data);
        clone._IsPermittedFor = _.cloneDeep(this._IsPermittedFor);
        clone._Represents = _.cloneDeep(this._Represents);
        clone._DevelopsAs = _.cloneDeep(this._DevelopsAs);
        clone._Manages = _.cloneDeep(this._Manages);
        clone._WorksIn = _.cloneDeep(this._WorksIn);
        clone._ActsAs = _.cloneDeep(this._ActsAs);

        //clone.Context = this.Context;
        return clone;
    }
}

export var Types = {
    AdminInfo,
    AdminUser,
    User,
    SMIdentity,
    AdminUserFillsSecurityRole,
    App,
    Contact,
    Developer,
    IdentityRepresentsOrganization,
    TenantUser,
    UserDevelopsAsDeveloper,
    UserManagesApp,
    Organization,
    Feature,
    Module,
    ModuleProvidesFeature,
    Permission,
    PermissionRequiresPermission,
    PermissionSecuresFeature,
    SecurityRole,
    SecurityRoleGrantsPermission,
    OrganizationOwnsTenant,
    Persona,
    PersonaExperiencesTenant,
    Tenant,
    TenantArea,
    TenantDeploysToArea,
    TenantEnablesTenantFeature,
    TenantFeature,
    TenantModule,
    TenantModuleProvidesTenantFeature,
    TenantPermitsIdentity,
    TenantSubscribesToTenantModule,
    TenantUserWorksInArea,
    UserActsAsPersona,
    AreaTier,
    TenantActivity,
    TenantUserActivity,
}
