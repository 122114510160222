<div class="unity-toasts">
    <div *ngFor="let toast of toasts" @verticalCollapse @triggerChildAnimation>
        <unity-toast 
            @slideUp
            @slideOut        
            [toast]="toast"
            (onClickLink)="onClickLink.emit($event)" 
            (dismiss)="dismiss($event)">
        </unity-toast>
    </div> 
</div>
