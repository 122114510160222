<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>    
        <div class="radios" [ngClass]="checkButtonAlignment()">
            <div *ngFor="let option of getOptions(); trackBy: trackOptions"
                (dblclick)="onDblClick()"
                class="custom-control form-check">
                <input
                    type="radio"
                    class="form-check-input"
                    [ngClass]="getInputCssClass()"
                    [id]="'FieldId:' + model.Id + ':' + option"
                    [name]="getInputIdOrName()"
                    [value]="option"
                    [checked]="option === model.Value"
                    [disabled]="model.Disabled"        
                    (change)="valueChange($event)"
                />
                <label
                    [for]="'FieldId:' + model.Id + ':' + option"
                    class="form-check-label"
                >
                    {{ option }}
                </label>
            </div>
        </div>
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>        
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>