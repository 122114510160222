
import { Task } from "@Core/CodeGen/Models/area.models"
import _ from "lodash";
import { TimerType } from "../Enums/timer-type-enum"
import { TaskWorkingStatus } from "@Work/Accounts/Workflow/task-list/task-cell/task-cell.component";

export interface TaskTimer {
    WorkflowId: string;
    TaskId: string;
    TaskName: string;
    Type: TimerType;
    DueOn: Date;
}

export class TaskUtils {
    /**
     *   Takes a Task object and returns the most imminent TaskTimer object
     *   @param task A Task object
    */
    public static getMostImminentTimerOnTask(task: Task): TaskTimer {
        if (_.isNil(task)) return null;

        if (task.CompletionStatus === "Done" || task.CompletionStatus === "Waived")
            return null;

        const taskTimers = []; 
        const taskTimer = {
            WorkflowId: task.WorkflowId,
            TaskId: task.Id,
            TaskName: task.Name,
            Type: null,
            DueOn: null
        };

        if (task.Deadline) {
            taskTimers.push({...taskTimer, Type: TimerType.Deadline, DueOn: task.Deadline});
        }

        if (task.SLADueOn) {
            taskTimers.push({...taskTimer, Type: TimerType.SLA, DueOn: task.SLADueOn});
        }

        const mostImminentTaskTimer = this.calculateMostImminentTaskTimer(taskTimers);

        return mostImminentTaskTimer;
    }

    /**
     *   Takes a list of Task objects and returns the most imminent TaskTimer
     *   @param tasks A list of Task objects
    */
    public static getMostImminentTimerInTaskList(tasks: Task[]): TaskTimer { 
        const taskTimers = _.filter(_.map(tasks, (task) => this.getMostImminentTimerOnTask(task)), t => !_.isNull(t));
        const mostImminentTaskTimer =  this.calculateMostImminentTaskTimer(taskTimers);

        return mostImminentTaskTimer;
    }

    /**
     *   Takes a list of TaskTimer objects and returns the most imminent TaskTimer object
     *   @param task A TaskTimer object
    */
    private static calculateMostImminentTaskTimer(taskTimers: TaskTimer[]): TaskTimer {
        if (_.isEmpty(taskTimers))
            return null;

        const mostImminentTaskTimer: TaskTimer = _.reduce(taskTimers, (prev, current) => {
            const prevRemainingTime: number = new Date(prev.DueOn).getTime() - new Date().getTime();
            const currentRemainingTime: number = new Date(current.DueOn).getTime() - new Date().getTime();

            if (prevRemainingTime < currentRemainingTime)
                return prev;

            return current;
        })

        return mostImminentTaskTimer;
    }
}
