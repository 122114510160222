import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';


@Component({
    selector: 'unity-modal',
    templateUrl: './unity-modal.component.html',
    styleUrls: ['./unity-modal.component.scss']
})
export class UnityModalComponent {
    @Input() title: string;
    @Input() primaryAction: string;
    @Input() primaryActionEnabled: boolean = true;
    @Input() secondaryAction?: string;
    @Input() backAction?: string;

    @Output() public onPrimaryAction: EventEmitter<any> = new EventEmitter();
    @Output() public onSecondaryAction: EventEmitter<any> = new EventEmitter();
    @Output() public onBackAction: EventEmitter<any> = new EventEmitter();
    @Output() public onClose: EventEmitter<any> = new EventEmitter();

    primaryActionClicked() {
        this.onPrimaryAction.emit()
    }       
};