import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {
  private isDraggingSubject = new BehaviorSubject<boolean>(false);
  
  setDragging(isDragging: boolean) {    
    const currentValue = this.isDraggingSubject.getValue();
    if (currentValue !== isDragging) {
      console.log(`Dragging state changing from ${currentValue} to ${isDragging}`);
    }
    this.isDraggingSubject.next(isDragging);
  }

  getDragging() {
    return this.isDraggingSubject.asObservable();
  }
}
