import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Program, Account, IAccount, ProgramWritesAccount } from '@Core/CodeGen/Models/area.models';
import { UserContext } from '@Core/Lib/Contexts/user-context';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { ContractDate } from '@Core/Lib/contract-date';

import { UserAccountsService } from '@Services/user-accounts-service';
import { LoadingService } from '@Services/loading-service';

import { AccountCreationModalComponent, AccountCreationModalResult } from '@Work/Shared/account-creation-modal/account-creation-modal.component';
import { BaseService } from '@Services/base-service';
import { Region } from '@Core/CodeGen/Models/configuration.models';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { FeatureFacetService } from '@Services/feature-facet.service';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';
import { AuthService } from '@Services/auth-service';

@Component({
    selector: 'new-business-button',
    templateUrl: './new-business-button.component.html',
    styleUrls: ['./new-business-button.component.scss']
})

export class NewBusinessButtonComponent implements OnInit, OnDestroy {
    public loading: boolean = false;
    public programList: AvailableProgramsViewModel[] = [];
    public regions: Region[] = [];
    public programRequired: boolean;

    private ngUnsubscribe: Subject<any> = new Subject();

    constructor(
        private tenantContext: TenantContext,
        private userContext: UserContext,
        private service: UserAccountsService,
        private router: Router,
        private modalService: NgbModal,
        private loadingService: LoadingService,
        private facetService: FeatureFacetService,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        this.userContext.getStore<Program>(new Program()).values
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.programList = [];
                data.forEach(program => {
                    this.programList.push(
                        {
                            Id: program.Id,
                            Name: program.Name,
                        });
                });
                this.loading = false;
            });

        this.tenantContext.getStore<Region>(new Region()).values
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.regions = data;
            });

        this.facetService.getAllFacets().subscribe(allFacets => {

            if (FeatureFacetService.checkIfFacetExists(TenantPermissions.Flag_Work_Accounts_ExplicitProgramAccess,
                allFacets, 'work', this.authService.getAreaKey())) {
                this.programRequired = true;
            }
        });
    }

    createNewAccount(): void {
        if (this.loading) {
            return;
        }
        const modalRef = this.modalService.open(AccountCreationModalComponent, {
            ariaLabelledBy: 'modal-basic-title',
            backdrop: "static",
            size: 'lg'
        });
        const modalComponent = modalRef.componentInstance as AccountCreationModalComponent;
        const self = this;

        modalComponent.programs = this.programList;
        modalComponent.regions = this.regions;
        modalComponent.programRequired = this.programRequired

        modalRef.componentInstance.onCreateAccount.subscribe((result: AccountCreationModalResult) => {
            if (!result) {
                return;
            }
            self.loading = true;

            const account = new Account();
            account.Name = result.accountName;
            account.Code = result.accountCode;
            account.Dba = result.dba;
            account.Phone = result.phone;
            account.Country = result.country;
            account.Address1 = result.address1;
            account.Address2 = result.address2;
            account.Address3 = result.address3;
            account.City = result.city;
            account.State = result.state;
            account.Zip = result.zip;

            if (result.programId != null) {
                const programEdge = new ProgramWritesAccount();
                const program = this.userContext.get(ModelUtils.createDomainId(new Program(), result.programId)) as Program;
                programEdge.setIsWrittenFor(program);
                account.setIsWrittenFor([programEdge]);
            }

            const createAccountDTO: CreateAccountDTO = {
                Account: account.serialize(),
                ProducerId: result.producerId,
            };

            this.loadingService.loading.next(true);
            this.service.createAccount(createAccountDTO).subscribe({
                next: (response: any) => {
                    modalRef.close();
                    self.loadingService.loading.next(false);
                    self.loading = false;

                    const responseData = response.Content;
                    const accountId = responseData["Id"];

                    if (!responseData["Runs"]) {
                        self.router.navigate(['Work', 'Account', accountId], { queryParams: { startworkflow: result.startWorkflow ? true : null } });
                    } else {
                        const workflowId = responseData["Runs"][0]["@Relationship"].Id;
                        self.router.navigate(['Work', 'Account', accountId, 'Workflow', workflowId]);
                    }
                },
                error: (error) => {
                    modalRef.componentInstance.errorMessage = BaseService.convertCommonErrors(error?.error?.Errors[0]);

                    //Error occurred - set loading to false
                    this.loadingService.loading.next(false);
                    this.loading = false;
                }
            });
        }, (reason) => {
        });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }
};

export interface AvailableProgramsViewModel {
    Id: string;
    Name: string;
}

export interface AvailableProducersViewModel {
    Id: string;
    Name: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    State: string;
    Zip: string;
    Code: string;
    NameCodeCombo: string;
}

export interface CreateAccountDTO {
    Account: IAccount;
    ProducerId?: string;
    AgentId?: string;
    Program?: ProgramDTO;
    Workflow?: WorkflowDTO;
}

interface WorkflowDTO {
    ProgramId: string;
    WorkflowCreatedFromId: string;
    ProposedEffectiveDate: ContractDate;
    Notes: string;
}
interface ProgramDTO {
    ProgramId: string;
    ProposedEffectiveDate: ContractDate;
}