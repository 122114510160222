<ng-container *ngIf="options">
    <div class="modal-header" *ngIf="options.title">
        <h3>{{options.title}}</h3>
        <button unity-button *ngIf="options.dismissButton" class="icon-only large close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <svg class="unity-icon-times"><use xlink:href="#unity-icon-times"></use></svg>
        </button>
    </div>
    <div class="modal-body" *ngIf="options.message">
        {{options.message}}
    </div>
    <div *ngIf="options.buttons" class="modal-footer">
        <button unity-button 
                *ngIf="options.buttons.leftButton" 
                [ngClass]="!options.buttons.leftButton.class ? 'primary' : options.buttons.leftButton.class" 
                (click)="performAction(options.buttons.leftButton)">
            {{options.buttons.leftButton.label}}
        </button>
        <button unity-button 
                *ngIf="options.buttons.centerButton"
                class="ms-1"
                [ngClass]="!options.buttons.centerButton.class ? 'secondary' : options.buttons.centerButton.class" 
                (click)="performAction(options.buttons.centerButton)">
            {{options.buttons.centerButton.label}}
        </button>
        <button unity-button 
                *ngIf="options.buttons.rightButton"
                class="ms-1"
                [ngClass]="!options.buttons.rightButton.class ? 'ghost' : options.buttons.rightButton.class" 
                (click)="performAction(options.buttons.rightButton)">
            {{options.buttons.rightButton.label}}
        </button>
    </div>
</ng-container>
<div *ngIf="!options" class="modal-body">No DialogOptionsSet</div>