<div [id]="'Field:' + model.Id + ':PreviousValue'">
    Previous: {{ getPreviousValue() }}
    <button unity-button 
        [id]="'Field:' + model.Id + ':RevertToPreviousValue'"
        class="link ms-1"
        (click)="revertToPreviousValue.emit()">
        <svg  class="icon-undo">
            <use xlink:href="#icon-undo"></use>
        </svg>
    </button>
</div>