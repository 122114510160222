import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import _ from "lodash";
import { SmFormTypes } from "../sm-form-field.component";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";

@Component({
    selector: 'sm-form-field-multi-select',
    templateUrl: './sm-form-field-multi-select.component.html',
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// Drop down multi-select, edit mode not supported at the moment
export class SmFormFieldMultiSelectComponent extends SmFormFieldBase {

    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    ngOnInit() {
        this.model.Value = this.formatValue(this.model.Value);
        super.ngOnInit();
    }

    public ngOnChanges() {
        this.model.Value = this.formatValue(this.model.Value);
    }

    getOptions(): string[] {
        return this.model.Options?.Options;
    }

    // Convert the model into something the kendo-multi-select expects
    formatValue(value: any): string[] {
        if (_.isNil(this.model.Value) || !this.model.Value?.length || this.model.Value == "")
            return [];
        else if (_.isArray(value)) {
            return value;
        } else {
            return [value];
        }
    }

    // Read-only view
    getFormattedValue() {
        return this.service.formatFieldValue(this.model.Value, SmFormTypes.MultiSelect, this.model.Options, this.formState);
    }

    valueChange(value: string[]) {
        var options = this.getOptions();
        if (!options?.length && !this.model.Options?.AllowSpaces) {
            const newValue: string[] = [];
    
            value.forEach(item => {
                const splitItems = item.split(" ").filter(Boolean);
        
                newValue.push(...splitItems);
            });
    
            value = newValue;
        }
        
        var uniqValues = _.uniq(value);

        this.model.Value = uniqValues;
        super.valueChange(uniqValues);        
    }

    protected override putFocusIntoInput(): void {
        this.element.nativeElement.querySelector('kendo-multiselect').focus()
        
    }
}