<tr #instance [id]="'FormSectionInstance:' + model.Id"  class="has_nest">
    <td *ngFor="let visibleLabel of labels; let i=index">
        <div>
            <ng-container *ngIf="i == 0">
                <div *ngIf="hasError; else participationStatus" class="section--status_icon">
                    <svg class="unity-icon-warning">
                        <use xlink:href="#unity-icon-warning"></use>
                    </svg>
                </div>
                <ng-template #participationStatus>
                    <div *ngIf="model.ParticipationStatus" class="section--status_icon">
                        <svg *ngIf="model.ParticipationStatus == 'Added'" class="unity-icon-plus">
                            <use xlink:href="#unity-icon-plus"></use>
                        </svg>
                        <svg *ngIf="model.ParticipationStatus == 'Changed'" class="unity-icon-pencil">
                            <use xlink:href="#unity-icon-pencil"></use>
                        </svg>
                        <svg *ngIf="model.ParticipationStatus == 'Deleted'" class="unity-icon-times">
                            <use xlink:href="#unity-icon-times"></use>
                        </svg>
                    </div>
                </ng-template>
            </ng-container>
            <div [ngClass]="{ 
                'value-added' : model.ParticipationStatus == 'Added',
                'value-deleted': model.ParticipationStatus == 'Deleted',
                'text-danger': formatNegatives(visibleLabel)
            }">
                <ng-container *ngIf="model.ParticipationStatus == 'Changed' && hasPreviousValue(visibleLabel); else normalRowValue">
                    <div class="value-added">
                        {{ getValueOfItemWithLabel(visibleLabel) }}
                    </div>
                    <div class="value-changed">
                        Previous: {{ getPreviousValueOfItemWithLabel(visibleLabel) }}
                    </div>
                </ng-container>
                <ng-container *ngIf="model.ParticipationStatus == 'Deleted'">
                    {{ getPreviousValueOfItemWithLabel(visibleLabel) }}
                </ng-container>
                <ng-template #normalRowValue>
                    <ng-container *ngIf="model.ParticipationStatus !== 'Deleted'">
                        {{ getValueOfItemWithLabel(visibleLabel) }}
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </td>
    <td>
        <div class="table-row-actions">
            <button unity-button 
                    *ngIf="readOnly"
                    [id]="'FormSection:' + parentId + ':Instance' + model.Id + ':ViewInstance'"
                    class="icon-only" 
                    [ngbPopover]="sectionPopover"
                    popoverClass="popover-fit_content"
                    autoClose="'outside'"
                    container="body"
                    placement="left">
                <svg class="unity-icon-eye">
                    <use xlink:href="#unity-icon-eye"></use>
                </svg>
            </button>
            <ng-template #sectionPopover>
                <div *ngFor="let element of getFieldsAndRows()" >
                    <sm-form-row *ngIf="element.ElementType == 'Row'" 
                        [model]="element" 
                        [readOnly]="true" 
                        [formState]="formState">
                    </sm-form-row>
                    <sm-form-field *ngIf="element.ElementType == 'Field'" 
                        [model]="element" 
                        [readOnly]="true" 
                        [formState]="formState">
                    </sm-form-field>
                </div> 
            </ng-template>
            <button unity-button 
                    *ngIf="!readOnly && model.ParticipationStatus !== 'Deleted'" 
                    [id]="'FormSection:' + parentId + ':Instance' + model.Id + ':EditInstance'"
                    class="icon-only" 
                    (click)="openSectionInstance()">
                <svg class="unity-icon-pencil">
                    <use xlink:href="#unity-icon-pencil"></use>
                </svg>
            </button>
            <button unity-button 
                    *ngIf="!readOnly && model.ParticipationStatus !== 'Deleted' && model.Access != 'PreventAddAndDelete'" 
                    class="icon-only"
                    [id]="'FormSection:' + parentId + ':Instance' + model.Id + ':DeleteInstance'"
                    [ngbPopover]="deletePopover"
                    autoClose="true"
                    container="body"
                    placement="left">
                <svg class="unity-icon-trash">
                    <use xlink:href="#unity-icon-trash"></use>
                </svg>
            </button>
            <ng-template #deletePopover>
                <div class="mb-2">
                    Deleting this will also delete all associated data, which may not be visible in the current application. Are you sure?
                </div>
                <div class="d-flex float-end mb-2">
                    <button unity-button 
                            id="ConfirmDelete" 
                            class="secondary" 
                            (click)="onDeleteSelf()">
                        Delete
                    </button>
                    <button unity-button 
                            id="CancelDelete"
                            class="ghost">
                        Cancel
                    </button>
                </div> 
            </ng-template>

            <button unity-button 
                    *ngIf="!model.ToggledToHide && getChildrenSections()?.length" 
                    class="icon-only toggle" 
                    (click)="model.ToggledToHide = true">
                <svg class="unity-icon-chevron-up">
                    <use xlink:href="#unity-icon-chevron-up"></use>
                </svg>
            </button>
            <button unity-button 
                    *ngIf="model.ToggledToHide && getChildrenSections()?.length" 
                    class="icon-only toggle" 
                    (click)="model.ToggledToHide = false">
                <svg class="unity-icon-chevron-down">
                    <use xlink:href="#unity-icon-chevron-down"></use>
                </svg>
            </button>
        </div>
    </td>
</tr>    
<tr *ngIf="!model.ToggledToHide && getChildrenSections()?.length">
    <td [attr.colspan]="labels.length + 1" class="nest">
        <ng-container *ngFor="let innerSection of getChildrenSections()">
            <sm-form-section 
                [model]="innerSection" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onValueChanged)="onValueChanged.emit($event)"
                (onSectionAdded)="onSectionAdded.emit($event)"
                (onSectionDeleted)="onSectionDeleted.emit($event)"
                (onDetectChanges)="updateStatuses()">
            </sm-form-section>
        </ng-container>
    </td> 
</tr>