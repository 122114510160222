import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationCardVM } from './sm-global-notifications-card/sm-global-notifications-card.component';
import { SmNotificationSettingsComponent } from '../sm-notification-settings/sm-notification-settings.component';
import { UserNotificationCenter } from '@Core/CodeGen/Models/configuration.models';

@Component({
    selector: 'sm-global-notifications',
    templateUrl: './sm-global-notifications.component.html',
    styleUrls: ['./sm-global-notifications.component.scss']
})
export class SmGlobalNotificationsComponent {
    @Input() settings: UserNotificationCenter;
    @Input() notificationCards: NotificationCardVM[] = [];
    
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Output() onClickLink: EventEmitter<NotificationCardVM> = new EventEmitter();

    public sortedAndFilteredNotifications: NotificationCardVM[] = [];
    public activeTab: string = "Notifications";

    constructor(private modalService: NgbModal) { }
    
    ngOnInit() {
        this.sortNotifications();
    }

    ngOnChange() {
        this.sortNotifications();
    }

    public sortNotifications() {
        this.sortedAndFilteredNotifications = _.orderBy(this.notificationCards, n => n.CreatedOnDate, 'desc');
    }

    public showSettings() {
        const modalRef = this.modalService.open(SmNotificationSettingsComponent, { backdrop: 'static', centered: true, size: 'md' });

        return modalRef;
    }

    onTabClick(tab: string): void {
        this.activeTab = tab;
    }

    getUnreadNotificationsCount(): number {
        if (!this.notificationCards) 
            return 0;

        return this.notificationCards.filter(notification => notification.IsRead === false).length;
    }
}
