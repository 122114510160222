import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { AdminUser } from "@Core/CodeGen/Models/admin.models";
import { TenantUser } from "@Core/CodeGen/Models/configuration.models";
import { TenantContext } from "@Core/Lib/Contexts/tenant-context";

import { AuthService } from "@Services/auth-service";
import { TenantTenantService } from "@Services/tenant-tenant-service";
import { UserService } from "@Services/user-service";

import { SmUserInfoComponent } from "@Shared/Components/sm-user-info/sm-user-info.component";

import { SmSimpleDialog, SimpleDialogOptions } from "@Shared/Components/sm-simple-dialog/sm-simple-dialog.component";
import { SmNotificationSettingsComponent } from "../sm-notification-settings/sm-notification-settings.component";
import { TenantPermissions } from "@Core/CodeGen/tenant-permissions.enum";
import { PermissionsShared } from "@Shared/Directives/sm-permissions-shared.class";
import { AllFeatureFacetsVM, FeatureFacetService } from "@Services/feature-facet.service";

@Component({
    selector: 'unity-user-menu',
    templateUrl: './unity-user-menu.component.html',
    styleUrls: ['./unity-user-menu.component.scss']
})
export class UnityUserMenuComponent implements OnDestroy, OnInit {
    @Input() adminUser: AdminUser;
    public userSubscription: Subscription;
    public userNotificationSubscription: Subscription;
    public user: TenantUser;
    public userVM: UnityUserVM;

    public MenuItems: any[] = [];

    constructor(
        public userService: UserService,
        public authService: AuthService,
        public tenantContext: TenantContext,
        private modalService: NgbModal,
        public tenantService: TenantTenantService, 
        public featureFacetsService: FeatureFacetService) { }

    ngOnInit() {
        this.buildUserVm();
        if (!this.adminUser) {
            this.featureFacetsService.getAllFacets().subscribe(facets => {
                this.populateMenu(facets);
            })
        } else {
            // there are no feature facets when you're using this menu in Admin mode. 
            this.populateMenu(null);
        }
        
    }

    private buildUserVm() {
        const self = this;

        if (!this.adminUser) {
            // Load the user        
            this.userSubscription = this.userService.User$.subscribe(_user => {
                self.user = _user;
                this.userVM = {
                    FirstName: self.user.FirstName,
                    LastName: self.user.LastName,
                    DisplayName: self.user.DisplayName,
                    Initials: self.user.Initials,
                    Title: self.user.Title
                }
            });
        }
        else {
            this.userVM = {
                FirstName: "",
                LastName: "",
                DisplayName: self.adminUser.DisplayName,
                Initials: self.adminUser.DisplayName.charAt(0).toUpperCase(),
                Title: ""
            }
        }
    }

    private populateMenu(featureFacets: AllFeatureFacetsVM) {
        if (!this.adminUser) {
            this.MenuItems.push({
                Label: "Profile",
                Icon: "user",
                Service: this,
                Action: function (service: UnityUserMenuComponent) {
                    service.userSettings();
                }
            });

            this.MenuItems.push({
                Label: "Notification Settings",
                Service: this,
                Action: function (service: UnityUserMenuComponent) {
                    service.notificationSettings();
                }
            });
        };

        this.MenuItems.push({
            Label: "Reset Password",
            Icon: "refresh",
            Service: this,
            Action: function (service: UnityUserMenuComponent) {
                this.resetTriggered = true;
                service.resetPassword();
            }
        });

        this.MenuItems.push({
            Label: "Log Off",
            Icon: "out",
            Service: this.authService,
            Action: (service: AuthService) => {
                service.logout();
            }
        });
    }

    ngOnDestroy() {
        this.userSubscription?.unsubscribe();
    }

    public userSettings() {
        const modalRef = this.modalService.open(SmUserInfoComponent, { backdrop: 'static', centered: true });
        modalRef.componentInstance.user = this.user;

        return modalRef;
    }

    public notificationSettings() {
        const modalRef = this.modalService.open(SmNotificationSettingsComponent, { backdrop: 'static', centered: true, size: 'md' });

        return modalRef;
    }

    private resetPassword() {
        var self = this;
        const dialogOptions: SimpleDialogOptions = {
            message: "Do you want to send an email to reset your password?",
            title: 'Reset Password',
            buttons: {
                leftButton: {
                    label: 'Send Reset Password Email',
                    action: function (activeModal: NgbActiveModal) {
                        self.resetUsersPassword(activeModal);
                    }
                },
                rightButton: {
                    label: 'Cancel',
                    action: function (activeModal: NgbActiveModal) {
                        activeModal.dismiss('cancel');
                    }
                }
            }
        };

        const dialog = this.modalService.open(SmSimpleDialog, { backdrop: 'static', centered: true });
        dialog.componentInstance.options = dialogOptions;
    }

    private resetUsersPassword(passwordModal: NgbActiveModal) {
        this.authService.recoverPassword(this.user.Email).subscribe({
            next: response => {
                passwordModal.close('complete');
                this.passwordResetFinished(true);
            }, 
            error: (error) => {
                this.passwordResetFinished(false);
            }
        });
    }

    private passwordResetFinished(wasSuccessful: boolean) {
        const dialogOptions: SimpleDialogOptions = {
            message: wasSuccessful ? 'Password reset email sent' : 'An error occurred',
            title: wasSuccessful ? 'Success' : 'Error',
            buttons: {
                leftButton: {
                    label: 'Ok',
                    action: function (activeModal: NgbActiveModal) {
                        activeModal.close('complete');
                    }
                }
            }
        };

        const dialog = this.modalService.open(SmSimpleDialog, { backdrop: 'static', centered: true });
        dialog.componentInstance.options = dialogOptions;
    }
};

export interface UnityUserVM {
    FirstName: string,
    LastName: string,
    DisplayName: string,
    Initials: string,
    Title: string
}