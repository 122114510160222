import _ from "lodash";

export class PermissionsShared {

    public static Has(grantedPermissions: string[], permission: string): boolean {
        return _.includes(grantedPermissions, permission);
    }

    public static HasAll(grantedPermissions: string[], permissions: string[]): boolean {
        return _.intersection(grantedPermissions, permissions).length === permissions.length;
    }

    public static HasAny(grantedPermissions: string[], permissions: string[]): boolean {
        return _.intersection(grantedPermissions, permissions).length > 0;
    }
}
