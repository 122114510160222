<div class="d-flex align-items-baseline justify-content-between single_line_ellipsis">
    <h2 id="QuickSearchCard:{{ MatchedItem.data.id }}:name" class="sle" [innerHTML]="resultName"></h2>
    <div id="QuickSearchCard:{{ MatchedItem.data.id }}:code" class="code ms-2" [innerHTML]="resultCode"></div>    
</div>
<div class="ms-2">
    <div id="QuickSearchCard:{{ MatchedItem.data.id }}:dba" class="dba" [innerHTML]="accountDba"></div>
    <ng-container *ngIf="SearchType === SearchDataTypes.Account">
        <div class="d-flex align-items-baseline single_line_ellipsis">
            <h3 *ngIf="!explctPrgmAccess && policyInfo != null" id="ProgramName" class="sle">{{policyInfo.ProgramName}}</h3>
            <h3 *ngIf="explctPrgmAccess && (policyInfo?.ProgramName || acctProgram != null)" id="ProgramNameExplct" class="sle">
                {{policyInfo?.ProgramName ?? acctProgram.data.Name}}
            </h3>
            <div *ngIf="policyInfo != null" id="PolicyDates" class="ms-2 policy_term">{{policyInfo.EffectiveDate}} - {{policyInfo.ExpirationDate}}</div>
            <ng-container *ngIf="explctPrgmAccess">
                <div *ngIf="MatchedItem.data.programIds?.length > 1" id="MorePrograms" class="ms-auto fw-bold">
                    {{MatchedItem.data.programIds.length - 1}} more
                </div>
            </ng-container>
        </div>

        <div *ngIf="policyInfo != null" class="d-flex align-items-baseline single_line_ellipsis ms-2">
            <div class="sle">{{policyInfo.ProductId | guidReplacer | async}} - {{policyInfo.Status}}</div>
            <div class="ms-2 policy_number" [innerHtml]="policyInfo.Number">{{policyInfo.Number}}</div>
        </div>
    </ng-container>
    <ng-container *ngIf="SearchType === SearchDataTypes.Account && this.MatchedItem?.highlights?.Tags">
        <div class="d-flex align-items-center ms-2">
            <div class="me-1"><svg class="unity-icon-tags"><use xlink:href="#unity-icon-tags"></use></svg></div>
            <div id="QuickSearchCard:{{ MatchedItem.data.id }}:tags" class="tags" [innerHTML]="accountTags"></div>
        </div>
    </ng-container>
</div>