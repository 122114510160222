import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@Services/auth-service';

@Component({
    selector: 'sm-select-tenant',
    templateUrl: './sm-select-tenant.component.html',
})
export class SmSelectTenantComponent {
    @Input() selectedTenant: string;

    @Output() onTenantSelect: EventEmitter<string> = new EventEmitter();
    @Output() onChangeUser: EventEmitter<any> = new EventEmitter();

    constructor(private authService: AuthService) { }

    public get tenants(): string[] {
        return this.authService.getAvailableTenants();
    }
};