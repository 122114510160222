import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, of, Subject } from 'rxjs';
import { FeatureFacetViewModel } from '@Shared/Components/sm-feature-facets/feature-facet.model';
import { TenantService } from '@Core/CodeGen/Services/tenant.service';
import _ from 'lodash';

@Injectable()
export class FeatureFacetService {

    // Schedule a clearing of an old context after 5 minutes
    protected interval = 5* 60 * 1000; // 5 minutes
    protected timer: any; // number | NodeJS.Timeout

    private data: AllFeatureFacetsVM;

    constructor (private tenantService: TenantService)
    {}

    public getAllFacets() : Observable<AllFeatureFacetsVM>
    {
        if (this.data)
        {
            this.resetTimer();
            return of(this.data);
        }
        else
        {
            let observable: Subject<AllFeatureFacetsVM> = new AsyncSubject<AllFeatureFacetsVM>();

            this.tenantService.ListAllEnabledFeatureFacets().subscribe((response:any) => {

                var content = response.Content;
                this.data = {
                    TenantFacets: content.tenantFacets,
                    ConfigFacets: content.configFacets,
                    AreaFacets: content.areaFacets
                };
                this.resetTimer();
                observable.next(this.data);
                observable.complete();
            });

            return observable;
        }
    }

    // Don't pass in spaName if you know that you are only checking the tenant facets (i.e. the facet is not a ByArea facet)
    public static checkIfFacetExists(facetId: string, allFacets: AllFeatureFacetsVM, spaName: string = null, currentArea: string = null) {
        if (spaName == 'admin')
            return false;

        var allFacetIds = _.map(allFacets.TenantFacets, f => f.id);

        if (_.includes(allFacetIds, facetId))
            return true;
        
        var configFacetIds = _.map(allFacets.ConfigFacets, f => f.id);
        if (_.includes(configFacetIds, facetId)) {
            if (spaName == 'conf')
                return true;
            
            var facet = _.find(allFacets.ConfigFacets, f => f.id === facetId);
            if (facet.byArea === false)
                return true;
            
            if (!currentArea)
            {
                console.error("Checking if facet exists for an area, but no area was provided.");
                return false;
            }

            var areaFacets = allFacets.AreaFacets[currentArea];
            if (!areaFacets)
                return false;

            var areaFacetids = _.map(areaFacets, f => f.id);
            if (_.includes(areaFacetids, facetId))
                return true;
        }

        return false;
    }

    public resetTimer(): void {
        clearInterval(this.timer);        
        this.timer = setTimeout(() => this.data = null, this.interval);
    }
}

export interface AllFeatureFacetsVM {
    TenantFacets: FeatureFacetViewModel[];
    ConfigFacets: FeatureFacetViewModel[];
    AreaFacets: { [areaKey: string]: FeatureFacetViewModel[] };
}