import { Pipe, PipeTransform } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantAssignableService } from '@Services/tenant-assignable-service';
import { TenantTenantService } from '@Services/tenant-tenant-service';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { DocumentCategory } from '@Core/CodeGen/Models/configuration.models';


@Pipe({
    name: 'guidReplacer',
    pure: true
})
export class GuidReplacerPipe implements PipeTransform {
    constructor(
        private tenantContext: TenantContext,
        private assignableService: TenantAssignableService,
        private tenantService: TenantTenantService
        ) { }

    /**
     * Replaces GUID with its associated name 
     * EX: "Document with Category {{ DocumentCategoryId|f5fba77d-72f8-4efa-9db8-631b3a878294 }} received"
     * = Document with Category Proposals received     
     */
    transform(data: string) {
        if (!data) {
            return of('');
        }

        const regex = new RegExp(/(?<=\{\{)(.*?)(?=\}\})/, 'g');
        const tokens = data.match(regex);
        if (!tokens) {
            return of(data);
        }
        const observables: Observable<string>[] = [];
        
        for (let token of tokens) {
            let [property, guid] = token.trim().split("|");
            if (property && guid) {
                switch (property) {
                    case 'ProductId':{
                        observables.push(this.tenantService.getProduct(guid).pipe(
                            map(t => {
                                return t.Name
                            })
                        ));
                        break;
                    }
                    case 'DocumentCategoryId': {
                        const docCategory = this.tenantContext.get(ModelUtils.createDomainId(new DocumentCategory(), guid)) as DocumentCategory;
                        if (docCategory) {
                            observables.push(of(docCategory.Category));
                        } else {
                            observables.push(of(''));
                        }
                        break;
                    }
                    case 'SMIdentity': {
                        observables.push(this.assignableService.GetAssignable(guid).pipe(
                            map(t => {
                                return t.DisplayName;
                            })));
                        break;
                    }
                    default:
                        break;
                }
            }
        };

        if (observables.length > 0) {
            return forkJoin(observables).pipe(
                map((substitutes: string[]) => {
                    let index = 0;
                    return data.replace(regex, () => {
                        return substitutes[index++];
                    }).replace('{{', '').replace('}}', '');
                }));
        }
        return of(data);
    }
}