import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textToControlId'
})

/**
 * Accepts two string values, removes all non-alpha characters (whitespaces included) 
 *  and returns a value suitable for an html id
 * @param idPrefix the 'left' side of the id prop, usually the Base Type of the class 
 *  being displayed in the element. eg. TaskApproval, ApplicationField, ProgramWorkgroupCard
 * @param idSuffix the 'right' side of the id prop, usually the GUID of the item when many
 *  instances of the same element type are displayed on the screen+
 * @returns a colon-separated concatenation of the supplied params
 */
export class TextToControlIdPipe implements PipeTransform {
    transform(idPrefix: string, idSuffix: string) : string {        
        var prefix = idPrefix.replace(/[^a-zA-Z\\s$]/gi, '');
        var suffix = idSuffix.replace(/[^a-zA-Z\\s$]/gi, '');

        return prefix + ':' + suffix;
    }
}