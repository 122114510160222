import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import _ from 'lodash';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { GlobalMessages } from '@Services/global-messages';
import { ProgramProgramsService } from '@Services/program-programs-service';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';

import { ProgramContext } from '@Core/Lib/Contexts/program-context';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { ITypedServiceResponse } from '@Core/Lib/model';
import { DataStore, DataGroup, DataPoint } from '@Core/CodeGen/Models/configuration.models';
import { AuthService } from './auth-service';


class Response extends HttpResponse<any> {}

@Injectable()
export class ProgramProgramsDataStoresService extends ProgramProgramsService {

    public constructor(protected http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected router: Router,
        protected tenantContext: TenantContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService,
        protected authService: AuthService
    ) {
        super(authService, http, globalMessages, router, tenantContext, loggingService, loadingService);
    }

    public loadDataStores(programId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseGetAll(programId, "DataStores");
        let request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(DataStore, request, context);
        return request;
    }

    public addDataStore(changeId: string, newDataStore: DataStore, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "DataStores", changeId);
        let request = this.http.post<any>(url, newDataStore.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DataStore, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public modifyDataStore(changeId: string, dataStore: DataStore, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "DataStores", changeId);
        let request = this.http.put<any>(url, dataStore.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DataStore, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public loadDataStore(dataStoreId: string, context: ProgramContext): Observable<ITypedServiceResponse<DataStore>> {
        let request = this.http.get<any>(this.serviceUrls.baseGetOne(context.programId, "DataStores", dataStoreId)).pipe(share());
        this.handleNormalGetRequest(DataStore, request, context);
        return request;
    }

    public loadDataGroups(programId: string, dataStoreId: string, context: ProgramContext): Observable<ITypedServiceResponse<DataGroup[]>> {
        let params = new HttpParams().set("dataStoreId", dataStoreId);
        let request = this.http.get<any>(this.serviceUrls.baseGetAll(programId, "DataGroups"), { params }).pipe(share());
        this.handleNormalGetRequest(DataGroup, request, context);
        return request;
    }

    public loadProgramDataGroups(programId: string, context: ProgramContext): Observable<ITypedServiceResponse<DataGroup[]>> {
        let request = this.http.get<any>(this.serviceUrls.baseGetAll(programId, "DataGroups")).pipe(share());
        this.handleNormalGetRequest(DataGroup, request, context);
        return request;
    }

    public loadDataPoints(programId: string, dataStoreId: string, context: ProgramContext): Observable<Response> {
        let params = new HttpParams().set("dataStoreId", dataStoreId);
        let request = this.http.get<any>(this.serviceUrls.baseGetAll(programId, "DataPoints"), { params }).pipe(share());
        this.handleNormalGetRequest(DataPoint, request, context);
        return request;
    }

    public addDataGroup(change: string, dataGroup: DataGroup, dataStoreId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "DataGroups", change, "DataStores", dataStoreId);
        let request = this.http.post<any>(url, dataGroup.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DataGroup, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public addDataPoint(change: string, dataPoint: DataPoint, dataStoreId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "DataPoints", change, "DataStores", dataStoreId);
        let request = this.http.post<any>(url, dataPoint.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DataPoint, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public modifyDataGroup(change: string, dataGroup: DataGroup, context: ProgramContext): Observable<Response> {
        // Note that no ID is required for this route because it accepts multiple DataGroup models.
        var url = this.serviceUrls.basePost(context.programId, "DataGroups", change);
        let request = this.http.put<any>(url, dataGroup.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DataGroup, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public modifyDataPoint(change: string, dataPoint: DataPoint, context: ProgramContext): Observable<Response> {
        // Note that no ID is required for this route because it accepts multiple DataGroup models.
        var url = this.serviceUrls.basePost(context.programId, "DataPoints", change);
        let request = this.http.put<any>(url, dataPoint.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DataPoint, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public deleteDataGroup(dataGroup: DataGroup, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.basePutAndDelete(context.programId, "DataGroups", dataGroup.Id, context.changeId);
        let request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(DataGroup, request, dataGroup, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public deleteDataPoint(dataPoint: DataPoint, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.basePutAndDelete(context.programId, "DataPoints", dataPoint.Id, context.changeId);
        let request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(DataPoint, request, dataPoint, context);
        this.postRevisionableEdit(request, context);
        return request;
    }    
}