import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from 'src/Shared/shared.module';
import { environment } from 'src/environments/environment';
import { AuthModule, AuthService as Auth0Service } from '@auth0/auth0-angular';

import { AccountsService as CodeGenAccountService } from '@Core/CodeGen/Services/accounts.service';
import { DataService } from '@Core/CodeGen/Services/data.service';
import { DocumentsService } from '@Core/CodeGen/Services/documents.service';
import { ProgramsService } from '@Core/CodeGen/Services/programs.service';
import { TenantService } from '@Core/CodeGen/Services/tenant.service';
import { WorkflowsService } from '@Core/CodeGen/Services/workflows.service';

import { HttpInterceptorProviders } from '@Core/Http-Interceptors';
import { AccountsService } from '@Services/account-accounts-service';
import { AccountWorkflowService } from '@Services/account-workflow-service';
import { ApiManagementService } from '@Services/api-management';
import { DocumentService } from '@Services/document-services';
import { ErrorHandlerService } from '@Services/error-handler-service';
import { FeatureFacetService } from '@Services/feature-facet.service';
import { GlobalMessages } from '@Services/global-messages';
import { LoadingService } from '@Services/loading-service';
import { LoggingService } from '@Services/logging-service';
import { NotificationService } from '@Services/notification-service';
import { PollingService } from '@Services/polling-service';
import { ProgramProgramsDataStoresService } from '@Services/program-programs-datastores-service';
import { ProgramProgramsService } from '@Services/program-programs-service';
import { ProgramRevisionWorkflowService } from '@Services/program-revision-workflow-service';
import { ReportsService } from '@Services/reports-service';
import { SyncHubStatusService } from '@Services/sync-hub-status-service';
import { TenantAssignableService } from '@Services/tenant-assignable-service';
import { TenantDataService } from '@Services/tenant-data-service';
import { TenantTenantService } from '@Services/tenant-tenant-service';
import { UserAccountsService } from '@Services/user-accounts-service';
import { UserWorkflowsService } from '@Services/user-workflows-service';
import { HttpClientModule } from '@angular/common/http';
import { LoginContainerComponent } from '@Mktg/Login/login-container.component';
import { LoginComponent } from '@Mktg/Login/login.component';
import { RegisterComponent } from '@Mktg/Register/register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminPollingService } from '@Admin/admin-polling-service';
import { AuthService } from '@Services/auth-service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AlertsHub } from '@Services/Hubs/AlertsHub';

@NgModule({
    imports: [
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientId,
            authorizationParams: {
                redirect_uri: environment.baseUrl,
            }
        }),
        SharedModule.forRoot(),

        BrowserModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxPermissionsModule.forRoot()
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        LoginContainerComponent,
        RegisterComponent,
    ],
    providers: [
        
        HttpInterceptorProviders,
        GlobalMessages,
        LoggingService,
        NotificationService,
        ApiManagementService,
        PollingService,
        AuthService,

        // Code Gen services
        ProgramsService,
        TenantService,
        CodeGenAccountService,
        DataService,
        DocumentsService,
        WorkflowsService,

        AccountsService,
        ProgramProgramsService,
        TenantDataService,
        TenantTenantService,
        TenantAssignableService,
        AccountWorkflowService,
        UserWorkflowsService,
        UserAccountsService,
        ProgramProgramsDataStoresService,
        DocumentService,
        ProgramRevisionWorkflowService,
        SyncHubStatusService,
        FeatureFacetService,

        LoadingService,
        Auth0Service,
        ReportsService,
        AdminPollingService,

        AlertsHub,

        {provide: ErrorHandler, useClass: ErrorHandlerService}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }