import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import _ from 'lodash';

import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { Program, Region } from '@Core/CodeGen/Models/configuration.models';
import { RegionUtils } from '@Core/Lib/Utils/region-utils';

@Pipe({
    name: 'smNomenclatureByRegion'
})
export class SmNomenclaturePipe implements PipeTransform {
// Show a value as Currency using the correct currency symbol based on the Region
    constructor(public tenantContext: TenantContext) { }

    transform(value: string, programId: string) : Observable<string> {
        return this.getAlias(value, programId);
    }

    private getAlias(value: string, programId: string): Observable<string> {
        return this.tenantContext.getStore<Region>(new Region()).values.pipe(map(regions => {
            var region: Region = null;
            if (programId) {
                region = RegionUtils.getProgramRegion(regions, programId);
            } else {
                region = _.find(regions, r => r.IsProgramDefault);
            }
    
            if (!region)
                return value;

            if (_.has(region.Aliases, value))
                return region.Aliases[value];

            return value;
        }));        
    }
}