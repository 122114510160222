import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import _ from 'lodash';
import { Observable, of } from 'rxjs';
import { share, map, shareReplay, catchError } from 'rxjs/operators';

import { GlobalMessages } from '@Services/global-messages';
import { BaseService } from '@Services/base-service';
import { LoadingService } from '@Services/loading-service';
import { LoggingService } from '@Services/logging-service';

import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { TenantUser, Types as TenantTypes, ITenantUser, SMIdentity, App } from '@Core/CodeGen/Models/configuration.models';

import { SmAssignableBadgeVM } from "@Shared/Components/sm-assignable-badge/sm-assignable-badge.viewmodel";


@Injectable()
export class TenantAssignableService
    extends BaseService {

    private serviceUrls = class ServiceUrls {
        private static baseUrl: string = BaseService.baseUrl + '/';
        public static Assignables: string = ServiceUrls.baseUrl + 'Tenant/Identities';
    }

    private CachedAssignablesRequests: Map<string, Observable<SMIdentity> | Observable<SMIdentity[]>> = new Map();

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        private tenantContext: TenantContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    public GetAssignable(identityKey: string): Observable<SMIdentity> {
        if (!identityKey) {
            const user: TenantUser = new TenantUser();
            user.DisplayName = "Unknown";
            user.Initials = "?";
            return of(user);
        }
        // First check if we have the data in the context
        const smIdentity = this.tenantContext.get(ModelUtils.createDomainId(new SMIdentity(), identityKey)) as SMIdentity;

        if (smIdentity) {
            // If we do then return an observable "of" smIdentity            
            return of(smIdentity);
        }

        // If we dont have it in context then check if there is already an http request
        const cachedRequest = this.CachedAssignablesRequests.get(identityKey) as Observable<SMIdentity> & Observable<SMIdentity[]>;
        if (cachedRequest) {
            // Return cached request
            return cachedRequest
                .pipe(
                    map((smIden: SMIdentity | SMIdentity[]) => {
                        // Here we check if its an array because it could be that the cached request was made to get multiple Assignables
                        if (_.isArray(smIden)) {
                            const smIdens = smIden as SMIdentity[];
                            for (const i of smIdens) {
                                if (i.Id === identityKey) {
                                    return i;
                                }
                            }
                        } else {
                            return smIden;
                        }
                    })
                );
        }

        const request = this.http.get<any>(`${this.serviceUrls.Assignables}`, {
            params: {
                ids: identityKey
            }
        }).pipe(
            // We use the map pipe to transform our data and return SmIdentity
            map(
                response => {
                    this.tenantContext.loadApiResponseModels(TenantTypes[response['Content'][0]["@Type"]], response);
                    const smIdent = this.tenantContext.get(ModelUtils.createDomainId(new SMIdentity(), identityKey)) as SMIdentity;
                    
                    // We have now loaded the model into the context 
                    this.CachedAssignablesRequests.delete(identityKey);
                    return smIdent;
                }),
            catchError(this.handleHttpError),
            shareReplay(1));

        const r = this.CachedAssignablesRequests.set(identityKey, request);
        // Return the request observable
        return r.get(identityKey) as Observable<SMIdentity>;
    }

    public getAssignableAsBadge(identityId): Observable<SmAssignableBadgeVM> {
        if (!identityId) 
            return null;
        return this.GetAssignable(identityId).pipe(
            map(t => {
                if (t) {
                    return { 
                        Type: t instanceof App ? "App" : "User", 
                        Name: t.DisplayName, 
                        Id: t.Id, 
                        Initials: t instanceof App ? null : (t as TenantUser).Initials, 
                        Title: t instanceof App ? null : (t as TenantUser).Title, 
                        Email: t instanceof App ? null : (t as TenantUser).Email,
                        Disabled: _.isNil(t?.Active) ? false : !t?.Active
                    } as SmAssignableBadgeVM;
                }
                else
                    return null;
            }));
    }

    public loadUsersByIdentity(identityKeys: string[]): Observable<TenantUser[]> {
        if (!identityKeys?.length)
            return of([]);
            
        
        var identityKeysToLoad = _.clone(identityKeys); 
        const existingTenantUsers: TenantUser[] = [];
        let i = identityKeysToLoad.length
        while (i--) {
            // If we have it in the context or if there is an existing request for this Assignable then remove from the url list
            const tenantUser = this.tenantContext.get(ModelUtils.createDomainId(new TenantUser(), identityKeysToLoad[i])) as TenantUser;
            if (tenantUser) {
                identityKeysToLoad.splice(i, 1);
                existingTenantUsers.push(tenantUser);
            }
        }
        if (identityKeysToLoad.length === 0) {
            return of(existingTenantUsers);
        }
        const query = `ids=${identityKeysToLoad.join('&ids=')}`;
        const request = this.http.get<any>(`${this.serviceUrls.Assignables}?${query}`)
            .pipe(
                map(x => x.Content),
                map(
                    (response: ITenantUser[]) => {
                        const results = response.map(tenantResponse => {
                            this.tenantContext.loadApiResponseModels(TenantTypes[tenantResponse["@Type"]], tenantResponse);
                            this.CachedAssignablesRequests.delete(tenantResponse.Id);
                            return this.tenantContext.get(ModelUtils.createDomainId(new TenantUser(), tenantResponse.Id)) as TenantUser;
                        });
                        return results.concat(existingTenantUsers);
                    }),
                catchError(this.handleHttpError),
                share());

                identityKeysToLoad.forEach(key => {
            this.CachedAssignablesRequests.set(key, request);
        });

        return this.CachedAssignablesRequests.get(identityKeysToLoad[0]) as Observable<TenantUser[]>;
    }
}