<div *ngIf="tenants?.length">
    <p>Please choose a tenant to proceed:</p>
    <div class="form-group">
        <select class="form-select" name="selectTenant" [(ngModel)]="selectedTenant">
            <option [ngValue]="null" disabled selected hidden>Please Select</option>
            <option *ngFor="let tenant of tenants">{{tenant}}</option>
        </select>
        <div *ngIf="tenant_invalid" class="alert alert-danger" id="tenantSelectionError">
            Please select a Tenant
        </div>
    </div>
    <button unity-button class="primary block my-3" [disabled]="!selectedTenant" 
        (click)="onTenantSelect.emit(selectedTenant)">
        Select Tenant
    </button>
    <div class="center">
        <a href="javascript:void(0)" (click)="onChangeUser.emit()">
            Change User
        </a>
    </div>
</div>
<ng-container *ngIf="!tenants?.length">
    <p>
        You do not have access to any tenants. Please contact an administrator to add you to a tenant.
    </p>
    <a href="javascript:void(0)" (click)="onChangeUser.emit()">Back to login</a>
</ng-container>