import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'unity-toggle',
    templateUrl: './unity-toggle.component.html',
    styleUrls: ['./unity-toggle.component.scss'],
})
export class UnityToggleComponent{
    @Input() disabled: boolean;
    @Input() controlName: string;
    @Input() toggledOn: boolean;

    @Output() onToggle: EventEmitter<boolean> = new EventEmitter();
};
