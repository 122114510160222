import { Component, Input } from '@angular/core';

@Component({
    selector: 'unity-icon',
    templateUrl: './unity-icon.component.html',
    styleUrls: ['./unity-icon.component.scss']
})
export class UnityIconComponent {
    @Input() icon: string;
    @Input() size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium'; // 12px, 16px, 20px, 24px
    // inherit = apply no color so parent components can override
    @Input() color: 'primary' | 'secondary' | 'tertiary' | 'disabled' | 'inherit' = 'primary'; 
};