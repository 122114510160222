export enum DateRangeString {
    Today = "Today",
    LastThirtyDays = "Last 30 days",
    LastSixtyDays = "Last 60 days",
    LastNinetyDays = "Last 90 days",
    ThisWeek = "This Week",
    LastWeek = "Last Week",
    ThisMonth = "This Month",
    LastMonth = "Last Month",
    ThisQuarter = "This Quarter"
}