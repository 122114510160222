import { Injectable } from '@angular/core';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// TenantContext - Singleton (injectable).  Applies to tenant administration.
@Injectable({
    providedIn: 'root',
})
export class TenantContext extends DataContext {
    public isLoaded: boolean = false;
    public DataPointTypes: BehaviorSubject<{ name: string, label: string }[]> = new BehaviorSubject<{ name: string, label: string }[]>([]);

    constructor() {
        super();
    }
}