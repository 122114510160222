import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';
import { AccountsService } from '@Services/account-accounts-service';
import { SearchResult, SearchDataType } from '@Shared/Components/sm-quick-search-card/sm-quick-search-card.component';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'account-search',
    templateUrl: './account-search.component.html',
    styleUrls: ['./account-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSearchComponent {

    @ViewChild('search') search: ElementRef;
    
    public searchFocus: boolean = false;
    public searching: boolean = false;
    public searchHasValue: boolean = false;
    public searchValue: string = "";
    public searchMessage: string = "";
    public overSearch: boolean = false;
    public foundAccounts: SearchResult[] = new Array<SearchResult>();

    public searchDebounceTimeout: any;
    public searchSubscription$: Subscription;

    public searchType: SearchDataType = SearchDataType.Account;

    public TENANT_PERMISSIONS = TenantPermissions;

    constructor(
        private accountService: AccountsService,
        private router: Router,
        private changeDetection: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.setDefaultSearchMessage();
    }

    public onSearchFocus(): void {
        this.searchFocus = true;
    }

    public onSearchBlur(): void {
        this.searchFocus = false;
    }

    public onSearchOver(): void {
        this.overSearch = true;
    }

    public onSearchOut(): void {
        this.overSearch = false;
    }

    public onSearchKeyup(event: KeyboardEvent): void {
        this.clearSearchTimeout();

        const searchValue = (<HTMLInputElement>event.target).value;
        this.searchValue = searchValue;

        if (searchValue.length > 0) {
            this.searchHasValue = true;
            this.searchMessage = "Minimum 3 characters to search";
        }
        else {
            this.searchHasValue = false;
            this.foundAccounts = [];
            this.setDefaultSearchMessage();
        }
        if (searchValue.length > 2) {
            this.performSearch(searchValue);
        } else {
            this.searching = false;
        }
    }

    private clearSearchTimeout() {
        clearTimeout(this.searchDebounceTimeout);
        this.searchDebounceTimeout = null;
    }

    private setDefaultSearchMessage() {
        this.searchMessage = "Start typing to search";
    }

    private performSearch(searchValue: string): void {
        var self = this;
        this.searching = true;
        this.searchMessage = "Searching...";

        if (this.searchHasValue) {          
            this.searchDebounceTimeout = setTimeout(() => {
                // If there is currently a search in progress, ignore the result. 
                if (this.searchSubscription$)
                    this.searchSubscription$.unsubscribe();

                this.searchSubscription$ = this.accountService.searchAccounts(searchValue).subscribe(
                    (response: any) => {
                        // Do this here and not at the beginning of the function to prevent some weird UI where the search list disappears temporarily
                        this.foundAccounts = []; 
                        var responseContent = response.Content; // This should be an array of objects. 
                        _.forEach(responseContent, obj => {

                            // We are going to deserialize this to our Search Account object.
                            var searchAccount = new SearchResult();
                            searchAccount.score = obj["@search.score"];
                            searchAccount.highlights = obj["@search.highlights"];
                            searchAccount.data = obj.document;  // Not an actual Account object. 
                            self.foundAccounts.push(searchAccount);
                        });
                        this.searching = false;
                        this.searchMessage = `Showing ${self.foundAccounts.length} results`;
                        this.changeDetection.detectChanges();
                    },
                );
                this.searchDebounceTimeout = null;
            }, 1000);     
        }
        else {
            this.foundAccounts = [];
            this.searching = false;
            this.searchMessage = "No results";
        }
    }

    public onSearchAccountAdded(foundAccount: any): void {
        this.router.navigate(['Work', 'Account', foundAccount.id]);
        this.clearSearchInput();
    }

    public clearSearchInput(): void {
        this.searchHasValue = false;
        this.searchValue = "";
        this.search.nativeElement.focus();
        this.foundAccounts = [];
        this.searching = false;
        this.setDefaultSearchMessage();
    }
}