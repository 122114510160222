<ng-container *ngIf="!readOnly">
    <input
        [id]="id"
        class="form-control"
        [(ngModel)]="value"
        [ngClass]="getClasses()"
        [mask]="componentMask"
        [disabled]="disabled"
        (ngModelChange)="valueChange($event)"
        (focusin)="focusin.emit($event)"
        (focusout)="focusout.emit($event)"
    />
</ng-container>
<ng-container *ngIf="readOnly">
    <span [id]="id">
        {{ value | mask: componentMask }}
    </span>
</ng-container>