import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import _ from "lodash";
import { Subscription } from "rxjs";

import { PartitionKeyIdPair } from '@Core/Lib/model';;

import { SmFormFieldVM } from "./sm-form-field.component";
import { SmFormHelper } from "./sm-form-helper";
import { SmFormSectionInstanceVM } from "./sm-form-section-instance.component";
import { SmElementFocusedEvent, SmFormService } from "./sm-form-service";
import { FormValueChangeEvent, SmFormElementVM, SmFormState, SectionDeletedEvent } from "./sm-form.component";

@Component({
    selector: 'sm-form-section',
    templateUrl: './sm-form-section.component.html',
    styleUrls: ['./sm-form-section.component.scss']
})
export class SmFormSectionComponent  {

    @Input() model: SmFormSectionVM;
    @Input() readOnly: boolean;
    @Input() hideSingleSectionArrow: boolean;
    @Input() formState: SmFormState;

    @ViewChild('section') sectionElementRef: ElementRef;
    
    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onSectionAdded: EventEmitter<SmFormSectionVM> = new EventEmitter();
    @Output() onSectionDeleted: EventEmitter<SectionDeletedEvent> = new EventEmitter();
    // Propagate whenever a change happens that merits parent components to recheck statuses.
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();

    public repeatableInstanceCount = 0;
    public allInstancesToggledToHide = false;

    public instancesHasChildSection = false;
    public minInstanceError = false;
    public maxInstanceError = false;
    public hasError = false;

    public hideSingleSubSectionArrow: boolean;
    public currentSortLabel: string = "";
    public currentSortOrder: 'asc' | 'desc' = 'asc';

    public elementChanged$: Subscription;
    public sectionAdded$: Subscription;
    public sectionDeleted$: Subscription;
    public elementFocused$: Subscription;

    constructor(public service: SmFormService,
        public cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.elementChanged$ = this.service.getElementChanged().subscribe(e => {
            if (e.ElementId === this.model.Id) {

                // The only properties that should be updated via this sync
                // are Hidden and Errors. Instances are updated below.
                this.model = {
                    ...this.model,
                    Hidden: e.ElementVM.Hidden,
                    Errors: e.ElementVM.Errors
                };
                this.updateStatuses();
            }
        });

        if (this.model.Repeatable) {
            this.sectionAdded$ = this.service.getSectionAdded().subscribe(e => {
                if (e.SectionVM.Id === this.model.Id) {
                    this.model = {
                        ...this.model,
                        Instances: e.SectionVM.Instances
                    };

                    this.updateStatuses();   
                }
            });
    
            this.sectionDeleted$ = this.service.getSectionDeleted().subscribe(e => {
                if (e.SectionVM.Id === this.model.Id) {
                    this.model = {
                        ...this.model,
                        Instances: e.SectionVM.Instances
                    };
                    this.updateStatuses();
                } else if (e.SectionVM.Id == this.model.ParentId 
                    || e.SectionVM.Instances.find(i => i.Id == this.model.ParentId)) {
                    this.model = {
                        ...this.model,
                        ParentIsDeleted: true
                    };
                    this.cdRef.detectChanges();
                }
            });
        }

        this.elementFocused$ = this.service.getElementFocused().subscribe(e => {
            this.handleElementFocused(e);
        });

        // Initial Sort
        if (this.model.Repeatable) {
            this.currentSortLabel = this.model.RepeatableVisibleLabels[0];
            this.currentSortOrder = 'asc';
        }    
        
        this.hideSingleSubSectionArrow = !this.hideSingleSectionArrow && this.model.Items.length > 1 ? false : true;
        this.updateStatuses(false);
        
        this.allInstancesToggledToHide = _.some(this.model.Instances, i => i.ToggledToHide);
    }

    ngOnDestroy() {
        this.elementChanged$?.unsubscribe();
        this.sectionAdded$?.unsubscribe();
        this.sectionDeleted$?.unsubscribe();
        this.elementFocused$?.unsubscribe();
    }

    private handleElementFocused(e: SmElementFocusedEvent) {
        if (e.Id == this.model.Id) {
            this.model.ToggledToHide = false;
            this.cdRef.detectChanges();

            if (e.Type == 'ParentSection') {
                // If we got here because of a 'ParentSection' issue, then we know its a min or max issue.
                // However, we got directed to the parent. Find the first child with a min or max issue.
                // If we can find it, redirect this message to it.
                var childRepeatableSectionWithMinOrMaxError = _.find(this.model.Items, i => {
                    return i.ElementType == 'Section'
                        && (i as SmFormSectionVM).Repeatable
                        && (
                            this.service.repeatableSectionHasMaxError(i as SmFormSectionVM)
                            || this.service.repeatableSectionHasMinError(i as SmFormSectionVM)
                        );
                }) as SmFormSectionVM;

                if (childRepeatableSectionWithMinOrMaxError) {
                    // We found a child with the min/max error, show and set focus to it.
                    childRepeatableSectionWithMinOrMaxError.ToggledToHide = false;
                    this.cdRef.detectChanges();
                    this.service.sendElementFocused(childRepeatableSectionWithMinOrMaxError.Id, 'Section');
                } else {
                    // We couldn't find it, just scroll to the parent (this)
                    this.sectionElementRef.nativeElement.scrollIntoViewIfNeeded(true);       
                }
            } else if (e.Type == 'Section') {
                // We want focus on this section, so scroll to it.
                this.sectionElementRef.nativeElement.scrollIntoViewIfNeeded(true);    

                if (this.minInstanceError) {
                    // We need to wait until the scroll is completed. 
                    // There is not a good way to do this, but most browsers
                    // (other than Chrome) max out at 500ms. 
                    setTimeout(() => this.onSectionAdded.emit(this.model), 500);
                }
               
            }
        }
    }

    toggleAllInstances(hide: boolean) {
        this.allInstancesToggledToHide = hide;
        this.model.Instances = _.map(this.model.Instances, i => { 
            return {
                ...i, 
                ToggledToHide: hide
            }; 
        });
    }

    public onChangeSort(label: string) {
        if (label == this.currentSortLabel) {
            if (this.currentSortOrder == 'asc')
                this.currentSortOrder = 'desc';
            else if (this.currentSortOrder == 'desc')
                this.currentSortOrder = 'asc';
        } else {
            this.currentSortLabel = label;
            this.currentSortOrder = 'asc';
        }
        this.sort();
    }

    public sort() {
        if (!this.model.Repeatable)
            return;

        if (this.currentSortLabel) {
            this.model.Instances = _.orderBy(this.model.Instances, i => {
                var field = _.find(i.Items, i => (i as SmFormFieldVM).Label == this.currentSortLabel) as SmFormFieldVM;
                return SmFormHelper.GetActualValue(field);
            }, this.currentSortOrder);
        } 
    }

    private updateStatuses(sendOutput: boolean = true) {
        this.updateInstancesHasChildSection();

        if (this.model.Repeatable) {
            this.minInstanceError = this.service.repeatableSectionHasMinError(this.model);
            this.maxInstanceError = this.service.repeatableSectionHasMaxError(this.model);

            this.repeatableInstanceCount = _.filter(this.model.Instances, i => i.ParticipationStatus != 'Deleted').length;
        }

        this.hasError = this.service.sectionHasError(this.model); 

        this.cdRef.detectChanges();
        if (sendOutput)
            this.onDetectChanges.emit();
        this.sort();
    }

    private updateInstancesHasChildSection() {
        var instanceItems = _.flatMap(this.model.Instances, i => i.Items);
        this.instancesHasChildSection = _.some(instanceItems, i => i.ElementType == 'Section');
    }
}

export interface SmFormSectionVM extends SmFormElementVM {
    Items: SmFormElementVM[];
    ParentId: string; // The id of its parent
    ParentPartitionKeyIdPair: PartitionKeyIdPair;
    ToggledToHide?: boolean;
    
    Repeatable: boolean;
    MinRepeatableInstances?: number;
    MaxRepeatableInstances?: number;
    Instances?: SmFormSectionInstanceVM[];
    RepeatableVisibleLabels?: string[];
    ParentIsDeleted?: boolean; // If this section is a nested repeatable and its parent is deleted
    Access?: string; // 'Normal' | 'PreventAddAndDelete' 
}