import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { AuthService } from '@Services/auth-service';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.headers.has(HttpHeaderNames.ContentType))
      request = request.clone({ headers: request.headers.set(HttpHeaderNames.ContentType, 'application/json') });

    if (!request.headers.has(HttpHeaderNames.Accept))
      request = request.clone({ headers: request.headers.set(HttpHeaderNames.Accept, 'application/json') });

    return next.handle(request).pipe();
  }

}