
<ng-container *ngFor="let section of model.Sections">
    <sm-form-section 
        [model]="section" 
        [readOnly]="readOnly"
        [formState]="formState"
        (onValueChanged)="onValueChanged.emit($event)"
        (onSectionAdded)="onSectionAdded.emit($event)"
        (onSectionDeleted)="onSectionDeleted.emit($event)"
        [hideSingleSectionArrow]="hideSingleSectionArrow">
    </sm-form-section>
</ng-container>