import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GlobalMessages } from '@Services/global-messages';
import { BaseService } from '@Services/base-service';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';

import { ProgramRevisionContext } from '@Core/Lib/Contexts/program-revision-context';


@Injectable()
export class ProgramRevisionWorkflowService extends BaseService {

    private serviceUrls = class ServiceUrls {
        public static baseUrl: string = BaseService.baseUrl + '/Workflows/';
    }

    constructor(
        private http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    loadProgramRevision(context: ProgramRevisionContext) {
        const url = this.serviceUrls.baseUrl + "Programs/Revisions/" + context.revisionId;
        const request = this.http.get<any>(url);
        return request;
    }
}
