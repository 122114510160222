import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '@Admin/admin.component';
import { ConfComponent } from '@Conf/conf.component';
import { MgmtComponent } from './Views/Mgmt/mgmt.component';
import { WorkComponent } from '@Work/work.component';
import { LoginContainerComponent } from '@Mktg/Login/login-container.component';
import { RegisterComponent } from '@Mktg/Register/register.component';

const routes: Routes = [
    { 
        // Default to the Mktg (Login) view
        path: '', 
        component: LoginContainerComponent,
    },
    {
        path: 'Login', 
        redirectTo: '', 
        pathMatch: 'full' 
    },
    {
        path: 'Register', 
        component: RegisterComponent
    },
    {
        path: 'Work',
        component: WorkComponent,
        loadChildren: () => import('./Views/Work/work.module').then(m => m.WorkModule) 
    },
    {
        path: 'Conf',
        component: ConfComponent,
        loadChildren: () => import('./Views/Conf/conf.module').then(m => m.ConfModule) 
    },
    {
        path: 'Admin',
        component: AdminComponent,
        loadChildren: () => import('./Views/Admin/admin.module').then(m => m.AdminModule) 
    },
    {
        path: 'Mgmt',
        component: MgmtComponent,
        loadChildren: () => import('./Views/Mgmt/mgmt.module').then(m => m.MgmtModule) 
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
