<ng-container *ngIf="!readOnly">
    <div class="custom-control form-check">
        <input 
            [(ngModel)]="model.Value" 
            type="checkbox" 
            [disabled]="model.Disabled"
            class="form-check-input"  
            [ngClass]="{ 'is-invalid': model?.Errors?.length }"
            [id]="getInputIdOrName()" 
            [name]="getInputIdOrName()" 
            (change)="valueChange($event)"
        >
        <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()"></sm-form-field-label>
    </div>
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>