import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import _ from 'lodash';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { GlobalMessages } from '@Services/global-messages';
import { BaseService } from '@Services/base-service';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';

import { ITypedServiceResponse } from '@Core/Lib/model';
import { UserContext } from '@Core/Lib/Contexts/user-context';
import { Activity, Account } from '@Core/CodeGen/Models/area.models';
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { CreateAccountDTO } from '@Work/Shared/new-business-button/new-business-button.component';


class Response extends HttpResponse<any> {}

@Injectable()
export class UserAccountsService extends BaseService {

    private serviceUrls = class ServiceUrls {
        public static baseUrl: string = BaseService.baseUrl + '/Accounts/';

        public static Activities: string = ServiceUrls.baseUrl + 'Activities';
        public static Accounts: string = ServiceUrls.baseUrl + 'Accounts';

        public static filterAccountsUnsubstituted: string = ServiceUrls.baseUrl + 'Filter?activeAccounts={{activeAccounts}}';
        public static filterAccounts(activeAccounts: boolean): string {
            return this.filterAccountsUnsubstituted.replace('{{activeAccounts}}', activeAccounts.toString());
        }

        public static bulkReassignAccountRoles: string =  ServiceUrls.baseUrl + 'BulkReassign';

    }

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        private userContext: UserContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    createAccount(createAccountDTO: CreateAccountDTO, extraContext?: DataContext): Observable<Response> {
        let cntxt = this.userContext;
        let request = this.http.post<any>(`${this.serviceUrls.baseUrl}`, createAccountDTO)
            .pipe(share());
        request.subscribe(
            response => {
                cntxt.loading = true;
                let obj = _.cloneDeep(response.Content);
                cntxt.loadApiResponseModels(Account, obj);
                if (extraContext) {
                    extraContext.loading = true;
                    extraContext.loadApiResponseModels(Account, response);
                    extraContext.loading = false;
                }
                cntxt.loading = false;
            }
        );
        return request;
    }

    loadAccounts(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Accounts).pipe(share());
        this.handleNormalGetRequest(Account, request, this.userContext);
        return request;
    }

    loadUserActivity(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Activities).pipe(share());
        this.handleNormalGetRequest(Activity, request, this.userContext);
        return request;
    }

    loadAccountById(accountId: string): Observable<Response> {
        const request = this.http.get<any>(`${this.serviceUrls.baseUrl}${accountId}`).pipe(share());
        this.handleNormalGetRequest(Account, request, this.userContext);
        return request;
    }

    public filterAccounts(filterDTO: AccountFilterDTO, activeAccounts: boolean): Observable<Response> {
        let request = this.http.post<any>(this.serviceUrls.filterAccounts(activeAccounts), filterDTO).pipe(share());

        return request;
    }

    public bulkReassign(bulkReassignDto: BulkReassignRoleInputDTO): Observable<Response> {
        let request = this.http.post<any>(this.serviceUrls.bulkReassignAccountRoles, bulkReassignDto).pipe(share());

        return request;
    }

    public bulkReassignStatus(operationId: string): Observable<HttpResponse<ITypedServiceResponse<BulkReassignRoleOutputDTO>>> {
        const requestOptions = _.extend({
            observe: 'response' as 'body',
        });

        const request = this.http.get<HttpResponse<ITypedServiceResponse<BulkReassignRoleOutputDTO>>>(
            this.serviceUrls.bulkReassignAccountRoles + "/" + operationId,
            requestOptions
            ).pipe(share());


        return request;
    }

}

export interface AccountFilterDTO {
    ProgramId: string;
    AccountRoleId: string;
    FilledBy: string;
    WorkflowIds: string[];
    ProductIds: string[];
    States: string[];
    ProducerIds: string[];
}

export interface BulkReassignRoleInputDTO {
    AccountRolesToReassign: string[];
    UserToAssign: string;
}

export interface BulkReassignRoleOutputDTO {
    AccountRolesToReassign: string[];
    TotalNumberOfOriginalItems: number;
}