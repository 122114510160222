import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnityToast, UnityToastClickLinkEvent } from './unity-toast.component';
import { slideOut } from '@Shared/Animations/slide-out.animation';
import { slideUp } from '@Shared/Animations/slide-up.animation';
import { triggerChildAnimation } from '@Shared/Animations/trigger-child.animation';
import { verticalCollapseAnimation } from '@Shared/Animations/vertical-collapse.animation';

@Component({
    selector: 'unity-toasts',
    templateUrl: './unity-toasts.component.html',
    styleUrls: ['./unity-toasts.component.scss'],
    animations: [verticalCollapseAnimation, triggerChildAnimation, slideUp, slideOut]
})

export class UnityToastsComponent {
    @Input() toasts: UnityToast[] = [];

    @Output() onClickLink: EventEmitter<UnityToastClickLinkEvent> = new EventEmitter();
    
    dismiss(e: Event) {
        const target = e.target as HTMLElement;
        if (target) {
            const unitySnack = target.closest('.unity-toast');
            if (unitySnack) {
                unitySnack.classList.add('fadeout');
                Promise.all(unitySnack.getAnimations().map((animation) => animation.finished)).then(
                    () => unitySnack.remove()
                );
            }
        }
    }
}