import { getCurrencySymbol } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import _ from "lodash";
import { NgxMaskService } from "ngx-mask";
import { FormatUtils } from "@Core/Lib/Utils/format-utils";

@Component({
    selector: 'sm-input-money',
    templateUrl: './sm-input-money.component.html',
    styleUrls: ['./sm-input-money.component.scss']
})
export class SmInputMoneyComponent {
    @Input() public id: string;
    @Input() public editModeClasses: string[];
    @Input() public value: string;
    @Input() public disabled: boolean;
    @Input() public readOnly: boolean;

    @Input() public displayAsWholeNumber: boolean;
    @Input() public currency: string = "USD";
    @Input() public displayNegativesInRed: boolean;

    @Output() public focusin: EventEmitter<any> = new EventEmitter();
    @Output() public focusout: EventEmitter<any> = new EventEmitter();
    @Output() public onChange: EventEmitter<any> = new EventEmitter();

    constructor(public maskService: NgxMaskService) {}

    public inputValue: string;
    public componentMask: string;
    public thousandSeparator: string;
    public decimalMarker: "," | ".";

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        this.thousandSeparator = FormatUtils.getThousandSeparator();
        this.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";

        this.maskService.thousandSeparator = this.thousandSeparator;
        this.maskService.decimalMarker = this.decimalMarker;

        // NOTE: displayAsWhole and displayNegativesInRed only apply to readOnly mode
        this.inputValue = SmInputMoneyComponent.convertMoneyBeforeMask(this.value, this.readOnly ? this.displayAsWholeNumber : false, this.readOnly);
        this.componentMask = SmInputMoneyComponent.getComponentMask(false);
    }

    public getFormattedMoney(): string {
        return SmInputMoneyComponent.formatMoney(this.value, this.maskService, this.displayAsWholeNumber, this.currency);
    }
    
    public static convertMoneyBeforeMask(value: any, displayAsWholeNumber: boolean, readOnly: boolean): string {
        // Note: The value passed in to this component should ALWAYS be in the en-US culture, 
        // using a comma as a thousand separator and a period as the decimal (because that is how it will be stored in our DB)
        // This component should translate from that into the correct expected culture (removing any thousand separators).
        // For example, if we get 1,234.56 as the input, but are in the german culture, it should output '1234,56'. 

        if (_.isNil(value) || value === '')
            return null;

        // Cut off the first character if its a currency symbol. We can't convert it to a number with it.
        if (_.isString(value) && _.isNaN(Number(value[0])) && value[0] != "-")
            value = value.substring(1);

        var decimalMarker = FormatUtils.getDecimalMarker();

        let convertedDecimal = displayAsWholeNumber ? 
            _.round(parseFloat(value)).toString():
            value.toString();

        // Remove the , as it should be the thousand separator
        convertedDecimal = convertedDecimal.split(',').join('');

        // ONLY for readonly mode, replace the '.' with the current decimal marker (which may still be a '.')
        // I don't fully understand why we have to do this, but the input control doesn't work well when
        // updating the value when we do this
        if (readOnly) {
            convertedDecimal = convertedDecimal.replace('.', decimalMarker);
        }
            
        return convertedDecimal;
    }

    public static formatMoney(value: any, maskService: NgxMaskService, displayAsWholeNumber: boolean,
        currency: string): string {
        if (_.isNil(value) || value === '')
            return '';

        maskService.thousandSeparator = FormatUtils.getThousandSeparator();
        maskService.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";

        var convertedDecimal = SmInputMoneyComponent.convertMoneyBeforeMask(value, displayAsWholeNumber, true);
        
        let formattedMoney = maskService.applyMask(convertedDecimal.toString(), 
            SmInputMoneyComponent.getComponentMask(displayAsWholeNumber));

        let output = formattedMoney;
        if (formattedMoney[0] == '-') {
            output = "(" + formattedMoney.substring(1) + ")";
        }

        return SmInputMoneyComponent.getCurrencySymbol(currency) + output;
    }

    valueChange(value) {
        // Our formatting can trigger a valueChange event. If the field is disabled or readonly, 
        // don't send the event.
        if (this.disabled || this.readOnly)
            return;

        if (_.isNil(value) || value === '')
            this.onChange.emit(null);
        else if (_.isFinite(value)) {
            this.onChange.emit(value);
        }
    }

    applyclasses() {
        return this.editModeClasses;
    }

    public isNegative() {
        return parseFloat(this.inputValue) < 0;
    }

    public static getComponentMask(displayAsWholeNumber: boolean): string {
        var numberOfDecimals = displayAsWholeNumber ? 0 : 2;
        return 'separator.' + numberOfDecimals;
    }

    public getCurrencySymbolForHtml() {
        return SmInputMoneyComponent.getCurrencySymbol(this.currency);
    }

    public static getCurrencySymbol(currency: string) {
        return getCurrencySymbol(currency, 'narrow');
    }
};