import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
  } from '@angular/common/http';
  
import { Observable } from 'rxjs';
import { retryWhen } from 'rxjs/operators';
import { BackoffRetryStrategy } from '@Core/Lib/retry-strategy';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(retryWhen(BackoffRetryStrategy()));
  }

}