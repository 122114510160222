import { Component, Input } from '@angular/core';

@Component({
    selector: 'sm-sync-status',
    templateUrl: './sm-sync-status.component.html',
    styleUrls: ['./sm-sync-status.component.scss']
})
export class SmSyncStatusComponent {
    
    @Input() status: SyncStatus;
    @Input() placement: string = "left";
    public syncClass: string;

    ngOnChanges() {
        this.syncClass = this.status.toLowerCase();
    }
}

export enum SyncStatus {
    Connected = "Connected",
    Disconnected = "Disconnected",
    Reconnecting = "Reconnecting"
}
