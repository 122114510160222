import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import _ from 'lodash';

@Component({
    selector: 'sm-simple-dialog',
    templateUrl: './sm-simple-dialog.component.html',
})
export class SmSimpleDialog {
    @Input() options: SimpleDialogOptions


    constructor(public activeModal: NgbActiveModal) { }

    performAction(button: DialogButtonOptions): void {
        if (!button || !button.action)
            return;

        button.action(this.activeModal);
    }
}

export interface SimpleDialogOptions {
    title?: string;
    dismissButton?: boolean;
    message: string;
    buttons: DialogButtonCollection;
}

export interface DialogButtonCollection {
    leftButton?: DialogButtonOptions
    centerButton?: DialogButtonOptions
    rightButton?: DialogButtonOptions
}

export interface DialogButtonOptions {
    label: string;
    class?: string | string[];

    // This function is called with the first argument being a reference to the active modal.
    action: Function; 
}