import { Observable, timer, throwError } from 'rxjs';
import { mergeMap, finalize, } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

// A strategy to retry a request when the authentication hasn't loaded yet
// (usually due to a 429 or a 503). 
export const NoAuthRetryStrategy = ({
    maxRetryAttempts = 4,
    scalingDuration = 1000,
    excludeWhen = defaultExclusionFunc
} : {
    maxRetryAttempts?: number,
    scalingDuration?: number,
    excludeWhen?: (response: HttpResponse<any>)=>boolean
} = {}) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            // if maximum number of retries have been met
            // or response does not pass the excludeWhen function, throw error
            if (retryAttempt > maxRetryAttempts || excludeWhen(error)) {
                return throwError(() => error);
            }
            console.log(
                `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
            );
            // retry after 1000ms, 2000ms, 3000ms, etc...
            return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('Retries are done!'))
    );
};

export const defaultExclusionFunc = (response: HttpResponse<any>) : boolean => {
    // Retry the 401 Unauthorized if we haven't actually loaded the
    // authentication yet. 
    if (response.status === 401)
        return false;
        
    return true;
};