import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';

@Component({
    selector: 'unity-toast',
    templateUrl: './unity-toast.component.html',
    styleUrls: ['./unity-toast.component.scss'],
})

export class UnityToastComponent {
    @Input() toast: UnityToast;
    @Input() standalone: boolean = false;

    @Output() onClickLink: EventEmitter<UnityToastClickLinkEvent> = new EventEmitter();
    @Output() dismiss: EventEmitter<any> = new EventEmitter();
}

export class UnityToast {
    public Id: string;
    public Type: UnityToastType = UnityToastType.Info;
    public Icon: string;
    public Message: string = "";
    public Subtitles: UnityToastSubtitle[] = [];
    public Description: string = "";
    public Links: UnityToastSubtitle[] = [];

    constructor(id: string, type: UnityToastType, message: string, subtitlesAndLinks: UnityToastSubtitle[], description: string) {
        this.Id = id;
        this.Type = type;
        this.Message = message;
        this.Description = description;

        this.Subtitles = _.filter(subtitlesAndLinks, s => !s.IsLink);
        this.Links = _.filter(subtitlesAndLinks, s => s.IsLink);

        switch (this.Type) {
            case 'error':
                this.Icon = 'error-fill';
                break;
            case 'warning':
                this.Icon = 'warning-fill';
                break;
            case 'success':
                this.Icon = 'checkmark-circle-fill';
                break;
            case 'announcement':
                this.Icon = 'megaphone-fill';
                break;
            default:
                this.Icon = 'information-fill';
        }
    }
}

export interface UnityToastSubtitle {
    Type: string;
    DisplayType?: string;
    Text: string;
    IsLink?: boolean;
    LinkIds?: { [key: string]: string };
}

export enum UnityToastType {
    Info = 'info',
    Announcement = 'announcement',
    Error = 'error',
    Warning = 'warning',
    Success = 'success'
}

export interface UnityToastClickLinkEvent {
    Id: string; // Toast Id
    Subtitle: UnityToastSubtitle;
}