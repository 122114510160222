import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import _ from 'lodash';
import { Guid } from '@Core/Lib/guid';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { TenantTenantService } from '@Services/tenant-tenant-service';
import { SearchResult, SearchDataType, SmQuickSearchCard } from '@Shared/Components/sm-quick-search-card/sm-quick-search-card.component';
import { Subscription } from 'rxjs';


@Component({
    selector: 'sm-producer-search',
    templateUrl: './sm-producer-search.component.html',
    styleUrls: ['./sm-producer-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmProducerSearchComponent {

    @HostBinding('class') @Input() resultsDirection: 'up' | 'down' = 'up';
    @Input() disabled: boolean = false;
    @Input() placeholderLabel: string = "Producer";

    @Output() producerSelectedEvent: EventEmitter<ProducerSelectedEvent> = new EventEmitter();

    @ViewChild('search') search: ElementRef;
    @ViewChild('results') results: ElementRef;
    
    public searchFocus: boolean = false;
    public searching: boolean = false;
    public searchHasValue: boolean = false;
    public searchValue: string = "";
    public searchMessage: string = "";
    public searchDirty: boolean = true;

    public foundProducers: SearchResult[] = new Array<SearchResult>();

    private tenantContext: TenantContext;
    public searchType: SearchDataType = SearchDataType.Producer;
    public searchSubscription$: Subscription;
    public searchDebounceTimeout: any;

    constructor(
        private tenantService: TenantTenantService,
        private changeDetection: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.tenantContext = new TenantContext();        
        const self = this;

        this.setDefaultSearchMessage();
    }

    public onSearchFocus(event: Event): void {
        const searchValue = (<HTMLInputElement>event.target).value;
        this.searchFocus = true;

        if (this.searchHasValue && searchValue != this.searchValue) {
            this.performSearch(searchValue);
        }
    }

    public onSearchBlur(): void {
        this.searchFocus = false;
        if (this.searchDirty) this.resetSearch();
    }

    public onSearchKeyup(event: KeyboardEvent): void {
        this.clearSearchTimeout();

        const searchValue = (<HTMLInputElement>event.target).value;
        this.searchValue = searchValue;
        
        if (searchValue?.length > 0) {
            this.searchHasValue = true;
            this.searchMessage = "Minimum 3 characters to search";
        } else {
            this.searchHasValue = false;
            this.foundProducers = [];
            this.setDefaultSearchMessage();
        }

        if (searchValue.length > 2) {
            this.performSearch(searchValue);
        } else {
            this.searching = false;
        }
    }

    private setDefaultSearchMessage() {
        this.searchMessage = "Start typing to search";
    }

    private performSearch(searchValue: string): void {
        var self = this;
        this.searching = true;
        this.searchMessage = "Searching...";

        if (this.searchHasValue) {
            this.tenantContext = new TenantContext();

            this.searchDebounceTimeout = setTimeout(() => {
                if (this.searchSubscription$)
                    this.searchSubscription$.unsubscribe();

                this.searchSubscription$ = this.tenantService.searchProducers(searchValue, this.tenantContext)
                    .subscribe((response: any) => {
                    // Do this here and not at the beginning of the function to prevent some weird UI where the search 
                    // list disappears temporarily
                    this.foundProducers = []; 
                    var responseContent = response.Content; // This should be an array of objects. 

                    _.forEach(responseContent, obj => {
                        // We are going to deserialize this to our Search Result object.
                        var searchResult = new SearchResult();
                        searchResult.score = obj["@search.score"];
                        searchResult.highlights = obj["@search.highlights"];
                        searchResult.data = obj.document;  // Not an actual Producer object. 
                        self.foundProducers.push(searchResult);
                    });

                    this.searching = false;
                    this.searchMessage = `Showing ${self.foundProducers.length} results`;
                    this.changeDetection.detectChanges();
                    this.results?.nativeElement?.focus();
                });

                this.searchDebounceTimeout = null
            }, 1000);     
        }
        else {
            this.foundProducers = [];
            this.searching = false;
            this.searchMessage = "No results";
        }
    }

    public resetSearch(): void {
        this.searchHasValue = false;
        this.searchValue = "";
        this.searching = false;
        this.searchDirty = true;
        this.foundProducers = [];
        this.setDefaultSearchMessage();
    }

    public clearSearchInput(): void {
        this.resetSearch();
        var ev: ProducerSelectedEvent = {
            Id: null,
            Name: null,
            Code: null
        }
        this.producerSelectedEvent.emit(ev);
        this.search.nativeElement.disabled = false;
        this.search.nativeElement.focus();
    }

    private clearSearchTimeout() {
        clearTimeout(this.searchDebounceTimeout);
        this.searchDebounceTimeout = null;
    }

    public onProducerSelected(producerData: any): void {
        this.searchValue = producerData.name + " (" + producerData.code + ")";
        this.searching = false;
        this.searchDirty = false;

        var event: ProducerSelectedEvent = {
            Id: producerData.id,
            Name: producerData.name,
            Code: producerData.code
        }
        this.producerSelectedEvent.emit(event);
        this.search.nativeElement.disabled = true;

        this.searchFocus = false;
    }
}

export interface ProducerSelectedEvent {
    Id: Guid,
    Name: string,
    Code: string
}