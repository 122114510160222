<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>
        <sm-input-zipcode
            [id]="getInputIdOrName()"
            [value]="model.Value" 
            [readOnly]="readOnly"
            [disabled]="model.Disabled" 
            [editModeClasses]="getInputCssClass()"
            (onChange)="valueChange($event)"
            (focusin)="onFocusChanged.emit(true)"
            (focusout)="onFocusChanged.emit(false)">
        </sm-input-zipcode>
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>        
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>