import { ChangeDetectorRef, Component, Input, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import _ from 'lodash';

import { AvailableProgramsViewModel } from '@Work/Shared/new-business-button/new-business-button.component';
import { PopupSettings } from '@progress/kendo-angular-popup';
import { ProducerSelectedEvent } from '@Shared/Components/sm-producer-search/sm-producer-search.component';
import { Guid } from '@Core/Lib/guid';
import { CAN_Province_Territories, US_States, LocationVM } from '@Core/Lib/Enums/locations';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';
import { Region } from '@Core/CodeGen/Models/configuration.models';
import { UnityStepVM } from '@Shared/Components/unity-stepper/unity-stepper.component';
import { Program } from '@Core/CodeGen/Models/area.models';

@Component({
    selector: 'account-creation-modal',
    templateUrl: './account-creation-modal.component.html',
    styleUrls: ['./account-creation-modal.component.scss'],
})
export class AccountCreationModalComponent implements OnInit {
    @Input() programs: AvailableProgramsViewModel[] = [];
    @Input() errorMessage: string;
    @Input() regions: Region[];
    @Input() programRequired: boolean;

    @Output() onCreateAccount: EventEmitter<AccountCreationModalResult> = new EventEmitter();

    @ViewChild('nameInput') nameInput: any;
    @ViewChild('countryInput') countryInput: ElementRef;

    public TENANT_PERMISSIONS = TenantPermissions;

    public accountName: string;
    public accountCode: string;
    public shellAccount: boolean;

    public dba: string;
    public phone: string;
    public country: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public zip: string;
    public producerName: string;
    public program?: Program;

    public us_states: LocationVM[] = US_States.getStates();
    public can_provinces: LocationVM[] = CAN_Province_Territories.getProvincesAndTerritories();

    public producerId: Guid;

    public popupSettings : PopupSettings;

    public accountCreationSteps: UnityStepVM[] = [
        { Name: "General Information"}, { Name: "Contact Information" }
    ];
    public accountCreationCurrentStep = 0;

    constructor(
        public activeModal: NgbActiveModal,
        public cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.shellAccount = false;

        if (this.regions?.length) {
            var defaultRegion = _.find(this.regions, r => r.IsProgramDefault);
            this.country = defaultRegion?.Country;
        } 
        if (!this.country)
            this.country = 'USA';    
    }

    createAccount(startWorkflow: boolean) {
        if (this.disableCreateAccount())
            return;

        const result: AccountCreationModalResult = {
            accountName: this.accountName,
            accountCode: this.accountCode,
            producerId: this.producerId?.toString(),
            dba: this.dba,
            phone : this.phone,
            country: this.country,
            address1: this.address1,
            address2: this.address2,
            address3: this.address3,
            city: this.city,
            state: this.state,
            zip: this.zip,
            startWorkflow : startWorkflow,
            programId: this.program ? this.program.Id : null
        };
        this.onCreateAccount.emit(result);
    }

    public setProducer(event: ProducerSelectedEvent): void {
        this.producerId = event.Id;
        this.producerName = `${event.Name} (${event.Code})`;
    }

    public disableNext() {
        return !this.accountName || (this.programRequired && !this.program);
    }

    public disableCreateAccount() {
        return !this.address1 || !this.phone || !this.city  || (!this.state && this.country != 'GBR') || !this.zip;
    }

    public next(e: any) {
        if (this.disableNext())
            return;

        this.accountCreationCurrentStep = 1;
        this.cdRef.detectChanges();
        this.countryInput.nativeElement.focus();

        e?.stopPropagation();
    }

    public changeStep(step: number) {
        // We should only be changing from 1 => 0
        if (step == 0)
            this.back();
    }

    public back() {
        this.accountCreationCurrentStep = 0;
        this.cdRef.detectChanges();
        this.nameInput.nativeElement.focus();
    }
}

export interface AccountCreationModalResult {
    accountName: string,
    accountCode: string,
    producerId: string,
    dba: string;
    phone: string;
    country: string;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    zip: string;
    startWorkflow: boolean;
    programId: string;
}