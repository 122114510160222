import { Injectable } from '@angular/core';
import { UserService } from '@Services/user-service';
import { UserContext } from '@Core/Lib/Contexts/user-context';

import { Notification, Types as TenantTypes } from "@Core/CodeGen/Models/configuration.models";
import { Types as AreaTypes } from "@Core/CodeGen/Models/area.models";
import { BaseModel } from '@Core/Lib/model';
import { AuthService } from '@Services/auth-service';
import { BaseHub } from './BaseHub';
import { LoggingService } from '@Services/logging-service';
import { SyncHubStatusService } from '@Services/sync-hub-status-service';
import { TenantService } from '@Core/CodeGen/Services/tenant.service';
import { AccountsService } from '@Core/CodeGen/Services/accounts.service';
import { NotificationService } from '@Services/notification-service';

@Injectable({
    providedIn: 'root'
})
export class AlertsHub extends BaseHub {

    private joinedUserGroup: boolean = false;
    private userTimeout: any; // number | NodeJS.Timeout

    route: string = "alerts";
    
    private DomainTypes = {
        "Tenant": TenantTypes,
        "Workflows": AreaTypes,
        "Data": AreaTypes,
        "Accounts": AreaTypes,
        "Documents": AreaTypes,
    };

    constructor(
        public userService: UserService,
        public auth: AuthService,
        private userContext: UserContext,
        protected logger: LoggingService,
        protected statusService: SyncHubStatusService,
        private tenantService: TenantService,
        private accountService: AccountsService,
        private notificationService: NotificationService
    ) {
        super(userService, auth, logger, statusService);
    }

    public initialize(): void {
        let self = this;
        if (this.isConnected() && this.user) {
            this.joinUserGroup();
        }
    }

    private joinUserGroup(): void {
        if (this.joinedUserGroup)
            return; // already joined

        if (!this.isConnected() || !this.user)
            return;

        this.hubConnection.invoke('Join', this.user.userKey);
        this.logger.logInfo(`${this.hubName}: User has joined the user's group.`);
        this.joinedUserGroup = true;
    }

    public mapMessageHandlers(): void {
        this.hubConnection.on('ProcessAlert', (message: any) => {
            let domain = message.EntityDomain;
            if (!domain) {
                this.logger.logWarn("message received without an EntityDomain");
                return;
            }

            this.logger.logVerbose("message received", { 
                domain: domain, 
                type: message.EntityType, 
                id: message.EntityId, 
                entity: JSON.parse(message.Entity) 
            });
            
            var type: { new(): BaseModel } = this.DomainTypes[domain][message.EntityType];

            if (!type) {
                this.logger.logWarn(`could not map message EntityType of '${message.EntityType}' to a DomainType`);
                return;
            }

            if (message.AreaKey == null || message.AreaKey == this.auth.getAreaKey()) {
                var model = this.userContext.loadApiResponseModels(type, message.Entity as string);

                if (message.EntityType == 'Notification') {
                        this.notificationService.add(model[0] as Notification);
                }

                setTimeout(() => {
                    console.info("logging inside a timeout to trigger CD"); // trigger Angular change detection
                }, 0);                
            }
        });
    }

    public onConnectionStartedCallback(): void {
        this.joinUserGroup();
    }

    public onConnectionClosedCallback(): void{
        this.joinedUserGroup = false;  // set to false so that a reconnect can rejoin.
    }

    public onPollingStart(): void {
        this.pollUser();
    }

    public onPollingStop(): void {
        if (this.userTimeout) {
            clearTimeout(this.userTimeout);
            this.userTimeout = null;
            this.logger.logVerbose("Ending polling of user alerts now that syncing has been established");
        }
    }

    private pollUser(hub?: AlertsHub): void {
        if (!hub && this.userTimeout) {
            return; // we are already Polling
        }

        hub = hub || this;
        if (hub.userTimeout) {
            hub.loadUserInfo();
        } else {
            hub.logger.logInfo("Reverting to polling user account info"); 
        }
        hub.userTimeout = setTimeout(() => {
            hub.pollUser(hub)
        }, 296000 + this.getRandomInt(0, 6000)); // Poll about every 5 minutes (+6 or -6 seconds)
    }

    private loadUserInfo(): void {
        this.logger.logVerbose(`Polling user alert data`);

        this.tenantService.ListUserNotificationCenters(this.userContext);
        this.accountService.ListUserNotificationCenters(this.userContext);
    }    
}