import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import _ from 'lodash';
import { BehaviorSubject, Subject, Observable, of, throwError } from 'rxjs';
import { share, map, takeUntil, first, catchError } from 'rxjs/operators';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';

import { ModelUtils } from '@Core/Lib/Utils/model-utils';
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { ProgramContext } from '@Core/Lib/Contexts/program-context';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { IStaticElementType, ITypedServiceResponse, IServiceResponse, IValidationCode } from "@Core/Lib/model";
import {
    Program, ProgramChange, ProgramRevision, WorkflowSet, Workflow,
    Phase, Task, WorkGroup, RuleSet,
    ProgramValidationMessage, TenantUser,
    AccountRole, ProgramObligatesAccountRole, ProgramEmploysTenantUser,
    Application, Element, Section, Rule, ProgramUsesWorkGroup,
    Producer, AssetGroup, Asset
} from '@Core/CodeGen/Models/configuration.models';
import { ProgramTeam } from '@Core/Models/ProgramTeam';
import { EditableStateEnum } from '@Core/Lib/Enums/editable-state-enum';
import { AuthService } from './auth-service';
import { AreaStandbyWorkflowCountDTO } from '@Admin/Tenants/tenants-edit/manage-standbys-modal/manage-standbys-modal.component';


class Response extends HttpResponse<any> { }

@Injectable()
/** Service for the Program context that accesses the Programs domain. */
export class ProgramProgramsService extends BaseService {

    protected serviceUrls = class ServiceUrls {

        protected static replaceValues(url: string, programId: string,
            itemName: string = null, itemId: string = null, changeId: string = null,
            componentName: string = null, componentId: string = null): string {
            return url
                .replace("{programId}", programId)
                .replace("{itemName}", itemName)
                .replace("{itemId}", itemId) // The item being acted on
                .replace("{changeId}", changeId)
                .replace("{componentName}", componentName)
                .replace("{componentId}", componentId);
        }

        public static baseProgramsUrl: string = BaseService.baseUrl + '/Programs/';
        public static programsUrl: string = ServiceUrls.baseProgramsUrl + '{programId}/'

        protected static baseGetAllUnsubstitued = ServiceUrls.programsUrl + '{itemName}';
        public static baseGetAll(programId: string, itemName: string) {
            return this.replaceValues(ServiceUrls.baseGetAllUnsubstitued, programId, itemName);
        }

        protected static baseGetOneUnsubstitued = ServiceUrls.programsUrl + '{itemName}/{itemId}';
        public static baseGetOne(programId: string, itemName: string, itemId: string) {
            return this.replaceValues(ServiceUrls.baseGetOneUnsubstitued, programId, itemName, itemId);
        }

        public static basePost(programId: string, itemName: string, changeId: string = null,
            componentName: string = null, componentId: string = null) {
            var url = ServiceUrls.programsUrl;
            if (componentName && componentId) // If the object is Revisionable, it needs to add the RevisionedComponent information
                url = url + '{componentName}/{componentId}/';
            url = url + '{itemName}'
            if (changeId) // If the API requires a change, add it
                url = url + '?changeId={changeId}'
            return this.replaceValues(url, programId, itemName, null, changeId, componentName, componentId);
        }

        public static basePutAndDelete(programId: string, itemName: string, itemId: string, changeId: string = null,
            workflowId: string = null) {
            var url = ServiceUrls.programsUrl + '{itemName}/{itemId}';
            if (changeId)
                url = url + '?changeId={changeId}'
            return this.replaceValues(url, programId, itemName, itemId, changeId, null, null);
        }

        //#region Programs
        public static specificProgram(programId: string): string {
            return (this.replaceValues(ServiceUrls.programsUrl, programId));
        }

        public static importProgram: string = `${ServiceUrls.baseProgramsUrl}Import`;

        //#endregion Programs

        //#region Program Changes

        public static changes: string = `${ServiceUrls.baseProgramsUrl}Changes`;
        public static change(changeId: string): string {
            return `${ServiceUrls.changes}/${changeId}`;
        }

        //#endregion Program Changes

        //#region Program Committing

        public static commitProgramChanges: string = ServiceUrls.baseProgramsUrl + 'Commit';
        public static uncommitProgramChanges: string = ServiceUrls.baseProgramsUrl + 'Uncommit';
        public static revertProgramChange(changeId: string): string {
            return this.change(changeId) + `/Revert`;
        }

        //#endregion Program Committing

        //#region Program Revisions

        private static programRevisions: string = ServiceUrls.baseProgramsUrl + 'Revisions';
        private static programRevisionUnsubstituted: string = ServiceUrls.programRevisions + '/{revisionId}';

        public static exportProgramRevision(revisionId: string): string {
            return `${ServiceUrls.programRevision(revisionId)}/Export`.replace("{revisionId}", revisionId);
        }

        public static undeployProgramRevision(revisionId: string): string {
            return `${ServiceUrls.programRevision(revisionId)}/Undeploy`;
        }

        public static setStandbyWorkflows(revisionId: string): string {
            return `${ServiceUrls.programRevision(revisionId)}/SetStandbyWorkflows`;
        }

        private static programRevision(revisionId: string): string {
            return ServiceUrls.programRevisionUnsubstituted.replace("{revisionId}", revisionId);
        }

        //#endregion Program Revisions

        //#region Enums
        public static PolicyRatedStatuses: string = ServiceUrls.baseProgramsUrl + 'PolicyRatedStatuses';
        //#endregion Enums
    }

    private loadingPrograms: boolean;

    public constructor(protected authService: AuthService,
        protected http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected router: Router,
        protected tenantContext: TenantContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    //#region Programs

    public loadProgramByKey(ProgramId: string, context: DataContext = this.tenantContext): Observable<any> {
        this.loadingPrograms = true;
        var url = this.serviceUrls.specificProgram(ProgramId);
        var self = this; // scope of "this" is lost within the following anonymous function
        const request = this.http.get<any>(url).pipe(share());
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Program, response);
                self.loadingPrograms = false;
            },
            error: error => {
                self.handleError(error);
                self.loadingPrograms = false;
            },
        });
        return request;
    }

    /**
    * Get an observable that will resolve to a Program with the given program key.
    */
    public getProgram(programId: string, context: DataContext = this.tenantContext): Observable<Program> {
        var dummyProgram = new Program();
        var programDomainId = ModelUtils.createDomainId(dummyProgram, programId); // build a domainId from the Key

        var findProgram = function (): Program {
            return context.get(programDomainId) as Program;
        }

        if (this.loadingPrograms) {
            let source = new Subject<Program>();
            let unsubscribe = new Subject();

            context.getStore(new Program()).values.pipe(takeUntil(unsubscribe))
                .subscribe(
                    roles => {
                        var p = findProgram();
                        if (p) {
                            source.next(p);
                            unsubscribe.next('');
                            unsubscribe.complete();
                        }
                    }
                );

            return source;
        }

        let program = findProgram();
        if (program) {
            return new BehaviorSubject<Program>(program);
        }
        else {
            return new BehaviorSubject<Program>(null);
        }
    }

    /** Retrieve a single program with the full HttpResponse. */
public retrieveProgramWithResponseHandleError(programId: string, context: DataContext = this.tenantContext)
        : Observable<HttpResponse<ITypedServiceResponse<Program>>> {

        var requestOptions = _.extend({
            observe: 'response' as 'body'
        });

        const request = this.http.get<HttpResponse<any>>(this.serviceUrls.specificProgram(programId),
            requestOptions).pipe(share());
        this.handleNormalGetRequest(Program, request, context);
        return request;
    }

    public retrieveProgramWithResponseThrowOnError(programId: string, context: DataContext = this.tenantContext)
        : Observable<HttpResponse<ITypedServiceResponse<Program>>> {
        var self = this;

        var requestOptions = _.extend({
            observe: 'response' as 'body'
        });

        const request = this.http
            .get<HttpResponse<any>>(this.serviceUrls.specificProgram(programId), requestOptions)
            .pipe(
                share(),
                catchError((error: any) => {
                    return throwError(() => error);
                })
            );

        request.subscribe((response: HttpResponse<any>) => {
            self.tenantContext.loadApiResponseModels(Program, response);
        });

        return request;
    }

    public addProgram(newProgram: Program): Observable<Response> {
        var self = this;
        let request = this.http.post<any>(this.serviceUrls.baseProgramsUrl, newProgram.serialize()).pipe(share());

        request.subscribe({
            next: response => {
                self.tenantContext.loadApiResponseModels(Program, response);
            },
            error: error => {
                let excludedPaths = ["Program.Name", "Program.Message", "Program.Description"];
                self.handleError(error, excludedPaths);
            }
        });

        return request;
    }

    public updateProgram(updatedProgram: Program, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.baseProgramsUrl + updatedProgram.Id;
        if (context.changeId)
            url = url + "?changeId=" + context.changeId;

        let request = this.http.put<any>(url, updatedProgram.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public importProgram(programZipFile: File, programName: string, programDescription: string)
        : Observable<HttpResponse<ITypedServiceResponse<Program>>> {
        const headers: HttpHeaders = new HttpHeaders().set(HttpHeaderNames.ContentType, "octet-stream");
        const params = { programName: programName, description: programDescription };
        const requestOptions = _.extend({
            headers: headers,
            observe: 'response' as 'body',
            params: params
        });

        const request = this.http.post<HttpResponse<ITypedServiceResponse<Program>>>(this.serviceUrls.importProgram,
            programZipFile, requestOptions).pipe(share());
        this.handleNormalGetRequest(Program, request, this.tenantContext);
        return request;
    }

    public validateProgramChange(changeId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePost(context.programId, "Validate") + "?changeId=" + changeId;
        let request = this.http.put<any>(url, null).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public getProgramValidationStatus(context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(context.programId, "Validate/Status");
        var requestOptions = _.extend({
            observe: 'response' as 'body'
        });
        let request = this.http.get<any>(url, requestOptions).pipe(share());
        this.handleNormalGetRequest(Program, request, context);
        return request;
    }

    public stopProgramValidation(context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePost(context.programId, "Validate/Stop");
        let request = this.http.put<any>(url, null).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public testTransform(testScriptDTO: TestScriptDTO, programId: string): Observable<IServiceResponse> {
        const url = this.serviceUrls.baseGetAll(programId, "TestScript");
        const request = this.http.post<IServiceResponse>(url, testScriptDTO).pipe(share());
        return request;
    }

    //#endregion Programs

    //#region Asset Groups

    public loadAssetGroups(programId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(programId, "AssetGroups");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(AssetGroup, request, context);
        return request;
    }

    public loadAssetGroup(assetGroupId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetOne(context.programId, "AssetGroups", assetGroupId);
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(AssetGroup, request, context);
        return request;
    }

    public addAssetGroup(changeId: string, assetGroup: AssetGroup, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePost(context.programId, "AssetGroups", changeId);
        const request = this.http.post<any>(url, assetGroup.serialize()).pipe(share());
        this.handleNormalPostPutRequest(AssetGroup, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public updateAssetGroup(changeId: string, assetGroup: AssetGroup, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "AssetGroups", assetGroup.Id, changeId);
        const request = this.http.put<any>(url, assetGroup.serialize()).pipe(share());
        this.handleNormalPostPutRequest(AssetGroup, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    //#endregion Asset Groups

    //#region Assets

    public loadAssets(ProgramId: string, assetGroupId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(ProgramId, "Assets");
        const requestUrl = assetGroupId !== null ? url + "?assetGroupId=" + assetGroupId : url;
        const request = this.http.get<any>(requestUrl).pipe(share());
        this.handleNormalGetRequest(Asset, request, context);
        return request;
    }

    public loadAsset(assetId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetOne(context.programId, "Assets", assetId);
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Asset, request, context);
        return request;
    }

    public addAsset(asset: Asset, file: File, context: ProgramContext, assetGroupId: string): Observable<any> {
        const url = this.serviceUrls.basePost(context.programId, "Assets", context.changeId, "AssetGroup", assetGroupId);
        const request = this.http.post<any>(url, asset.serialize()).pipe(share());
        request.subscribe({
            next: response => {
                var asset = new Asset().deserialize(response.Content, context);
                var uploadUrl = this.convertAssetUploadUrl(asset.data["UploadUrl"]);

                const uploadRequest = this.http.post<any>(uploadUrl, file, {
                    headers: new HttpHeaders().set(HttpHeaderNames.ContentType, "octet-stream"),
                }).pipe(share());
                uploadRequest.subscribe({
                    next: response => {
                        new Asset().deserialize(response.Content, context);
                    },
                    error: error => {
                        this.handleError(error)
                    }
                });
            },
            error: error => {
                this.handleError(error)
            }
        });
        return request;
    }

    // Converts the upload url to one that our API will accept coming from the application.
    // This is needed because our backend passes this url to us, but it is in the form of someone directly accessing the API.
    public convertAssetUploadUrl(uploadUrl: string): string {
        var positionOfAssets = uploadUrl.indexOf("Programs/");
        return this.serviceUrls.baseProgramsUrl + uploadUrl.substring(positionOfAssets + "Programs/".length);
    }

    public updateAsset(asset: Asset, file: File, context: ProgramContext, assetGroupId: string, changeId: string): Observable<Response> {
        let url = this.serviceUrls.specificProgram(context.programId)
            + 'AssetGroup/' + assetGroupId + '/Assets/' + asset.Id + '?changeId=' + changeId;

        if (file.size > 0) url = url + '&replace=true'

        const request = this.http.put<any>(url, asset.serialize()).pipe(share());
        request.subscribe({
            next: response => {
                var asset = new Asset().deserialize(response.Content, context);
                var uploadUrl = this.convertAssetUploadUrl(asset.data["UploadUrl"]);

                if (file.size > 0) {
                    const uploadRequest = this.http.post<any>(uploadUrl, file, {
                        headers: new HttpHeaders().set(HttpHeaderNames.ContentType, "octet-stream"),
                    }).pipe(share());
                    uploadRequest.subscribe({
                        next: response => {
                            new Asset().deserialize(response.Content, context);
                        },
                        error: error => {
                            this.handleError(error)
                        }
                    });
                }
            },
            error: error => {
                this.handleError(error)
            }
        });
        return request;
    }

    public downloadAsset(context: ProgramContext, assetId: string, revisionId: string) {
        const self = this;
        const url = this.serviceUrls.baseGetOne(context.programId, "Assets", assetId) + "/Content";

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('revisionId', revisionId);
        
        const options = {
            params,
            responseType: 'Blob' as 'json', // the body will be binary
            observe: 'response' as 'body' // we want the full response, not just the body
        };

        this.http.get<HttpResponse<Blob>>(url, options)
            .subscribe({
                next: response => {
                    self.saveToFileSystem(response);
                },
                error: error => {
                    self.handleError(error);
                }
            });
    }

    //#endregion Assets

    //#region Teams

    public loadProgramWorkGroups(context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(context.programId, "WorkGroups");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(WorkGroup, request, context);
        return request;
    }

    public addWorkGroupsToProgram(workGroupIds: string[], authLevel: number, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "ProgramUsesWorkGroup");
        var fakeEdge = new ProgramUsesWorkGroup();
        var body = _.map(workGroupIds, wgId => {
            return {
                ['@Type']: fakeEdge.Type,
                IsUsedById: context.programId,
                UsesId: wgId,
                AuthorityLevel: authLevel
            }
        });
        var request = this.http.post<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public updateProgramUsesWorkGroup(edge: ProgramUsesWorkGroup, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "ProgramUsesWorkGroup", edge.Id);
        const body = {
            ['@Type']: edge.Type,
            Id: edge.Id,
            IsUsedById: context.programId,
            UsesId: ModelUtils.getIdFromDomainId(edge._Uses),
            AuthorityLevel: edge.AuthorityLevel
        }
        var request = this.http.put<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public loadProgramTeamMembers(context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(context.programId, "TenantUsers");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(TenantUser, request, context);
        return request;
    }

    public addTenantUsersToProgram(userIds: string[], managers: string[], context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "ProgramEmploysTenantUser");
        var fakeEdge = new ProgramEmploysTenantUser();
        var body = _.map(userIds, uId => {
            return {
                ['@Type']: fakeEdge.Type,
                IsEmployedById: context.programId,
                EmploysId: uId,
                IsManager: _.includes(managers, uId)
            }
        });
        var request = this.http.post<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public modifyTenantUserInProgram(edgeId: string, isManager: boolean, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "ProgramEmploysTenantUser", edgeId);
        var fakeEdge = new ProgramEmploysTenantUser();
        var body = {
            ['@Type']: fakeEdge.Type,
            Id: edgeId,
            IsManager: isManager
        };
        var request = this.http.put<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public loadProgramAccountRoles(context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(context.programId, "AccountRoles");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(AccountRole, request, context);
        return request;
    }

    public addAccountRoleToProgram(roleId: string, authLevel: number,
        userIds: string[], initialId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "ProgramObligatesAccountRoles");
        const body = {
            ['@Type']: new ProgramObligatesAccountRole().Type,
            IsObligatedById: context.programId,
            ObligatesId: roleId,
            AuthorityLevel: authLevel,
            UserIds: userIds,
            InitiallyFilled: initialId
        };

        var request = this.http.post<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public updateProgramObligatesAccountRole(edge: ProgramObligatesAccountRole, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "ProgramObligatesAccountRoles", edge.Id);
        const body = {
            ['@Type']: edge.Type,
            Id: edge.Id,
            IsObligatedById: context.programId,
            ObligatesId: ModelUtils.getIdFromDomainId(edge._Obligates),
            AuthorityLevel: edge.AuthorityLevel,
            UserIds: edge.UserIds,
            InitiallyFilled: edge.InitiallyFilled
        };
        var request = this.http.put<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public deleteProgramAccountRole(accountRoleEdge: ProgramObligatesAccountRole, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "ProgramObligatesAccountRoles", accountRoleEdge.Id);
        let request = this.http.delete<any>(url).pipe(share());
        request.subscribe(() => {
            context.remove(accountRoleEdge.Obligates());
        });
        return request;
    }

    public removeTeamMember(employsUserEdge: ProgramEmploysTenantUser, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "ProgramEmploysTenantUser", employsUserEdge.Id);
        let request = this.http.delete<any>(url).pipe(share());
        request.subscribe(
            response => {
                context.remove(employsUserEdge);
                // Update ProgramObligatesAccountRole edges
                context.loadApiResponseModels(Program, response);
            }
        );
        return request;
    }

    public deployProgramTeam(context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.specificProgram(context.programId) + "Team/Deploy";
        const body = null;
        var request = this.http.put<any>(url, body).pipe(share());
        this.handleNormalPostPutRequest(Program, request, context);
        return request;
    }

    public loadPublishedProgramTeam(context: ProgramContext): Observable<ITypedServiceResponse<ProgramTeam>> {
        const url = this.serviceUrls.specificProgram(context.programId) + "Team";
        const request = this.http.get<ITypedServiceResponse<ProgramTeam>>(url)
            .pipe(share());
        request.subscribe({
            next: response => {
                context.programTeam.next(response.Content);
            },
            error: error => {
                this.handleError(error)
            }
        });
        return request;
    }

    //#endregion Teams

    //#region Program Changes

    /** Get an observable that will resolve to a ProgramChange with the given changeId, where changeId looks like "ProgramChange:<guid>". */
    public getChange(changeId: string, context: ProgramContext, ignoreContext: boolean = false): Observable<ProgramChange> {
        var programChangeDomainId = ModelUtils.createDomainId(new ProgramChange(), changeId); // build a domainId from the Key

        var findProgramChange = function (): ProgramChange {
            return context.get(programChangeDomainId) as ProgramChange;
        }
        let programChange = findProgramChange();

        if (!ignoreContext && programChange) {
            return new BehaviorSubject<ProgramChange>(programChange);
        }
        else {
            this.loadProgramChangeById(changeId, context);

            let source = new Subject<ProgramChange>();
            context.getStore(new ProgramChange()).values.subscribe(
                response => {
                    var p = findProgramChange();
                    if (p) {
                        source.next(p);
                    }
                }
            );

            return source;
        }
    }

    public addNewChangeToRevision: boolean = false;

    /**
     * This requires that the edge to the Program is included in the model
     */
    public addChange(newChange: ProgramChange, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.changes;
        let request = this.http.post<any>(url, newChange.serialize()).pipe(share());

        request.subscribe({
            next: response => {
                // add the new change to the data context
                context.loadApiResponseModels(ProgramChange, response);

                if (self.addNewChangeToRevision) {
                    context.getStore<ProgramRevision>(new ProgramRevision()).values.pipe(first()).subscribe(
                        revisions => {
                            var revision = _.maxBy(revisions as ProgramRevision[], r => r.RevisionNo);
                            self.addChangesToRevision(revision.Id, [response.Content.Id], context);
                        }
                    )
                    self.addNewChangeToRevision = false;
                }

            },
            error: error => {
                let excludedPaths = ["ProgramChange.Comments"];
                self.handleError(error, excludedPaths);
            }
        });

        return request;
    }

    public loadProgramChanges(programId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.changes;
        const params = new HttpParams().set('programId', programId)
            .set('includeReleased', 'true')
        let request = this.http.get<any>(url, { params }).pipe(share())
        this.handleNormalGetRequest(ProgramChange, request, context);
        return request;
    }


    /**
     * Retrieve a specific ProgramChange from the service.
     * @param changeId The Id (a.k.a. Key) of the ProgramChange to be returned.
     * @param context If null, the response will not be deserialized.
     */
    private loadProgramChangeById(changeId: string, context: ProgramContext = null): Observable<Response> {
        var url = this.serviceUrls.change(changeId);
        let request = this.http.get<any>(url).pipe(share());
        if (context)
            this.handleNormalGetRequest(ProgramChange, request, context);
        return request;
    }

    private editableState: BehaviorSubject<EditableStateVM> = new BehaviorSubject<EditableStateVM>({ EditableState: EditableStateEnum.None });

    public setEditableState(newEditabledState: EditableStateVM): void {
        this.editableState.next(newEditabledState);
    }

    public getEditableState(): Observable<EditableStateVM> {
        return this.editableState;
    }

    public lockProgramChange(programChange: ProgramChange, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.change(programChange.Id) + '/Lock';
        let request = this.http.put<any>(url, programChange.serialize(programChange)).pipe(share());

        request.subscribe({
            next: response => {
                // update the change in the data context
                context.loadApiResponseModels(ProgramChange, response);
                // if the user has the change Locked, load the Change in Edit mode
                this.setEditableState({ ChangeId: programChange.Id, EditableState: EditableStateEnum.Edit });
            },
            error: error => {
                self.handleError(error)
            }
        });

        return request;
    }

    public unlockProgramChange(programChange: ProgramChange, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.change(programChange.Id) + '/Unlock';
        let request = this.http.put<any>(url, programChange.serialize()).pipe(share());

        request.subscribe({
            next: response => {
                context.loadApiResponseModels(ProgramChange, response);
                this.setEditableState({ EditableState: EditableStateEnum.None });
            },
            error: error => {
                self.handleError(error)
            }
        });

        return request;
    }

    public completeProgramChange(programChange: ProgramChange, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.change(programChange.Id) + '/Complete';
        let request = this.http.put<any>(url, programChange.serialize(programChange)).pipe(share());

        request.subscribe({
            next: response => {
                //Update change content
                context.loadApiResponseModels(ProgramChange, response);
                this.setEditableState({ EditableState: EditableStateEnum.None });
            },
            error: error => {
                self.handleError(error)
            }
        });

        return request;
    }

    public stealLockOnProgramChange(programChange: ProgramChange, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.change(programChange.Id) + '/Steal';
        let request = this.http.put<any>(url, programChange.serialize(programChange)).pipe(share());

        request.subscribe({
            next: response => {
                // update the change in the data context
                context.loadApiResponseModels(ProgramChange, response);
                this.setEditableState({ ChangeId: programChange.Id, EditableState: EditableStateEnum.Edit });
            },
            error: error => {
                self.handleError(error)
            }
        });

        return request;
    }

    commitProgramChanges(programId: string, changes: string[], context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.commitProgramChanges;

        var self = this;
        let request = this.http.put<any>(url, changes).pipe(share());
        request.subscribe({
            next: response => {
                self.loadProgramChanges(programId, context);
            },
            error: error => {
                self.handleError(error);
            }
        });

        return request;
    }

    uncommitProgramChanges(programId: string, changes: string[], context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.uncommitProgramChanges;

        var self = this;
        let request = this.http.put<any>(url, changes).pipe(share());
        request.subscribe({
            next: response => {
                self.loadProgramChanges(programId, context);
            },
            error: error => {
                self.handleError(error);
            }
        });

        return request;
    }

    revertProgramChange(programId: string, changeId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.revertProgramChange(changeId);

        var self = this;
        let request = this.http.put<any>(url, changeId).pipe(share());
        request.subscribe({
            next: response => {
                self.loadProgramChanges(programId, context);
            },
            error: error => {
                self.handleError(error);
            }
        });

        return request;
    }
    //#endregion Program Changes

    //#region Program Revisions

    public loadProgramRevisions(programId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseProgramsUrl + "Revisions?programId=" + programId;
        let request = this.http.get<any>(url).pipe(share())
        this.handleNormalGetRequest(ProgramRevision, request, context);
        return request;
    }

    public retrieveProgramConfiguration(programId: string, revision: string, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.baseProgramsUrl + programId + "/Configuration";
        url = revision != null && revision !== "" ? url + "?revision=" + revision : url;
        let request = this.http.get<any>(url).pipe(share())

        request.subscribe({
            next: response => {
                context.loadResponseModels(response);
            },
            error: error => {
                self.handleError(error)
            }
        });

        return request;
    }

    public loadProgramRevision(programId: string, revisionId: string, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.baseProgramsUrl + programId + "/Load?revisionIdOrNumber=" + revisionId;
        let request = this.http.get<any>(url).pipe(share())

        request.subscribe({
            next: response => {
                context.loadResponseModels(response);
            },
            error: error => {
                self.handleError(error)
            }
        });

        return request;
    }

    public startProgramRevision(programId: string, revision: ProgramRevision, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseProgramsUrl + programId + "/Revisions";
        var request = this.http.post<any>(url, revision.serialize()).pipe(share());
        this.handleNormalPostPutRequest(ProgramRevision, request, context);
        return request;
    }

    public modifyProgramRevision(programId: string, revision: ProgramRevision, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseProgramsUrl + programId + "/Revisions/" + revision.Id;
        var request = this.http.put<any>(url, revision.serialize()).pipe(share());

        this.handleNormalPostPutRequest(ProgramRevision, request, context);

        return request;
    }

    public addChangesToRevision(revisionId: string, changeIds: string[], context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseProgramsUrl + "Revisions/" + revisionId + "/AddChanges";
        var request = this.http.put<any>(url, changeIds).pipe(share());
        this.handleNormalPostPutRequest(ProgramRevision, request, context);
        return request;
    }

    public removeChangesFromRevision(revisionId: string, changeIds: string[], context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseProgramsUrl + "Revisions/" + revisionId + "/RemoveChanges";
        var request = this.http.put<any>(url, changeIds).pipe(share());
        this.handleNormalPostPutRequest(ProgramRevision, request, context);
        return request;
    }

    public deployToArea(revisionId: string, revision: ProgramRevision,
        area: string, programContext: ProgramContext): Observable<HttpResponse<ITypedServiceResponse<ProgramRevision>>> {
        const url = this.serviceUrls.baseProgramsUrl + "Revisions/" + revisionId + "/Deploy?areaKey=" + area;
        const requestOptions = _.extend({
            observe: 'response' as 'body',
        });

        const request = this.http.put<HttpResponse<ITypedServiceResponse<ProgramRevision>>>(url,
            revision.serialize(),
            requestOptions).pipe(share());
        this.handleNormalPostPutRequest(ProgramRevision, request, programContext);

        return request;
    }

    public getDeploymentStatus(revisionId: string, programContext?: ProgramContext):
        Observable<HttpResponse<ITypedServiceResponse<ProgramRevision>>> {
        let dataContext: DataContext = programContext ? programContext : this.tenantContext;

        var url = this.serviceUrls.baseProgramsUrl + "Revisions/" + revisionId + "/DeploymentStatus";

        const requestOptions = _.extend({
            observe: 'response' as 'body',
        });

        const request = this.http.get<HttpResponse<ITypedServiceResponse<ProgramRevision>>>(url,
            requestOptions).pipe(share());

        this.handleNormalGetRequest(ProgramRevision, request, dataContext);

        return request;
    }

    public exportProgramRevisions(revisionId: string): void {
        var url = this.serviceUrls.exportProgramRevision(revisionId);

        var self = this;
        const options = {
            responseType: 'Blob' as 'json', // the body will be binary
            observe: 'response' as 'body' // we want the full response, not just the body
        };
        this.http.get<HttpResponse<Blob>>(url, options)
            .subscribe({
                next: response => {
                    self.saveToFileSystem(response);
                },
                error: error => {
                    self.handleError(error);
                }
        });
    }

    public undeployProgramRevision(revisionId: string, programContext: ProgramContext):
        Observable<HttpResponse<ITypedServiceResponse<ProgramRevision>>> {

        var url = this.serviceUrls.undeployProgramRevision(revisionId);
        const requestOptions = _.extend({
            observe: 'response' as 'body',
        });

        const request = this.http.put<HttpResponse<ITypedServiceResponse<ProgramRevision>>>(url,
            null,
            requestOptions).pipe(share());

        this.handleNormalPostPutRequest(ProgramRevision, request, programContext);

        return request;
    }

    public ListProgramsUsingAdminToken(userContext: DataContext, tenantKey: string, areaKey?: string) {
        this.authService.getAdminTenantAreaToken({ tenantKey, areaKey }).subscribe((response: any) => {
            const headers: HttpHeaders = new HttpHeaders()
                .set(HttpHeaderNames.Authorization, `Bearer ${response.Content.token}`);

            const options: Object = {
                headers: headers,
                withCredentials: true
            }

            var url = this.serviceUrls.baseProgramsUrl;

            // Make the request
            let request = this.http.get<any>(url, options).pipe(share());

            // Update the userContext (used in the manage standbys modal)
            this.handleNormalGetRequest(Program, request, userContext);
        });
    }

    public ListProgramRevisionsUsingAdminToken(userContext: DataContext, tenantKey: string, programId: string, areaKey?: string) {
        this.authService.getAdminTenantAreaToken({ tenantKey, areaKey }).subscribe((response: any) => {
            const headers: HttpHeaders = new HttpHeaders()
                .set(HttpHeaderNames.Authorization, `Bearer ${response.Content.token}`);

            const options: Object = {
                headers: headers,
                withCredentials: true
            }

            var url = this.serviceUrls.baseProgramsUrl + "Revisions?programId=" + programId;

            // Make the request
            let request = this.http.get<any>(url, options).pipe(share());

            // Update the userContext (used in the manage standbys modal)
            this.handleNormalGetRequest(ProgramRevision, request, userContext);
        });
    }

    public ListWorkflowsUsingAdminToken(userContext: DataContext, tenantKey: string, programId: string, revisionId: string, areaKey?: string) {
        this.authService.getAdminTenantAreaToken({ tenantKey, areaKey }).subscribe((response: any) => {
            const headers: HttpHeaders = new HttpHeaders()
                .set(HttpHeaderNames.Authorization, `Bearer ${response.Content.token}`);

            let params = new HttpParams();
            params = params.set('revisionId', revisionId);

            const options: Object = {
                headers: headers,
                withCredentials: true,
                params
            };

            let url = this.serviceUrls.baseGetAll(programId, "Workflows");

            // Make the request
            let request = this.http.get<any>(url, options).pipe(share());

            // Update the userContext (used in the manage standbys modal)
            this.handleNormalGetRequest(Workflow, request, userContext);
        });
    }

    public SetStandbyWorkflows(tenantKey: string, revisionID: string,
        areaStandbyWorkflowCountDTO: AreaStandbyWorkflowCountDTO[],
        areaKey?: string) {
        this.authService.getAdminTenantAreaToken({ tenantKey, areaKey }).subscribe((response: any) => {
            const headers: HttpHeaders = new HttpHeaders()
                .set(HttpHeaderNames.Authorization, `Bearer ${response.Content.token}`);

            const options: Object = {
                headers: headers,
                withCredentials: true
            }

            // Build the url
            var url = this.serviceUrls.setStandbyWorkflows(revisionID);

            // Make the request
            let request = this.http.put<any>(url, areaStandbyWorkflowCountDTO, options).pipe(share()).subscribe(response => { });

            return request;
        });
    }

    //#endregion Program Revisions

    //#region Validation Messages
    public getProgramValidationMessages(context: ProgramContext): Observable<Response> {
        // Clear the old store before loading the new Messages as the old items may have been deleted
        context.clearStore(new ProgramValidationMessage(), true);

        var url = this.serviceUrls.baseGetAll(context.programId, "ValidationMessages");
        let request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(ProgramValidationMessage, request, context);
        return request;
    }

    public getValidationCodes(): Observable<IValidationCode[]> {
        var url = this.serviceUrls.baseProgramsUrl + "ValidationCodes";

        let request = this.http.get<any>(url);
        var obs = request.pipe(map((response: any) => {
            return <IValidationCode[]>response.Content;
        }));
        return obs;
    }
    //#endregion Validation Messages

    //#region Applications

    public addApplication(changeId: string, newApplication: Application, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "Applications", changeId);
        let request = this.http.post<any>(url, newApplication.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Application, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public addElement(changeId: string, newElement: Element, applicationId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(context.programId, "Elements", changeId, "Applications", applicationId);
        let request = this.http.post<any>(url, newElement.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Element, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public updateElement(changeId: string, element: Element, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "Elements", element.Id, changeId);
        let request = this.http.put<any>(url, element.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Element, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public moveElement(changeId: string, elementId: string, beforeId: string, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.basePutAndDelete(context.programId, "Elements", elementId) + '/MoveElement?changeId=' + changeId;
        if (beforeId)
            url = url + "&before=" + beforeId;
        let request = this.http.put<any>(url, {}).pipe(share());
        this.handleNormalPostPutRequest(Element, request, context);
        return request;
    }

    public deleteElement(element: Element, changeId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "Elements", element.Id, changeId);
        let request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(Element, request, element, context);

        this.postRevisionableEdit(request, context);
        return request;
    }

    public updateApplication(changeId: string, application: Application, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "Applications", application.Id, changeId);
        let request = this.http.put<any>(url, application.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Application, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public deleteApplication(changeId: string, application: Application, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePutAndDelete(context.programId, "Applications", application.Id, changeId);
        let request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(Application, request, application, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public loadApplications(programId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseGetAll(programId, "Applications");
        var request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Application, request, context);
        return request;
    }

    public loadApplication(programId: string, applicationId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.baseGetOne(programId, "Applications", applicationId);
        var request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Application, request, context);
        return request;
    }

    public loadApplicationSectionsWithElements(context: ProgramContext, applicationId: string = null): Observable<Response> {
        let params = new HttpParams();
        if (applicationId)
            params.append('ApplicationId', applicationId)

        var url = this.serviceUrls.baseGetAll(context.programId, "Sections");
        let request = this.http.get<any>(url, { params }).pipe(share());
        this.handleNormalGetRequest(Section, request, context);
        return request;
    }

    public getStaticElementTypes(): Observable<IStaticElementType[]> {
        var self = this;
        var url = self.serviceUrls.baseProgramsUrl + "StaticElementTypes";
        let request = self.http.get<any>(url);
        var obs = request.pipe(map((response: any) => {
            return <IStaticElementType[]>response.Content;
        }));
        return obs;
    }
    //#endregion Applications

    //#region Rules Domain

    //#region RuleSets

    public loadRuleSets(programId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(programId, "RuleSets");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(RuleSet, request, context);
        return request;
    }

    public loadRuleSet(ruleSetId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetOne(context.programId, "RuleSets", ruleSetId);
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(RuleSet, request, context);
        return request;
    }

    public addRuleSet(changeId: string, ruleSet: RuleSet, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePost(context.programId, "RuleSets", changeId);
        const request = this.http.post<any>(url, ruleSet.serialize()).pipe(share());
        this.handleNormalPostPutRequest(RuleSet, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public updateRuleSet(changeId: string, ruleSet: RuleSet, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "RuleSets", ruleSet.Id, changeId);
        const request = this.http.put<any>(url, ruleSet.serialize()).pipe(share());
        this.handleNormalPostPutRequest(RuleSet, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public deleteRuleSet(ruleSet: RuleSet, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "RuleSets", ruleSet.Id, context.changeId);
        const request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(RuleSet, request, ruleSet, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    //#endregion RuleSets

    //#region Rules

    public loadRules(ProgramId: string, ruleSetId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(ProgramId, "Rules");
        const requestUrl = ruleSetId !== null ? url + "?ruleSetId=" + ruleSetId : url;
        const request = this.http.get<any>(requestUrl).pipe(share());
        this.handleNormalGetRequest(Rule, request, context);
        return request;
    }

    public addRule(changeId: string, rule: Rule, ruleSetId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePost(context.programId, "Rules", changeId, "RuleSets", ruleSetId);
        const request = this.http.post<any>(url, rule.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Rule, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public updateRule(changeId: string, rule: Rule, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Rules", rule.Id, changeId);
        const request = this.http.put<any>(url, rule.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Rule, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public deleteRule(changeId: string, rule: Rule, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Rules", rule.Id, changeId);
        const request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(Rule, request, rule, context);
        this.postRevisionableEdit(request, context);
        return request;
    }
    //#endregion Rules

    //#endregion Rules Domain

    //#region Workflows Domains

    //#region Workflow Sets
    public loadWorkflowSets(programKey: string, context: ProgramContext): Observable<Response> {
        var self = this;
        var url = this.serviceUrls.baseGetAll(programKey, "WorkflowSets");
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe({
            next: response => {
                context.loadApiResponseModels(WorkflowSet, response);
            },
            error: error => {
                let excludedPaths = ["WorkflowSet.Name", "WorkflowSet.Description"];
                self.handleError(error, excludedPaths);
            }
        });
        return request;
    }

    public addWorkflowSet(programKey: string, workflowSet: WorkflowSet, changeId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(programKey, "WorkflowSets", changeId);
        let request = this.http.post<any>(url, workflowSet.serialize()).pipe(share());
        this.handleNormalPostPutRequest(WorkflowSet, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public updateWorkflowSet(programKey: string, workflowSet: WorkflowSet, changeId: string, context: ProgramContext): Observable<Response> {
        var url = this.serviceUrls.basePost(programKey, "WorkflowSets", changeId);
        let request = this.http.put<any>(url, workflowSet.serialize()).pipe(share());
        this.handleNormalPostPutRequest(WorkflowSet, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public deleteWorkflowSet(workflowSet: WorkflowSet, changeId: string, context: ProgramContext): Observable<ITypedServiceResponse<WorkflowSet>> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "WorkflowSets", workflowSet.Id, changeId);
        const request = this.http.delete<ITypedServiceResponse<WorkflowSet>>(url).pipe(share());
        this.handleNormalDeleteRequest(WorkflowSet, request, workflowSet, context);
        this.postRevisionedEdit(request, context);
        return request;
    }
    //#endregion Workflow Sets

    //#region Worfklows
    public saveWorkflow(workflow: Workflow, changeId: string, context: ProgramContext): Observable<ITypedServiceResponse<Workflow>> {
        let request: Observable<ITypedServiceResponse<Workflow>>;

        if (workflow.Id) {
            //Update
            var putUrl = this.serviceUrls.basePutAndDelete(context.programId, "Workflows", workflow.Id, changeId);
            request = this.http.put<ITypedServiceResponse<Workflow>>(putUrl, workflow.serialize()).pipe(share());
        }
        else {
            //Add
            var postUrl = this.serviceUrls.basePost(context.programId, "Workflows", changeId);
            request = this.http.post<ITypedServiceResponse<Workflow>>(postUrl, workflow.serialize()).pipe(share());
        }

        this.handleNormalPostPutRequest(Workflow, request, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public deleteWorkflow(workflow: Workflow, changeId: string, context: ProgramContext): Observable<ITypedServiceResponse<Workflow>> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Workflows", workflow.Id, changeId);
        const request = this.http.delete<ITypedServiceResponse<Workflow>>(url).pipe(share());
        this.handleNormalDeleteRequest(Workflow, request, workflow, context);
        this.postRevisionedEdit(request, context);
        return request;
    }

    public loadWorkflows(programKey: string, context: DataContext): Observable<Response> {
        let url = this.serviceUrls.baseGetAll(programKey, "Workflows");
        let request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Workflow, request, context);
        return request;
    }

    public loadWorkflow(workflowDefId: string, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.baseGetOne(context.programId, "Workflows", workflowDefId);
        let request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Workflow, request, context);
        return request;
    }

    //#endregion Workflows

    //#region Phases

    public loadPhases(programId: string, workflowId: string, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.baseGetAll(programId, "Phases");
        let params;
        if (workflowId !== null) {
            params = new HttpParams().set("workflowId", workflowId);
        }
        let request = this.http.get<any>(url, {
            params: params
        }).pipe(share());

        this.handleNormalGetRequest(Phase, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public addPhase(phaseModel: Phase, changeId: string, workflowId: string, before = null, context: ProgramContext) {
        let url = this.serviceUrls.basePost(context.programId, "Phases", changeId, "Workflows", workflowId);
        if (before) url = url + "&before=" + before;
        const request = this.http.post<any>(url, phaseModel.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Phase, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public modifyPhase(phaseModel: Phase, changeId: string, context: ProgramContext) {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Phases", phaseModel.Id, changeId);
        const request = this.http.put<any>(url, phaseModel.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Phase, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public deletePhase(phaseModel: Phase, changeId: string, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Phases", phaseModel.Id, changeId);
        let request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(Phase, request, phaseModel, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public movePhase(changeId: string, phaseId: string, beforeId: string, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.basePutAndDelete(context.programId, "Phases", phaseId) + '/MovePhase?changeId=' + changeId;
        if (beforeId)
            url = url + "&before=" + beforeId;
        let request = this.http.put<any>(url, {}).pipe(share());
        this.handleNormalPostPutRequest(Workflow, request, context);
        return request;
    }
    //#endregion Phases

    //#region Tasks
    public loadTasks(programId: string, workflowId: string, context: ProgramContext): Observable<Response> {
        let params;
        if (workflowId !== null) {
            params = new HttpParams().set("workflowId", workflowId);
        }
        let url = this.serviceUrls.baseGetAll(programId, "Tasks");
        let request = this.http.get<any>(url, {
            params: params
        }).pipe(share());

        this.handleNormalGetRequest(Task, request, context);
        return request;
    }

    public loadTasksByWorkflowSet(programId: string, workflowSetId: string, context: ProgramContext): Observable<Response> {
        let params = new HttpParams().set("workflowSetId", workflowSetId);
        let url = this.serviceUrls.baseGetAll(programId, "Tasks");
        let request = this.http.get<any>(url, {
            params: params
        }).pipe(share());

        this.handleNormalGetRequest(Task, request, context);
        return request;
    }

    public addTask(changeId: string, taskDef: Task, workflowId: string, context: ProgramContext) {
        const url = this.serviceUrls.basePost(context.programId, "Tasks", changeId, "Workflows", workflowId);
        const request = this.http.post<any>(url, taskDef.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public modifyTask(taskDef: Task, workflowId: string, context: ProgramContext, changeId: string = null) {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Tasks", taskDef.Id, changeId, workflowId);
        const request = this.http.put<any>(url, taskDef.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public deleteTask(changeId: string, taskDef: Task, context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.basePutAndDelete(context.programId, "Tasks", taskDef.Id, changeId);
        let request = this.http.delete<any>(url).pipe(share());
        this.handleNormalDeleteRequest(Task, request, taskDef, context);
        this.postRevisionableEdit(request, context);
        return request;
    }

    public moveTask(changeId: string, taskId: string, beforeId: string, context: ProgramContext): Observable<Response> {
        let url = this.serviceUrls.basePutAndDelete(context.programId, "Tasks", taskId) + '/MoveTask?changeId=' + changeId;
        if (beforeId)
            url = url + "&before=" + beforeId;
        let request = this.http.put<any>(url, {}).pipe(share());
        this.handleNormalPostPutRequest(Phase, request, context);
        return request;
    }
    //#endregion Tasks
    //#endregion Workflows Domain

    //#region Producers

    public loadProducers(context: ProgramContext): Observable<Response> {
        const url = this.serviceUrls.baseGetAll(context.programId, "Producers");
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Producer, request, context);
        return request;
    }

    //#endregion Producers

    //#region Enums
    public loadPolicyRatedStatuses(): Observable<Response> {
        var url = this.serviceUrls.PolicyRatedStatuses;

        let request = this.http.get<any>(url).pipe(share());
        this.handleRequestWithoutResponse(request);
        return request;
    }
    //#endregion Enums

    //#region Private Helpers
    protected postRevisionableEdit(request: Observable<Response>, context: ProgramContext) {
        const self = this;

        // After a revisionable has been edited, we need to reload the ValidationMessages as well as the ProgramChange
        request.subscribe(
            response => {
                self.getProgramValidationMessages(context);

                // The Change will invalidate, so if its not already invalid, we need to reload it.
                if (context.changeId) {
                    var change = context.get(ModelUtils.createDomainId(new ProgramChange(), context.changeId)) as ProgramChange;
                    if (change?.ValidationStatus != "Invalid") {
                        self.loadProgramChangeById(context.changeId, context);
                    }
                }
            }
        )
    }

    protected postRevisionedEdit(request: Observable<Response | ITypedServiceResponse<any>>, context: ProgramContext) {
        const self = this;

        // After a revisioned has been edited, we need to reload the ValidationMessages as well as the ProgramChange
        request.subscribe(
            response => {
                self.getProgramValidationMessages(context);

                // All changes will invalidate, so we will need to reload them.
                self.loadProgramChanges(context.programId, context);
            }
        )
    }
    //#endregion Private Helpers
}

export interface EditableStateVM {
    ChangeId?: string;
    RevisionId?: string;
    EditableState: EditableStateEnum
}

export interface TestScriptDTO {
    ScriptFunction: string;
    Conditions: ConditionalLineDTO[];
    Parameters: TestScriptParametersDTO[];
    SetDateTo: string,
    ReturnValue?: string;
}

export interface TestScriptParametersDTO {
    Name: string;
    Id: string;
    Value: any;
    IsList?: boolean;
}

export interface ConditionalLineDTO {
    Condition: string;
    ResultIfTrue: string;
}