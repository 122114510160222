import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import _ from 'lodash';

import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { AuthService } from "@Services/auth-service";
import { TenantService } from '@Core/CodeGen/Services/tenant.service';
import { Observable, catchError, combineLatest, map, of, switchMap, take, } from 'rxjs';

@Injectable()
export class ReportsService {
    private config: ReportsConfigValuesDTO;
    private authToken: string;
    
    constructor(private http: HttpClient,
        private authService: AuthService,
        private tenantService: TenantService
    ) { }

    public openReports() {
        this.authenticateReports().subscribe(() => window.open(this.config.appUrl));
    }

    public authenticateReports(): Observable<boolean> {
        return combineLatest([
            this.getInsurityBIToken(), 
            this.getReportsConfigValues()
        ]).pipe(
            take(1),
            switchMap(([token, config]: [string, ReportsConfigValuesDTO]) => {
                if (!token || !config) {
                    return;
                }
    
                const headers: HttpHeaders = new HttpHeaders()
                    .set(HttpHeaderNames.Authorization, `Bearer ${this.authToken}`);
    
                const options: any = {
                    headers: headers,
                    responseType: 'text',
                    withCredentials: true
                };
    
                return this.http.get<any>(config.authUrl, options).pipe(
                    switchMap(response => {
                        const isSuccess = response != null;
                        return of(isSuccess);
                    }),
                    catchError(error => {
                        return of(false)
                    })
                );
            })
        );
    }

    public logoutReports(logoutUri: string) {
        const headers: HttpHeaders = new HttpHeaders()
            .set(HttpHeaderNames.Authorization, `Bearer ${this.authToken}`);

        const options: Object = {
            headers: headers,
            responseType: 'text',
            withCredentials: true
        };

        return this.http.delete<any>(logoutUri, options);
    }

    public getReportsConfigValues(): Observable<ReportsConfigValuesDTO> {
        if (this.config) {
            return of(this.config);
        }

        return this.tenantService.RetrieveReportConfigValues().pipe(
            map((response: any) => {
                this.config = response.Content as ReportsConfigValuesDTO;
                return this.config;
            })
        );
    }

    public getInsurityBIToken(): Observable<string> {
        if (this.authToken) {
            return of(this.authToken);
        }

        return this.authService.insurityBIToken().pipe(
            map((response: any) => {
                this.authToken = response.Content as string;
                return this.authToken;
            })
        );
    }
}

export interface ReportsConfigValuesDTO {
    appId: string;
    sheetId: string;
    authUrl: string;
    appUrl: string;
    wssUrl: string;
}