import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'unity-stepper',
    templateUrl: './unity-stepper.component.html',
    styleUrls: ['./unity-stepper.component.scss']
})
export class UnityStepperComponent {
    @Input() currentStep: number; // 0-based index of the current step
    @Input() steps: UnityStepVM[];
    @Input() layoutDirection: 'vertical' | 'horizontal' = 'vertical';
    @Input() style: 'circles' | 'panels' | 'bullets' = 'circles';
    @Input() backwardsCapability: 'none' | 'single' | 'all' = 'all'; // Ability to go back steps by clicking the completed step

    @Output() goToStep: EventEmitter<number> = new EventEmitter();

    public canClickStep(i: number): boolean {
        if (this.backwardsCapability == 'none')
            return false;

        if (this.backwardsCapability == 'single' && i == this.currentStep -1)
            return true;

        if (this.backwardsCapability == 'all' && i < this.currentStep)
            return true;

        return false;
    }
};

export interface UnityStepVM {
    Name?: string;
    Description?: string;
}