import { Component, Input, ChangeDetectorRef } from '@angular/core';

import { interval, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { TimeInStrPipe, TimeMaxUnitEnum } from '@Shared/Pipes/time.pipe';

@Component({
    selector: 'sm-time-since',
    templateUrl: './sm-time-since.component.html',
})
export class SmTimeSinceComponent {
    @Input() start: Date;
    @Input() displayTimeUntil: boolean = false; // Use time until instead

    public text: string;
    private timerSubscription: Subscription;
    
    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (!this.timerSubscription) {
            this.resetTimer();
        }
    }

    ngOnChanges() {
        if (this.timerSubscription) {
            this.resetTimer();
        }
    }
    
    public resetTimer() {
        const self = this;

        if (!this.start)
            return;

        // Interval will run every minute or the time assigned is >= onehour then it will run every hour.
        const oneHour = 3600000;
        let _interval = 60000;

        this.setTimeStr();

        const assignedTime = new Date().getTime() - this.start.getTime();
        if (assignedTime >= oneHour) {
            _interval = oneHour;
        }

        if (this.start) {
            let subject = new Subject<number>();
            this.timerSubscription = subject.pipe(
                switchMap(int => interval(int)),
                tap(() => {
                    const assignedTime = new Date().getTime() - self.start.getTime();
                    if (assignedTime >= oneHour) {
                        // Update interval to one hour
                        subject.next(oneHour);
                    }
                    this.setTimeStr();
                    self.cdr.detectChanges();
                })
            ).subscribe();

            //Start the interval
            subject.next(_interval);
        }
    }

    private setTimeStr() {
        let pipe = new TimeInStrPipe();
        var now = (new Date()).getTime();

        var time = this.displayTimeUntil ? (this.start.getTime() - now) : (now - this.start.getTime());

        this.text = pipe.transform(time, true, false, TimeMaxUnitEnum.Week);   
    }
}