import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import _ from 'lodash';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';
import { ITypedServiceResponse } from '@Core/Lib/model';

import { DataContext } from '@Core/Lib/Contexts/data-context';
import * as Models from '@Core/CodeGen/Models/area.models';

class Response extends HttpResponse<any> { }

@Injectable()
export class DataService extends BaseService {

    public static baseUrl: string = "";

    constructor(protected http: HttpClient,
                protected globalMessages: GlobalMessages,
                protected loggingService: LoggingService,
                protected loadingService: LoadingService) {
        super(globalMessages, loggingService, loadingService);
        DataService.baseUrl = BaseService.baseUrl + "/Data/";
    }

    public RetrieveAnInsuranceApplication(contexts: DataContext[] | DataContext, workflowSetId: string, applicationId: string): Observable<ITypedServiceResponse<Models.Application>> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Applications/{applicationId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{applicationId}", applicationId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Application, request, context);
        });
        return request;
    }

    public AddAnApplicationSection(body: any, workflowSetId: string, applicationId: string,
        optionalParameters: {
            details?: string
        }): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Applications/{applicationId}/AddSection";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{applicationId}", applicationId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.details != null)
            params = params.set('details', optionalParameters.details);

        // Make the request
        let request = this.http.post<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveApplicationSection(contexts: DataContext[] | DataContext, workflowSetId: string, sectionId: string, partitionKey: string,
        optionalParameters: {
            details?: string
        }): Observable<ITypedServiceResponse<Models.Section>> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Sections/{sectionId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{sectionId}", sectionId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('partitionKey', partitionKey);
        if (optionalParameters?.details != null)
            params = params.set('details', optionalParameters.details);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.Section, request, context);
        });
        return request;
    }

    public RemoveApplicationSections(idOfDeletedItem: string, contexts: DataContext[] | DataContext, workflowSetId: string): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Sections";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.delete<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalDeleteRequest(null, request, idOfDeletedItem, context);
        });
        return request;
    }

    public SaveFieldValue(body: any, workflowSetId: string, fieldId: string, partitionKey: string): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Fields/{fieldId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{fieldId}", fieldId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        params = params.set('partitionKey', partitionKey);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ExtractData(workflowSetId: string,
        optionalParameters: {
            edition?: string,
            returnXml?: string,
            includeEmpty?: string,
            includeTracking?: string,
            compactJson?: string
        }): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Extract";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.edition != null)
            params = params.set('edition', optionalParameters.edition);
        if (optionalParameters?.returnXml != null)
            params = params.set('returnXml', optionalParameters.returnXml);
        if (optionalParameters?.includeEmpty != null)
            params = params.set('includeEmpty', optionalParameters.includeEmpty);
        if (optionalParameters?.includeTracking != null)
            params = params.set('includeTracking', optionalParameters.includeTracking);
        if (optionalParameters?.compactJson != null)
            params = params.set('compactJson', optionalParameters.compactJson);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ImportData(body: any, workflowSetId: string,
        optionalParameters: {
            edition?: string
        }): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.edition != null)
            params = params.set('edition', optionalParameters.edition);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ImportCompactData(body: any, workflowSetId: string,
        optionalParameters: {
            edition?: string,
            desiredState?: string
        }): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import/Compact";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.edition != null)
            params = params.set('edition', optionalParameters.edition);
        if (optionalParameters?.desiredState != null)
            params = params.set('desiredState', optionalParameters.desiredState);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ImportXmlData(body: any, workflowSetId: string,
        optionalParameters: {
            edition?: string
        }): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import/XML";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.edition != null)
            params = params.set('edition', optionalParameters.edition);

        // Make the request
        let request = this.http.put<any>(url, body, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public CompareXmlData(workflowSetId: string,
        optionalParameters: {
            edition?: string
        }): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Compare/XML";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.edition != null)
            params = params.set('edition', optionalParameters.edition);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveStatusOfDatastoreImport(contexts: DataContext[] | DataContext, workflowSetId: string): Observable<ITypedServiceResponse<Models.DataStore>> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import/Status";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.DataStore, request, context);
        });
        return request;
    }

    public RetrieveStatusOfAllOngoingDatastoreImports(workflowSetId: string): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import/AllStatuses";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveStatusOfSpecificDatastoreImport(workflowSetId: string, importId: string): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import/Status/{importId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{importId}", importId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public RetrieveSpecificDatastoreImportResults(workflowSetId: string, importId: string): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Import/Results/{importId}";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{importId}", importId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

    public ListRiskDetail(contexts: DataContext[] | DataContext, workflowSetId: string,
        optionalParameters: {
            includeDetails?: string,
            showAllRules?: string
        }): Observable<ITypedServiceResponse<Models.RuleSetRiskAssessment>> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/RiskDetail";
        url = url.replace("{workflowSetId}", workflowSetId);

        // Add the query parameters that aren't null
        let params = new HttpParams();
        if (optionalParameters?.includeDetails != null)
            params = params.set('includeDetails', optionalParameters.includeDetails);
        if (optionalParameters?.showAllRules != null)
            params = params.set('showAllRules', optionalParameters.showAllRules);

        // Make the request
        let request = this.http.get<any>(url, { params }).pipe(share());

        // The user can pass in a single context. Convert it to a list.
        if (contexts && !Array.isArray(contexts))
            contexts = [contexts];

        // Update the contexts (if any provided)
        _.each(contexts, context => {
            this.handleNormalGetRequest(Models.RuleSetRiskAssessment, request, context);
        });
        return request;
    }

    public RetrieveClearanceData(workflowSetId: string, workflowId: string): Observable<Response> {
        // Build the url
        var url = DataService.baseUrl + "{workflowSetId}/Workflows/{workflowId}/ClearanceData";
        url = url.replace("{workflowSetId}", workflowSetId);
        url = url.replace("{workflowId}", workflowId);

        // Make the request
        let request = this.http.get<any>(url).pipe(share());

        request.subscribe(() => {});

        return request;
    }

}