import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";

@Component({
    selector: 'sm-form-field-select',
    templateUrl: './sm-form-field-select.component.html',
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// Drop down selection
export class SmFormFieldSelectComponent extends SmFormFieldBase {

    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    getOptions(): string[] {
        return this.model.Options.Options;
    }

    protected override putFocusIntoInput(): void {
        this.element.nativeElement.querySelector('select').focus()
    }
}