<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>    
        <sm-input-money
            class="w-100"
            [id]="getInputIdOrName()"
            [value]="model.Value ?? ''" 
            [displayAsWholeNumber]="model.Options?.DisplayAsWholeNumber ?? false"
            [displayNegativesInRed]="model.Options?.DisplayNegativesInRed ?? false"
            [currency]="model.Options?.Currency ?? formState?.Currency"
            [editModeClasses]="getInputCssClass()"
            [disabled]="model.Disabled"
            [readOnly]="readOnly"
            (onChange)="valueChange($event)"
            (focusin)="onFocusChanged.emit(true)"
            (focusout)="onFocusChanged.emit(false)">
        </sm-input-money>   
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>           
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>