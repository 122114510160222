import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@Services/auth-service';
import _ from 'lodash';
import { IServiceResponse } from '@Core/Lib/model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'login-container',
    templateUrl: './login-container.component.html',
})
export class LoginContainerComponent implements OnInit {

    tenantClaim = 'http://instec-underwriting.com/identity/claims/tenant';
    selectTenants = false;
    selectedTenant: string;
    tenants: string[] = [];

    recover_password_invalid: boolean;
    public errorMessage: string;

    public isAuthenticated: boolean;

    loading: boolean;

    constructor(private auth: AuthService) { }

    ngOnInit(): void {
        this.recover_password_invalid = false;
        this.loading = false;

        this.auth.idTokenClaims$.subscribe((bearerToken) => {
            if (bearerToken) {
                this.generateClaims(bearerToken.__raw);
                this.isAuthenticated = true;
            } else {
                this.isAuthenticated = false;
            }
        });

        this.auth.error$.subscribe(err => {
            this.errorMessage = err.message;
        })
    }

    changeUser() {
        // Clear cookies so they can change credentials.
        this.auth.logout(true);
    }

    generateClaims(bearerToken: string) {
        this.loading = true;
        this.errorMessage = null;

        this.auth.generateClaims(bearerToken)
            .subscribe({
                next: response => {
                    this.checkTenants(response);
                }, 
                error: (error: HttpErrorResponse) => {
                    this.loading = false;
                    let body = error.error as IServiceResponse;
                    if (error.status === 429) { // too many requests
                        this.errorMessage = "The system is currently busy";
                    }
                    else if (error.status === 403) {
                        this.errorMessage = "You no longer have access. Contact your administrator.";

                    } else {
                        this.errorMessage = "Unknown Error";
                    }
                }
            });
    }

    checkTenants(response: any) {
        if (response.Content['admin']) {
            window.location.replace(environment.baseUrl + "/Admin");
            return;
        }
        else if (response.Content['tenantKey']) {
            window.location.replace(environment.baseUrl + "/Work");
            return;
        }
        else if (response.Content['tenants']) {
            this.tenants = response.Content['tenants'];
            if (this.tenants.length === 1) {
                this.tenantSelect(this.tenants[0])
                return;
            }
            this.selectedTenant = null;
            this.selectTenants = true;
            this.loading = false;
            return;
        }
    }

    tenantSelect(tenantKey: string) {
        this.loading = true;
        this.auth.selectTenant(tenantKey).subscribe({
            next: () => {
                window.location.replace(environment.baseUrl + "/Work");
            }, 
            error: () => {
                this.loading = false;
            }
        });
    }

    onLoginClick() {
        this.auth.login();
    }
};