import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    @Input() selectTenants = false;
    @Input() selectedTenant: string;
    @Input() tenants: string[] = [];
    @Input() loading: boolean;
    @Input() errorMessage: string;

    @Output() onLoginClick: EventEmitter<any> = new EventEmitter();
    @Output() onTenantSelect: EventEmitter<string> = new EventEmitter();
    @Output() onChangeUser:  EventEmitter<any> = new EventEmitter();

    public changeForm(form_name: string) {
        this.loading = false;
    }

    public changeUser() {
        this.onChangeUser.emit();
    }

    public tenantSelect(tenantKey: string) {
        this.loading = true;
        this.onTenantSelect.emit(tenantKey);
    }
}