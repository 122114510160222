<!-- <div class="title_bar--item title_bar--search" [ngClass]="{ 'focused': searchFocus }"> -->
<div *sm-has-permission="TENANT_PERMISSIONS.Work_Accounts_SearchAccounts" class="title_bar--item title_bar--search" [ngClass]="{ 'focused': searchFocus }">
        <div class="search_wrap">
        <input class="form-control input-search"
               type="search"
               [placeholder]="'Search ' + ('Accounts' | smNomenclatureByRegion:programId | async) + '...'"
               #search
               (mouseover)="onSearchOver()"
               (mouseout)="onSearchOut()"
               (focus)="onSearchFocus()"
               (blur)="!overSearch && onSearchBlur()"
               (keyup)="onSearchKeyup($event)"
               [(ngModel)]="searchValue" />
        <button unity-button *ngIf="searchHasValue" class="icon-only" (click)="clearSearchInput()" (mouseover)="onSearchOver()">
            <svg class="unity-icon-times">
                <use xlink:href="#unity-icon-times"></use>
            </svg>
        </button>
        <svg *ngIf="!searchHasValue" class="icon-search">
            <use xlink:href="#icon-search"></use>
        </svg>
        <div *ngIf="searchFocus" class="results" [class.has_results]="foundAccounts.length">
            <div class="message">
                <svg *ngIf="searching" class="icon-completing">
                    <use xlink:href="#icon-completing"></use>
                </svg> {{searchMessage}}
            </div>
            <div class="results--list">
                <ng-container *ngFor="let foundAccount of foundAccounts; let i=index">
                    <sm-quick-search-card id="QuickSearchCard:{{ foundAccount.data.id }}"
                                              [MatchedItem]="foundAccount"
                                              [SearchType]="searchType"
                                              (mousedown)="onSearchAccountAdded(foundAccount.data)">
                    </sm-quick-search-card>
                    <ng-container *ngIf="foundAccounts.length - 1 != i">
                        <hr>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>