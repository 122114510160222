import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import _ from "lodash";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";

@Component({
    selector: 'sm-form-field-money',
    templateUrl: './sm-form-field-money.component.html',
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmFormFieldMoneyComponent extends SmFormFieldBase {

    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    public valueChange(e: any) {
        this.model.Value =  e;
        super.valueChange(e, 'input');
    }
}