import { Injectable } from "@angular/core";
import { HubConnectionState } from "@microsoft/signalr";
import { SyncStatus } from "@Shared/Components/sm-sync-status/sm-sync-status.component";
import _ from "lodash";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class SyncHubStatusService {

    private _states: Map<string, HubConnectionState> = 
        new Map<string, HubConnectionState>();

    private _hubs: BehaviorSubject<Map<string, string>> = 
        new BehaviorSubject<Map<string, string>>(new Map<string, string>());

    private _status: BehaviorSubject<SyncStatus> = new BehaviorSubject<SyncStatus>(null);

    constructor(){}

    public get states() : Observable<Map<string, string>> { 
        return this._hubs;
    }

    public get status() : Observable<string> {
        return this._status;
    }

    public setHubState(hubName: string, state: HubConnectionState) {
        this._states.set(hubName, state);
        this.update();
    }

    public removeHub(hubName: string)
    {
        this._states.delete(hubName);
        this.update();
    }

    private update(): void {
        // If there are no states, then we are not using any hubs
        if (!this._states.size) {
            this.pushChanges(null);
            return;
        }

        // If any hub is reconnecting, show reconnecting
        if (_.some([...this._states.values()], (state) => {
                                   return state === HubConnectionState.Reconnecting;
                                })) {
            this.pushChanges(SyncStatus.Reconnecting);
        } 
        // else if all hub are connected, show connected
        else if (_.every([...this._states.values()], (state: string) => {
                                         return state === HubConnectionState.Connected
                                        })) {
            this.pushChanges(SyncStatus.Connected);
        }
        // otherwise we must be disconnected 
        else {
            this.pushChanges(SyncStatus.Disconnected);
        }

        this._hubs.next(new Map<string, string>(this._states));
    }

    private pushChanges(newStatus: SyncStatus): void {
        this._hubs.next(new Map<string, string>(this._states));
        if (this._status.value !== newStatus) {
            this._status.next(newStatus);
        }
    }

}