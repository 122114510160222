<div class="modal-header header">
    <h3 class="title">{{title}}</h3>
    <button unity-button class="icon-only xlarge on_dark" type="button"
        aria-labelledby="unity-icon-button-label-close" (click)="onClose.emit()">
        <span id="unity-icon-button-label-close" hidden>Close</span>
        <svg class="unity-icon-times" aria-hidden="true">
            <use xlink:href="#unity-icon-times"></use>
        </svg>

    </button>
</div>
<div class="modal-body body">
    <ng-content></ng-content>
</div>
<div class="modal-footer footer">
    <div class="back">
        <button *ngIf="backAction" unity-button class="ghost" type="button" (click)="onBackAction.emit()">
            <span class="label">{{backAction}}</span>
        </button>
    </div>
    <div class="actions">
        <button *ngIf="primaryAction" unity-button id="PrimaryButton" class="primary" type="button" [disabled]="!primaryActionEnabled" (click)="onPrimaryAction.emit()">
            <span class="label">{{primaryAction}}</span>
        </button>
        <button *ngIf="secondaryAction" unity-button class="secondary" type="button" (click)="onSecondaryAction.emit()">
            <span class="label">{{secondaryAction}}</span>
        </button>
    </div>
</div>