<span class="unity-loading-indicator circular {{color}} {{size}} {{display}}">
    <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" class="unity-icon-indeterminate unity-icon indeterminate">
        <defs>
            <linearGradient id="insurity-gradient" x1="0" x2="1" y1="0" y2="0">
                <stop class="stop3" offset="0%"></stop>
                <stop class="stop2" offset="50%"></stop>
                <stop class="stop1" offset="100%"></stop>
            </linearGradient>
        </defs>
        <circle cx="22" cy="22" r="20"></circle>
    </svg>
    <span class="message"><ng-content></ng-content></span>
</span>