import _ from "lodash";

export class RouteUtils {
    public static BuildEntityRoute(entity: string, entries: RouteEntryItem[]): string {
        if (!entity) return null;

        switch (entity) {
            case 'Program':
                return `/Conf/Programs/${RouteUtils.GetIdFromEntry('Program', entries)}/Overview`;
            case 'Account':
                return `/Work/Account/${RouteUtils.GetIdFromEntry('Account', entries)}`;
            case 'Task':
                return `/Work/Account/${RouteUtils.GetIdFromEntry('Account', entries)}/Workflow/${RouteUtils.GetIdFromEntry('Workflow', entries)}`;
            case 'Journal':
                return `/Work/Account/${RouteUtils.GetIdFromEntry('Account', entries)}`;
            case 'Document':
                return `/Work/Account/${RouteUtils.GetIdFromEntry('Account', entries)}`;                
            default:
                return null;
        }
    }

    private static GetIdFromEntry(entryType: string, routeEntries: RouteEntryItem[]): string {
        var entry = _.find(routeEntries, (e: RouteEntryItem) =>  { return e.Type == entryType; });
        return entry?.Id;
    }
}

export interface RouteEntryItem {
    Type: string;
    Id: string;
}