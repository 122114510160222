import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';

import { NoAuthRetryStrategy } from '@Core/Lib/no-auth-retry-strategy';
import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { UserInteractionTypes } from '@Core/Lib/Enums/user-interaction-type.enum';

import { AuthService } from '@Services/auth-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private REFRESH_THRESHOLD: number = 15 * 60000;

    constructor(private auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let expiration: Date = this.auth.getExpiration();

        // If the authentication hasn't loaded (usually due to a 429 or 503), 
        // we'll get a 401. So retry in the hopes that the authentication 
        // will load before we retry
        if (!this.auth.authLoaded && !expiration && !this.auth.getSubscriptionKey()
                && !req.url.endsWith(this.auth.CurrentUserURL) && !req.url.endsWith(this.auth.LogoutURL))
            return next.handle(req).pipe(retryWhen(NoAuthRetryStrategy()));

        if (expiration) {
            if (new Date() >= expiration && !req.url.endsWith(this.auth.LogoutURL)) {
                // Don't make any requests if the token has expired.
                console.log("Token has expired", expiration, new Date());
                this.auth.logout();
                return throwError(() => new HttpErrorResponse({ error: "Token has expired" }));
            }

            let subscriptionKey:string = this.auth.getSubscriptionKey();
            if (subscriptionKey)
            {
                req = req.clone({
                    withCredentials: true,
                    headers: req.headers.set('Ocp-Apim-Subscription-Key', subscriptionKey)
                });
            }
            else
            {
                req = req.clone({ withCredentials: true });
            }

            const interactiveHeader = req.headers.get(HttpHeaderNames.EventInteractionType);
            if (new Date() > new Date(expiration.getTime() - this.REFRESH_THRESHOLD) && interactiveHeader !== UserInteractionTypes.Noninteractive) {
                // if the token is close to expiring, then refresh it.
                this.auth.refresh();
            }
        }
        
        return next.handle(req).pipe(catchError(x => this.handleAuthError(x, req)));
    }

    private handleAuthError(err: HttpErrorResponse, req: HttpRequest<any>): Observable<any> {
        if (err.status === 401) {
            // No need to logout, if we were already trying to logout
            if (!req.url.endsWith(this.auth.LogoutURL)) {
                debugger;
                this.auth.logout();
            }
            return of(err.message);
        }
        return throwError(() => err);
    }
}