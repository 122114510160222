import { Component, EventEmitter, Input, forwardRef, HostBinding, ElementRef, Renderer2, Output } from '@angular/core';

import { DateUtils } from '@Core/Lib/Utils/date-utils';

@Component({
    selector: 'unity-control-date',
    templateUrl: './unity-control-date.component.html',
    styleUrls: ['../unity-form-controls.scss']
})
export class UnityFormControlDateComponent {
    @Input() label: string;
    @Input() id: string;
    @Input() name: string;
    @Input() value: Date;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    
    @Input() required: boolean = false;
    @HostBinding('class') @Input() state: 'disabled' | 'readonly' | 'invalid' | 'warning' | 'success';
    @Input() infoText: string = "";
    @Input() hintText: string = "";
    @Input() disabled: boolean;

    @Output() onChange: EventEmitter<Date> = new EventEmitter();

    public _minDate: string;
    public _maxDate: string;
    public _value: string

    // The queue value that is changed
    private waitingChangeValue: string;
    private hasWaitingChange: boolean;
    private outgoingChangeTimeout: any;

    constructor(
        private renderer:Renderer2,
        private elementRef:ElementRef
    ) {}

    ngOnInit() {
        this.renderer.listen(this.elementRef.nativeElement, 'paste', (e) => {
            const pastedValue = e.clipboardData.getData('text');
            if (!Number.isNaN(new Date(pastedValue).getTime())) {
                e.target.value = new Date(pastedValue).toISOString().split("T")[0];
            }
        });
    }

    ngOnChanges() {
        this._minDate = DateUtils.ConvertForDateInput(this.minDate);
        this._maxDate = DateUtils.ConvertForDateInput(this.maxDate);
        this._value = DateUtils.ConvertForDateInput(this.value);
    }

    public change(e) {
        this.clearTimeout();
        this.hasWaitingChange = true;
        this.waitingChangeValue = e.target.value;

        this.outgoingChangeTimeout = setTimeout(() => {
            this.sendChange();
            this.outgoingChangeTimeout = null;
        }, 1000);  

    }

    public blur() {
        if (this.hasWaitingChange) {
            this.sendChange();
        }
    }

    private clearTimeout() { 
        if (this.outgoingChangeTimeout) {
            clearTimeout(this.outgoingChangeTimeout);
            this.outgoingChangeTimeout = null;
        }      
    }

    private sendChange() {
        this.clearTimeout();

        if (this.hasWaitingChange) {
            this.hasWaitingChange = false;
            this._value = this.waitingChangeValue;
            this.value = DateUtils.ConvertInputStringToDate(this._value);
            this.onChange.emit(this.value);
        }      

        this.waitingChangeValue = null;
    }
};