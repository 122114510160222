import {
    ElementRef,
    Directive, Input, TemplateRef,
    Renderer2,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
    selector: '[ngbTooltipSticky]',
    exportAs: 'ngbTooltipSticky'
})
export class NgbTooltipStickyDirective extends NgbTooltip implements OnInit, OnDestroy {
    @Input() ngbTooltipSticky: TemplateRef<any>;
    
    tooltipTitle: string;
    ngpTooltip: TemplateRef<any>;
    canCloseTooltip: boolean;

    toggle(): void {
        super.toggle();
    }
    
    isOpen(): boolean {
        return super.isOpen();
    }
    
    constructor(
        private _elRef: ElementRef,
        private _render: Renderer2,
        private changeRef: ChangeDetectorRef
    ){
        super();
        this.triggers = 'manual';
        this.container = 'body';
        this.openDelay = 500;
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.ngbTooltip = this.ngbTooltipSticky;
        var delay;
    
        this._render.listen(this._elRef.nativeElement, 'mouseenter', () => {
            delay = setTimeout(() => {
                this.canCloseTooltip = true;
                this.open();
                this.changeRef.detectChanges();
            }, this.openDelay);
        });
    
        this._render.listen(this._elRef.nativeElement, 'mouseleave', (event: Event) => {
            clearTimeout(delay);
            setTimeout(() => {
                if (this.canCloseTooltip) {
                    this.canCloseTooltip = false;
                    this.close();
                    this.changeRef.detectChanges();
                }
            }, 100);
        });
    
        this._render.listen(this._elRef.nativeElement, 'click', () => {
            this.close();
        });
    }
    
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
    
    public open() {
        super.open();
        setTimeout(() => {
            let tooltip;
            const listPop = window.document.querySelectorAll('.tooltip');

            if (listPop.length > 1) {
                tooltip = listPop[listPop.length - 1];
            } else {
                tooltip = listPop[0];
            }
    
            if (tooltip) {
                this._render.listen(tooltip, 'mouseenter', () => {
                    this.canCloseTooltip = false;
                });
    
                this._render.listen(tooltip, 'mouseleave', () => {
                    this.canCloseTooltip = true;
                    setTimeout(() => {
                        if (this.canCloseTooltip) {
                            this.close();
                        }
                    }, 0);
                });
            }
        }, 0);
    }
    
    close() {
        super.close();
    }
}