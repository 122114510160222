<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>

        <input *ngIf="!model.Options?.MultiLineEntry"
            [id]="getInputIdOrName()"
            [(ngModel)]="model.Value" 
            type="text" 
            class="form-control" 
            [ngClass]="getInputCssClass()"
            [minlength]="model.Options?.MinLength"
            [maxlength]="model.Options?.MaxLength"
            [disabled]="model.Disabled"
            (input)="valueChange($event, 'input')"
            (focusin)="onFocusChanged.emit(true)"
            (focusout)="onFocusChanged.emit(false)"
        >
        <textArea *ngIf="model.Options?.MultiLineEntry"
            [id]="getInputIdOrName()"
            [name]="getInputIdOrName()"
            [(ngModel)]="model.Value" 
            ngDefaultControl
            class="form-control"
            [ngClass]="getInputCssClass()" 
            [minlength]="model.Options?.MinLength"
            [maxlength]="model.Options?.MaxLength"
            [disabled]="model.Disabled" 
            (input)="valueChange($event, 'input')"
            (focusin)="onFocusChanged.emit(true)"
            (focusout)="onFocusChanged.emit(false)"
        ></textArea>

        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>