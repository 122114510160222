import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import _ from 'lodash';

import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';
import { AuthService } from "@Services/auth-service";
import { ReportsService } from "@Services/reports-service";
import { environment } from 'src/environments/environment';
import { NgxPermissionsService } from "ngx-permissions";

@Component({
    selector: 'sm-main-menu',
    templateUrl: './sm-main-menu.component.html',
    styleUrls: ['./sm-main-menu.component.scss']
})
export class SmMainMenuComponent implements OnInit {
    @Input() view: string;

    public menuItems: MainMenuItemVM[];
    
    constructor(
        public router: Router,
        private permissionsService: NgxPermissionsService,
        private authService: AuthService,
        private reportsService: ReportsService) 
    { }
    
    ngOnInit() {
        this.menuItems = [];

        if (this.view != 'Admin') {
            this.addMenuItem({
                Label: "Work",
                RouteUrl: "Work",
                Order: 0,
                IsExternal: false
            });
        }

        this.permissionsService.getPermissions();
        let configureMenu = this.permissionsService.hasPermission([
            TenantPermissions.SystemAccess,
            TenantPermissions.Tenant,
            TenantPermissions.Programs,
        ]);

        let manageMenu = this.permissionsService.hasPermission([
            TenantPermissions.Manage
        ]);

        manageMenu.then(result => {
            if (result) {
                this.addMenuItem({
                    Label: "Manage",
                    RouteUrl: "Mgmt",
                    Order: 1,
                    IsExternal: false,
                    Disabled: !this.authService.isProgramManager(),
                    Title: this.authService.isProgramManager() ? null : 'You currently are not listed as a manager for any programs.'
                });
            }
        })
       

        configureMenu.then(result => {
            if (result) {
                this.addMenuItem({
                    Label: "Configure",
                    RouteUrl: "Conf",
                    Order: 2,
                    IsExternal: false
                });
            }
        });

        if (this.authService.spaName === 'work') 
        {
            let reportsPermissions = this.permissionsService.hasPermission([
                TenantPermissions.Work_Reports,
            ]);
    
            reportsPermissions.then(result => {
                if (result) {
                    this.addMenuItem({
                        Label: "Reports",
                        RouteUrl: "reports",
                        Order: 3,
                        IsExternal: true
                    });
                }
            });
        }

        this.addMenuItem({
            Label: "Documentation",
            RouteUrl: "documentation",
            Order: 4,
            IsExternal: true,
            Title: "This will direct you to the Insurity Knowledge Center where you will be prompted to log in or create an account."
        });
    }

    addMenuItem(menuItem: MainMenuItemVM) {
        this.menuItems.push(menuItem);
        this.menuItems = _.sortBy(this.menuItems, m => m.Order);
    }

    isActive(menuItem: MainMenuItemVM): boolean {
        return this.view == menuItem.RouteUrl;
    }

    itemClick(menuItem: MainMenuItemVM) {
        if (menuItem.RouteUrl == "reports"){
            this.reportsService.openReports();
            return;
        }

        if (menuItem.RouteUrl == 'documentation') {
            window.open(environment.ikcDocumentationUrl);
            return;
        }

        const url = environment.baseUrl + menuItem.RouteUrl;
        this.router.navigate([menuItem.RouteUrl]);
    }
};

interface MainMenuItemVM {
    Label: string;
    RouteUrl: string;
    Order: number;
    IsExternal: boolean;
    Title?: string;
    Disabled?: boolean;
}