<ng-container *ngFor="let alert of alerts">
    <ngb-alert type="light" [dismissible]="alert.dismissible" (closed)="closeAlert(alert)">
        <svg *ngIf="alert.icon" class="icon-alert"><use xlink:href="#icon-alert"></use></svg>
        <div class="message">{{ alert.message }}</div>
        <button unity-button 
                *ngIf="alert.action" 
                (click)="performAction(alert)" 
                class="link">
            {{alert.action.label}}
        </button>
    </ngb-alert>
</ng-container>