import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@Services/auth-service';
import { AdminPollingService as AdminPollingService } from './admin-polling-service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit {
    public environmentName: string = environment.env;
    public refreshRate = 60;
    public minRefreshRateSeconds = 15;
    public maxRefreshRateSeconds = 300;
    public refreshRateSliderIncrement = 15;

    private sliderValueForNoRefresh = this.maxRefreshRateSeconds + this.refreshRateSliderIncrement;

    public Math: Math = Math; // make this available to the template

    constructor(private authService: AuthService, private pollingService: AdminPollingService) {
        this.authService.spaName = 'admin';

        const storedValue: string = window.localStorage.getItem('refreshRate');
        if (storedValue) {
            const storedRefreshRate = parseInt(storedValue);
            if (this.minRefreshRateSeconds <= storedRefreshRate && storedRefreshRate <= this.maxRefreshRateSeconds) {
                this.refreshRate = storedRefreshRate;
            }
            else {
                this.refreshRate = this.sliderValueForNoRefresh;
            }
        }
        this.authService.spaName = 'admin';
    }

    public ngOnInit(): void {
        this.updateRefreshRate(this.refreshRate);
    }

    public updateRefreshRate(newRefreshRate: number) {
        let effectiveRefreshRate: number;
        if (this.minRefreshRateSeconds <= newRefreshRate && newRefreshRate <= this.maxRefreshRateSeconds) {
            effectiveRefreshRate = newRefreshRate;
        }
        else {
            effectiveRefreshRate = AdminPollingService.noRefreshValue;
        }
        this.pollingService.updateRefreshRate(effectiveRefreshRate);
    }
};