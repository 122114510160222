<div class="unity-message {{toast?.Type}} unity-toast" 
    [ngClass]="{ 'unity-toast-non-standalone': !standalone }"
    [id]="'Toast:' + toast?.Id">
    <svg attr.class="unity-icon status-icon unity-icon-{{toast?.Icon}}">
        <use attr.xlink:href="#unity-icon-{{toast?.Icon}}"></use>
    </svg>
    <h4 class="message">{{ toast?.Message }}</h4>
    <div class="subtitle">
        <h5 *ngFor="let subtitle of toast.Subtitles" class="subtitle_entry single_line">
            {{ subtitle.DisplayType ?? subtitle.Type }}: {{ subtitle.Text }}
        </h5>
    </div>
    <div class="details">{{ toast?.Description }}</div>
    <div class="actions">
        <ng-container *ngFor="let link of toast.Links">
            <nav>
                <a class="unity-button link small" 
                    (click)="onClickLink.emit({ Id: toast.Id, Subtitle: link})">
                    Go to {{link.DisplayType ?? link.Type}}: {{link.Text}}
                </a>
            </nav>
        </ng-container>
    </div>
    <button *ngIf="!standalone" unity-button class="icon-only large" (click)="dismiss.emit($event)">
        <svg class="unity-icon unity-icon-times">
            <use xlink:href="#unity-icon-times"></use>
        </svg>
    </button>
</div>