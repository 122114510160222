import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * This interceptor solves two problems in local environments using proxy routes.
 *
 * 1. It ensures that SPA-initiated requests include "proxy".
 *
 * 2. It handles a 404 caused by a browser-initiated-GET to the
 * location header specified by the HTTP server in a 302 response,
 * which doesn't include "proxy".
 */
@Injectable()
export class LocalProxyRouteInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let self = this;

        // Ensures any request URL that doesn't incldue the base URL
        // is skipped.
        if (!req.url.includes(this.baseUrl)){
            return next.handle(req);
        }

        // Ensure the request URL includes the base URL (if one applies).
        if (this.usingBaseUrl) {
            if (req.url.startsWith('http')) { // fully-qualified URL
                if (!req.url.includes(self.baseUrl + '/')) {
                    let fixedUrl = new URL(req.url);
                    fixedUrl.pathname = self.baseUrl + fixedUrl.pathname;
                    req = req.clone({
                        url: fixedUrl.toString()
                    });
                }
            }
            else if (!req.url.startsWith(self.baseUrl + '/')) { // relative URL
                let fixedUrl = self.baseUrl + req.url;
                req = req.clone({
                    url: fixedUrl.toString()
                });
            }
        }

        if (!this.usingBaseUrl) {
            // no special handling required.
            return next.handle(req);
        }
        else {
            return next.handle(req).pipe(
                catchError((error) => {
                    let response = error as HttpErrorResponse;
                    if (response
                        && response.status === 404 // original URL was not found
                        && !response.url.includes(self.baseUrl + '/') // The URL is missing part of the base URL
                        && response.url.startsWith('http')) // must use a non-relative URL
                    {
                        let fixedUrl = new URL(response.url);
                        fixedUrl.pathname = self.baseUrl + fixedUrl.pathname;
                        req = req.clone({
                            url: fixedUrl.toString()
                        });
                        return next.handle(req);
                    }
                    return throwError(() => error);
                })
            );
        }
    }

    private get usingBaseUrl(): boolean {
        return this.baseUrl.length > 1;
    }

    /** When a proxy is in use, this will contain a value like "proxy". */
    private get baseUrl(): string {
        if (this._baseUrl == null) {
            this._baseUrl = environment.applicationEndpoint || "";
        }
            
        return this._baseUrl;
    }
    private _baseUrl: string;
}