<div class="modal-header">
    <h3>New {{ "Account" | smNomenclatureByRegion | async }}</h3>
    <button unity-button 
        class="icon-only xlarge close on_dark" 
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <svg class="unity-icon-times">
            <use xlink:href="#unity-icon-times"></use>
        </svg>
    </button>
</div>

<unity-stepper
    [steps]="accountCreationSteps"
    [currentStep]="accountCreationCurrentStep"
    [layoutDirection]="'horizontal'"
    [style]="'panels'"
    (goToStep)="changeStep($event)">
</unity-stepper>

<div class="modal-body">
    <div *ngIf="accountCreationCurrentStep == 0" (keyup.enter)="$event.stopPropagation(); next()">
        <div class="form-group mt-3">
            <label for="accountName">{{ "Account" | smNomenclatureByRegion | async }} Name <span class="text-danger">*</span></label>
            <input type="text" 
                [(ngModel)]="accountName"
                #nameInput name="accountName" 
                class="form-control"
                id="accountName" 
                placeholder="Organization Name, Co."
                autocomplete="off" 
                autofocus 
                ngbAutofocus>
        </div>
        <div *sm-tenant-has-facet="TENANT_PERMISSIONS.Flag_Work_Accounts_NewAccountNumberOverride" class="form-group">
            <label for="accountCode">{{ "Account" | smNomenclatureByRegion | async }} Number</label>
            <input type="text" 
                [(ngModel)]="accountCode" 
                name="accountCode" 
                class="form-control" 
                id="accountCode"
                placeholder="System will generate if left blank"
                autocomplete="off">
        </div>
        <div class="form-group">
            <label for="dba">DBA</label>
            <input type="text" 
                placeholder="Doing Business As" 
                [(ngModel)]="dba" 
                name="dba" 
                class="form-control"
                id="dba" 
                autocomplete="off">
        </div>
        <div class="form-group" #producer>
            <label>{{ "Producer" | smNomenclatureByRegion | async }}</label>
            <sm-producer-search (producerSelectedEvent)="setProducer($event)"></sm-producer-search>
        </div>
        <ng-container *sm-tenant-has-facet="TENANT_PERMISSIONS.Flag_Work_Accounts_ExplicitProgramAccess">
            <div class="form-group mt-3">
                <label for="selectedProgram">Program <span class="text-danger">*</span></label>
                <select
                    name="selectedProgram"
                    [(ngModel)]="program"                    
                    class="form-select form-control"
                >
                    <option [ngValue]="undefined" disabled selected hidden>Select a Program...</option>
                    <option *ngFor="let prog of programs" [ngValue]="prog">
                        {{prog.Name}}
                    </option>
                </select>
            </div>
        </ng-container>
    </div>

    <div *ngIf="accountCreationCurrentStep == 1" (keyup.enter)="$event.stopPropagation(); createAccount(false)">
        <div class="form-group mt-3">
            <label for="country">Country <span class="text-danger">*</span></label>
            <select
                id="country"
                #countryInput
                class="form-select form-control"
                [(ngModel)]="country"
            >
                <option [ngValue]="'USA'">United States</option>
                <option [ngValue]="'GBR'">United Kingdom</option>
                <option [ngValue]="'CAN'">Canada</option>
            </select>
        </div>
        <div class="form-group">
            <label for="phone">Phone <span class="text-danger">*</span></label>
            <input type="text" 
                placeholder="(  )    -" 
                [(ngModel)]="phone" 
                name="phone" 
                class="form-control" 
                id="phone"
                autocomplete="off">
        </div>
        <div class="form-group">
            <label for="address1">Address 1 <span class="text-danger">*</span></label>
            <input type="text" 
                placeholder="Address 1" 
                [(ngModel)]="address1" 
                name="address1" 
                class="form-control"
                id="address1" 
                autocomplete="off">
        </div>
        <div class="form-group">
            <label for="address2">Address 2</label>
            <input type="text" 
                [(ngModel)]="address2" 
                name="address2" 
                class="form-control" 
                id="address2"
                autocomplete="off">
        </div>
        <div class="form-group">
            <label for="address2">Address 3</label>
            <input type="text"
                [(ngModel)]="address3" 
                name="address3" 
                class="form-control" 
                id="address3"
                autocomplete="off">
        </div>
        <div class="d-flex w-100">
            <div class="form-group me-2" style="flex:1">
                <ng-container *ngIf="country == 'USA' || country == 'CAN'">
                    <ng-container *ngTemplateOutlet="CITY"></ng-container>
                </ng-container>
                <ng-container *ngIf="country == 'GBR'">
                    <ng-container *ngTemplateOutlet="STATE"></ng-container>
                </ng-container>
            </div>
            <div class="form-group" [ngClass]="{ 'col-sm-2': country == 'USA'}">
                <ng-container *ngIf="country == 'USA' || country == 'CAN'">
                    <ng-container *ngTemplateOutlet="STATE"></ng-container>
                </ng-container>
                <ng-container *ngIf="country == 'GBR'">
                    <ng-container *ngTemplateOutlet="CITY"></ng-container>
                </ng-container>
            </div>
            <div class="form-group ms-2 col-sm-3">
                <ng-container *ngTemplateOutlet="ZIP"></ng-container>
            </div>
        </div>
        <ng-template #CITY>
            <label for="city">
                <ng-container *ngIf="country == 'USA'">City</ng-container>
                <ng-container *ngIf="country == 'GBR'">Post Town</ng-container>
                <ng-container *ngIf="country == 'CAN'">Municipality</ng-container>
                <span class="text-danger">*</span>
            </label>
            <input type="text" [(ngModel)]="city" name="city" class="form-control" id="city" autocomplete="off">
        </ng-template>
        <ng-template #STATE>
            <ng-container *ngIf="country == 'USA'">
                <label for="state">State <span class="text-danger">*</span></label>
                <select class="form-select form-control" [(ngModel)]="state" name="state" id="state" required>
                    <option *ngFor="let state of us_states" [ngValue]="state.Abbreviation">
                        {{state.Abbreviation}}
                    </option>
                </select>
            </ng-container>
            <ng-container *ngIf="country == 'GBR'">
                <label for="state">Locality</label>
                <input id="state" class="form-control" [(ngModel)]="state" />
            </ng-container>
            <ng-container *ngIf="country == 'CAN'">
                <label for="state">Province/Territory <span class="text-danger">*</span></label>
                <select id="state" class="form-select form-control" [(ngModel)]="state">
                    <option *ngFor="let prov of can_provinces" [ngValue]="prov.Abbreviation">{{prov.Name}}</option>
                </select>
            </ng-container>
        </ng-template>
        <ng-template #ZIP>
            <label for="zip">
                <ng-container *ngIf="country == 'USA'">Zipcode</ng-container>
                <ng-container *ngIf="country == 'GBR'">Postcode</ng-container>
                <ng-container *ngIf="country == 'CAN'">Postal Code</ng-container>
                <span class="text-danger">*</span>
            </label>
            <input type="text" [(ngModel)]="zip" name="zip" class="form-control" id="zip" autocomplete="off">
        </ng-template>
    </div>
</div>

<div *ngIf="errorMessage" class="alert alert-danger">
    {{errorMessage}}
</div>
<div class="modal-footer">
    <ng-container *ngIf="accountCreationCurrentStep == 0">
        <button unity-button
                id="AccountCreationNextButton"
                [disabled]="disableNext()"
                class="primary"
                (click)="next()">
            Next
        </button>
    </ng-container>
    <ng-container *ngIf="accountCreationCurrentStep == 1">
        <div class="button--left">
            <button unity-button
                    id="AccountCreationBackButton"
                    class="tertiary"
                    (click)="back()">
                Back
            </button>
        </div>
        <button unity-button
                [disabled]="disableCreateAccount()"
                class="primary"
                (click)="createAccount(false)">
            Create {{ "Account" | smNomenclatureByRegion | async }}
        </button>
        <button unity-button
                [disabled]="disableCreateAccount()"
                class="secondary ms-1"
                (click)="createAccount(true)">
            Create {{ "Account" | smNomenclatureByRegion | async }} & Start a Workflow
        </button>
    </ng-container>
    <button unity-button
            class="ghost"
            (click)="activeModal.close()">
        Cancel
    </button>
</div>