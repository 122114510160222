import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import _ from "lodash";
import { SmFormFieldVM } from "../sm-form-field.component";
import { FormValueChangeEvent, SmFormElementVM, SmFormState } from "../sm-form.component";

@Component({
    selector: 'sm-form-row',
    templateUrl: './sm-form-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmFormRowComponent  {
    // This is an element that displays its fields inline. 

    @Input() model: SmFormRowVM;
    @Input() readOnly: boolean;
    @Input() formState: SmFormState;

    @Output() onValueChanged: EventEmitter<FormValueChangeEvent> = new EventEmitter();
    @Output() onDetectChanges: EventEmitter<any> = new EventEmitter();
}

export interface SmFormRowVM extends SmFormElementVM {
    // Note: A Row is not a real Element of an Application, so some of the
    // base properties will not be applicable

    Fields: SmFormFieldVM[]; 
    SpecialType: 'Currency' | null; // Some rows have specific behavior beyond just the display
}