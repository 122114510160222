import { Component, Input } from "@angular/core";
import { Area } from "@Core/CodeGen/Models/configuration.models";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'sm-select-area-modal',
    templateUrl: './sm-select-area-modal.component.html',
})
export class SmSelectAreaModalComponent {
    @Input() selectedAreaAliasOrName: string;
    @Input() areas: Area[] = [];

    constructor(public activeModal: NgbActiveModal) { }

    onCancel(): void {
        this.activeModal.dismiss();
    }
};