import _ from "lodash";
import { DateTimeComponent, TimeSpan } from "../contract-date";
import { DateRangeString } from '@Core/Lib/Enums/date-range-string-enum';
import { ContractDateUtils } from "./contract-date-utils";

export class DateUtils {
    static addHours(date:Date, hours: number): Date{
        date.setTime(date.getTime() + (hours * 60 * 60 *1000));
        return date;
    }

    static getShortYear(date:Date): string{
        return date.getUTCFullYear().toString().substring(-2);
    }

    static isSameDate(date1: Date, date2: Date) {
        var firstDate = new Date(date1);
        var secondDate = new Date(date2);
        return firstDate.getUTCFullYear() == secondDate.getUTCFullYear() &&
            firstDate.getUTCMonth() == secondDate.getUTCMonth() &&
            firstDate.getUTCDate() == secondDate.getUTCDate();
    }

    static isAfterDate(date1: Date, date2: Date) {
        var firstDate = new Date(date1);
        var secondDate = new Date(date2);

        return firstDate.getUTCFullYear() > secondDate.getUTCFullYear() 
            || (firstDate.getUTCFullYear() == secondDate.getUTCFullYear() 
                && firstDate.getUTCMonth() > secondDate.getUTCMonth()) 
            || (firstDate.getUTCFullYear() == secondDate.getUTCFullYear() 
                && firstDate.getUTCMonth() == secondDate.getUTCMonth() 
                && firstDate.getUTCDate() > secondDate.getUTCDate()
        );

    }

    static convertDateRangeToString(range: DateRange): string {
        return range.StartDate.toLocaleDateString() + ' - ' + range.EndDate.toLocaleDateString();
    }

    public static Add(date: Date, timeSpan: TimeSpan): Date {
        if (!timeSpan)
            throw new Error("TimeSpan argument cannot be null.");
        if (!date)
            return null;

        let newDate: Date = new Date(date);
        switch (timeSpan.Units) {
            // Years and Months have to be handled differently
            // because we can't convert those to a number of days.
            case DateTimeComponent.Years:
                newDate.setFullYear(newDate.getFullYear() + timeSpan.Value);
                break;
            case DateTimeComponent.Months:
                newDate.setMonth(newDate.getMonth() + timeSpan.Value);
                break;
            default:
                let days = Math.round(timeSpan.getTotalDays());
                newDate.setDate(newDate.getDate() + days);
                break;
        }

        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
    }

    public static AddTimePeriod(date: Date, timePeriod: string): Date {
        if (!timePeriod)
            throw new Error("timePeriod argument cannot be null.");

        let newDate: Date = new Date(date);
        var contractDate = ContractDateUtils.convertFromDate(newDate);
        contractDate = ContractDateUtils.addTimePeriod(contractDate, timePeriod);

        return ContractDateUtils.convertToDate(contractDate);
    }

    // Convert a date into the format YYYY-MM-DD for html date inputs
    public static ConvertForDateInput(date: Date): string {
        if (!date)
            return null;
        return date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');
    }

    // Convert the output of a date input YYYY-MM-DD back to a Date
    public static ConvertInputStringToDate(dateStr: string): Date {
        if (_.isNil(dateStr))
            return null;

        var split = dateStr.split('-');

        return new Date(Number(split[0]), Number(split[1]) - 1, Number(split[2]));
    }

    public static ConvertDateRangeStringToDateRange(dateRangeString: DateRangeString) : DateRange {
        const currentDate: Date = new Date();

        let dateRange = {
            StartDate: null, 
            EndDate: null
        };

        if (dateRangeString === DateRangeString.Today) {
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-1, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.LastThirtyDays) {
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-30, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.LastSixtyDays) {
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-60, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.LastNinetyDays) {
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-90, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.ThisWeek) {
            let currentDayOfWeek = currentDate.getDay();
            let daysUntilCurrentDay: number = currentDayOfWeek - 1; // 1 is Monday which represents the start of UW week
            if (daysUntilCurrentDay == -1) {
                daysUntilCurrentDay = 6;
            }
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-daysUntilCurrentDay, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.LastWeek) {
            let currentDayOfWeek: number = currentDate.getDay();
            let daysUntilCurrentDay: number = currentDayOfWeek - 1; // 1 is Monday which represents the start of UW week
            if (daysUntilCurrentDay == -1) {
                daysUntilCurrentDay = 6;
            }
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-daysUntilCurrentDay - 8, DateTimeComponent.Days));
            dateRange.EndDate = this.Add(currentDate, new TimeSpan(-daysUntilCurrentDay - 1, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.ThisMonth) {
            dateRange.StartDate = this.Add(currentDate, new TimeSpan(-1, DateTimeComponent.Months));
        }
        else if (dateRangeString === DateRangeString.LastMonth) {
            let thisMonth: Date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            dateRange.StartDate = this.Add(thisMonth, new TimeSpan(-1, DateTimeComponent.Months));
            dateRange.EndDate = this.Add(thisMonth, new TimeSpan(-1, DateTimeComponent.Days));
        }
        else if (dateRangeString === DateRangeString.ThisQuarter) {
            let monthsToBeginningOfQuarter = (currentDate.getMonth() + 2) % 3;

            dateRange.StartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsToBeginningOfQuarter, 1);
        }

        if (dateRange.StartDate != null)
            return dateRange;

        return null;
    }
}


export interface DateRange {
    StartDate: Date;
    EndDate?: Date;
}