import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { share } from 'rxjs/internal/operators/share';

import { GlobalMessages } from '@Services/global-messages';
import { BaseService } from '@Services/base-service';
import { LoadingService } from '@Services/loading-service';
import { LoggingService } from '@Services/logging-service';

import { TenantContext } from '@Core/Lib/Contexts/tenant-context';


@Injectable()
export class TenantDataService extends BaseService {

    public serviceUrls = class ServiceUrls {
        public static baseDataUrl: string = BaseService.baseUrl + '/Data/';
        private static baseProgramsUrl: string = BaseService.baseUrl + '/Programs/';
        public static dataPointDefinitionTypesUrl: string = ServiceUrls.baseProgramsUrl + 'DataPointTypes/';
    }

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected loggingService: LoggingService,
        private tenantContext: TenantContext,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    public getDataPointTypes(): Observable<string[]> {
        const self = this;
        const request = this.http.get<any>(this.serviceUrls.dataPointDefinitionTypesUrl).pipe(share());

        request.subscribe({
            next: response => {
                self.tenantContext.DataPointTypes.next(response.Content);
            },
            error: error => {
                self.tenantContext.DataPointTypes.next(null);
                self.handleError(error)
            }
        });

        return request;
    }
}
