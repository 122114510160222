import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@Services/auth-service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
    selector: 'mgmt',
    templateUrl: './mgmt.component.html',
    styleUrls: ['./mgmt.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MgmtComponent {
    public loading = false;

    constructor(private authService: AuthService, private router: Router) {
        const self = this;

        this.authService.spaName = 'mgmt';
        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    setTimeout(() => self.loading = true);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    setTimeout(() => self.loading = false);
                    break;
                }
                default: {
                    break;
                }
            }
        });
        
    }
};
