import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { GlobalMessages, AppMessage } from '@Services/global-messages';
import { BaseService } from '@Services/base-service';
import { LoadingService } from '@Services/loading-service';
import { LoggingService } from '@Services/logging-service';

import { HttpHeaderNames } from '@Core/Lib/Enums/http-header-names';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class PollingService
    extends BaseService {

    private trackersById: Map<string, PollingTracker<any>> = new Map<string, PollingTracker<any>>();

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    /**
     * Kick off (or start listening to) an observable that recursively polls to the location
     * specified in the header of the given response.
     * Polling ends when the response code no longer indicates a redirect.
     * * id: the ID of the resource of type T
     * * response: the starting point for polling.  If respsonse code is not 202 or 302, no polling will occur.
     * * finalMessage: a message to be broadcast (anywhere in the SPA) when the polling completes.
     * * finalAction: an action to be executed (anywhere in the SPA) when the polling completes.
     **/
    public startPolling<T>(id: string, initialRequestFactory: () => Observable<HttpResponse<T>>,
        finalMessage: string = null, finalAction: (finalResponse: T) => void = null): Observable<T> {
        const tracker: PollingTracker<T> = this.trackersById.get(id);
        if (tracker) {
            // Overwrite these properties; last requester wins
            if (finalMessage) {
                tracker.finalMessage = finalMessage;
            }
            if (finalAction) {
                tracker.finalAction = finalAction;
            }

            // We assume that the initialRequestFactory is a duplicate of the source we are currently polling.

            return tracker.observable;
        }
        else {
            // start a new tracker
            return this.addNewTracker<T>(id, initialRequestFactory, finalMessage, finalAction);
        }
    }

    private addNewTracker<T>(id: string, initialRequestFactory: () => Observable<HttpResponse<T>>,
        finalMessage: string, finalAction: (finalResponse: T) => void): Observable<T> {
        let tracker = new PollingTracker<T>(id, null, finalMessage, finalAction);
        this.trackersById.set(id, tracker);

        // ping the initial request
        let self = this;
        initialRequestFactory().subscribe(
            response => self.processSuccessResponse(tracker, response));

        return tracker.observable;
    }

    private processSuccessResponse<T>(tracker: PollingTracker<T>, response: HttpResponse<T>): void {
        tracker.observable.next(response.body as T);
        if (this.shouldKeepPolling(response, tracker)) {
            this.pollNext<T>(tracker, response);
        }
        else {
            this.finalizeTracker(tracker);
        }
    }

    private pollNext<T>(tracker: PollingTracker<T>, lastResponse: HttpResponse<T>): void {
        var self = this;

        var requestOptions = _.extend({
            observe: 'response' as 'body' // we want the full response, not just the body
        });

        let url = this.getRedirectLocation(lastResponse);
        let delaySeconds = Math.max(this.getRetryDelay(lastResponse), 1);
        setTimeout(() => {
            self.http.get<HttpResponse<T>>(url, requestOptions).subscribe({
                next: response => self.processSuccessResponse(tracker, response),
                error: errorResponse => self.handleError(errorResponse)
            });
        }, delaySeconds * 1000);
    }

    private finalizeTracker<T>(tracker: PollingTracker<T>): void {
        if (this.trackersById.delete(tracker.id)) {
            const finalValue: T = tracker.observable.getValue();
            tracker.observable.complete();

            if (tracker.finalMessage)
                this.globalMessages.Add(new AppMessage(tracker.finalMessage, 'info', 5000));

            if (tracker.finalAction)
                tracker.finalAction(finalValue);
        }
    }

    private shouldKeepPolling<T>(response: HttpResponse<any>, tracker: PollingTracker<T>): boolean {
        // keep polling if the last response is a temporary redirect
        let locHeader: string = this.getRedirectLocation(response);
        let decision = (response.status === 202 || response.status === 302) // 202 (Accepted) or 302 (Found)
            && locHeader != null;

        if (environment.env !== 'production')
            console.log("PollingService.shouldKeepPolling", { decision: decision, trackingId: tracker.id, response: response });

        return decision;
    }

    /** The client checks the body as well as the HTTP header
    * because the web browser may strip off the header values in certain
    * cases where automatic redirects are involved. */
    private getRedirectLocation(response: HttpResponse<any>): string {
        let url: string = response.headers.get(HttpHeaderNames.Location)
            || (response.body && response.body.Content && response.body.Content['@PollingLocation']);

        if (url && !url.startsWith('http')) {
            url = BaseService.baseUrl + url; // convert a relative URL into a non-relative URL
        }

        return url;
    }

    /** The client checks the body as well as the HTTP header
    * because the web browser may strip off the header values in certain
    * cases where automatic redirects are involved. */
    private getRetryDelay(response: HttpResponse<any>): number {
        return parseInt(response.headers.get(HttpHeaderNames.RetryAfter))
            || (response.body && response.body.Content && response.body.Content['@PollingRetryAfter'])
            || 7;
    }
}

class PollingTracker<T> {
    public constructor(id: string, initialValue: T, finalMessage: string, 
        finalAction: (finalResponse: T) => void) {
        this.id = id;
        this.observable = new BehaviorSubject<T>(initialValue);
        this.finalMessage = finalMessage;
        this.finalAction = finalAction;
    }

    public id: string;
    public readonly observable: BehaviorSubject<T>;
    public finalMessage: string;
    public finalAction: (finalResponse: T) => void;
}