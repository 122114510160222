import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Component({
    selector: '[unity-button]',
    templateUrl: './unity-button.component.html',
    styleUrls: ['./unity-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnityButtonComponent {

    @HostBinding('attr.role') @Input() role = 'button';
    @HostBinding('attr.type') @Input() type = 'button';
    @Input() class : string = '';
    @Input() disabled : boolean = false;
    @Input() active : boolean = false;
    @Input() disableOnClick : boolean = false;
    @Input() badgeCount: number = 0;

    @HostBinding("attr.disabled") get isDisabled(){
        if ( this.disabled === true ) {
            return "disabled";
        }
        return null;
    };
    @HostBinding("class") get computedClasses(){
        let classesString = this.class;
        if (this.active && this.class.includes('icon-only')) classesString += " on";
        return classesString;
    };
    @HostListener('click', ['$event.target']) click() {
        if (this.disabled !== true) {
            this.disabled = this.disableOnClick;
            this.onClick.emit();
        }
    };

    @Output() onClick = new EventEmitter<string>();
};