<div *ngIf="!readOnly" class="input_money--edit">
    <div class="input_money--prefix">{{getCurrencySymbolForHtml()}}</div>
    <!-- Note: SM-8163 Ideally, we would use the [prefix] of an input to put the currency symbol -->
    <!-- However, having a prefix with ngx-mask prevents clearing the value to be null -->
    <!-- If this is fixed in ngx-mask, we can switch back to the prefix solution -->
    <input
        [id]="id"
        class="form-control input_money--input"
        [(ngModel)]="inputValue"
        [ngClass]="applyclasses()"
        [mask]="componentMask"
        [thousandSeparator]="thousandSeparator"
        [decimalMarker]="decimalMarker"
        [disabled]="disabled"
        [allowNegativeNumbers]="true"
        [dropSpecialCharacters]="true"
        (ngModelChange)="valueChange($event)"
        (focusin)="focusin.emit($event)"
        (focusout)="focusout.emit($event)"
    />
</div>
<ng-container *ngIf="readOnly">
    <span [id]="id" [class]="displayNegativesInRed && isNegative() ? 'text-danger' : ''">
        {{ getFormattedMoney() }}
    </span>
</ng-container>