import { Component, Input } from '@angular/core';
import { SmAssignableBadgeVM } from "@Shared/Components/sm-assignable-badge/sm-assignable-badge.viewmodel";
import { SmDateFormats } from '@Shared/Pipes/sm-date.pipe';

@Component({
    selector: 'sm-date-by',
    templateUrl: './sm-date-by.component.html',
    styleUrls: ['./sm-date-by.component.scss']
})
export class SmDateByComponent {
    @Input() LeftText: String = null;
    @Input() ShowDate: Boolean = true;
    @Input() Date: Date = new Date();
    @Input() Format: SmDateFormats | string = SmDateFormats.Short; // available pre-defined formats in sm-date.pipe.ts
    @Input() ShowUserBadge: Boolean = true;
    @Input() ShowUserName: Boolean = false;
    @Input() User: SmAssignableBadgeVM;
}
