<header>
    <nav unity-nav class="horizontal">
        <a href="javascript:void(0)" class="unity-tab" [class.active]="activeTab == 'Notifications'"
            (click)="onTabClick('Notifications')" >
            Notifications ({{ getUnreadNotificationsCount() }})
        </a>
    </nav>
    <div class="actions">
        <button unity-button class="icon-only small" (click)="showSettings()">
            <svg class="unity-icon unity-icon-cog">
                <use xlink:href="#unity-icon-cog"></use>
            </svg>
        </button>
        <button id="closeNotificationsButton" unity-button class="icon-only small" (click)="onClose.emit()">
                <svg class="unity-icon unity-icon-times">
                    <use xlink:href="#unity-icon-times"></use>
            </svg>
        </button>
    </div>
</header>

<div id="tabContent" class="tab-content" [ngSwitch]="activeTab">
    <div *ngSwitchCase="'Notifications'">
        <ng-container *ngFor="let card of sortedAndFilteredNotifications">
            <sm-global-notifications-card 
                [id]="'notification:' + card.Id" 
                [vm]="card" 
                (onClose)="onClose.emit($event)"
                (onClickLink)="onClickLink.emit($event)"/>
        </ng-container>
    </div>
</div>
