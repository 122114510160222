import { Component, Input } from '@angular/core';

import _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TenantUser } from '@Core/CodeGen/Models/configuration.models';

import { TenantTenantService } from '@Services/tenant-tenant-service';

@Component({
    selector: 'sm-user-info',
    templateUrl: './sm-user-info.component.html',
})
export class SmUserInfoComponent {
    @Input() user: TenantUser;

    constructor(public activeModal: NgbActiveModal,
        public tenantService: TenantTenantService)
        {}

    updateUserInfo(): void {
        var userClone = this.user.clone();
        this.tenantService.updateUser(userClone, true);
        this.activeModal.dismiss();
    }

    close(): void {
        this.activeModal.dismiss();
    }
};
