import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import _ from 'lodash';

import { Activity } from "@Core/CodeGen/Models/area.models";
import { DataStore } from "@Core/Lib/Contexts/data-store";
import { Notification } from '@Core/CodeGen/Models/configuration.models';

@Injectable()
export class NotificationService {

    private activityStore: DataStore<Activity>;

    public values: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
    public last: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(null) ;
        
    private notifications: Map<string, Notification> = new Map<string, Notification>();

    public add(notification: Notification) : boolean
    {
        if (!this.notifications.has(notification.Id)) {
            this.notifications.set(notification.Id, notification);
            this.addMessage(notification);
            return true;
        }
        return false;
    }

    private addMessage(notification: Notification): void {
        this.values.getValue().unshift(notification);

        let activitiesDeleted = false;

        this.last.next(notification);

        this.values.next(this.values.getValue());

        if (activitiesDeleted)
            this.activityStore.broadcast();  // broadcast the deletes
    }
}