import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoggingService } from '@Services/logging-service';
import { UserService } from '@Services/user-service';
import { LoadingService } from '@Services/loading-service';

import { App, Developer } from "@Core/CodeGen/Models/configuration.models";
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';


class Response extends HttpResponse<any> { }

@Injectable()
export class ApiManagementService extends BaseService {

    private serviceUrls = class ServiceUrls {

        private static baseUrl: string = BaseService.baseUrl + '/';

        public static integrations: string = ServiceUrls.baseUrl + 'Tenant/Integrations';
        public static developers: string = ServiceUrls.baseUrl + 'Tenant/Developers'; // TODO: SM-1341: remove this route
        public static integrationIndividual: string = ServiceUrls.integrations + '/{id}';
        public static developerIndividual: string = ServiceUrls.developers + '/{id}';
        public static integrationCredentials: string = ServiceUrls.integrationIndividual + '/Credentials';
        public static enableIntegration: string = ServiceUrls.integrationIndividual + '/Enable';
        public static disableIntegration: string = ServiceUrls.integrationIndividual + '/Disable';
        public static deleteIntegration: string = ServiceUrls.integrationIndividual;
    }

    private loadingIntegrations: boolean;

    public constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        private tenantContext: TenantContext,
        protected loggingService: LoggingService,
        private userService: UserService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);

        this.userService.Has(TenantPermissions.Tenant_Integrations).subscribe(has => {
            if (has)
            {
                this.loadIntegrations(this.tenantContext);
            }
        })
    }

    /**
     * Transforms a URL sent from the backend into the correct format.
     * The backend currently sends urls as if we were calling from API management.
     * @param url Url sent directly from the Back End
     */
    public static transformUrl(url: string): string {
        return `${BaseService.baseUrl}${url}`;
    }


    public loadIntegrations(context: DataContext = this.tenantContext) {
        this.loadingIntegrations = true;
        var self = this;

        const request = this.http.get<any>(this.serviceUrls.integrations).pipe(share());
        request.subscribe({
            next: response => {
                var responseObjects: any[] = response.Content;
                this.loadApiResults(responseObjects);
                self.loadingIntegrations = false;
            },
            error: error => {
                self.handleError(error);
                self.loadingIntegrations = false;
            },
        });
    }

    public getApplication(id: string): Observable<App> {
        var self = this;
        var findApplication = function (): App {
            return self.tenantContext.getStore(new App()).values.getValue().find(app => app.Id === id) as App;
        }

        // Are we still loading?
        if (this.loadingIntegrations) {
            let source = new Subject<App>();

            this.tenantContext.getStore(new App()).values.subscribe(
                applications => {
                    var app = findApplication();
                    if (app) {
                        source.next(app);
                    }
                    //TODO: Clean up the subscription
                }
            );

            return source;
        }

        let application = findApplication();

        // is the role in our store?
        if (application) {
            return new BehaviorSubject<App>(application);
        }
        // id is invalid, so return null
        else {
            return new BehaviorSubject<App>(null);
        }
    }

    public getDeveloper(id: string): Observable<Developer> {
        var self = this;
        var findDeveloper = function (): Developer {
            return self.tenantContext.getStore(new Developer()).values.getValue().find(dev => dev.Id === id) as Developer;
        }

        // Are we still loading?
        if (this.loadingIntegrations) {
            let source = new Subject<Developer>();

            this.tenantContext.getStore(new Developer()).values.subscribe(
                developers => {
                    var dev = findDeveloper();
                    if (dev) {
                        source.next(dev);
                    }
                    //TODO: Clean up the subscription
                }
            );

            return source;
        }

        let developer = findDeveloper();

        // is the role in our store?
        if (developer) {
            return new BehaviorSubject<Developer>(developer);
        }
        // id is invalid, so return null
        else {
            return new BehaviorSubject<Developer>(null);
        }
    }

    public addIntegration(newApplication: App): Observable<Response> {

        let self = this;
        let request = this.http.post<any>(this.serviceUrls.integrations, newApplication.serialize()).pipe(share());

        request.subscribe(
            response => this.loadApiResults(response.Content)
        );

        return request;
    }

    public updateApplication(application: App): Observable<Response> {
        let request = this.http.put<any>(this.serviceUrls.integrationIndividual.replace("{id}", application.Id), 
            application.serialize()).pipe(share());

        request.subscribe(
            response => this.loadApiResults(response.Content)
        );

        return request;
    }


    public addDeveloper(newDeveloper: Developer): Observable<Response> {
        let request = this.http.post<any>(this.serviceUrls.developers, newDeveloper.serialize()).pipe(share());

        request.subscribe(
            response => this.loadApiResults(response.Content)
        );

        return request;
    }

    public updateDeveloper(developer: Developer): Observable<Response> {

        var self = this;
        let request = this.http.put<any>(this.serviceUrls.developerIndividual.replace("{id}", developer.Id), developer.serialize()).pipe(share());

        request.subscribe(
            response => this.loadApiResults(response.Content)
        );

        return request;
    }

    public getCredentials(identityKey: string): Observable<Response> {
        var self = this;
        let request = this.http.get<any>(this.serviceUrls.integrationCredentials.replace("{id}", identityKey)).pipe(share());
        this.handleRequestWithoutResponse(request);

        return request;
    }

    public enableIntegration(identityKey: string): Observable<Response> {
        var self = this;
        let request = this.http.put<any>(this.serviceUrls.enableIntegration.replace("{id}", identityKey), "").pipe(share());

        request.subscribe({
            next: response => {
                var responseObjects: any[] = response.Content;
                this.loadApiResults(responseObjects);
            },
            error: error => self.handleError(error)
        });

        return request;
    }

    public disableIntegration(identityKey: string): Observable<Response> {
        var self = this;
        let request = this.http.put<any>(this.serviceUrls.disableIntegration.replace("{id}", identityKey), "").pipe(share());

        request.subscribe({
            next: response => {
                var responseObjects: any[] = response.Content;
                this.loadApiResults(responseObjects);
            },
            error: error => self.handleError(error)
        });

        return request;
    }

    public deleteIntegration(integration: App | Developer): Observable<Response> {
        var self = this;
        let request = this.http.delete<any>(this.serviceUrls.deleteIntegration.replace("{id}", integration.Id)).pipe(share());
        
        request.subscribe({
            next: response => {
                this.tenantContext.remove(integration);
            },
            error: error => self.handleError(error)
        });

        return request;
    }

    public regenerateCredentials(identityKey: string): Observable<Response> {
        let request = this.http.post<any>(this.serviceUrls.integrationCredentials.replace("{id}", identityKey), "").pipe(share());
        this.handleRequestWithoutResponse(request);
        return request;
    }

    private loadApiResults(responseObjects: any) {
        this.tenantContext.loading = true;

        if (_.isArray(responseObjects)) {
            responseObjects.forEach(ro => {
                this.deserializeObject(ro);
            });
        }
        else if (_.has(responseObjects, "@Type")) {
            this.deserializeObject(responseObjects);
        }

        this.tenantContext.loading = false;
    }

    private deserializeObject(obj: any) {
        if (obj["@Type"] === new Developer().Type) {
            this.tenantContext.deserializeSingleObject(Developer, obj);
        } else if (obj["@Type"] === new App().Type) {
            this.tenantContext.deserializeSingleObject(App, obj);
        }
    }

}