import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, NEVER, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AdminPollingService {

    public static readonly noRefreshValue: number = 0;
    public $refreshTimer: Observable<number>;
    private $refreshRate: BehaviorSubject<number> = new BehaviorSubject(15);

    constructor() {
        this.$refreshTimer = this.$refreshRate.pipe(switchMap((newRate) => {
            if (newRate === 0) {
                return NEVER;
            }
            return interval(newRate * 1000);
        }))
    }

    public updateRefreshRate(refreshRate: number) {
        window.localStorage.setItem('refreshRate', refreshRate.toString())
        this.$refreshRate.next(refreshRate);
    }
}