<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>    
        <kendo-multiselect
            [ngClass]="getInputCssClass()"
            [id]="getInputIdOrName()"
            [data]="getOptions()"
            [(ngModel)]="model.Value"
            [popupSettings]="{ height: 0 }"
            [disabled]="model.Disabled"
            [readonly]="model.Disabled"
            [allowCustom]="!(getOptions()?.length)"
            (valueChange)="valueChange($event)"
        >
        </kendo-multiselect>
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>           
    <sm-form-previous-value 
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>