<div class="producer_search" [ngClass]="{ 'producer_search-focused': searchFocus, 'has_search_value': searchHasValue }"
    (blur)="onSearchBlur()">
    <div *ngIf="searchFocus" class="results">
        <div class="message">
            <svg *ngIf="searching" class="icon-completing">
                <use xlink:href="#icon-completing"></use>
            </svg> {{searchMessage}}
        </div>
        <div *ngIf="foundProducers.length" class="results--list">
            <ng-container *ngFor="let foundProducer of foundProducers; let i=index">
                <div class="producer_search--card" 
                    tabIndex="1" 
                    #results
                    (keydown.enter)="$event.stopPropagation(); onProducerSelected(foundProducer.data)">
                    <sm-quick-search-card id="ProducerSearchCard:{{ foundProducer.data.id }}"
                        [MatchedItem]="foundProducer" 
                        [SearchType]="searchType"
                        (mousedown)="onProducerSelected(foundProducer.data)">
                    </sm-quick-search-card>
                    <ng-container *ngIf="foundProducers.length - 1 != i">
                        <hr>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="d-flex align-items-center">
        <input class="form-control input-search"
            type="search"
            [placeholder]="'Find a ' + placeholderLabel + '...'"
            #search
            (focus)="onSearchFocus($event)"
            (keyup)="onSearchKeyup($event)"
            [disabled]="disabled"
            [(ngModel)]="searchValue" />
        <svg *ngIf="searchHasValue" 
            role="button" 
            class="unity-icon-times" 
            tabIndex="1"
            (keydown.enter)="$event.stopPropagation(); clearSearchInput()"
            (click)="clearSearchInput()">
            <use xlink:href="#unity-icon-times"></use>
        </svg>
    </div>
</div>