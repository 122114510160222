<div class="unity-user_menu--section profile">
    <unity-avatar [model]="userVM"></unity-avatar>
    <div class="unity-user_menu--info">
        <div id="unityUserName" class="unity-user_menu--name">{{userVM?.DisplayName || (userVM?.FirstName + " " + userVM?.LastName)}}</div>
        <div class="unity-user_menu--title">{{userVM?.Title}}</div>
        <!-- <div class="unity-user_menu--company">{{user?.Company}}</div> -->
    </div>
</div>

<div class="unity-user_menu--section">

    <ul *ngIf="userVM" class="unity-user_menu--actions">
        <li *ngFor="let MenuItem of MenuItems" class="unity-user_menu--action">
            <button unity-button id="{{'button' | textToControlId: MenuItem.Label}}" class="link" (click)="MenuItem.Action(MenuItem.Service)">
                {{MenuItem.Label}}
            </button>
        </li>
    </ul>

    <div class="unity-user_menu--footer">
        <div *ngIf="false">
            Version: {{productVersion}}<br>
            Deployed on {{lastDeployedDate}}
        </div>
        <img class="unity-user_menu--logo" src="assets/logo-insurity-black.svg"/>
    </div>

</div>
