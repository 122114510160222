import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@Services/auth-service';
import _ from 'lodash';

@Component({
    selector: 'register',
    templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
    constructor(
        public auth: AuthService,
        public router: Router

    ) { }

    // The purpose of this page is purely a place to send users who have received an invite link
    // We will automatically redirect to the Auth0 Login page

    ngOnInit() {
        this.auth.login();
    }
}