<svg class="unity-icon-sync" role="button" [ngClass]="syncClass" [ngbPopover]="syncPop" [placement]="placement" [autoClose]="'outside'" container="body">
    <use xlink:href="#unity-icon-sync"></use>
    <line x1="35" y1="0" x2="0" y2="35" />
</svg>
<ng-template #syncPop>
    <div class="align-items-baseline">
        <div>Live Updates: <span class="ms-1" [ngClass]="syncClass">{{status}}</span></div>
        <div *ngIf="status != 'Connected'">
            You may still continue work as normal, but the experience may be diminished while the sync service is restored.
        </div>
    </div>
</ng-template>