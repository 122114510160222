<div class="login">
    <div class="login--center">
        <div class="login--center-panel">
            <div class="brand_name">
                Sure Underwriting Workbench
            </div>
            
            <div class="panel">
                <div class="access">
                    <div class="access--login">
                        <!-- LOGIN FORM -->
                        <div *ngIf="!selectTenants else selectTenant" class="login_form">
                            <button unity-button class="primary block" (click)="onLoginClick.emit()">Login</button>
                            <div *ngIf="errorMessage" class="alert alert-danger mt-2">Login failed: {{ errorMessage }}</div>
                        </div>
            
                        <!-- SELECT TENANT -->
                        <ng-template #selectTenant>
                            <sm-select-tenant
                                [selectedTenant]="selectedTenant"
                                (onTenantSelect)="tenantSelect($event)"
                                (onChangeUser)="changeUser()">
                            </sm-select-tenant>
                        </ng-template>
                    </div>
            
                    <div class="loading" *ngIf="loading"></div>
                </div>
            </div>
        
            <footer>
                Sure Underwriting Workbench is a product of <a href="https://insurity.com" target="_blank">
                    <img src="assets/logo-insurity-white.svg" alt="Insurity, LLC logo" class="insurity_logo" />
                </a>
            </footer>
        
        </div>
    </div>
</div>