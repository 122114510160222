import { Program as ProgramDef, Region } from "@Core/CodeGen/Models/configuration.models";
import { Program } from "@Core/CodeGen/Models/area.models";
import _ from "lodash";
import { ModelUtils } from "./model-utils";
import { RegionAliasVM } from "@Conf/Shared/region-card/region-card.component";

export class RegionUtils {
    public static isProgramRegionCommon(regions: Region[], programs: Program[]): boolean {
        const programRegions = programs.map(program => {
            return RegionUtils.getProgramRegion(regions, program.Id);
        });

        const isCommon = programRegions.every((region) => region?.Id === programRegions[0]?.Id);

        return isCommon;
    }

    public static getProgramRegion(regions: Region[], programId: string): Region {
        const region =  _.find(regions, region => _.some(region?.Formats(), edge => {
            return edge?.Out == ModelUtils.createDomainId(new ProgramDef(), programId)
        }));

        return region || null;
    }

    public static createAliasVMs(aliases: { [aliasLabel: string]: string }): RegionAliasVM[] {
        let regionAliasVMs: RegionAliasVM[] = [];

        Object.entries(aliases).forEach( ([key, value]) => {
            let isPlural = false;
            let singularCounterpart;
            if (key.endsWith("ies")) {
                isPlural = true;
                singularCounterpart = key.slice(0,-3) + "y";
            }
            else if (key.endsWith("s")) {
                isPlural = true;
                singularCounterpart = key.slice(0,-1);
            }

            let targetVMToUpdate = _.find(regionAliasVMs, aliasVM => aliasVM.BaseSingular === key
                || aliasVM.BaseSingular === singularCounterpart
                || aliasVM.BasePlural === key);

            if (targetVMToUpdate) {
                if (isPlural) {
                    targetVMToUpdate.BasePlural = key;
                    targetVMToUpdate.OverridePlural = value;
                }
                else {
                    targetVMToUpdate.BaseSingular = key;
                    targetVMToUpdate.OverrideSingular = value;
                }
            }
            else {
                let regionAlias = {
                    BaseSingular: !isPlural ? key : "",
                    BasePlural: isPlural ? key : "",
                    OverrideSingular: !isPlural ? value : "",
                    OverridePlural: isPlural ? value : ""
                } as RegionAliasVM;

                regionAliasVMs.push(regionAlias);
            }
        });

        return regionAliasVMs;
    }
}