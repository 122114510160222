export class FormatUtils {

    public static getThousandSeparator(): string {
        if (typeof Intl !== 'object') {
            return ','; // fallback
        }

        // We assume the locale is using 3 digits between the thousand separator,
        // so if a language isn't using thousand separators, we will have to rethink this solution
        // Note: Spain only puts the thousand separator once there is more than 4 digits
        const formattedThousand = new Intl.NumberFormat(navigator.language).format(10000);  
        return formattedThousand.charAt(2);
    }

    public static getDecimalMarker(): string {
        if (typeof Intl !== 'object') {
            return '.'; // fallback
        }
        const fomattedDecimal = new Intl.NumberFormat(navigator.language).format(1.1);
        return fomattedDecimal.charAt(1);
    }

    public static reverseFormatDecimal(value: string): number {
        var thousandSeparator = FormatUtils.getThousandSeparator();
        var decimalMarker = FormatUtils.getDecimalMarker();
        var input = value.split(thousandSeparator).join("").replace(decimalMarker, '.');
        return parseFloat(input);
    }

    public static reverseFormatInteger(value: string): number {
        var thousandSeparator = FormatUtils.getThousandSeparator();
        var decimalMarker = FormatUtils.getDecimalMarker();
        var input = value.split(thousandSeparator).join('').replace(decimalMarker, '.');
        return parseInt(input);
    }
}