import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";

@Component({
    selector: 'sm-form-field-radio-options',
    templateUrl: './sm-form-field-radio-options.component.html',
    styleUrls: ['./sm-form-field-radio-options.component.scss'],
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// Used for YesNo Fields
export class SmFormFieldRadioOptionsComponent extends SmFormFieldBase {

    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    public getOptions(): string[] {
        return this.model.Options.Options;
    }

    public onDblClick(): void {
        this.model.Value = null;
        super.valueChange(null);
    }

    public trackOptions = (_: number, option: string) => {
        return option;
    }

    public valueChange(e) {
        this.model.Value = e.target.value;
        super.valueChange(e);
    }

    public checkButtonAlignment() {
        if (this.model.Options?.ButtonAlignment === 'Vertical') {
            return 'flex-column';
        }
    }
}