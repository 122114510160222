import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import _ from 'lodash';
import { Observable, ObservableInput } from "rxjs";
import { mergeMap, share, take } from 'rxjs/operators';

import { ApiManagementService } from '@Services/api-management';
import { BaseService } from '@Services/base-service';
import { AppMessage, GlobalMessages } from "@Services/global-messages";
import { LoggingService } from '@Services/logging-service';
import { LoadingService } from '@Services/loading-service';

import { AccountJournalEntry, AccountRole, Phase, Workflow,
    Task, ProgramRevision } from '@Core/CodeGen/Models/area.models';
import { AccountContext } from "@Core/Lib/Contexts/account-context";
import { ContractDate } from '@Core/Lib/contract-date';
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { ModelUtils } from "@Core/Lib/Utils/model-utils";
import { UserContext } from '@Core/Lib/Contexts/user-context';


class Response extends HttpResponse<any> { }

@Injectable()
export class AccountWorkflowService extends BaseService {

    private serviceUrls = class ServiceUrls {
        public static baseUrl: string = BaseService.baseUrl + '/Workflows/';

        private static WorkflowsByWorkflowSetUnsubstituted: string = ServiceUrls.baseUrl + "{workflowSetId}/Workflows";
        public static WorkflowsByWorkflowSet(workflowSetId: string) {
            return ServiceUrls.WorkflowsByWorkflowSetUnsubstituted
                .replace("{workflowSetId}", workflowSetId);
        }

        public static SingleWorkflow(workflowSetId: string, workflowId: string) {
            return ServiceUrls.WorkflowsByWorkflowSet(workflowSetId) + "/" + workflowId;
        }

        public static AccountRoles: string = ServiceUrls.baseUrl + 'AccountRoles';
        public static Phases: string = ServiceUrls.baseUrl + 'Phases';
        public static Tasks: string = ServiceUrls.baseUrl + 'Tasks';

        public static TasksByWorkflowSet(workflowSetId: string): string {
            return `${ServiceUrls.baseUrl}${workflowSetId}/Tasks`;
        }

        public static Task(workflowSetId: string, taskId: string): string {
            return `${ServiceUrls.baseUrl}${workflowSetId}/Tasks/${taskId}`;
        }

        public static Phase(workflowSetId: string, phaseId: string): string {
            return `${ServiceUrls.baseUrl}${workflowSetId}/Phases/${phaseId}`;
        }

        public static WorkflowSets: string = ServiceUrls.baseUrl + 'WorkflowSets';
    }

    constructor(
        private http: HttpClient,
        protected globalMessages: GlobalMessages,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    private getAccountIdQueryParams(accountId: string): HttpParams {
        return new HttpParams()
            .set("accountid", ModelUtils.getIdFromDomainId(accountId));
    }

    refreshAccount(context: AccountContext, accountId: string) {
        this.loadWorkflows(context, accountId);
        this.loadTasks(context, accountId, true);
        this.loadRoles(context, accountId);
    }

    restartWorkflow(context: AccountContext, workflowId: string, workflowSetId: string): Observable<Response> {
        const url = `${this.serviceUrls.WorkflowsByWorkflowSet(workflowSetId)}/${workflowId}/Restart`
        const request = this.http.put<any>(url, null).pipe(share());

        this.handleNormalPostPutRequest(Workflow, request, context);
        return request;
    }

    loadWorkflow(context: AccountContext, workflowId: string, workflowSetId: string): Observable<Response> {
        const url = `${this.serviceUrls.WorkflowsByWorkflowSet(workflowSetId)}/${workflowId}`;
        const request = this.http.get<any>(url).pipe(share());

        this.handleNormalGetRequest(Workflow, request, context);
        return request;
    }

    loadWorkflows(context: AccountContext, accountId: string): Observable<Response> {
        const params = this.getAccountIdQueryParams(accountId);
        const request = this.http.get<any>(this.serviceUrls.baseUrl, { params }).pipe(share());

        this.handleNormalGetRequest(Workflow, request, context);
        return request;
    }

    startWorkflow(context: AccountContext, userContext: UserContext, startWorkflowDTO: StartWorkflowDTO): Observable<Response> {

        const url = this.serviceUrls.baseUrl;
        let params = new HttpParams();
        params = params.set('browserMode', true);

        const request = this.http.put<any>(url, startWorkflowDTO, { params })
            .pipe(share());

        request.subscribe({
            next: response => {
                if (userContext) {
                    const responseClone = _.cloneDeep(response);
                    userContext.loadApiResponseModels(Workflow, responseClone);
                }
                context.loadApiResponseModels(Workflow, response);
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    getStartingWorkflowDefs(context: DataContext, programId?: string, proposedEffectiveDate?: ContractDate): Observable<Response> {
        const params = new HttpParams()
            .set("programId", programId)
            .set("proposedEffectiveDate", proposedEffectiveDate.toISOString());

        const request = this.http.get<any>(this.serviceUrls.baseUrl + 'Starting',
            { params }).pipe(share());
        return request;
    }

    getWorkflowDefsForWorkflowSet(workflowSetId: string, accountId: string): Observable<Response> {
        var url = this.serviceUrls.WorkflowSets + '/' + workflowSetId + '/Available?accountId=' + accountId;
        const request = this.http.get<any>(url).pipe(share());
        return request;
    }

    retrieveRevisionForWorkflow(workflowSetId: string, workflowId: string, context: AccountContext): Observable<Response> {
        var url = this.serviceUrls.SingleWorkflow(workflowSetId, workflowId) + '/Revision';
        const request = this.http.get<any>(url).pipe(share());

        this.handleNormalGetRequest(ProgramRevision, request, context);
        return request;
    }

    updateWorkflowEffectiveDate(workflowSetId: string, workflowId: string,
        effectiveDate: ContractDate, context: AccountContext): Observable<Response> {
        var url = this.serviceUrls.WorkflowsByWorkflowSet(workflowSetId) + "/" + workflowId + '/EffectiveDate';
        var dto: ContractDateDTO = {
            EffectiveDate: effectiveDate
        };
        const request = this.http.put<any>(url, dto).pipe(share());
        this.handleNormalGetRequest(Workflow, request, context);
        return request;
    }

    loadTasks(context: AccountContext, accountId: string, isInteractive: boolean): Observable<Response> {
        const params = this.getAccountIdQueryParams(accountId);        
        const interactiveHeader = this.getUserInteractionHeader(isInteractive);
        const request = this.http.get<any>(this.serviceUrls.Tasks, { headers: interactiveHeader, params }).pipe(share());
        this.handleNormalGetRequest(Task, request, context);
        return request;
    }

    loadRoles(context: AccountContext, accountId: string): Observable<Response> {
        const params = new HttpParams().set("accountId", accountId);
        const request = this.http.get<any>(this.serviceUrls.AccountRoles,
            { params }).pipe(share());
        this.handleNormalGetRequest(AccountRole, request, context);
        return request;
    }

    updatePhase(phase: Phase, context: AccountContext): void {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId =>
            this.http.put<any>(this.serviceUrls.Phase(workflowSetId, phase.Id), phase.serialize()));
        this.handleNormalPostPutRequest(Phase, request, context);
    }

    // TODO: Change request to PATCH when SM-409 has been resolved. Patch is not working.
    updateTask(task: Task, context: AccountContext): void {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId =>
            this.http.put<any>(this.serviceUrls.Task(workflowSetId, task.Id), task.serialize()));
        this.handleNormalPostPutRequest(Task, request, context);
    }

    executeAction(taskId: string, content: TaskExecuteDTO, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.Task(workflowSetId, taskId) + '/Execute';
            return this.http.put<any>(url, content);
        }).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);

        return request;
    }

    stopExecutingTask(taskId: string, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.Task(workflowSetId, taskId) + '/StopExecuting';
            return this.http.put<any>(url, null);
        }).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);

        return request;
    }

    assignAndUnlockTask(assigneeId: string, taskId: string, Comment, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.Task(workflowSetId, ModelUtils.getIdFromDomainId(taskId)) + '/Assign/'
                + ModelUtils.getIdFromDomainId(assigneeId);
            return this.http.put<any>(url, { Comment })
        }).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);

        return request;
    }

    unlockTask(taskId: string, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + '/Unlock';
            return this.http.put<any>(url, [taskId]);
        }).pipe(share());
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    createTask(dto: TaskCreateDTO, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId);
            return this.http.post<any>(url, dto);
        }).pipe(share());
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    editTask(taskId: string, dto: TaskCreateDTO, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.Task(workflowSetId, taskId);
            return this.http.put<any>(url, dto);
        }).pipe(share());
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    completeTask(taskId: string, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + '/Complete'
            return this.http.put<any>(url, [taskId]);
        }).pipe(share());
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request
    }

    redoTask(taskId: string, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.Task(workflowSetId, taskId) + '/Redo';
            return this.http.put<any>(url, null);
        }).pipe(share());
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    lockTasks(tasks: Task[], context: AccountContext, workflowSetId = null) {
        let taskIds: string[] = _.map(tasks, (task) => task.Id);
        return this.lockTasksByIds(taskIds, context, workflowSetId);
    }

    lockTasksByIds(taskIds: string[], context: AccountContext, workflowSetId = null) {
        let request: Observable<any>;
        if (!workflowSetId) {
            request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
                const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + "/Lock";
                return this.http.put<any>(url, taskIds);
            }).pipe(share());
        } else {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + "/Lock";
            request = this.http.put<any>(url, taskIds).pipe(share());
        }

        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    unlockTasksByIds(taskIds: string[], context: AccountContext, workflowSetId = null) {
        let request: Observable<any>;
        if (!workflowSetId) {
            request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
                const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + "/Unlock";
                return this.http.put<any>(url, taskIds);
            }).pipe(share());
        } else {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + "/Unlock";
            request = this.http.put<any>(url, taskIds).pipe(share());
        }

        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    stealTaskLock(taskId: string, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + '/Lock/' + taskId;
            return this.http.put<any>(url, null);
        }).pipe(share())
        request.subscribe({
            next: response => {
                context.loadApiResponseModels(Task, response);

                _.forEach(response.Errors, msg => {
                    this.globalMessages.Add(new AppMessage(msg, 'danger', 5000));
                });
            },
            error: error => {
                this.handleError(error);
            }
        });
        return request;
    }

    reloadTask(url: string, context: AccountContext): void {
        let request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(Task, request, context);
    }

    retrieveTask(taskId: string, context: AccountContext): Observable<Response> {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.Task(workflowSetId, taskId);
            return this.http.get<any>(url);
        }).pipe(share());

        this.handleNormalGetRequest(Task, request, context);

        return request;
    }

    // This method requires the calling party to handle the response
    pollTask(url: string): Observable<Response> {
        let request = this.http.get<any>(ApiManagementService.transformUrl(url));
        return request;
    }

    waiveTask(taskId: string, waiveJournalEntry: AccountJournalEntry, context: AccountContext) {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + "/" + taskId + "/Waive";
            const params = new HttpParams().set("waiveJournalEntryId", waiveJournalEntry.Id);
            return this.http.put<any>(url, null, { params });
        }).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);
        return request;
    }

    undoWaivedTask(taskId: string, context: AccountContext) {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            const url = this.serviceUrls.TasksByWorkflowSet(workflowSetId) + "/" + taskId + "/UndoWaive";
            return this.http.put<any>(url, null);
        }).pipe(share());
        this.handleNormalPostPutRequest(Task, request, context);
        return request;
    }

    deferTask(taskId: string, DeferTime: Date, Comment: string, context: AccountContext) {
        const request = this.makeRequestWithWorkflowSet(context, workflowSetId => {
            // Convert to ISO string for API request
            const url = this.serviceUrls.Task(workflowSetId, taskId) + "/Defer";
            return this.http.put<any>(url, {
                DeferTime,
                Comment
            });
        }).pipe(share());

        this.handleNormalPostPutRequest(Task, request, context);

        return request;
    }

    private makeRequestWithWorkflowSet<T>(context: AccountContext, requestDelegate: (workflowSetId: string) => ObservableInput<T>): Observable<T> {
        return context.currentWorkflowSetId.pipe(
            take(1),
            mergeMap((workflowSetId: string) => requestDelegate(workflowSetId)));
    }
}

export interface TaskExecuteDTO {
    Action: TaskActionDTO;
}

export interface TaskActionDTO {
    TaskType: "TaskRatingSystem" | "TaskCorrespondence";
    Action: string;
}

export interface TaskRatingActionDTO extends TaskActionDTO {
    TaskType: "TaskRatingSystem";
    WorkflowNotes?: string;
    CommitOnSuccess? : boolean;
}

export interface TaskCorrespondenceActionDTO extends TaskActionDTO {
    TaskType: "TaskCorrespondence",
    To?: string,
    Cc?: string,
    Bcc?: string,
    Subject?: string,
    Body?: string
}

export interface StartWorkflowDTO {
    AccountId: string;
    WorkflowDefId: string;
    WorkflowSetId?: string;
    ProgramId: string;
    EffectiveDate: ContractDate;
    WorkflowNotes: string;
    Products: StartWorkflowDTOProduct[];
}

export interface StartWorkflowDTOProduct {
    ProductId: string;
    TransactionTypeId?: string;
    Selected: boolean;
}

export interface ContractDateDTO {
    EffectiveDate: ContractDate;
}

export interface TaskCreateDTO {
    Name: string;
    Instructions: string;
    WorkflowId: string;
    PhaseId: string;
    ProgramId: string;
    RequiresApproval: boolean;
    DeferTime: Date;
    DeferComment: string;
    DelegateAssignableId: string;
}
