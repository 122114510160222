import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import _ from 'lodash';
import { BehaviorSubject, Observable, Subject, of, AsyncSubject } from 'rxjs';
import { share } from 'rxjs/operators';

import { BaseService } from '@Services/base-service';
import { GlobalMessages } from '@Services/global-messages';
import { LoadingService } from '@Services/loading-service';
import { LoggingService } from '@Services/logging-service';

import { Document } from '@Core/CodeGen/Models/area.models';
import { AccountRole, Area, Company, CustomTable, CustomTableRow, DocumentCategory, EmailTemplate,
    IProducer, Producer, Product, Program, ProgramRevision, SecurityRole, SMIdentity, TenantUser,
    TransactionType, WorkGroup, Webhook } from '@Core/CodeGen/Models/configuration.models';
import { DataContext } from '@Core/Lib/Contexts/data-context';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { ITypedServiceResponse } from '@Core/Lib/model';
import { ModelUtils } from '@Core/Lib/Utils/model-utils';

import { FeatureFacetViewModel } from '@Shared/Components/sm-feature-facets/feature-facet.model';


class Response extends HttpResponse<any> { }

@Injectable()
export class TenantTenantService
    extends BaseService {

    private serviceUrls = class ServiceUrls {
        private static replaceValues(url: string, baseId: string = null): string {
            return url
                .replace("{BaseId}", baseId);
        }
        private static baseUrl: string = BaseService.baseUrl + '/';
        public static Tenant: string = ServiceUrls.baseUrl + 'Tenant/';

        public static ProgramsBase: string = ServiceUrls.baseUrl + "Programs/"
        public static Programs: string = ServiceUrls.Tenant + 'Programs/';
        public static SecurityRoles: string = ServiceUrls.Tenant + 'SecurityRoles';
        public static FeatureFacets: string = ServiceUrls.Tenant + 'FeatureFacets';
        public static Users: string = ServiceUrls.Tenant + 'Users';
        public static Identities: string = ServiceUrls.Tenant + 'Identities';
        public static Companies: string = ServiceUrls.Tenant + 'Companies';
        public static Producers: string = ServiceUrls.Tenant + 'Producers';
        public static CompanyProducts: string = 'Products';
        public static Products: string = ServiceUrls.Tenant + 'Products';
        public static TransactionTypes: string = ServiceUrls.Tenant + 'Transactions';
        public static TransactionInsPolicyConfigs: string = ServiceUrls.Tenant + 'TransactionInsPolicyConfigs';
        public static DocumentCategories: string = ServiceUrls.Tenant + 'DocumentCategories';
        public static Documents: string = ServiceUrls.Tenant + 'Documents';
        public static Areas: string = ServiceUrls.Tenant + 'Areas';
        public static ProgramRevisions: string = ServiceUrls.Tenant + "Revisions";
        public static CustomTables: string = ServiceUrls.Tenant + "Tables";
        public static CustomTableRows: string = ServiceUrls.Tenant + "Rows";
        public static Data: string = ServiceUrls.baseUrl + 'Data';
        public static Workgroups: string = ServiceUrls.baseUrl + 'Tenant/Workgroups';
        public static EmailTemplates: string = ServiceUrls.Tenant + "EmailTemplates";
        public static ResendUserInvites: string = ServiceUrls.Users + '/ResendInvite';
        public static ProducerSearchTemplate: string = ServiceUrls.Producers + '/Search';

        public static accountRoles: string = ServiceUrls.Tenant + 'AccountRoles';

        public static CompaniesUnsubstituted: string = ServiceUrls.Companies + '/{BaseId}/';
        public static CompaniesPost(companyId: string): string {
            return (this.replaceValues(ServiceUrls.CompaniesUnsubstituted, companyId));
        }

        public static ProductsUnsubstituted: string = ServiceUrls.CompanyProducts + '/{BaseId}/';
        public static ProductsPost(productId: string): string {
            return (this.replaceValues(ServiceUrls.ProductsUnsubstituted, productId));
        }

        public static RemoveUserFromTenantUnsubstituted: string = ServiceUrls.Users + '/{BaseId}/Remove';
        public static RemoveUserFromTenant(userId: string): string {
            return (this.replaceValues(ServiceUrls.RemoveUserFromTenantUnsubstituted, userId));
        }

        public static EnableUserForTenantUnsubstituted: string = ServiceUrls.Users + '/{BaseId}/Enable';
        public static EnableUserForTenant(userId: string): string {
            return (this.replaceValues(ServiceUrls.EnableUserForTenantUnsubstituted, userId));
        }

        public static customTableRow(rowId): string {
            return ServiceUrls.CustomTableRows + "/" + rowId;
        }

        public static customTableRowByTable(tableId: string): string {
            return ServiceUrls.CustomTables + "/" + tableId + "/Rows";

        }

        private static baseWebhooksUrl: string = BaseService.baseUrl + '/Tenant/Webhooks'

        public static Webhooks(): string {
            return ServiceUrls.baseWebhooksUrl;
        }

        public static WebhooksById(webhookId: string): string {
            return `${ServiceUrls.baseWebhooksUrl}/${webhookId}`;
        }
    }

    private loadingSecurityRoles: boolean;
    private loadingUsers: boolean;
    public companiesLoaded: boolean;
    public producersLoaded: boolean;
    public docCategoriesLoaded: boolean;

    constructor(private http: HttpClient,
        protected globalMessages: GlobalMessages,
        private tenantContext: TenantContext,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }

    public loadPrograms(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Programs).pipe(share());
        this.handleNormalGetRequest(Program, request, this.tenantContext);
        return request;
    }

    public loadProgramRevisions(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.ProgramRevisions).pipe(share());
        this.handleNormalGetRequest(ProgramRevision, request, this.tenantContext);
        return request;
    }

    public loadProgramValidationMessages(programId: string): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.ProgramsBase + programId + "/ValidationMessages").pipe(share());
        return request;
    }

    public loadProducers(): Observable<Response> {
        var self = this;
        const request = this.http.get<any>(this.serviceUrls.Producers).pipe(share());
        this.handleNormalGetRequest(Producer, request, this.tenantContext);
        return request;
    }

    public loadProducer(producerIdOrCode: string): Observable<Response> {
        var self = this;
        const request = this.http.get<any>(`${this.serviceUrls.Producers}/${producerIdOrCode}`).pipe(share());
        this.handleNormalGetRequest(Producer, request, this.tenantContext);    
        return request;
    }

    public loadCompanies(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Companies).pipe(share());
        this.handleNormalGetRequest(Company, request, this.tenantContext);
        return request;
    }

    public loadProducts(): Observable<ITypedServiceResponse<Product[]>> {
        const request = this.http.get<any>(this.serviceUrls.Products).pipe(share());
        this.handleNormalGetRequest(Product, request, this.tenantContext);
        return request;
    }

    private hasProduct(productId: string): boolean {
        return this.tenantContext.has(productId);
    }

    public getProduct(productId: string): Observable<Product> {
        const productDomainId = ModelUtils.createDomainId(new Product(), productId);

        if (this.hasProduct(productDomainId)) {
            return of(this.tenantContext.get(productDomainId) as Product);
        }
        else {
            const request = this.http.get<any>(this.serviceUrls.Products  + '/' + productId).pipe(share());
            this.handleNormalGetRequest(Product, request, this.tenantContext);
            return request;
        };
    }

    // #region Transactions
    public loadTransactionTypes(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.TransactionTypes).pipe(share());
        this.handleNormalGetRequest(TransactionType, request, this.tenantContext);
        return request;
    }

    public loadTransactionType(id: string): Observable<Response> {
        const url = this.serviceUrls.TransactionTypes + "/" + id;
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(TransactionType, request, this.tenantContext);
        return request;
    }

    // #endregion Transactions

    public loadUsers(): Observable<Response> {
        this.loadingUsers = true;
        var self = this;

        let request = this.http.get<any>(this.serviceUrls.Users).pipe(share());

        request.subscribe({
            next: response => {
                self.tenantContext.loadApiResponseModels(SMIdentity, response);
                self.loadingUsers = false;
            },
            error: error => {
                self.handleError(error)
                self.loadingUsers = false;
            }
        });

        return request;
    }

    public getFeatureFacets(): Observable<FeatureFacetViewModel[]> {
        var observable : Subject<FeatureFacetViewModel[]> = new AsyncSubject<FeatureFacetViewModel[]>();

        this.http.get<any>(this.serviceUrls.FeatureFacets).subscribe({
            next: response => {
                observable.next(response.Content);
                observable.complete();
            },
            error: error => {
                this.handleError(error);
                observable.error(error);
            }
        });

        return observable;
    }

    public loadSecurityRoles(): void {
        this.loadingSecurityRoles = true;
        this.http.get<any>(this.serviceUrls.SecurityRoles).subscribe({
            next: response => {
                this.tenantContext.loadApiResponseModels(SecurityRole, response);
                this.loadingSecurityRoles = false;
            },
            error: error => {
                this.handleError(error)
                this.loadingSecurityRoles = false;
            }
        });
    }

    public getSecurityRole(id: string): Observable<SecurityRole> {
        var self = this;
        var findRole = function (): SecurityRole {
            return _.find(self.tenantContext.getStore(new SecurityRole()).values.getValue(), { Id: id }) as SecurityRole;
        }

        // Are we still loading?
        if (this.loadingSecurityRoles) {
            let source = new Subject<SecurityRole>();

            this.tenantContext.getStore(new SecurityRole()).values.subscribe(
                () => {
                    var r = findRole();
                    if (r) {
                        source.next(r);
                    }
                    //TODO: Clean up the subscription
                }
            );

            return source;
        }

        let role = findRole();

        // is the role in our store?
        if (role) {
            return new BehaviorSubject<SecurityRole>(role);
        }
        // id is invalid, so return null
        else {
            return new BehaviorSubject<SecurityRole>(null);
        }
    }

    public getUser(id: string): Observable<TenantUser> {
        let self = this;

        // Are we still loading?
        if (this.loadingUsers) {
            let source = new Subject<TenantUser>();

            this.tenantContext.getStore(new TenantUser()).values.subscribe(
                () => {
                    let r = self.findUser(id);
                    if (r) {
                        source.next(r);
                    }
                    //TODO: Clean up the subscription
                }
            );

            return source;
        }

        let user = this.findUser(id);

        // is the role in our store?
        if (user) {
            return new BehaviorSubject<TenantUser>(user);
        }
        // id is invalid, so return null
        else {
            return new BehaviorSubject<TenantUser>(null);
        }
    }

    public findUser(id: string): TenantUser {
        return _.find(this.tenantContext.getStore(new TenantUser()).values.getValue(), { Id: id }) as TenantUser;
    }

    // POST
    public createSecurityRole(securityRole: SecurityRole, handleErrors: boolean = true): Observable<Response> {

        let request = this.http.post<any>(this.serviceUrls.SecurityRoles, securityRole.serialize()).pipe(share());

        request.subscribe({
            next: response => {
                new SecurityRole().deserialize(response.Content, this.tenantContext);
            },
            error: error => handleErrors ? this.handleError(error) : null
        });

        return request;
    }

    // PUT
    public updateSecurityRole(securityRole: SecurityRole, handleErrors: boolean = true): Observable<Response> {

        let savableRole = securityRole.serialize();

        let request = this.http.put<any>(this.serviceUrls.SecurityRoles + '/' + securityRole.Id, savableRole).pipe(share());

        request.subscribe({
            next: response => new SecurityRole().deserialize(response.Content, this.tenantContext),
            error: error => handleErrors ? this.handleError(error) : null
        });

        return request;
    }

    // PUT
    public updateUser(user: TenantUser, handleErrors: boolean = true): Observable<Response> {

        let userForSave = user.serialize();

        let request = this.http.put<any>(this.serviceUrls.Users + '/' + user.Id, userForSave).pipe(share());

        request.subscribe({
            next: response => this.tenantContext.loadApiResponseModels(TenantUser, response),
            error: error => handleErrors ? this.handleError(error) : null
        });

        return request;
    }

    // PUT
    public updateProduct(product: Product, handleErrors: boolean = true): Observable<Response> {

        let productForSave = product.serialize();

        let request = this.http.put<any>(this.serviceUrls.Products + '/' + productForSave.Id, productForSave).pipe(share());

        request.subscribe({
            next: response => this.tenantContext.loadApiResponseModels(Product, response),
            error: error => handleErrors ? this.handleError(error) : null
        });

        return request;
    }

    // PUT
    public updateCompany = (company: Company, handleErrors: boolean = true): Observable<Response> => {

        let companyForSave = company.serialize();

        let request = this.http.put<any>(this.serviceUrls.Companies + '/' + companyForSave.Id, companyForSave).pipe(share());

        request.subscribe({
            next: response => this.tenantContext.loadApiResponseModels(Company, response),
            error: error => handleErrors ? this.handleError(error) : null
        });

        return request;
    }

    // PUT
    public updateProducer(producer: Producer, handleErrors: boolean = true): Observable<Response> {

        let producerForSave = producer.serialize();

        let request = this.http.put<any>(this.serviceUrls.Producers + '/' + producerForSave.Id, producerForSave).pipe(share());

        request.subscribe({
            next: response => this.tenantContext.loadApiResponseModels(Producer, response),
            error: error => handleErrors ? this.handleError(error) : null
        });

        return request;
    }

    convertUserToSavableUser(user: TenantUser) {
        var savableUser = {
            Key: user.Id,
            Email: user.Email,
            FirstName: user.FirstName,
            MiddleName: user.MiddleName,
            LastName: user.LastName,
            NameSuffix: user.NameSuffix,
            Phone: user.Phone,
            Title: user.Title,
            Honorific: user.Honorific,
            FillsSecurityRole: user.FillsSecurityRole().map(secRole => secRole.Id),
        };

        return savableUser;
    }


    //#region Users
    public inviteUsers(users: TenantUser[])
        : Observable<ITypedServiceResponse<TenantUser[]>> {
        var serializedUsers = _.map(users, e => e.serialize());
        let request = this.http.post<any>(this.serviceUrls.Users, serializedUsers).pipe(share());

        request.subscribe({
            next: response => {
                this.tenantContext.loadApiResponseModels(TenantUser, response);
            },
            error: error => null // without this line of code, InviteUsers.Component.inviteUsers' error
            // channel won't be triggered, and error reponse will not be displayed.
        });
        return request;
    }

    public resendUserInvite(users: { Email: string, FillsSecurityRole: any[] }[]): Observable<Response> {
        let request = this.http.post<any>(this.serviceUrls.ResendUserInvites, users).pipe(share());
        this.handleRequestWithoutResponse(request);
        return request;
    }

    public removeUserFromTenant(userId: string): Observable<Response> {
        let request = this.http.put<any>(this.serviceUrls.RemoveUserFromTenant(userId), null).pipe(share());
        this.handleNormalPostPutRequest(TenantUser, request, this.tenantContext);
        return request;
    }

    public enableUserForTenant(userId: string): Observable<Response> {
        let request = this.http.put<any>(this.serviceUrls.EnableUserForTenant(userId), null).pipe(share());
        this.handleNormalPostPutRequest(TenantUser, request, this.tenantContext);
        return request;
    }
    //#endregion Users

    // POST
    public addCompany = (company: Company): Observable<Response> => {
        let request = this.http.post<any>(this.serviceUrls.Companies, company.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Company, request, this.tenantContext);
        return request;
    }

    // POST
    public addProducer(producer: Producer): Observable<Response> {
        let request = this.http.post<any>(this.serviceUrls.Producers, producer.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Producer, request, this.tenantContext);
        return request;
    }

    public getProducer(producerIdOrCode: string, handleErrors: boolean = true): Observable<ITypedServiceResponse<IProducer>> {
        let request = this.http.get<ITypedServiceResponse<IProducer>>(`${this.serviceUrls.Producers}/${producerIdOrCode}`).pipe(share());

        request.subscribe({
            next: response => { },
            error: error => handleErrors ? this.handleError(error) : null
        });
        return request;
    }

    public searchProducers(searchString: string, tenantContext: TenantContext): Observable<Response> {
        var url = this.serviceUrls.ProducerSearchTemplate;
        let request = this.http.get<any>(url + "?searchTerms=" + encodeURIComponent(searchString)).pipe(share());
        this.handleRequestWithoutResponse(request);
        return request;
    }

    // POST
    public addProduct(product: Product): Observable<Response> {
        const url = this.serviceUrls.Products;
        let request = this.http.post<any>(url, product.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Product, request, this.tenantContext);
        return request;
    }

    /**
     * @param newDocumentCategory
     * @returns {Observable<Response>}
     */
    public addDocumentCategory(docCategory: DocumentCategory): Observable<Response> {
        const request = this.http.post<any>(this.serviceUrls.DocumentCategories, docCategory.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DocumentCategory, request, this.tenantContext);
        return request;
    }

    /**
     * @param docCategory
     * @param context
     */
    public updateDocumentCategory(docCategory: DocumentCategory, context: DataContext = this.tenantContext): Observable<Response> {
        const request = this.http.put<any>(`${this.serviceUrls.DocumentCategories}/${docCategory.Id}`, docCategory.serialize()).pipe(share());
        this.handleNormalPostPutRequest(DocumentCategory, request, context);
        return request;
    }

    /**
     * @param docCategory
     * @param context
     */
    public deleteDocumentCategory(docCategory: DocumentCategory, context: DataContext = this.tenantContext): Observable<Response> {
        const request = this.http.delete<any>(this.serviceUrls.DocumentCategories).pipe(share());
        this.handleNormalDeleteRequest(DocumentCategory, request, docCategory, context);
        return request;
    }

    // #region Account Roles

    public loadAccountRoles(context: DataContext = this.tenantContext): Observable<Response> {
        var url = this.serviceUrls.accountRoles;
        var request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(AccountRole, request, context);
        return request;
    }

    public addAccountRole(accountRole: AccountRole, context: TenantContext): Observable<Response> {
        const url = this.serviceUrls.accountRoles;
        const request = this.http.post<any>(url, accountRole.serialize()).pipe(share());
        this.handleNormalPostPutRequest(AccountRole, request, context);
        return request;
    }

    public editAccountRole(accountRole: AccountRole, context: TenantContext): Observable<Response> {
        const url = this.serviceUrls.accountRoles + '/' + accountRole.Id;
        const request = this.http.put<any>(url, accountRole.serialize()).pipe(share());
        this.handleNormalPostPutRequest(AccountRole, request, context);
        return request;
    }

    // #endregion Account Roles

    // #region Work Groups

    public loadWorkGroups(context: DataContext = this.tenantContext): Observable<Response> {
        const url = this.serviceUrls.Workgroups;
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(WorkGroup, request, context);
        return request;
    }

    public loadWorkGroup(id: string, context: DataContext = this.tenantContext): Observable<Response> {
        const url = this.serviceUrls.Workgroups + "/" + id;
        const request = this.http.get<any>(url).pipe(share());
        this.handleNormalGetRequest(WorkGroup, request, context);
        return request;
    }

    public addWorkGroup(workGroup: WorkGroup, context: TenantContext): Observable<Response> {
        const url = this.serviceUrls.Workgroups;
        const request = this.http.post<any>(url, workGroup.serialize()).pipe(share());
        this.handleNormalPostPutRequest(WorkGroup, request, context);
        return request;
    }

    public updateWorkGroup(workGroup: WorkGroup, context: TenantContext): Observable<Response> {
        const url = this.serviceUrls.Workgroups + '/' + workGroup.Id;
        const request = this.http.put<any>(url, workGroup.serialize()).pipe(share());
        this.handleNormalPostPutRequest(WorkGroup, request, context);
        return request;
    }

    // #endregion Work Groups

    //#region Areas

    public loadAreas(context: DataContext = this.tenantContext): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Areas).pipe(share());
        this.handleNormalGetRequest(Area, request, context);
        return request;
    }

    //#endregion

    //#region Settings
    public loadCustomTables(context: DataContext = this.tenantContext): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.CustomTables).pipe(share());
        this.handleNormalGetRequest(CustomTable, request, context);
        return request;
    }

    public loadCustomTableRows(context: DataContext = this.tenantContext): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.CustomTableRows).pipe(share());
        this.handleNormalGetRequest(CustomTableRow, request, context);
        return request;
    }

    public loadCustomTableRowsForTable(tableId: string, context: DataContext = this.tenantContext): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.customTableRowByTable(tableId)).pipe(share());
        this.handleNormalGetRequest(CustomTableRow, request, context);
        return request;
    }

    //#endregion

    //#region EmailTemplates
    public loadEmailTemplates(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.EmailTemplates);
        this.handleNormalGetRequest(EmailTemplate, request, this.tenantContext);
        return request;
    }

    public addEmailTemplate(emailTemplate: EmailTemplate): Observable<Response> {
        const request = this.http.post<any>(this.serviceUrls.EmailTemplates, emailTemplate.serialize()).pipe(share());
        this.handleNormalPostPutRequest(EmailTemplate, request, this.tenantContext);
        return request;
    }

    public updateEmailTemplate(emailTemplate: EmailTemplate): Observable<Response> {
        var url = this.serviceUrls.EmailTemplates + '/' + emailTemplate.Id;
        const request = this.http.put<any>(url, emailTemplate.serialize()).pipe(share());
        this.handleNormalPostPutRequest(EmailTemplate, request, this.tenantContext);
        return request;
    }


    //#endregion EmailTemplates

    //#region Webhooks

    public loadWebhooks(): Observable<Response> {
        const request = this.http.get<any>(this.serviceUrls.Webhooks()).pipe(share());
        this.handleNormalGetRequest(Webhook, request, this.tenantContext);
        return request;
    }

    public createWebhook(webhook: Webhook): Observable<Response> {
        const request = this.http.post<any>(this.serviceUrls.Webhooks(), webhook.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Webhook, request, this.tenantContext);
        return request;
    }

    public regenerateWebhookSigningKey(webhook: Webhook): Observable<Response> {
        var url = this.serviceUrls.WebhooksById(webhook.Id) + "/RegenerateSigningKey";
        const request = this.http.put<any>(url, null).pipe(share());
        this.handleNormalPostPutRequest(Webhook, request, this.tenantContext);
        return request;
    }

    public editWebhook(webhook: Webhook): Observable<Response> {
        const request = this.http.put<any>(this.serviceUrls.WebhooksById(webhook.Id), webhook.serialize()).pipe(share());
        this.handleNormalPostPutRequest(Webhook, request, this.tenantContext);
        return request;
    }

    public deleteWebhook(webhook: Webhook): Observable<Response> {
        const request = this.http.delete<any>(this.serviceUrls.WebhooksById(webhook.Id)).pipe(share());
        this.handleNormalDeleteRequest(Webhook, request, webhook, this.tenantContext);
        return request;
    }

    public updateWebhookActivity(updateDTO: UpdateWebhookActivityDTO): Observable<Response> {
        const request = this.http.put<any>(`${this.serviceUrls.Webhooks()}/UpdateActivity`, updateDTO).pipe(share());
        return request;
    }

    public loadWebhookLogs(handleErrors: boolean = true, 
        eventDateFilter: string = "Today",
        numActivitiesString: string = "50",
        webhookName: string = null,
        eventName: string = null,
        action: string = null,
        status: string = null,
        area: string = null,
        accountNum: string = null,
        sentDateFilter: string = null,
        completedDateFilter: string = null,
        webhookId: string = null,
        accountId: string = null,
        workflowId: string = null): Observable<Response> {
        var url = this.serviceUrls.Webhooks() + "/Activities";
        let params = new HttpParams();

        if (eventDateFilter != null) {
            params = params.set("eventDateFilter", eventDateFilter);
        }

        if (numActivitiesString != null) {
            params = params.set("numActivities", numActivitiesString.replace(/,/g, ''));
        }        

        if (webhookName != null) {
            params = params.set("webhookName", webhookName);
        }
        if (eventName != null) {
            params = params.set("eventName", eventName);
        }
        if (action != null) {
            params = params.set("action", action);
        }
        if (status != null) {
            params = params.set("status", status);
        }
        if (area != null) {
            params = params.set("area", area);
        }
        if (accountNum != null) {
            params = params.set("accountNum", accountNum);
        }
        if (sentDateFilter != null) {
            params = params.set("sentDateFilter", sentDateFilter);
        }        
        if (completedDateFilter != null) {
            params = params.set("completedDateFilter", completedDateFilter);
        }
        if (webhookId != null) {
            params = params.set("webhookId", webhookId);
        }
        if (accountId != null) {
            params = params.set("accountId", accountId);
        }
        if (workflowId != null) {
            params = params.set("workflowId", workflowId);
        }

        const request = this.http.get<any>(url, { params }).pipe(share());

        return request;
    }    

    //#endregion Webhooks
}

export interface IApiManagementInfo {
    apiDocumentationUrl: string,
    apiServiceUrl: string
}

export interface UpdateWebhookActivityDTO {
    EventId: string;
    Status: string;
    AcknowledgeActivity: boolean;
}
