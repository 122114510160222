import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NotificationService } from '@Services/notification-service';
import { UnityToast, UnityToastClickLinkEvent, UnityToastSubtitle, UnityToastType } from '../unity-toasts/unity-toast.component';
import { Notification, UserNotificationCenter } from '@Core/CodeGen/Models/configuration.models';
import { UserContext } from '@Core/Lib/Contexts/user-context';
import { RouteEntryItem, RouteUtils } from '@Core/Lib/Utils/route-utils';
import _ from 'lodash';
import { Router } from '@angular/router';
import { GuidReplacerPipe } from '@Work/Pipes/guid-replacer.pipe';
import { TenantAssignableService } from '@Services/tenant-assignable-service';
import { TenantTenantService } from '@Services/tenant-tenant-service';
import { AsyncPipe } from '@angular/common';
import { SmNomenclaturePipe } from '@Shared/Pipes/sm-nomenclature.pipe';
import { TenantContext } from '@Core/Lib/Contexts/tenant-context';
import { TenantService } from '@Core/CodeGen/Services/tenant.service';
import { AccountsService } from '@Core/CodeGen/Services/accounts.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TenantPermissions } from '@Core/CodeGen/tenant-permissions.enum';

@Component({
    selector: 'sm-toast-list',
    templateUrl: './sm-toast-list.component.html',
    styleUrls: ['./sm-toast-list.component.scss']
})
export class SmToastListComponent implements OnInit {
    public notifications: UnityToast[] = [];
    public showNotifications: boolean = false;
    private hasAccessToConfigure: boolean = false;

    private nomenclaturePipe: SmNomenclaturePipe;
    private guidReplacerPipe: GuidReplacerPipe;
    private asyncPipe: AsyncPipe;

    constructor(
        private service: NotificationService, 
        private userContext: UserContext,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private assignableService: TenantAssignableService,
        private tenantTenantService: TenantTenantService,
        private tenantContext: TenantContext,
        private tenantService: TenantService,
        private accountsService: AccountsService,
        private permissionsService: NgxPermissionsService,
        private destroyRef: DestroyRef) 
    { }

    ngOnInit() {
        this.asyncPipe = new AsyncPipe(this.cdRef);
        this.guidReplacerPipe = new GuidReplacerPipe(this.tenantContext, this.assignableService, this.tenantTenantService);
        this.nomenclaturePipe = new SmNomenclaturePipe(this.tenantContext);

        this.permissionsService.hasPermission([
            TenantPermissions.SystemAccess,
            TenantPermissions.Tenant,
            TenantPermissions.Programs,
        ]).then((result) => {
            this.hasAccessToConfigure = result;
        })

        this.userContext.getStore<UserNotificationCenter>(new UserNotificationCenter()).values
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(data => {
                if (!data?.length)
                    return;

                var userNotificationCenter = data[0] as UserNotificationCenter;
                // Only show if we want to enable notifications.
                // Also, check if we are already showing notifications so we don't duplicate
                if (userNotificationCenter.EnableInAppNotificationPopups && !this.showNotifications)
                    this.init();
        });
    }

    init() {
        this.showNotifications = true;

        this.service.last
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
            last => {
                if (last)
                    this.addNotification(last)
            }
        );
    }

    addNotification(notification: Notification) {
        var self = this;

        var toast = this.convertNotificationToUnityToast(notification)

        this.notifications.push(toast);
        this.cdRef.detectChanges();
        setTimeout(() => {
            self.closeAlert(toast);
        }, 5000);
    }

    onClickLink(e: UnityToastClickLinkEvent) {
        if (!e.Subtitle.IsLink)
            return;

        var notification = _.find(this.service.values.value, n => n.Id == e.Id);
        if (notification?.Domain == 'Area')
            this.accountsService.MarkNotificationsAsRead([e.Id], this.userContext);
        else if (notification?.Domain == 'Configuration')
            this.tenantService.MarkNotificationsAsRead([e.Id], this.userContext);

        var entries: RouteEntryItem[] = _.map(Object.getOwnPropertyNames(e.Subtitle.LinkIds), entityType => {
            var entityId = e.Subtitle.LinkIds[entityType];
            var entry: RouteEntryItem = {
                Type: entityType,
                Id: entityId
            };
            return entry;
        });
        var url = RouteUtils.BuildEntityRoute(e.Subtitle.Type, entries)
        this.router.navigate([url]);
    }

    closeAlert(notification: UnityToast) {
        const index: number = this.notifications.indexOf(notification);
        if (index != -1)
            this.notifications.splice(index, 1);
    }

    performAction(notification: UnityToast) {
        this.closeAlert(notification);
    }

    private convertNotificationToUnityToast(notification: Notification) {
        var lastSubtitleNum = _.max(_.map(notification.Subtitles, s => s.OrderNum));

        var programId = notification.ContextIds ? notification.ContextIds["Program"] : null;

        var subtitles =  _.map(notification.Subtitles, s => {
            var noLink = notification.Domain == 'Configuration' && !this.hasAccessToConfigure;

            var subtitle: UnityToastSubtitle = {
                Type: s.EntityType,
                DisplayType: this.asyncPipe.transform(this.nomenclaturePipe.transform(s.EntityType, programId)),
                Text: s.EntityText,
                IsLink: !noLink && lastSubtitleNum == s.OrderNum
            };

            if (subtitle.IsLink)
                subtitle.LinkIds = notification.ContextIds;
            
            return subtitle;
        });

        return new UnityToast(
            notification.Id,
            UnityToastType.Announcement, 
            this.asyncPipe.transform(this.guidReplacerPipe.transform(notification.Message)),
            subtitles,
            notification.Description
        );
    }
}