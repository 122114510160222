import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Area } from "@Core/CodeGen/Models/configuration.models";
import { TenantPermissions } from "@Core/CodeGen/tenant-permissions.enum";
import { TenantContext } from "@Core/Lib/Contexts/tenant-context";
import { UserContext } from "@Core/Lib/Contexts/user-context";
import { AuthService } from "@Services/auth-service";
import { TenantTenantService } from "@Services/tenant-tenant-service";
import { UserService } from "@Services/user-service";
import { PermissionsShared } from "@Shared/Directives/sm-permissions-shared.class";
import _ from "lodash";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'sm-select-area',
    templateUrl: './sm-select-area.component.html',
    styleUrls: ['./sm-select-area.component.scss']
})
export class SmSelectAreaComponent implements OnInit, OnDestroy {
    // We can display this component in a modal. If so, we will have our own button to select an area
    @Input() isModal: boolean;
    @Input() selectedAreaAliasOrName: string;
    @Input() areas: Area[] = [];

    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    private ngUnsubscribe: Subject<any> = new Subject();

    public TENANT_PERMISSIONS = TenantPermissions;
    public filteredAreas: Area[] = [];
    public selectedArea: Area;
    public areaHasBeenSelected = false;

    public allPermissions: string[] = [
        this.TENANT_PERMISSIONS.SystemAccess,
        this.TENANT_PERMISSIONS.Tenant,
        this.TENANT_PERMISSIONS.Programs
    ];
    public canAccessConfig: boolean = false;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private tenantTenantService: TenantTenantService,
        private userContext: UserContext,
        private tenantContext: TenantContext,
        private userService: UserService) {
    }

    ngOnInit() {
        if (!this.areas?.length) {
            this.tenantTenantService.loadAreas(this.tenantContext);
        }

        combineLatest([
            this.authService.authContext$,
            this.userService.Permissions,
            this.tenantContext.getStore(new Area()).values
        ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([authContext, grantedPermissions, allAreas]) => {
            var areasUserHasAccessTo = this.authService.getAvailableAreasForCurrentTenant();
            if (PermissionsShared.HasAll(grantedPermissions, this.allPermissions)) {
                this.canAccessConfig = true;
            }

            if (!this.areas?.length)
                this.areas = _.sortBy(allAreas, a => a.Order);

            this.autoSelectArea(areasUserHasAccessTo);
        });
    }

    public goToConfig() {
        this.router.navigate(["Conf"]);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    areaSelect() {
        this.areaSelected();
        
        this.authService.selectArea(this.selectedArea.Name).subscribe(() => {
            if (this.isModal) {
                this.userService.reset();
                this.userContext.clearAll(true);

                var view = this.router.url.split('/');
                this.router.navigate([view[1]]).then(() => {
                    window.location.reload();     
                });
            } else {
                this.router.navigate(['..'], { relativeTo: this.route });
            }
            
            this.userContext.quickDataLoaded = false;
            this.userContext.accountsLoaded = false;
        });
    }

    areaSelected() {
        // Mark that the area has been selected by the user, so don't auto select
        this.areaHasBeenSelected = true;
    }

    private autoSelectArea(areasUserHasAccessTo: string[]) {
        // Don't auto select if the area has already been picked by the user
        if (!this.areas?.length || this.areaHasBeenSelected)
            return;

        // Only show areas User has access to
        this.filteredAreas = _.map(areasUserHasAccessTo, aKey => _.find(this.areas, a => a.Name == aKey));
        this.filteredAreas = _.sortBy(this.filteredAreas, a => a.Order);

        if (!this.filteredAreas?.length || this.areaHasBeenSelected)
            return;
        else if (this.filteredAreas?.length === 1 && !this.canAccessConfig) {
            this.selectedArea = this.filteredAreas[0];
            this.areaSelect();
        }
        else {
            if (!this.selectedAreaAliasOrName) {
                let lastArea = this.filteredAreas[this.filteredAreas.length - 1];
                this.selectedAreaAliasOrName = lastArea.Alias ?? lastArea.Name;
            }

            this.selectedArea = _.find(this.filteredAreas, a => a.Alias == this.selectedAreaAliasOrName
                || a.Name == this.selectedAreaAliasOrName);
        }
    }
};