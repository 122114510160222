import { Component, EventEmitter, Input, Output } from "@angular/core";
import _ from "lodash";
import { NgxMaskService } from "ngx-mask";
import { FormatUtils } from "@Core/Lib/Utils/format-utils";

@Component({
    selector: 'sm-input-number',
    templateUrl: './sm-input-number.component.html'
})
export class SmInputNumberComponent {
    @Input() public id: string;
    @Input() public editModeClasses: string[];
    @Input() public value: string;
    @Input() public disabled: boolean;
    @Input() public numberOfDecimals: number;
    @Input() public readOnly: boolean;
    @Input() public displayAsPercentage: boolean;
    @Input() public displayNegativesInRed: boolean;

    @Output() public focusin: EventEmitter<any> = new EventEmitter();
    @Output() public focusout: EventEmitter<any> = new EventEmitter();
    @Output() public onChange: EventEmitter<any> = new EventEmitter();

    constructor(public maskService: NgxMaskService) {}

    public inputValue: string;
    public componentMask: string;
    public thousandSeparator: string;
    public decimalMarker: "," | ".";

    ngOnInit() {
        this.initialize();
    }

    ngOnChanges() {
        this.initialize();
    }

    initialize() {
        this.thousandSeparator = FormatUtils.getThousandSeparator();
        this.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";

        this.maskService.thousandSeparator = this.thousandSeparator;
        this.maskService.decimalMarker = this.decimalMarker;

        this.inputValue = SmInputNumberComponent.convertNumberBeforeMask(this.value, this.numberOfDecimals, this.displayAsPercentage, this.readOnly);

        var numberOfDecimals = this.numberOfDecimals;
        
        if (_.isNil(numberOfDecimals))
            numberOfDecimals = 3; 
        else if (this.displayAsPercentage) {
            numberOfDecimals = numberOfDecimals - 2;
            if (numberOfDecimals < 0)
                numberOfDecimals = 0;
        }

        this.componentMask = SmInputNumberComponent.getComponentMask(numberOfDecimals);
    }

    public static formatNumber(value: any, maskService: NgxMaskService, numDecimalPlaces: number, 
        displayAsPercentage: boolean): string {

        maskService.thousandSeparator = FormatUtils.getThousandSeparator();
        maskService.decimalMarker = FormatUtils.getDecimalMarker() as "," | ".";

        if (_.isNil(value) || value === '')
            return '';

        if (_.isNil(numDecimalPlaces))
            numDecimalPlaces = 3;

        var convertedDecimal = SmInputNumberComponent.convertNumberBeforeMask(value, numDecimalPlaces, displayAsPercentage, true);
        
        const formattedDecimal = maskService.applyMask(convertedDecimal, SmInputNumberComponent.getComponentMask(numDecimalPlaces));
        return displayAsPercentage ? formattedDecimal + '%' : formattedDecimal;
    }

    public static convertNumberBeforeMask(value: any, numDecimalPlaces: number, displayAsPercentage: boolean, 
        readOnly: boolean): string {
        
        // Note: The value passed in to this component should ALWAYS be in the en-US culture, 
        // using a comma as a thousand separator and a period as the decimal (because that is how it will be stored in our DB)
        // This component should translate from that into the correct expected culture (removing any thousand separators).
        // For example, if we get 1,234.56 as the input, but are in the german culture, it should output '1234,56'. 

        var decimalMarker = FormatUtils.getDecimalMarker();

        if (_.isNil(value) || value === '')
            return null;

        let convertedDecimal = displayAsPercentage ? 
            parseFloat((parseFloat(value) * 100).toFixed(numDecimalPlaces)).toString() :
            value.toString();

        // Remove the , as it should be the thousand separator
        convertedDecimal = convertedDecimal.trimEnd("0").split(',').join('')

        // ONLY for readonly mode, replace the '.' with the current decimal marker (which may still be a '.')
        // I don't fully understand why we have to do this, but the input control doesn't work well when
        // updating the value when we do this
        if (readOnly) {
            convertedDecimal = convertedDecimal.replace('.', decimalMarker);
        }

        return convertedDecimal;
    }

    valueChange(value) {
        // Our formatting can trigger a valueChange event. If the field is disabled or readonly, 
        // don't send the event.
        if (this.disabled || this.readOnly)
            return;

        if (_.isNil(value) || value === '')
            this.onChange.emit(null);
        else if (_.isFinite(value)) {
            this.onChange.emit(this.displayAsPercentage ? 
                parseFloat((value/100).toFixed(this.numberOfDecimals)) : 
                value);
        }
    }

    applyclasses() {
        return this.editModeClasses;
    }

    formatNegatives() {
        return this.displayNegativesInRed && parseFloat(this.inputValue) < 0;
    }

    public static getComponentMask(numberOfDecimals: number): string {
        return 'separator.' + numberOfDecimals;
    }
};