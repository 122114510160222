
<ul class="menu_main">
    <li class="menu_main--item" *ngFor="let menuItem of menuItems">
        <button unity-button 
                class="link" 
                [title]="menuItem.Title ?? ''"
                [ngClass]="{ 'on': isActive(menuItem) }"
                [disabled]="menuItem.Disabled" 
                (click)="itemClick(menuItem)">
            {{ menuItem.Label }}
            <svg *ngIf="isActive(menuItem)" class="unity-icon-checkmark">
                <use xlink:href="#unity-icon-checkmark"></use>
            </svg>
        </button>
    </li>
</ul>
