<label *ngIf="label" [for]="id">
    {{label}}
    <span *ngIf="required" class="indicator-required">*</span>
    <svg *ngIf="infoText" class="unity-icon unity-icon-information" [ngbTooltip]="infoText" container="body">
        <use xlink:href="#unity-icon-information"></use>
    </svg>
</label>
<div class="unity-form-row">
    <input type="date" 
        [id]="id"
        [name]="name"
        [value]="_value"
        [min]="_minDate"
        [max]="_maxDate"
        [disabled]="disabled"
        (change)="change($event)"
        (blur)="blur()"
    />
    <svg *ngIf="state == 'invalid'" class="unity-form-status-icon unity-icon unity-icon-error-fill">
        <use xlink:href="#unity-icon-error-fill"></use>
    </svg>
    <svg *ngIf="state == 'warning'" class="unity-form-status-icon unity-icon unity-icon-warning-fill">
        <use xlink:href="#unity-icon-warning-fill"></use>
    </svg>
    <svg *ngIf="state == 'success'" class="unity-form-status-icon unity-icon unity-icon-checkmark">
        <use xlink:href="#unity-icon-checkmark"></use>
    </svg>
</div>
<div *ngIf="hintText" class="message">{{hintText}}</div>