<div [ngClass]="{ 'd-flex' : !readOnly }">
    <sm-form-field
        class="me-1 d-block"
        [model]="currencyField" 
        [readOnly]="readOnly"
        [formState]="formState"
        (onValueChanged)="onCurrencyFieldChange($event)"
        (onDetectChanges)="onDetectChanges.emit($event)">        
    </sm-form-field>

    <sm-form-field
        class="d-block"
        [model]="moneyField" 
        [readOnly]="readOnly"
        [formState]="formState"
        (onValueChanged)="onValueChanged.emit($event)"
        (onDetectChanges)="onDetectChanges.emit($event)">
    </sm-form-field>
</div>