import { Component, ElementRef } from "@angular/core";
import { SmFormService } from "../sm-form-service";
import { SmFormFieldBase } from "./sm-form-field-base.component";

@Component({
    selector: 'sm-form-field-boolean',
    templateUrl: './sm-form-field-boolean.component.html',
    outputs: ['onValueChanged', 'onFocusChanged']
})
// Checkbox (Not fully implemented)
export class SmFormFieldBooleanComponent extends SmFormFieldBase {
    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }
}