<div *ngIf="!model.Hidden" 
    [id]="'FormField:' + model.Id" 
    [ngClass]="{ 'form-group': !readOnly, 'field--readOnly': readOnly }">
    <ng-container [ngSwitch]="model.FieldType"> 
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Boolean">
            <sm-form-field-boolean 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-boolean>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.ContractDate">
            <sm-form-field-date 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-date>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Decimal">
            <sm-form-field-decimal 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-decimal>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Email">
            <sm-form-field-email 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-email>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Integer">
            <sm-form-field-integer 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-integer>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.MultiSelect">
            <sm-form-field-multi-select 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-multi-select>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Money">
            <sm-form-field-money 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-money>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Phone">
            <sm-form-field-phone 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"             
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-phone>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.RadioOptions">
            <sm-form-field-radio-options 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"              
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-radio-options>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Select">
            <sm-form-field-select 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-select>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.String">
            <sm-form-field-string 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-string>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Timestamp">
            <sm-form-field-timestamp 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-timestamp>
        </ng-container>    
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Url">
            <sm-form-field-url 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-url>
        </ng-container>
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Year">
            <sm-form-field-year 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-year>
        </ng-container>    
        <ng-container *ngSwitchCase="SM_FORM_TYPES.Zip">
            <sm-form-field-zip 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-zip>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <sm-form-field-string 
                [model]="model" 
                [readOnly]="readOnly"
                [formState]="formState"
                (onFocusChanged)="focusChanged($event)"
                (onValueChanged)="valueChanged($event)">
            </sm-form-field-string>
        </ng-container>
    </ng-container>
    <div *ngIf="!readOnly && model?.Errors?.length">
        <div *ngFor="let err of model.Errors" class="invalid-feedback d-block">
            {{err}}
        </div>
    </div>
</div>