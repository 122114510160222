import { ContractDate, DateTimeComponent, TimeSpan } from "@Core/Lib/contract-date";
import _ from "lodash";

export class ContractDateUtils
{
    public static convertToDate(contractDate: ContractDate): Date {
        if (_.isNil(contractDate) )
            return null;
        return new Date(contractDate.Year, contractDate.Month, contractDate.Day);
    }

    public static convertFromDate(date: Date): ContractDate {
        if (_.isNil(date))
            return null;
        return new ContractDate(date.getFullYear(), date.getMonth(), date.getDate());
    }

    /**
     * Returns a new ContractDate created by adding the short-hand timePeriod to the given date.
     * @param date starting date
     * @param timePeriod short-hand time period expressed as a number followed by 'd', 'm', or 'y'
     */
    public static addTimePeriod(date: ContractDate, timePeriod: string) {
        if (_.isNil(date) || _.isNil(timePeriod))
            return date;

        let unit = timePeriod.slice(-1);
        var period = Number(timePeriod.slice(0, -1));

        let dateTimePeriod: DateTimeComponent;
        if (unit === 'y')
            dateTimePeriod = DateTimeComponent.Years;
        else if (unit === 'm')
            dateTimePeriod = DateTimeComponent.Months;
        else if (unit === 'd')
            dateTimePeriod = DateTimeComponent.Days;
        else {
            console.error("Time Period is not in the correct format (1-3 numbers followed by a 'y', 'm', or 'd'");
            return date;
        }

        return date.Add(new TimeSpan(period, dateTimePeriod));
    }
}
