// Not quite an enum, but close

import _ from "lodash";


export interface LocationVM {
    Name: string;
    Abbreviation: string;
}

export class US_States {

    public static getStates(): LocationVM[] {
        return [
            { Abbreviation: 'AK', Name: 'Alaska'},
            { Abbreviation: 'AL', Name: 'Alabama'},
            { Abbreviation: 'AR', Name: 'Arkansas'},
            { Abbreviation: 'AZ', Name: 'Arizona'},
            { Abbreviation: 'CA', Name: 'California'},
            { Abbreviation: 'CO', Name: 'Colorado'},
            { Abbreviation: 'CT', Name: 'Connecticut'},
            { Abbreviation: 'DC', Name: 'District of Columbia'},
            { Abbreviation: 'DE', Name: 'Delaware'},
            { Abbreviation: 'FL', Name: 'Florida'},
            { Abbreviation: 'GA', Name: 'Georgia'},
            { Abbreviation: 'HI', Name: 'Hawaii'},
            { Abbreviation: 'IA', Name: 'Iowa'},
            { Abbreviation: 'ID', Name: 'Idaho'},
            { Abbreviation: 'IL', Name: 'Illinois'},
            { Abbreviation: 'IN', Name: 'Indiana'},
            { Abbreviation: 'KS', Name: 'Kansas'},
            { Abbreviation: 'KY', Name: 'Kentucky'},
            { Abbreviation: 'LA', Name: 'Louisiana'},
            { Abbreviation: 'MA', Name: 'Massachusetts'},
            { Abbreviation: 'MD', Name: 'Maryland'},
            { Abbreviation: 'ME', Name: 'Maine'},
            { Abbreviation: 'MI', Name: 'Michigan'},
            { Abbreviation: 'MN', Name: 'Minnesota'},
            { Abbreviation: 'MO', Name: 'Missouri'},
            { Abbreviation: 'MS', Name: 'Mississippi'},
            { Abbreviation: 'MT', Name: 'Montana'},
            { Abbreviation: 'NC', Name: 'North Carolina'},
            { Abbreviation: 'ND', Name: 'North Dakota'},
            { Abbreviation: 'NE', Name: 'Nebraska'},
            { Abbreviation: 'NH', Name: 'New Hampshire'},
            { Abbreviation: 'NJ', Name: 'New Jersey'},
            { Abbreviation: 'NM', Name: 'New Mexico'},
            { Abbreviation: 'NV', Name: 'Nevada'},
            { Abbreviation: 'NY', Name: 'New York'},
            { Abbreviation: 'OH', Name: 'Ohio'},
            { Abbreviation: 'OK', Name: 'Oklahoma'},
            { Abbreviation: 'OR', Name: 'Oregon'},
            { Abbreviation: 'PA', Name: 'Pennsylvania'},
            { Abbreviation: 'RI', Name: 'Rhode Island'},
            { Abbreviation: 'SC', Name: 'South Carolina'},
            { Abbreviation: 'SD', Name: 'South Dakota'},
            { Abbreviation: 'TN', Name: 'Tennessee'},
            { Abbreviation: 'TX', Name: 'Texas'},
            { Abbreviation: 'UT', Name: 'Utah'},
            { Abbreviation: 'VA', Name: 'Virginia'},
            { Abbreviation: 'VT', Name: 'Vermont'},
            { Abbreviation: 'WA', Name: 'Washington'},
            { Abbreviation: 'WI', Name: 'Wisconsin'},
            { Abbreviation: 'WV', Name: 'West Virginia'},
            { Abbreviation: 'WY', Name: 'Wyoming'}
        ];
    } 
}

export class CAN_Province_Territories {

    public static getProvincesAndTerritories(): LocationVM[] {
        return [
            { Abbreviation: 'AB', Name: 'Alberta'},
            { Abbreviation: 'BC', Name: 'British Columbia'},
            { Abbreviation: 'MB', Name: 'Manitoba'},
            { Abbreviation: 'NB', Name: 'New Brunswick'},
            { Abbreviation: 'NL', Name: 'Newfoundland and Labrador'},
            { Abbreviation: 'NS', Name: 'Nova Scotia'},
            { Abbreviation: 'ON', Name: 'Ontario'},
            { Abbreviation: 'PE', Name: 'Prince Edward Island'},
            { Abbreviation: 'QC', Name: 'Quebec'},
            { Abbreviation: 'SK', Name: 'Saskatchewan'},
        ];
    } 
}