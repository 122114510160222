<section *ngIf="!model.Hidden"
    #section
    class="sm-form-section"
    [id]="'FormSection:' + model.Id"  
    [ngClass]="{ 'section-readOnly': readOnly }">
    <div class="section--header">
        <div class="top">
            <div *ngIf="hasError" class="section--status_icon">
                <svg class="unity-icon unity-icon-warning">
                    <use xlink:href="#unity-icon-warning"></use>
                </svg>
            </div>
            <h2>
                {{model.Label}} 
                <span *ngIf="model.Repeatable">
                    ({{ repeatableInstanceCount }})
                </span>
                <button unity-button *ngIf="!readOnly && model.Repeatable && model.Access != 'PreventAddAndDelete'" 
                    [disabled]="model.ParentIsDeleted"
                    [id]="'FormSection:' + model.Id + ':AddSection'"  
                    class="ghost add" 
                    (click)="onSectionAdded.emit(this.model)">
                    <svg class="unity-icon-plus">
                        <use xlink:href="#unity-icon-plus"></use>
                    </svg>
                </button>
            </h2>
            <button unity-button *ngIf="!hideSingleSectionArrow && !model.ToggledToHide" [id]="'FormSectionCollapse:' + model.Id"  
                class="icon-only toggle_all" title="Toggle all nested sections"
                (click)="model.ToggledToHide = true">
                <svg class="unity-icon unity-icon-chevron-up">
                    <use xlink:href="#unity-icon-chevron-up"></use>
                </svg>
            </button>
            <button unity-button *ngIf="!hideSingleSectionArrow && model.ToggledToHide" [id]="'FormSectionExpand:' + model.Id"
                class="icon-only toggle_all" title="Toggle all nested sections"
                (click)="model.ToggledToHide = false">
                <svg class="unity-icon-chevron-down">
                    <use xlink:href="#unity-icon-chevron-down"></use>
                </svg>
            </button>
        </div>
        <div class="message" *ngIf="!readOnly">
            <small *ngIf="minInstanceError" class="text-danger">minimum of {{model.MinRepeatableInstances}} required</small>
            <small *ngIf="maxInstanceError" class="text-danger">maximum of {{model.MaxRepeatableInstances}} allowed</small>
        </div>
        <p *ngIf="model.Description">{{ model.Description }}</p>
    </div>
    <div class="section--body" *ngIf="!model.ToggledToHide">
        <ng-container *ngIf="!model.Repeatable">
            <div *ngFor="let item of model.Items">
                <ng-container *ngIf="item.ElementType === 'Section'">
                    <sm-form-section 
                        [model]="item" 
                        [readOnly]="readOnly"
                        [hideSingleSectionArrow]="hideSingleSubSectionArrow"
                        [formState]="formState"
                        (onValueChanged)="onValueChanged.emit($event)"
                        (onSectionAdded)="onSectionAdded.emit($event)"
                        (onSectionDeleted)="onSectionDeleted.emit($event)"
                        (onDetectChanges)="updateStatuses()">
                    </sm-form-section>
                </ng-container>
                <ng-container *ngIf="item.ElementType === 'Field'">
                    <sm-form-field 
                        [model]="item" 
                        [readOnly]="readOnly"
                        [formState]="formState"
                        (onValueChanged)="onValueChanged.emit($event)"
                        (onDetectChanges)="updateStatuses()">
                    </sm-form-field>
                </ng-container>
                <ng-container *ngIf="item.ElementType === 'StaticText'">
                    <sm-form-static-element 
                        [model]="item">
                    </sm-form-static-element>
                </ng-container>
                <ng-container *ngIf="item.ElementType === 'Row'">
                    <sm-form-row
                        [model]="item" 
                        [readOnly]="readOnly"
                        [formState]="formState"
                        (onValueChanged)="onValueChanged.emit($event)"
                        (onDetectChanges)="updateStatuses()">
                    </sm-form-row>
                </ng-container>
            </div> 
        </ng-container>
        <ng-container *ngIf="model.Repeatable">
            <table>
                <thead>
                    <tr>
                        <th *ngFor="let visibleLabel of model.RepeatableVisibleLabels; let i=index">
                            <div [ngClass]="{ 'first-column' : i == 0 }">
                                {{visibleLabel}}
                                <button unity-button class="icon-only" [ngClass]="{ 'on' : currentSortLabel == visibleLabel }" (click)="onChangeSort(visibleLabel)">
                                    <ng-container *ngIf="currentSortLabel == visibleLabel" >
                                        <svg *ngIf="currentSortOrder == 'asc'" class="unity-icon-sort-asc">
                                            <use xlink:href="#unity-icon-sort-asc"></use>
                                        </svg>
                                        <svg *ngIf="currentSortOrder == 'desc'" class="unity-icon-sort-desc">
                                            <use xlink:href="#unity-icon-sort-des"></use>
                                        </svg>
                                    </ng-container>
                                    <ng-container *ngIf="currentSortLabel != visibleLabel">
                                        <svg class="unity-icon-sort">
                                            <use xlink:href="#unity-icon-sort"></use>
                                        </svg>
                                    </ng-container>
                                </button>
                            </div>
                        </th>
                        <th>
                            <div>
                                <button unity-button *ngIf="instancesHasChildSection && !allInstancesToggledToHide" 
                                    [id]="'FormSection:' + model.Id + ':ToggleAllToHide'"  
                                    class="icon-only toggle_all" 
                                    title="Toggle all sections"
                                    (click)="toggleAllInstances(true)">
                                    <svg class="icon-arrow-up-double">
                                        <use xlink:href="#icon-arrow-up-double"></use>
                                    </svg>
                                </button>
                                <button unity-button *ngIf="instancesHasChildSection && allInstancesToggledToHide" 
                                    [id]="'FormSection:' + model.Id + ':ToggleAllToShow'"  
                                    class="icon-only toggle_all" title="Toggle all sections"
                                    (click)="toggleAllInstances(false)">
                                    <svg class="icon-arrow-down-double">
                                        <use xlink:href="#icon-arrow-down-double"></use>
                                    </svg>
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!model.Instances?.length" class="zero">
                        <td [attr.colspan]="model.RepeatableVisibleLabels.length + 1">
                            <div><i>No {{model.Label}} records</i></div>
                        </td>
                    </tr>
                    <ng-container *ngFor="let instance of model.Instances">
                        <sm-form-section-instance
                            [model]="instance"
                            [readOnly]="readOnly"
                            [labels]="model.RepeatableVisibleLabels"
                            [parentId]="model.Id"
                            [formState]="formState"
                            (onValueChanged)="onValueChanged.emit($event)"
                            (onSectionAdded)="onSectionAdded.emit($event)"
                            (onSectionDeleted)="onSectionDeleted.emit($event)"
                            (onDetectChanges)="updateStatuses()"
                        >
                        </sm-form-section-instance>
                    </ng-container>        
                </tbody>
            </table>  
        </ng-container>                   
    </div>
</section>