import { Component, EventEmitter, Input, Output } from "@angular/core";
import _ from "lodash";
import { NgxMaskService } from "ngx-mask";

@Component({
    selector: 'sm-input-zipcode',
    templateUrl: './sm-input-zipcode.component.html'
})
export class SmInputZipcodeComponent {
    @Input() public id: string;
    @Input() public editModeClasses: string[];
    @Input() public value: string;
    @Input() public disabled: boolean;
    @Input() public readOnly: boolean;

    @Output() public focusin: EventEmitter<any> = new EventEmitter();
    @Output() public focusout: EventEmitter<any> = new EventEmitter();
    @Output() public onChange: EventEmitter<any> = new EventEmitter();

    constructor(public maskService: NgxMaskService) {}

    public componentMask: string = SmInputZipcodeComponent.getComponentMask();

    public static formatZipcode(value: any, maskService: NgxMaskService): string {        
        const formattedZipcode = maskService.applyMask(value, SmInputZipcodeComponent.getComponentMask());
        return formattedZipcode;
    }

    valueChange(value) {
        // Our formatting can trigger a valueChange event. If the field is disabled or readonly, 
        // don't send the event.
        if (this.disabled || this.readOnly)
            return;
        
        this.onChange.emit(value);
    }

    getClasses() {
        return this.editModeClasses;
    }

    public static getComponentMask(): string {
        return 'AAAAA-AAAA';
    }
};