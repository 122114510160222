import { ChangeDetectionStrategy, Component, ElementRef } from "@angular/core";
import { SmFormFieldBase } from "./sm-form-field-base.component";
import { SmFormService } from "../sm-form-service";

@Component({
    selector: 'sm-form-field-string',
    templateUrl: './sm-form-field-string.component.html',
    outputs: ['onValueChanged', 'onFocusChanged'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmFormFieldStringComponent extends SmFormFieldBase {

    constructor(protected service: SmFormService, protected element: ElementRef) {
        super(service, element);
    }

    protected override putFocusIntoInput(): void {
        if (this.model.Options?.MultiLineEntry) {
            this.element.nativeElement.querySelector('textarea').focus()
        } else {
            super.putFocusIntoInput();
        }
    }
}