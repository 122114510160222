import { Component, ElementRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '@Services/auth-service';
import { DragDropService } from '@Services/drag-drop-service';
import { AlertsHub } from '@Services/Hubs/AlertsHub';

@Component({
    selector: 'conf',
    templateUrl: './conf.component.html',
    styleUrls: ['./conf.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ConfComponent {
    constructor(public authService: AuthService,
        private elementRef: ElementRef,
        private dragDropService: DragDropService,
        private alertsHub: AlertsHub) {
        this.authService.spaName = 'conf';
    }

    ngOnInit() {
        this.alertsHub.startConnection();
    }

    onDragOver(event: DragEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.dragDropService.setDragging(true);
    }

    onDragLeave(event: DragEvent): void {
        if (!event.relatedTarget || !this.elementRef.nativeElement.contains(event.relatedTarget)) {
            this.dragDropService.setDragging(false);
        }
    }
};
