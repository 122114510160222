

<ng-container *ngIf="!readOnly">
    <sm-form-field-label [model]="model" (revertToPreviousValue)="onRevertToPreviousValue()">
    </sm-form-field-label>
    <div class="prefix_suffix">
        <div class="prefix_suffix_label" *ngIf="model.Prefix">{{model.Prefix}}</div>
        <unity-control-date
            class="unity-form-group date m-0"
            [ngClass]="getInputCssClass()" 
            [id]="getInputIdOrName()"
            [name]="getInputIdOrName()"
            [state]="model.Disabled ? 'disabled' : null"
            [disabled]="model.Disabled"
            [value]="valueAsDate"
            (onChange)="change($event)">
        </unity-control-date>
        <div class="prefix_suffix_label" *ngIf="model.Suffix">{{model.Suffix}}</div>
    </div>        
    <sm-form-previous-value
        *ngIf="fieldHasPreviousValue()"
        [model]="model"
        [formState]="formState"
        (revertToPreviousValue)="onRevertToPreviousValue($event)">
    </sm-form-previous-value>
</ng-container>
<ng-container *ngIf="readOnly">
    <sm-info-stack [model]="getInfoStack()">
    </sm-info-stack>
</ng-container>