<div class="modal-header">
    <h3>Edit {{getTitle()}}</h3>
    <button unity-button class="icon-only large close" id="SectionModalCloseButton" aria-label="Close" (click)="activeModal.dismiss()">
        <svg class="unity-icon-times">
            <use xlink:href="#unity-icon-times"></use>
        </svg>
    </button>
</div>

<div class="modal-body">
    <div *ngFor="let element of elements">
        <sm-form-row 
            *ngIf="element.ElementType == 'Row'"
            [model]="element" 
            [readOnly]="false" 
            [formState]="formState"
            (onValueChanged)="onValueChanged.emit($event)"
            (onDetectChanges)="onDetectChanges.emit()">
        </sm-form-row>
        <sm-form-field 
            *ngIf="element.ElementType == 'Field'"
            [model]="element" 
            [readOnly]="false" 
            [formState]="formState"
            (onValueChanged)="onValueChanged.emit($event)"
            (onDetectChanges)="onDetectChanges.emit()">
        </sm-form-field>
    </div> 
</div>

<div class="modal-footer">
    <button unity-button type="button" id="SectionModalDoneButton" class="primary" (click)="activeModal.dismiss()">Done</button>
</div>
