<div class="modal-header">
    <h3>Edit User</h3>
</div>

<div class="modal-body">
    <div class="form-group">
        <label>First Name</label>
        <input class="form-control" [(ngModel)]="user.FirstName" />
    </div>
    <div class="form-group">
        <label>Last Name</label>
        <input class="form-control" [(ngModel)]="user.LastName" />
    </div>
    <div class="form-group">
        <label>Display Name</label>
        <input class="form-control" [(ngModel)]="user.DisplayName" />
    </div>
    <div class="form-group">
        <label>Initials</label>
        <input class="form-control" [(ngModel)]="user.Initials" />
    </div>
    <div class="form-group">
        <label>Title</label>
        <input class="form-control" [(ngModel)]="user.Title" />
    </div>
    <div class="form-group">
        <label>Phone</label>
        <input class="form-control" [(ngModel)]="user.Phone" />
    </div>
</div>

<div class="modal-footer">
    <button unity-button class="primary" [disabled]="!user.DisplayName" (click)="updateUserInfo()">Update User</button>
    <button unity-button class="tertiary" (click)="close()">Cancel</button>
</div>