import { Component, Input } from '@angular/core';

@Component({
    selector: 'unity-loading-indicator',
    templateUrl: './unity-loading-indicator.component.html',
    styleUrls: ['./unity-loading-indicator.component.scss']
})
export class UnityLoadingIndicatorComponent {
    @Input() type: string = "circular";
    @Input() size: UnityLoadingIndicatorSize = UnityLoadingIndicatorSize.Large;
    @Input() color: UnityLoadingIndicatorColor = UnityLoadingIndicatorColor.UnityGradient;
    @Input() display: UnityLoadingIndicatorDisplay = UnityLoadingIndicatorDisplay.Contained;
};

export enum UnityLoadingIndicatorColor {
    UnityGradient = '',
    White = 'white',
    Black = 'black',
    Gray = 'gray',
    Magenta = 'magenta',
}

export enum UnityLoadingIndicatorSize {
    Large = '',
    Small = 'small'
}

export enum UnityLoadingIndicatorDisplay {
    Contained = 'contained',
    Overlay = 'overlay',
    FullScreen = 'full-screen'
}