<ol class="unity-stepper" [ngClass]="layoutDirection + ' ' + style">
    <li *ngFor="let step of steps; let i = index" 
        [ngClass]="{ 'complete': i < currentStep, 'current': i == currentStep, 'disabled': !canClickStep(i) }"
        (click)="canClickStep(i) && goToStep.emit(i)">
        <div class="step">
            <svg *ngIf="i < currentStep" class="unity-form-status-icon unity-icon unity-icon-checkmark">
                <use xlink:href="#unity-icon-checkmark"></use>
            </svg>
        </div>
        <div class="info">
            <div class="title">{{step.Name}}</div>
            <div class="description">{{step.Description}}</div>
        </div>
    </li>
</ol>